import React from "react";
import { Select, MenuItem, Typography } from "@mui/material";

const ResolutionDropdown = ({
  sortedNetworkTags,
  currentTab,
  selectedResolution,
  setSelectedResolution,
}) => {
  return (
    <Select
      displayEmpty
      renderValue={(value) => (
        <Typography color='primary'>{value ? value : "Resolutions"}</Typography>
      )}
      sx={{ ml: 1, minWidth: 150, maxHeight: 35 }}
      value={selectedResolution || ""}
      onChange={(e) => setSelectedResolution(e.target.value)}
    >
      {sortedNetworkTags[currentTab]?.map((resolution, index) => (
        <MenuItem key={index} value={resolution}>
          <Typography color='primary'>{resolution}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ResolutionDropdown;
