import React, { useContext, useState } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import { AuthContext } from "../context/authContext";

const CreateSolanaAccount = () => {
  const [loading, setLoading] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    website: "",
  });

  const { currentUser } = useContext(AuthContext);

  const handleChange = (e) => {
    setClientDetails({
      ...clientDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Call API to create Solana account
      const accountResponse = await axios.post("/api/tokens/create-account", {
        clientId: currentUser.clientIdUsers, // Pass client ID here
      });
      const { publicKey, secretKey } = accountResponse.data.account;

      // Get existing client to fetch type
      const clientResponse = await axios.get(
        `/api/clients/${currentUser.clientIdUsers}`
      );
      const clientType = clientResponse.data.type;

      // Save client details along with the Solana account info in the database
      await axios.post("/api/clients", {
        clientId: currentUser.clientIdUsers,
        name: clientDetails.name,
        address: clientDetails.address,
        phone: clientDetails.phone,
        email: clientDetails.email,
        website: clientDetails.website,
        data: {
          wallet: {
            solana: {
              solanaPublicKey: publicKey,
              solanaSecretKey: secretKey,
            },
          },
        },
        type: clientType, // Use the type from the existing client
      });

      setLoading(false);
      setAccountCreated(true);

      // Reset the form after 1.5 seconds
      setTimeout(() => {
        setAccountCreated(false);
        setClientDetails({
          name: "",
          address: "",
          phone: "",
          email: "",
          website: "",
        });
      }, 1500);
    } catch (error) {
      console.error(
        "Error creating Solana account or saving client details:",
        error
      );
      setLoading(false);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
          minHeight: 120,
        }}
      >
        <Card>
          <CardContent>
            <Typography variant='h5' component='div' gutterBottom>
              Create New Solana Account
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : accountCreated ? (
              <Typography variant='h6' color='success.main'>
                Account created successfully!
              </Typography>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  label='Name'
                  name='name'
                  value={clientDetails.name}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  required
                />
                <TextField
                  label='Address'
                  name='address'
                  value={clientDetails.address}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  required
                />
                <TextField
                  label='Phone'
                  name='phone'
                  value={clientDetails.phone}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  required
                />
                <TextField
                  label='Email'
                  name='email'
                  value={clientDetails.email}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  required
                />
                <TextField
                  label='Website'
                  name='website'
                  value={clientDetails.website}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                />
                <Box sx={{ mt: 2, textAlign: "right" }}>
                  <Button type='submit' variant='contained' color='primary'>
                    Create Account
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default CreateSolanaAccount;
