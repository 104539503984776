import React, { useState } from "react";
import { Box, Button, ImageList, ImageListItem } from "@mui/material";
import { useSelector } from "react-redux";
import { ASSETS_URL } from "../../../config";

const BackgroundsSelector = ({ selectedBrand, values, setValues }) => {
  const allBackgrounds = useSelector((store) => store.backgrounds);
  const [selectedBackground, setSelectedBackground] = useState(null);

  const filteredBackgrounds = allBackgrounds.filter(
    (background) => background.brandId === selectedBrand
  );

  const handleBackgroundClick = (fileName) => {
    const selected = allBackgrounds.find(
      (background) => background.data.fileName === fileName
    );

    if (!selected) return;

    const { positive, negative } = selected.data.flags || {};

    setValues((prevValues) => {
      const updatedBackground = { ...prevValues.positions.background };

      Object.keys(updatedBackground).forEach((resolutionKey) => {
        updatedBackground[resolutionKey] = {
          ...updatedBackground[resolutionKey],
          imageUrl: fileName,
          positive,
          negative,
          visible: true,
          // width,
          // height,
          // zIndex: 1,
          // Set other properties like x, y, width, height if needed
        };
      });

      return {
        ...prevValues,
        positions: {
          ...prevValues.positions,
          background: updatedBackground,
        },
      };
    });

    setSelectedBackground(selected);
  };

  const resetSelection = () => {
    setSelectedBackground(null);
  };

  return (
    <Box sx={{ width: "100%", height: 450, overflowY: "scroll" }}>
      {selectedBackground ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            cursor: "pointer",
            // mt: 10,
          }}
        >
          <img
            src={`${ASSETS_URL}/${selectedBackground.data.fileName}`}
            alt={selectedBackground.data.fileName}
            loading='lazy'
            style={{
              maxWidth: "80%",
              maxHeight: "80%",
              width: "auto",
              height: "auto",
              objectFit: "contain",
              margin: "auto",
            }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Button onClick={resetSelection} variant='outlined'>
              Select Another Background
            </Button>
          </Box>
        </Box>
      ) : (
        <ImageList variant='quilted' cols={5} gap={10} rowHeight={164}>
          {filteredBackgrounds.map((background) => (
            <ImageListItem
              key={background.id}
              onClick={() => handleBackgroundClick(background.data.fileName)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <img
                src={`${ASSETS_URL}/${background.data.fileName}`}
                alt={background.data.fileName}
                loading='lazy'
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
};

export default BackgroundsSelector;
