import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ASSETS_URL } from "../config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QueryBuilderOutlined from "@mui/icons-material/QueryBuilderOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import GridViewIcon from "@mui/icons-material/GridView";
import SelectCategory from "./SelectCategory";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

function ClientTable({
  rows,
  setFilter,
  title,
  actionsDetailButton,
  onCategoryChange,
  setIsListView,
  cardColors,
  onAdClick,
}) {
  const [selected, setSelected] = useState("all");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  // Helper function to get the list of industries
  const getIndustries = () => {
    return [
      "Agriculture",
      "Automotive",
      "Banking",
      "Biotechnology",
      "Construction",
      "Consumer Goods",
      "Education",
      "Energy",
      "Entertainment",
      "Fashion",
      "Finance",
      "Food & Beverage",
      "Healthcare",
      "Hospitality",
      "Information Technology",
      "Insurance",
      "Manufacturing",
      "Media",
      "Mining",
      "Pharmaceuticals",
      "Real Estate",
      "Retail",
      "Telecommunications",
      "Transportation",
      "Utilities",
    ];
  };

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "approved":
        return (
          <CheckCircleIcon
            fontSize='small'
            sx={{ color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            fontSize='small'
            sx={{ color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
        return (
          <QueryBuilderOutlined
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      default:
        return (
          <QueryBuilderOutlined
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
    }
  };

  const filteredRows = selectedIndustry
    ? rows.filter((row) => row.data?.industry === selectedIndustry)
    : rows;

  return (
    <Card
      sx={{
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", m: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: 2,
            mb: 2,
            borderColor: theme.palette.primary.tableBorderColor,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {title === "Brands" && (
              <FormControl sx={{ mr: 2, minWidth: 120 }}>
                <InputLabel>Industry</InputLabel>
                <Select
                  value={selectedIndustry}
                  onChange={handleIndustryChange}
                  label='Industry'
                >
                  <MenuItem value=''>
                    <em>All</em>
                  </MenuItem>
                  {getIndustries().map((industry) => (
                    <MenuItem key={industry} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: 18,
                color: theme.palette.primary.tableLabelMain,
                position: "relative",
                display: "inline-block",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom:
                    title === "Recent Ads" ||
                    title === "Your Recent Ads" ||
                    title === "Ads"
                      ? "-4px"
                      : "-2px",
                  height: "2px",
                  width: "100%",
                  backgroundImage:
                    "linear-gradient(90deg, #F37979 0%, #A06EAF 100%)",
                },
              }}
            >
              {title}
            </Box>
          </Box>

          {title === "Ads" && (
            <Box>
              <SelectCategory
                cardColors={cardColors}
                sx={{ ml: 20 }}
                onCategoryChange={onCategoryChange}
              />
            </Box>
          )}

          {(title === "Ads" ||
            title === "Recent Ads" ||
            title === "Your Recent Ads") && (
            <Box sx={{ display: "flex", gap: 1 }}>
              {title === "Ads" && (
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: cardColors.item1,
                    mt: 1,
                  }}
                >
                  STATUS:
                </Typography>
              )}
              <Button
                onClick={() => {
                  setFilter("all");
                  setSelected("all");
                }}
                variant='button'
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color:
                    selected === "all" ? theme.typography.fontColor : "#9E9E9E",
                }}
              >
                All
              </Button>
              <Button
                onClick={() => {
                  setFilter("pending");
                  setSelected("pending");
                }}
                variant='button'
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color:
                    selected === "pending"
                      ? theme.typography.fontColor
                      : "#9E9E9E",
                }}
              >
                Pending
              </Button>
              <Button
                onClick={() => {
                  setFilter("approved");
                  setSelected("approved");
                }}
                variant='button'
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color:
                    selected === "approved"
                      ? theme.typography.fontColor
                      : "#9E9E9E",
                }}
              >
                Approved
              </Button>
              <Button
                onClick={() => {
                  setFilter("rejected");
                  setSelected("rejected");
                }}
                variant='button'
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color:
                    selected === "rejected"
                      ? theme.typography.fontColor
                      : "#9E9E9E",
                }}
              >
                Rejected
              </Button>
              <IconButton
                sx={{ maxHeight: 30, pt: 1 }}
                onClick={() => setIsListView(false)}
              >
                {title === "Ads" && <GridViewIcon />}
              </IconButton>
            </Box>
          )}
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 650, width: "100%" }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    color: theme.palette.primary.tableLabel,
                    fontWeight: 600,
                    fontSize: 12,
                    borderBottom: "1px solid #F0F0F0",
                    pt: 3,
                    pb: 0.5,
                    pl: 0,
                    width: "10%",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline",
                      "&:after": {
                        content: '""',
                        position: "absolute",
                        bottom: "-10.2px",
                        left: 0,
                        width: "100%",
                        height: "2px",
                        background: theme.palette.primary.tableLabel,
                      },
                    }}
                  >
                    ID
                  </Box>
                </TableCell>
                {(() => {
                  const idColumnWidth = 10;
                  let headerArray = [];

                  if (title === "Clients") {
                    headerArray = [
                      "CLIENT NAME",
                      "ADDRESS",
                      "EMAIL",
                      "PHONE",
                      "CREATED",
                      "UPDATED",
                      "WEBSITE",
                      "ACTIONS",
                    ];
                  } else if (title === "Brands") {
                    headerArray = [
                      "BRAND NAME",
                      "LOGO",
                      "DESCRIPTION",
                      "INDUSTRY",
                      "CREATED",
                      "UPDATED",
                      "ACTIONS",
                    ];
                  } else if (title === "Users") {
                    headerArray = [
                      "FIRST NAME",
                      "LAST NAME",
                      "EMAIL",
                      "USERNAME",
                      "ROLE",
                      "CLIENT",
                      "CREATED",
                      "UPDATED",
                      "ACTIONS",
                    ];
                  } else if (title === "Categories") {
                    headerArray = [
                      "NAME",
                      "DESCRIPTION",
                      "CREATED",
                      "UPDATED",
                      "ACTIONS",
                    ];
                  } else if (
                    title === "Recent Ads" ||
                    title === "Your Recent Ads"
                  ) {
                    headerArray = [
                      "NAME",
                      "BRAND",
                      "CREATED BY",
                      "CREATED",
                      "UPDATED",
                      "STATUS",
                    ];
                  } else if (title === "Ads") {
                    headerArray = [
                      "NAME",
                      "BRAND",
                      "CREATED BY",
                      "CREATED",
                      "UPDATED",
                      "STATUS",
                      "ACTIONS",
                    ];
                  }

                  return headerArray.map((headCell, index) => (
                    <TableCell
                      key={`${headCell}-${index}`}
                      align='left'
                      sx={{
                        color: theme.palette.primary.tableLabel,
                        fontWeight: 600,
                        fontSize: 12,
                        borderBottom: "1px solid #F0F0F0",
                        pt: 3,
                        pb: 0.5,
                        pl: 0,
                        width: `${(100 - idColumnWidth) / headerArray.length}%`,
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline",
                          "&:after": {
                            content: '""',
                            position: "absolute",
                            bottom: "-10.2px",
                            left: 0,
                            width: "100%",
                            height: "2px",
                            background: theme.palette.primary.tableLabel,
                          },
                        }}
                      >
                        {headCell}
                      </Box>
                    </TableCell>
                  ));
                })()}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: 65,
                  }}
                >
                  <TableCell
                    align='left'
                    sx={{
                      pl: 0,
                      borderBottom: "1px solid #F0F0F0",
                      width: "4%",
                      color: theme.typography.fontColor,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {row.id}
                  </TableCell>
                  {(title === "Clients"
                    ? [
                        row.name,
                        row.address,
                        row.email,
                        row.phone,
                        row.created,
                        row.updated,
                        row.website,
                        actionsDetailButton(row),
                      ]
                    : title === "Brands"
                    ? [
                        row.name,
                        row.brandLogo,
                        row.description,
                        row.data?.industry || "", // Ensure industry is shown
                        row.created,
                        row.updated,
                        actionsDetailButton(row),
                      ]
                    : title === "Users"
                    ? [
                        row.firstName,
                        row.lastName,
                        row.email,
                        row.username,
                        row.role,
                        row.clientName,
                        row.created,
                        row.updated,
                        actionsDetailButton(row),
                      ]
                    : title === "Categories"
                    ? [
                        row.name,
                        row.description,
                        row.created,
                        row.updated,
                        actionsDetailButton(row),
                      ]
                    : title === "Recent Ads" || title === "Your Recent Ads"
                    ? [
                        row.title,
                        row.brand,
                        row.createdBy,
                        row.created,
                        row.updated,
                        { status: row.status, approverName: row?.approverName },
                      ]
                    : title === "Ads"
                    ? [
                        row.title,
                        row.brand,
                        row.createdBy,
                        row.createdAt,
                        row.updatedAt,
                        { status: row.status, approverName: row?.approverName },
                        actionsDetailButton(row),
                      ]
                    : []
                  ).map((cellData, index) => (
                    <TableCell
                      key={index}
                      align='left'
                      sx={{
                        pl: 0,
                        borderBottom: "1px solid #F0F0F0",
                        width:
                          (title === "Ads" ||
                            title === "Recent Ads" ||
                            title === "Your Recent Ads") &&
                          index === 5
                            ? "16.6%"
                            : "10%",
                        color: theme.typography.fontColor,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {(title === "Recent Ads" ||
                        title === "Your Recent Ads" ||
                        title === "Ads") &&
                      index === 5 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {typeof cellData === "object" ? (
                            <>
                              {getStatusIcon(cellData.status)}
                              {cellData.status === "pending"
                                ? `Waiting for ${cellData.approverName}`
                                : cellData.status}
                            </>
                          ) : (
                            <>
                              {getStatusIcon(cellData)}
                              {cellData}
                            </>
                          )}
                        </Box>
                      ) : index === 1 && title === "Brands" ? (
                        <img
                          style={{ maxHeight: 30 }}
                          src={`${ASSETS_URL}/${cellData}`}
                          alt={row.name}
                        />
                      ) : (
                        cellData
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(title === "Ads" || title === "Recent Ads") && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ color: "white" }}
              onClick={() => navigate("/ads")}
            >
              VIEW ALL
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default ClientTable;
