import { createSlice } from "@reduxjs/toolkit";

const adsDataSlice = createSlice({
  name: "adsData",
  initialState: {},

  reducers: {
    setAdsData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAdsData } = adsDataSlice.actions;
export default adsDataSlice.reducer;
