import { useDispatch } from "react-redux";
import axios from "axios";
import { BRANDS_URL } from "../../../config";

export const useDeleteRequest = () => {
  const dispatch = useDispatch();

  const handleDeleteRequest = (
    url,
    imageIds,
    dispatchFunc,
    setSelectedFunc,
    deleteManyFunc,
    brandId
  ) => {
    if (Array.isArray(imageIds)) {
      const deleteRequests = imageIds.map(async (imageId) => {
        try {
          const response = await axios.delete(
            `${BRANDS_URL}/${url}/${imageId}`,
            { data: { brandId } }
          );
          if (response.status === 200) {
            setSelectedFunc((prev) => ({ ...prev, [imageId]: false }));
          } else {
            console.error("Error: Unexpected response status", response.status);
            alert(
              `Failed to delete image: Unexpected response status ${response.status}`
            );
          }
        } catch (error) {
          console.error(
            "Error deleting image with ID",
            imageId,
            ":",
            error.message
          );
          alert(`Failed to delete image: ${error.message}`);
        }
      });

      Promise.all(deleteRequests)
        .then(() => {
          dispatch(deleteManyFunc({ ids: imageIds }));
        })
        .catch((error) => {});
    } else {
      axios
        .delete(`${BRANDS_URL}/${url}/${imageIds}`, { data: { brandId } })
        .then((response) => {
          if (response.status === 200) {
            dispatch(dispatchFunc({ id: imageIds }));
            setSelectedFunc((prev) => ({ ...prev, [imageIds]: false }));
          } else {
            alert();
          }
        })
        .catch((error) => {
          alert(`Failed to delete image: ${error.message}`);
        });
    }
  };

  return handleDeleteRequest;
};
