import React, { useState, useEffect, useContext } from "react";
import {
  DialogContent,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { keyframes } from "styled-components";
import { AI_URL } from "../../../config";
import axiosInstance from "../../../services/axiosInstance";
import { AuthContext } from "../../../context/authContext";

const TextGenerationComponent = ({
  adData,
  adiData,
  setIsLoading,
  selectedLanguage,
  setValues,
  selectedProduct,
}) => {
  const currentUser = useContext(AuthContext);

  const [loading, setLoading] = useState({
    headline: false,
    subHeadline: false,
    disclaimer: false,
  });
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [responseText, setResponseText] = useState({
    headline: "",
    subHeadline: "",
    disclaimer: "",
  });

  useEffect(() => {
    const generateText = async () => {
      if (
        selectedLanguage &&
        adData.brand &&
        adData.category &&
        selectedProduct?.data?.productName &&
        selectedProduct?.data?.productDescription
      ) {
        setIsLoading(true); // Start loading
        try {
          await handleGenerateText(
            selectedLanguage,
            adData.brand,
            adData.category,
            selectedProduct?.data?.productName,
            selectedProduct?.data?.productDescription
          );
        } catch (error) {
          console.error("Error during text generation:", error);
        }
        setIsLoading(false); // End loading
      }
    };

    generateText();
  }, [
    selectedLanguage,
    adData.brand,
    adData.category,
    selectedProduct?.productName,
    selectedProduct?.data?.productDescription,
  ]);

  const handleGenerateText = async (textType) => {
    console.log("handleGenerateText called with textType:", textType);

    let missingData = [];
    if (!selectedLanguage) missingData.push("language");
    if (!adData.brand) missingData.push("brand");
    if (!adData.category) missingData.push("category");
    if (!selectedProduct?.data?.productName) missingData.push("product name");
    if (!selectedProduct?.data?.productDescription)
      missingData.push("product description");

    if (missingData.length > 0) {
      console.error(`Required data is missing: ${missingData.join(", ")}`);
      return;
    }

    const validTextTypes = ["headline", "subHeadline", "disclaimer"];
    const isTextTypeValid = validTextTypes.includes(textType);

    // Update loading state based on textType
    if (isTextTypeValid) {
      setLoading((prevLoading) => {
        return { ...prevLoading, [textType]: true };
      });
    } else {
      setIsLoading(true); // Use the existing isLoading state for other cases
    }

    const requestData = {
      clientId: currentUser.currentUser.clientIdUsers, // Include clientId here
      language: selectedLanguage,
      brand: adData.brand,
      category: adData.category,
      productName: selectedProduct?.data?.productName,
      productDescription: selectedProduct?.data?.productDescription,
      textType: isTextTypeValid ? textType : undefined,
    };

    try {
      const response = await axiosInstance.post(
        `${AI_URL}/generate-ad-text`,
        requestData
      );

      if (response.data) {
        // Update responseText state
        let updatedResponseText = { ...responseText };

        if (isTextTypeValid && response.data[textType]) {
          updatedResponseText[textType] = response.data[textType]
            .replace(
              `${textType.charAt(0).toUpperCase() + textType.slice(1)}: `,
              ""
            )
            .replace(/\"/g, "");
        } else {
          updatedResponseText = {
            headline: response.data.headline
              ? response.data.headline.replace(/\"/g, "")
              : responseText.headline,
            subHeadline: response.data.subHeadline
              ? response.data.subHeadline.replace(/\"/g, "")
              : responseText.subHeadline,
            disclaimer: response.data.disclaimer
              ? response.data.disclaimer.replace(/\"/g, "")
              : responseText.disclaimer,
          };
        }

        setResponseText(updatedResponseText);

        // Update positions in the values state
        setValues((prevValues) => {
          let updatedPositions = updateTextTypePositions(
            prevValues.positions,
            updatedResponseText,
            validTextTypes
          );

          return { ...prevValues, positions: updatedPositions };
        });

        setShowResponseDialog(true);
      } else {
        console.error("Unexpected response format for generating text");
      }
    } catch (error) {
      console.error("Error generating text:", error);
    } finally {
      // Reset the appropriate loading state
      if (isTextTypeValid) {
        setLoading((prevLoading) => {
          return { ...prevLoading, [textType]: false };
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  function updateTextTypePositions(
    existingPositions,
    newTexts,
    validTextTypes
  ) {
    let updatedPositions = { ...existingPositions };

    validTextTypes.forEach((textType) => {
      if (updatedPositions[textType]) {
        updatedPositions[textType] = Object.keys(
          updatedPositions[textType]
        ).reduce((acc, resolution) => {
          acc[resolution] = {
            ...updatedPositions[textType][resolution],
            [textType]: newTexts[textType],
          };
          return acc;
        }, {});
      }
    });

    return updatedPositions;
  }

  // function formatResponseText(data, textType) {
  //   const formattedData = {
  //     headline: data.headline ? data.headline.replace("Headline: ", "") : "",
  //     subHeadline: data.subHeadline
  //       ? data.subHeadline.replace("Subheadline: ", "")
  //       : "",
  //     disclaimer: data.disclaimer
  //       ? data.disclaimer.replace("Disclaimer: ", "")
  //       : "",
  //   };

  //   return textType ? { [textType]: formattedData[textType] } : formattedData;
  // }

  const handleCloseResponseDialog = () => {
    setShowResponseDialog(false);
  };

  const handleRetryGenerateText = (textType) => {
    // Close dialog and re-trigger text generation
    // setShowResponseDialog(false);
    handleGenerateText(textType);
  };

  return (
    <Dialog open={showResponseDialog} onClose={handleCloseResponseDialog}>
      <DialogTitle>AI Generated Text</DialogTitle>

      {/* Add another Box with a 1px height horizontal line here */}
      <Box
        sx={{
          borderBottom: "1px solid #000", // 1px height horizontal line
          width: "90%",
          my: 1, // Adjust the spacing as needed
          ml: 3,
          mt: -1,
        }}
      />

      <DialogContent>
        <Box sx={{ marginBottom: 2, minWidth: 400 }}>
          <Typography color='primary' variant='subtitle1'>
            Headline:
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: loading.headline ? "center" : "space-between",
              alignItems: "center",
            }}
          >
            {loading.headline ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Typography>{responseText.headline}</Typography>
                <IconButton
                  onClick={() => handleRetryGenerateText("headline")}
                  color='primary'
                >
                  <RefreshIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <Typography color='primary' variant='subtitle1'>
            Subheadline:
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: loading.subHeadline ? "center" : "space-between",
              alignItems: "center",
            }}
          >
            {loading.subHeadline ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Typography>{responseText.subHeadline}</Typography>
                <IconButton
                  onClick={() => handleRetryGenerateText("subHeadline")}
                  color='primary'
                >
                  <RefreshIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <Typography color='primary' variant='subtitle1'>
            Disclaimer:
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: loading.disclaimer ? "center" : "space-between",
              alignItems: "center",
            }}
          >
            {loading.disclaimer ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Typography>{responseText.disclaimer}</Typography>
                <IconButton
                  onClick={() => handleRetryGenerateText("disclaimer")}
                  color='primary'
                >
                  <RefreshIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCloseResponseDialog}>OK</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TextGenerationComponent;
