import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Select,
  MenuItem,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { getCategoryData } from "../helpers/dataForModal";

const CategoriesModalComponent = ({
  userTitle,
  values,
  setValues,
  open,
  setOpen,
  backgroundColor,
  handleAddEntity,
  handleEditCategory,
  inititalValues,
}) => {
  const theme = useTheme();
  const categoryData = getCategoryData(values);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    // console.log("Modal is open");
    // console.log("Current values:", values);
    // console.log("User title:", userTitle);
  }, [open, values, userTitle]);

  const handleClose = () => {
    setOpen(false);
    setValues(inititalValues);
  };

  const handleInputChange = (e, property) => {
    const value = e.target.value;
    setValues((prev) => {
      return { ...prev, [property]: value };
    });
    setFormChanged(true);
  };

  const sectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    borderBottom: "1px solid #D9D9D9",
  };

  const textStyle = {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.typography.fontColor,
  };

  const inputStyle = {
    width: "50%",
    color: theme.typography.fontColor,
  };

  function handleButtonClick() {
    handleAddEntity()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: "700px" }, p: 0 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Card sx={{ px: 4, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}>
          <CardContent>
            <Box
              sx={{
                ...sectionStyle,
                fontSize: 18,
                fontWeight: "bold",
                color: theme.typography.fontColorHeadline,
                pt: 3.9,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: theme.typography.fontColorHeadline,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: -4,
                    height: "2px",
                    backgroundImage: backgroundColor,
                  },
                }}
              >
                {userTitle}
              </Typography>
              <Divider orientation='horizontal' flexItem />
            </Box>

            {categoryData.map((data, index) => (
              <React.Fragment key={index}>
                <Box sx={sectionStyle}>
                  <Typography sx={textStyle}>{data.label}</Typography>
                  {data.property === "type" ? (
                    <Select
                      value={data.value}
                      onChange={(e) => handleInputChange(e, data.property)}
                      sx={inputStyle}
                    >
                      {data.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      sx={inputStyle}
                      variant='standard'
                      value={data.value || ""}
                      onChange={(e) => handleInputChange(e, data.property)}
                      disabled={
                        userTitle === "Edit Category" && data.property === "id"
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: theme.typography.fontColor,
                          fontWeight: "normal",
                        },
                      }}
                    />
                  )}
                </Box>
              </React.Fragment>
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                disabled={!formChanged}
                variant='contained'
                color='primary'
                size='small'
                style={{ color: theme.palette.primary.light }}
                onClick={() => {
                  if (userTitle === "Edit Category") {
                    handleEditCategory();
                  } else {
                    handleButtonClick();
                  }
                }}
              >
                {userTitle.includes("Edit")
                  ? "UPDATE CATEGORY"
                  : "ADD CATEGORY"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default CategoriesModalComponent;
