import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const PlatformSelection = ({ selectedPlatform, handlePlatformClick }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
      {["Instagram", "Facebook", "LinkedIn"].map((platform) => (
        <Box
          key={platform}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => handlePlatformClick(platform)}
            sx={{
              border: selectedPlatform.includes(platform)
                ? "2px solid"
                : "none",
              borderColor: "primary.main",
              borderRadius: 2,
            }}
          >
            {platform === "Instagram" && (
              <InstagramIcon sx={{ fontSize: 30 }} />
            )}
            {platform === "Facebook" && <FacebookIcon sx={{ fontSize: 30 }} />}
            {platform === "LinkedIn" && <LinkedInIcon sx={{ fontSize: 30 }} />}
          </IconButton>
          <Typography variant='caption' align='center'>
            {platform}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PlatformSelection;
