import React, { useContext, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, TextField, Card, FormControl, Input } from "@mui/material";
import inputImg from "../../images/addPhoto.png";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/system";
import { AuthContext } from "../../context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { addTemplate } from "./features/templateSlice";
import TemplateChip from "./components/TemplateChip";
import {
  ACTIONS_URL,
  ASSETS_URL,
  TEMPLATES_URL,
  UPLOAD_MULTIPLE_URL,
  UPLOAD_URL,
} from "../../config";
import { getImageDimensions } from "../brands/helpers/getImageDimensions";
import { excludeObjectsFromLayout } from "./helpers/excludeObjectsFromLayout";

export default function AddTemplate(props) {
  const brandsList = useSelector((store) => store.brands);
  const LayoutsList = useSelector((store) => store.layouts);

  const [selectedFiles, setSelectedFiles] = useState({});

  const getBrandNameById = (brandId) => {
    const brand = brandsList.find((brand) => brand.id === brandId);
    return brand ? brand.name : "";
  };

  // TODO ADD TYPES TO DB
  const types = LayoutsList.map((layout) => ({
    id: layout.brandId,
    type: layout.type,
    name: layout.title,
  }));

  const dispatch = useDispatch();

  const { handleClose } = props;
  const initialState = useLocation().state;
  const [state, setState] = useState(LayoutsList || {});
  const [id, setId] = useState(state?.id || "");

  const [backgroundImage, setbackgroundImage] = useState(
    `${ASSETS_URL}/${state?.backgroundimage}` || null
  );
  const [productImage, setproductImage] = useState(
    `${ASSETS_URL}/${state?.productImage}` || null
  );
  const [logoBrand1, setLogoBrand1] = useState(
    `${ASSETS_URL}/${state?.logoBrand1}` || null
  );
  const [logoBrand2, setLogoBrand2] = useState(
    `${ASSETS_URL}/${state?.logoBrand2}` || null
  );
  const [logoBrand3, setLogoBrand3] = useState(
    `${ASSETS_URL}/${state?.logoBrand3}` || null
  );
  const [logoThirdParty, setLogoThirdParty] = useState(
    `${ASSETS_URL}/${state?.logoThirdParty}` || null
  );
  const [sticker1, setSticker1] = useState(
    `${ASSETS_URL}/${state?.sticker1}` || null
  );
  const [sticker2, setSticker2] = useState(
    `${ASSETS_URL}/${state?.sticker2}` || null
  );
  const [sticker3, setSticker3] = useState(
    `${ASSETS_URL}/${state?.sticker3}` || null
  );
  const [callToAction, setCallToAction] = useState(
    `${ASSETS_URL}/${state?.callToAction}` || null
  );
  const [thumbnail, setThumbnail] = useState(
    `${ASSETS_URL}/${state?.thumbnail}` || null
  );

  const [values, setValues] = useState({
    type: "",
    title: "",
    description: "",
    cat: "",
    headline: "",
    subHeadline: "",
    backgroundimage: "",
    productImage: "",
    logoBrand1: "",
    logoBrand2: "",
    logoBrand3: "",
    logoThirdParty: "",
    sticker1: "",
    sticker2: "",
    sticker3: "",
    callToAction: "",
    thumbnail: "",
    status: "",
    brand_id: "",
    positions: {},
  });

  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const templateCreated = "created";
  const type = "TEMPLATE";
  const time = new Date();
  let savedDatafromPostResponse;

  // Existing useEffect
  useEffect(() => {
    const selectedLayout = LayoutsList.find(
      (layout) => layout.type === values.type
    );

    if (selectedLayout) {
      const updatedLayout = excludeObjectsFromLayout(
        selectedLayout.layout,
        values.type
      );
      setValues((prevValues) => ({
        ...prevValues,
        positions: updatedLayout,
      }));
    }
  }, [values.type]);

  const handlePostRequest = async (e) => {
    // e.preventDefault();

    try {
      await axios
        .post(TEMPLATES_URL, {
          type: values.type,
          title: values.title,
          description: values.description,
          cat: values.cat,
          headline: values.headline,
          subHeadline: values.subHeadline,
          backgroundimage: values.backgroundimage,
          productImage: values.productImage,
          logoBrand1: values.logoBrand1,
          logoBrand2: values.logoBrand2,
          logoBrand3: values.logoBrand3,
          logoThirdParty: values.logoThirdParty,
          sticker1: values.sticker1,
          sticker2: values.sticker2,
          sticker3: values.sticker3,
          callToAction: values.callToAction,
          thumbnail: values.thumbnail,
          status: values.status,
          uid: currentUser.id,
          brand_id: values.brand_id,
          positions: values.positions,
          clientId: currentUser.clientIdUsers, // Added this line
        })
        .then((response) => {
          // const { data } = response;
          savedDatafromPostResponse = response.data;

          dispatch(
            addTemplate({
              id: savedDatafromPostResponse.id,
              type: savedDatafromPostResponse.type,
              title1: savedDatafromPostResponse.title1,
              description: savedDatafromPostResponse.description,
              cat: savedDatafromPostResponse.cat,
              headline: savedDatafromPostResponse.headline,
              subHeadline: savedDatafromPostResponse.subHeadline,
              backgroundimage: savedDatafromPostResponse.backgroundimage,
              productImage: savedDatafromPostResponse.productImage,
              logoBrand1: savedDatafromPostResponse.logoBrand1,
              logoBrand2: savedDatafromPostResponse.logoBrand2,
              logoBrand3: savedDatafromPostResponse.logoBrand3,
              logoThirdParty: savedDatafromPostResponse.logoThirdParty,
              sticker1: savedDatafromPostResponse.sticker1,
              sticker2: savedDatafromPostResponse.sticker2,
              sticker3: savedDatafromPostResponse.sticker3,
              callToAction: savedDatafromPostResponse.callToAction,
              thumbnail: savedDatafromPostResponse.thumbnail,
              status: savedDatafromPostResponse.status,
              uid: currentUser.id,
              brand_id: savedDatafromPostResponse.brand_id,
            })
          );
        });

      await axios.post(ACTIONS_URL, {
        actionType: "Create",
        objectType: "Template",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        content: savedDatafromPostResponse,
      });
      props.socket?.emit("sendCommentNotification", {
        id: savedDatafromPostResponse.id,
        senderName: currentUser,
        input: values.title,
        action: templateCreated,
        postId: savedDatafromPostResponse.id,
        time,
        type: type,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTemplate = async (e) => {
    console.log("Template clicked");
    e.preventDefault();

    let action;
    try {
      action = templateCreated;
      handlePostRequest();
    } catch (err) {
      console.log(err);
    }

    navigate("/templates");
  };

  console.log(values);

  const handleFileChange = (e, positionKey, brand) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    getImageDimensions(file)
      .then((dimensions) => {
        const formData = new FormData();
        formData.append("brand", brand);
        formData.append("type", positionKey);
        formData.append("files", file);

        return axios.post(UPLOAD_MULTIPLE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      })
      .then((response) => {
        // Assuming the response data is an array and we are interested in the first item
        const uploadedFileName = response.data[0].fileName;

        setValues((prevValues) => {
          const newPositions = JSON.parse(JSON.stringify(prevValues.positions));

          if (newPositions.hasOwnProperty(positionKey)) {
            Object.keys(newPositions[positionKey]).forEach((resolution) => {
              newPositions[positionKey][resolution].imageUrl = uploadedFileName;
            });
          } else {
            console.error("No such key in positions: ", positionKey);
          }

          return {
            ...prevValues,
            positions: newPositions,
            [positionKey]: uploadedFileName,
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlebackgroundImageChange = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "background", brandName);
  };

  const handleproductImageChange = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "product", brandName);
  };

  const handleLogoBrand1Change = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "logoBrand1", brandName);
  };

  const handleLogoBrand2Change = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "logoBrand2", brandName);
  };

  const handleLogoBrand3Change = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "logoBrand3", brandName);
  };

  const handleLogoThirdPartyChange = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "logoThirdParty", brandName);
  };

  const handleSticker1Change = (e) => {
    const brandName = getBrandNameById(values.brand_id);
    handleFileChange(e, "sticker1", brandName);
  };

  const handleSticker2Change = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // setValues({
    //   file5: file,
    // });

    const formData = new FormData();
    formData.append("file", file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSticker2(reader.result);
    };
    reader.readAsDataURL(file);

    axios
      .post(UPLOAD_MULTIPLE_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setValues((prevState) => ({
          ...prevState,
          sticker2: response.data.fileName,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSticker3Change = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // setValues({
    //   file5: file,
    // });

    const formData = new FormData();
    formData.append("file", file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSticker3(reader.result);
    };
    reader.readAsDataURL(file);

    axios
      .post(UPLOAD_MULTIPLE_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setValues((prevState) => ({
          ...prevState,
          sticker3: response.data.fileName,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCallToActionChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    const brandName = getBrandNameById(values.brand_id); // Retrieve brand name based on ID
    formData.append("brand", brandName);
    formData.append("type", "callToAction"); // Assuming 'callToAction' as the type
    formData.append("files", file); // Use 'files' as the key for consistency

    const reader = new FileReader();
    reader.onloadend = () => {
      setCallToAction(reader.result);
    };
    reader.readAsDataURL(file);

    axios
      .post(UPLOAD_MULTIPLE_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setValues((prevState) => ({
          ...prevState,
          callToAction: response.data.fileName,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleThumbnail = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    const brandName = getBrandNameById(values.brand_id); // Retrieve brand name based on ID
    formData.append("brand", brandName);
    formData.append("type", "thumbnail"); // Assuming 'thumbnail' as the type
    formData.append("files", file); // Use 'files' as the key for consistency

    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnail(reader.result);
    };
    reader.readAsDataURL(file);

    axios
      .post(UPLOAD_MULTIPLE_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setValues((prevState) => ({
          ...prevState,
          thumbnail: response.data.fileName,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box sx={{ paddingTop: 5, paddingLeft: 15 }}>
      <Box sx={{ padding: 2, border: 0 }}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 20,
            gap: 20,
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleAddTemplate}
        >
          {/* ****************** CATEGORY INPUT  ****************** */}

          <FormControl sx={{ display: "flex" }}>
            <TemplateChip
              data={brandsList}
              setValues={setValues}
              label={"brand"}
            />
            <TemplateChip data={types} setValues={setValues} label={"type"} />
          </FormControl>

          {/* ****************** TEXT INPUT FIELDS ****************** */}

          <TextField
            disabled
            label='ID'
            variant='standard'
            color='primary'
            fullWidth
            required
            value={id}
          />
          <TextField
            label='Title'
            variant='standard'
            color='primary'
            fullWidth
            required
            defaultValue={values.title || ""}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          />
          <TextField
            label='Description'
            variant='standard'
            color='primary'
            fullWidth
            required
            defaultValue={values.description || ""}
            onChange={(e) =>
              setValues({ ...values, description: e.target.value })
            }
          />
          <TextField
            label='Headline'
            variant='outlined'
            color='primary'
            multiline
            rows={4}
            fullWidth
            required
            defaultValue={values.headline || ""}
            onChange={(e) => setValues({ ...values, headline: e.target.value })}
          />

          <TextField
            label='Sub-headline'
            variant='outlined'
            color='primary'
            multiline
            rows={4}
            fullWidth
            required
            defaultValue={values.subHeadline || ""}
            onChange={(e) =>
              setValues({ ...values, subHeadline: e.target.value })
            }
          />

          {/* ****************** FILE INPUT FIELDS ****************** */}

          <Box
            sx={{
              boxShadow: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              maxWidth: "100%",
              maxHeight: "80%",
              // paddingLeft: 10,
              // paddingRight: 10,
            }}
          >
            {/* ******** BACKGROUND IMAGE ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.background ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input1").click()}
                />
              ) : (
                <img
                  src={backgroundImage}
                  alt=''
                  onClick={() => document.getElementById("file-input1").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input1'
                type='file'
                name='file'
                placeholder='background image'
                onChange={handlebackgroundImageChange}
              />
              Background img
              {values.file1 && (
                <p>
                  {values.file1.name.length > 20
                    ? values.file1.name.slice(0, 20) + "..."
                    : values.file1.name}
                </p>
              )}
            </Box>

            {/* ******** PRODUCT IMG ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
                gap: 2,
                padding: 0,
              }}
            >
              {!values.product ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input2").click()}
                />
              ) : (
                <>
                  <img
                    src={productImage}
                    alt=''
                    onClick={() =>
                      document.getElementById("file-input2").click()
                    }
                  />
                  {/* <p>Change</p> */}
                </>
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input2'
                type='file'
                name='file'
                label=''
                onChange={handleproductImageChange}
              />
              Product img
              {values.file2 && (
                <p>
                  {values.file2.name.length > 20
                    ? values.file2.name.slice(0, 20) + "..."
                    : values.file2.name}
                </p>
              )}
            </Box>

            {/* ******** LOGO BRAND 1 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.logoBrand1 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input3").click()}
                />
              ) : (
                <img
                  src={logoBrand1}
                  alt=''
                  onClick={() => document.getElementById("file-input3").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input3'
                type='file'
                name='file'
                label=''
                onChange={handleLogoBrand1Change}
              />
              Logo Brand 1
              {values.file3 && (
                <p>
                  {values.file3.name.length > 20
                    ? values.file3.name.slice(0, 20) + "..."
                    : values.file3.name}
                </p>
              )}
            </Box>
            {/* ******** LOGO BRAND 2 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",

                gap: 2,
              }}
            >
              {!values.logoBrand2 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input4").click()}
                />
              ) : (
                <img
                  src={logoBrand2}
                  alt=''
                  onClick={() => document.getElementById("file-input4").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input4'
                type='file'
                name='file'
                label=''
                onChange={handleLogoBrand2Change}
              />
              Logo Brand 2
              {values.file4 && (
                <p>
                  {values.file4.name.length > 20
                    ? values.file4.name.slice(0, 20) + "..."
                    : values.file4.name}
                </p>
              )}
            </Box>

            {/* ******** LOGO BRAND 3 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.logoBrand3 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input5").click()}
                />
              ) : (
                <img
                  src={logoBrand3}
                  alt=''
                  onClick={() => document.getElementById("file-input5").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input5'
                type='file'
                name='file'
                label=''
                onChange={handleLogoBrand3Change}
              />
              Logo Brand 3
              {values.file5 && (
                <p>
                  {values.file5.name.length > 20
                    ? values.file5.name.slice(0, 20) + "..."
                    : values.file5.name}
                </p>
              )}
            </Box>

            {/* ******** LOGO THIRD PARTY ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.logoThirdParty ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input6").click()}
                />
              ) : (
                <img
                  src={logoThirdParty}
                  alt=''
                  onClick={() => document.getElementById("file-input6").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input6'
                type='file'
                name='file'
                label=''
                onChange={handleLogoThirdPartyChange}
              />
              Logo Third Party
              {values.file6 && (
                <p>
                  {values.file6.name.length > 20
                    ? values.file6.name.slice(0, 20) + "..."
                    : values.file6.name}
                </p>
              )}
            </Box>

            {/* ******** STICKER 1 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.sticker1 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input7").click()}
                />
              ) : (
                <img
                  src={sticker1}
                  alt=''
                  onClick={() => document.getElementById("file-input7").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input7'
                type='file'
                name='file'
                label=''
                onChange={handleSticker1Change}
              />
              Sticker 1
              {values.file7 && (
                <p>
                  {values.file7.name.length > 20
                    ? values.file7.name.slice(0, 20) + "..."
                    : values.file7.name}
                </p>
              )}
            </Box>

            {/* ******** STICKER 2 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.sticker2 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input8").click()}
                />
              ) : (
                <img
                  src={sticker2}
                  alt=''
                  onClick={() => document.getElementById("file-input8").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input8'
                type='file'
                name='file'
                label=''
                onChange={handleSticker2Change}
              />
              Sticker 2
              {values.file8 && (
                <p>
                  {values.file8.name.length > 20
                    ? values.file8.name.slice(0, 20) + "..."
                    : values.file8.name}
                </p>
              )}
            </Box>

            {/* ******** STICKER 3 ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.sticker3 ? (
                <img
                  src={inputImg}
                  onClick={() => document.getElementById("file-input9").click()}
                />
              ) : (
                <img
                  src={sticker3}
                  alt=''
                  onClick={() => document.getElementById("file-input9").click()}
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input9'
                type='file'
                name='file'
                label=''
                onChange={handleSticker3Change}
              />
              Sticker 3
              {values.file9 && (
                <p>
                  {values.file9.name.length > 20
                    ? values.file9.name.slice(0, 20) + "..."
                    : values.file9.name}
                </p>
              )}
            </Box>
            {/* ******** CALL TO ACTION ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.callToAction ? (
                <img
                  src={inputImg}
                  onClick={() =>
                    document.getElementById("file-input10").click()
                  }
                />
              ) : (
                <img
                  src={callToAction}
                  alt=''
                  onClick={() =>
                    document.getElementById("file-input10").click()
                  }
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input10'
                type='file'
                name='file'
                label=''
                onChange={handleCallToActionChange}
              />
              Call to action
              {values.file10 && (
                <p>
                  {values.file10.name.length > 20
                    ? values.file10.name.slice(0, 20) + "..."
                    : values.file10.name}
                </p>
              )}
            </Box>

            {/* ******** THUMBNAIL ******** */}

            <Box
              sx={{
                display: "flex",
                maxWidth: 100,
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!values.thumbnail ? (
                <img
                  src={inputImg}
                  onClick={() =>
                    document.getElementById("file-input11").click()
                  }
                />
              ) : (
                <img
                  src={thumbnail}
                  alt=''
                  onClick={() =>
                    document.getElementById("file-input11").click()
                  }
                />
              )}
              <Input
                sx={{ display: "none" }}
                id='file-input11'
                type='file'
                name='file'
                label=''
                onChange={handleThumbnail}
              />
              Thumbnail
              {values.file11 && (
                <p>
                  {values.file11.name.length > 20
                    ? values.file11.name.slice(0, 20) + "..."
                    : values.file11.name}
                </p>
              )}
            </Box>
          </Box>

          {/* ****************** BUTTONS ****************** */}
          <Card
            sx={{
              boxShadow: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></Card>

          <Box
            sx={{
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)" }}
              type='submit'
              color='primary'
              variant='contained'
              onClick={handleClose}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
