import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "templates",
  initialState: [],
  reducers: {
    addTemplate: (state, action) => {
      state.push(action.payload);
    },
    setTemplates: (state, action) => {
      const existingTemplate = action.payload.res.filter(
        (template) => !state.some((a) => a.id === template.id)
      );

      state.push(...existingTemplate);
    },
    editTemplate: (state, action) => {
      const {
        id,
        title,
        description,
        cat,
        text1,
        text2,
        backgroundimage,
        productImage,
        logoBrand1,
        logoBrand2,
        logoBrand3,
        logoThirdParty,
        sticker1,
        sticker2,
        sticker3,
        call_to_action,
        status,
        createdAt,
        updatedAt,
        uid,
      } = action.payload;
      const existingTemplate = state.find((user) => user.id === id);
      if (existingTemplate) {
        existingTemplate.title = title;
        existingTemplate.description = description;
        existingTemplate.cat = cat;
        existingTemplate.text1 = text1;
        existingTemplate.text2 = text2;
        existingTemplate.backgroundimage = backgroundimage;
        existingTemplate.productImage = productImage;
        existingTemplate.logoBrand1 = logoBrand1;
        existingTemplate.logoBrand2 = logoBrand2;
        existingTemplate.logoBrand3 = logoBrand3;
        existingTemplate.logoThirdParty = logoThirdParty;
        existingTemplate.sticker1 = sticker1;
        existingTemplate.sticker2 = sticker2;
        existingTemplate.sticker3 = sticker3;
        existingTemplate.call_to_action = call_to_action;
        existingTemplate.status = status;
        existingTemplate.createdAt = createdAt;
        existingTemplate.updatedAt = updatedAt;
        existingTemplate.uid = uid;
      }
    },
    deleteOneTemplate: (state, action) => {
      const { id } = action.payload;
      const existingTemplate = state.find((user) => user.id === id);
      if (existingTemplate) {
        return state.filter((user) => user.id !== id);
      }
    },

    deleteManyTemplates: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((template, index) => {
        if (idsToDelete.has(template.id)) {
          state.splice(index, 1);
        }
      });
    },

    updateTemplates: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addTemplate,
  setTemplates,
  editTemplate,
  deleteOneTemplate,
  updateTemplates,
} = templateSlice.actions;
export default templateSlice.reducer;
