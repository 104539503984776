import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import Moveable from "react-moveable";
import { useDispatch, useSelector } from "react-redux";
import { setEditingComponent } from "../../../features/editingComponentSlices";

const DraggableResizableShape = ({
  handleElementDragResize,
  resolution,
  setEditingResolution,
  editable,
  values,
  verticalGuidelines,
  horizontalGuidelines,
}) => {
  const dispatch = useDispatch();

  const [shapes, setShapes] = useState({});
  const [target, setTarget] = useState(null);

  const [isElementEditable, setIsElementEditable] = useState(false);

  const moveableRef = useRef(null);

  const editingComponent = useSelector((state) => state.editingComponent);

  // Function to update rotation in shapes
  const updateShapeRotations = () => {
    const updatedShapes = { ...shapes };
    let changesMade = false;

    Object.keys(shapes).forEach((shapeKey) => {
      const newRotation =
        values?.positions?.[shapeKey]?.[resolution]?.rotate || 0;
      if (newRotation !== updatedShapes[shapeKey]?.rotate) {
        updatedShapes[shapeKey] = {
          ...updatedShapes[shapeKey],
          rotate: newRotation,
        };
        changesMade = true;
      }
    });

    if (changesMade) {
      setShapes(updatedShapes);
    }
  };

  // useEffect to call updateShapeRotations
  useEffect(() => {
    updateShapeRotations();
  }, [values.positions, resolution]);

  useEffect(() => {
    const newShapes = {};
    Object.keys(values.positions).forEach((shapeKey) => {
      let shapeType;
      if (shapeKey.includes("circle")) {
        shapeType = "circle";
      } else if (shapeKey.includes("rectangle")) {
        shapeType = "rectangle";
      } else if (shapeKey.includes("line")) {
        shapeType = "line";
      }

      if (shapeType) {
        const shapeData = values.positions[shapeKey][resolution];
        if (shapeData && shapeData.visible) {
          newShapes[shapeKey] = {
            ...shapeData,
            type: shapeType,
            zIndex: shapeData.zIndex || 1,
          };
          setIsElementEditable(
            values?.positions?.[shapeKey]?.[resolution]?.editable || false
          );
        }
      }
    });

    setShapes(newShapes);
  }, [values, resolution]);

  // Update Moveable's state when shape properties change
  useEffect(() => {
    if (moveableRef.current && target) {
      moveableRef.current.updateRect();
    }
  }, [shapes, target]);

  const onDragStart = ({ target }) => {
    setTarget(target);
  };

  const onDrag = ({ target, left, top }) => {
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
  };

  const onDragEnd = () => {
    const shapeKey = target.getAttribute("data-shape-id");
    const data = {
      x: parseInt(target.style.left, 10),
      y: parseInt(target.style.top, 10),
    };
    handleElementDragResize(null, data, shapeKey, false, resolution);
  };

  const onResize = ({ target, width, height, drag }) => {
    // Update the target style immediately for a smooth resizing effect
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    target.style.left = `${drag.left}px`;
    target.style.top = `${drag.top}px`;
  };

  const onResizeEnd = () => {
    const shapeKey = target.getAttribute("data-shape-id");
    const shape = shapes[shapeKey];

    // Calculate the new position
    const newX = parseInt(target.style.left, 10);
    const newY = parseInt(target.style.top, 10);

    // Calculate the new size
    const newWidth = parseInt(target.style.width, 10);
    const newHeight = parseInt(target.style.height, 10);

    // Update the shapes state with the new size and position
    setShapes((prevShapes) => ({
      ...prevShapes,
      [shapeKey]: {
        ...shape,
        width: newWidth,
        height: newHeight,
        x: newX,
        y: newY,
      },
    }));

    // Prepare the data for handleElementDragResize function
    const data = {
      width: newWidth,
      height: newHeight,
      x: newX,
      y: newY,
    };

    // Call the handleElementDragResize function with the new data
    handleElementDragResize(null, data, shapeKey, true, resolution);
  };

  const onRotate = ({ target, beforeRotate }) => {
    target.style.transform = `rotate(${beforeRotate}deg)`;
  };

  const onRotateEnd = () => {
    const shapeKey = target.getAttribute("data-shape-id");
    const rotationMatch = target.style.transform.match(/rotate\(([^)]+)deg\)/);
    const rotate = rotationMatch ? parseFloat(rotationMatch[1]) : 0;
    const data = {
      x: parseInt(target.style.left, 10),
      y: parseInt(target.style.top, 10),
      rotation: rotate,
    };

    handleElementDragResize(null, data, shapeKey, false, resolution);
  };

  const renderShape = (shapeKey, shape) => {
    if (!shape || !shape.visible) return null;

    // Fetch the rotation directly from values.positions
    const rotation = values?.positions?.[shapeKey]?.[resolution]?.rotation || 0;

    let commonStyle = {
      background: shape.color || "transparent",
      width: shape.width ? `${shape.width}px` : "auto",
      height: shape.height ? `${shape.height}px` : "auto",
      opacity: shape.opacity || 1,
      position: "absolute",
      boxShadow: shape.boxShadow || "none",
      zIndex: shape.zIndex,
      left: shape.x || 0,
      top: shape.y || 0,
      transform: `rotate(${rotation}deg)`, // Apply rotation here
      border: shape.borderStyle || "none",
    };

    if (shape.type === "circle") {
      commonStyle.borderRadius = "50%";
    } else if (shape.type === "rectangle") {
      // rectangle specific styles (if any)
    } else if (shape.type === "line") {
      commonStyle.height = "1px"; // Customize line styling as needed
    }

    return (
      <div
        key={shapeKey}
        style={commonStyle}
        onClick={() => {
          if (editable) {
            setTarget(document.querySelector(`[data-shape-id="${shapeKey}"]`));
            dispatch(setEditingComponent(shapeKey));
            setEditingResolution(resolution);
          }
        }}
        data-shape-id={shapeKey}
      />
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      {Object.keys(shapes).map((shapeKey) =>
        renderShape(shapeKey, shapes[shapeKey])
      )}
      {editable &&
        editingComponent === target?.getAttribute("data-shape-id") && (
          <Moveable
            ref={moveableRef}
            keepRatio={true}
            target={target}
            draggable={isElementEditable}
            resizable={isElementEditable}
            rotatable={isElementEditable}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onResize={onResize}
            onResizeEnd={onResizeEnd}
            onRotate={onRotate}
            onRotateEnd={onRotateEnd}
            snappable={true} // Enable snapping
            snapThreshold={10} // Set snap threshold
            snapDirections={{
              left: true,
              top: true,
              right: true,
              bottom: true,
              center: true,
              middle: true,
            }} // Snap directions
            verticalGuidelines={verticalGuidelines}
            horizontalGuidelines={horizontalGuidelines}
            elementGuidelines={[]} // Element guidelines, add elements if needed
            snapRotataionThreshold={5}
            snapRotationDegrees={[0, 30, 60, 90, 120, 150, 180, 210, 240, 270]}
          />
        )}
    </Box>
  );
};

export default DraggableResizableShape;
