import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ASSETS_URL } from "../../../config";
import BadgeSelector from "./BadgeSelector";
import LogoSelector from "./LogoSelector";
import { useSelector } from "react-redux";

const camelCaseToWords = (str) => {
  return (
    str
      // Insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // Uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

const ProductUpdateModal = ({
  image,
  productId,
  open,
  handleClose,
  handleUpdate,
  productName,
  setProductName,
  productDescription,
  setProductDescription,
  flags,
  setFlags,
  uploadFiles,
  handleUpdateProduct,
  seriesOptions,
}) => {
  const LayoutsList = useSelector((store) => store.layouts);

  const [selectedSeries, setSelectedSeries] = useState(
    image.data.selectedSeries || ""
  );

  const [selectedLayout, setSelectedLayout] = useState(
    image?.data?.layoutTitle
  );

  // console.log("selectedLayout", selectedLayout);

  const [openBadgeSelector, setOpenBadgeSelector] = useState(false);
  const [openLogoSelector, setOpenLogoSelector] = useState(false);

  const fileInputRef = useRef(null);

  // Set initial selected layout based on image data if available
  useEffect(() => {
    if (image && image.data && image.data.layoutTitle) {
      const initialLayout = LayoutsList.find(
        (layout) => layout.title === image.data.layoutTitle
      );
      if (initialLayout) {
        setSelectedLayout(initialLayout);
      }
    }
  }, [image, LayoutsList]);

  // const handleLayoutChange = (event) => {
  //   const layoutTitle = event.target.value;
  //   const layout = LayoutsList.find((layout) => layout.title === layoutTitle);
  //   setSelectedLayout(layout);
  // };

  const handleBadgeClick = async (selectedBadges) => {
    await handleUpdateProduct(
      image.id,
      image.data.selectedSeries,
      image.data.productName,
      image.data.productDescription,
      image.data.flags,
      image.data.fileName,
      selectedBadges, // Pass the selected badges array
      image.data.logos // Retain the current logos array
    );

    setOpenBadgeSelector(false);
  };

  const handleLogoClick = async (selectedLogos) => {
    await handleUpdateProduct(
      image.id,
      image.data.selectedSeries,
      image.data.productName,
      image.data.productDescription,
      image.data.flags,
      image.data.fileName,
      image.data.badges, // Retain the current badges array
      selectedLogos // Pass the selected logos array
    );

    setOpenLogoSelector(false);
  };

  const handleChangeSeries = (event) => {
    setSelectedSeries(event.target.value);
  };

  const handleFlagChange = (event) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleSubmit = () => {
    handleUpdateProduct(
      productId,
      selectedSeries,
      productName,
      productDescription,
      flags,
      image.data.fileName,
      image.data.badges,
      image.data.logos,
      selectedLayout.layout, // Pass the selected layout's layout object
      selectedLayout.title
    );
    handleClose();
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    await uploadFiles(e, "products", true, async (uploadedFileName) => {
      // After uploading, call handleUpdate with the new filename
      handleUpdate(
        productId,
        selectedSeries,
        productName,
        productDescription,
        flags,
        uploadedFileName
      );
    });
  };

  // Define the flags to be displayed for the product
  const productFlags = [
    "processorIntel",
    "processorAMD",
    "microsoft",
    "ubuntu",
    "integratedGraphics",
    "discreteGraphics",
    "aiFeature",
    "etailMarketing",
    "advertising",
    "positive",
  ];

  // console.log("<<<<<<<<<<<<<<<< IAMGE >>>>>>>>>>>>>>>>>>>>>>>>>>", image);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "600px",
          maxHeight: "90vh", // Set a maximum height
          overflowY: "auto", // Enable vertical scrolling
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <TextField
          label='Name'
          variant='outlined'
          fullWidth
          margin='normal'
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          size='small'
        />
        <TextField
          label='Description'
          variant='outlined'
          fullWidth
          margin='normal'
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          size='small'
          multiline
          rows={6}
        />
        <FormControl fullWidth margin='dense' size='small'>
          <InputLabel size='small'>Series</InputLabel>
          <Select
            value={selectedSeries}
            label='Series'
            onChange={handleChangeSeries}
            size='small'
          >
            {seriesOptions.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Layout Selector */}
        <FormControl fullWidth margin='dense' size='small'>
          <InputLabel size='small'>Layout</InputLabel>
          <Select
            value={selectedLayout?.title || ""}
            label='Layout'
            onChange={(e) => {
              const layoutTitle = e.target.value;
              const layout = LayoutsList.find(
                (layout) => layout.title === layoutTitle
              );
              setSelectedLayout(layout);
            }}
            size='small'
          >
            {LayoutsList.map((layout) => (
              <MenuItem key={layout.id} value={layout.title}>
                {layout.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space",
          }}
        >
          <FormGroup>
            {productFlags.map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={flags[key]}
                    onChange={handleFlagChange}
                    name={key}
                  />
                }
                label={camelCaseToWords(key)}
              />
            ))}
          </FormGroup>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Center items horizontally
              width: "100%", // Take full width of the parent container
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleImageClick}
              >
                {/* Product Image */}
                <img
                  src={`${ASSETS_URL}/${image.data.fileName}`}
                  alt={productName}
                  loading='lazy'
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    objectFit: "contain",
                  }}
                />
              </Paper>

              {/* Logos */}
              {image.data.logos && image.data.logos.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {image.data.logos.map((logo) => (
                    <img
                      key={logo.id}
                      src={`${ASSETS_URL}/${logo?.data?.fileName}`}
                      alt={`Logo ${logo.id}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "60px",
                        objectFit: "contain",
                        backgroundColor: "grey",
                      }}
                      onClick={() => setOpenLogoSelector(true)}
                    />
                  ))}
                </Box>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenLogoSelector(true)}
                  sx={{ mt: 2 }}
                >
                  Assign Logo
                </Button>
              )}

              {/* Badges */}
              {image.data.badges && image.data.badges.length > 0 ? (
                image.data.badges.map((badge, index) => (
                  <img
                    key={index}
                    src={`${ASSETS_URL}/${badge.data.fileName}`}
                    alt={`Badge ${badge.id}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "40px",
                      objectFit: "contain",
                      marginTop: "10px",
                      marginRight: "5px",
                    }}
                    onClick={() => setOpenBadgeSelector(true)}
                  />
                ))
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setOpenBadgeSelector(true)}
                  sx={{ mt: 2 }}
                >
                  Assign Badge
                </Button>
              )}
            </Grid>
          </Box>

          <input
            type='file'
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* Badge Selector Modal */}
          <BadgeSelector
            onBadgeClick={handleBadgeClick}
            showIntel={flags?.processorIntel}
            showAMD={flags?.processorAMD}
            open={openBadgeSelector}
            handleClose={() => setOpenBadgeSelector(false)}
          />

          {/* Badge Selector Modal */}
          <LogoSelector
            onLogoClick={handleLogoClick}
            showIntel={flags?.processorIntel}
            showAMD={flags?.processorAMD}
            open={openLogoSelector}
            handleClose={() => setOpenLogoSelector(false)}
          />
        </Box>

        <Box textAlign='center' marginTop={2}>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Update Product
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductUpdateModal;
