import { Box, Grid } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserCard from "../../components/UserCard";
import AdReadyHelp from "../../components/AdReadyHelp";
import AnalyticsCard from "./components/AnalyticsCard";
import FunFact from "./components/FunFact";
import AdsPreviewContainer from "./components/AdsPreviewContainer";
import ActiveUsers from "./components/ActiveUsers";
import RecentComments from "./components/RecentComments";
import { AuthContext } from "../../context/authContext";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import AdsTable from "../../components/Table";
import Charts from "../charts/Charts";
import { useTheme } from "@mui/material/styles";
import TokenBalance from "../../components/TokenBalance";
import CreateSolanaAccount from "../../components/CreateSolanaAccount";
import ClientSolanaAccount from "../../components/ClientSolanaAccount";

function Dashboard() {
  const AdsList = useSelector((store) => store.ads);
  const UsersList = useSelector((store) => store.users);
  const BrandsList = useSelector((store) => store.brands);
  const CommentsList = useSelector((store) => store.comments);
  const ClientsList = useSelector((store) => store.clients);

  const theme = useTheme();
  const [numberOfAds, setNumberOfAds] = useState(0);
  const [pendingAds, setPendingAds] = useState([]);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("all");
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    let filteredAdsList = AdsList;
    if (filter !== "all") {
      filteredAdsList = AdsList.filter((ad) => ad.status === filter);
    }

    const newRows = filteredAdsList.map((ad) => {
      const user = UsersList.find((user) => user.id === ad?.uid);
      const createdBy = user ? user.username : "Unknown";
      const brand = BrandsList.find((brand) => brand.id === ad?.brand_id);
      const brandName = brand ? brand.name : "Unknown";
      const approver = UsersList.find((user) => user.id === ad?.approverId);
      const approverName = approver ? approver.firstName : "Unknown";

      return {
        id: ad.id,
        title: ad.title,
        brand: brandName,
        createdBy,
        created: formatDateToLocaleString(ad.createdAt),
        updated: formatDateToLocaleString(ad.updatedAt),
        status: ad.status,
        approverName,
      };
    });

    setRows(newRows);
  }, [AdsList, UsersList, BrandsList, filter]);

  useEffect(() => {
    setNumberOfAds(AdsList.length);
    const filterPendingAds = AdsList.filter((ad) => ad.status === "pending");
    setPendingAds(filterPendingAds);
  }, [AdsList]);

  const cardColors = {
    item1: theme.typography.fontColor,
    item2: theme.typography.fontColor,
    item3: theme.typography.fontColor,
  };

  console.log(currentUser);

  const backgroundColor = "linear-gradient(45deg, #A06EAF 30%, #F37979 90%)";

  return (
    <Grid container spacing={2} sx={{ pl: 10, pr: 2, pt: 2 }}>
      {/* Top row with UserCard and AdReadyHelp */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <UserCard
          CommentsList={CommentsList}
          UsersList={UsersList}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfAds={numberOfAds}
          pendingAds={pendingAds}
          cardHeader={"Dashboard"}
          backgroundColor={backgroundColor}
          numberOfItems={AdsList.length}
          rows={rows}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      {/* Full-width row for AnalyticsCard and TokenBalanceCard */}
      <Grid container item xs={12}>
        {currentUser.clientName === "AdReady" &&
        currentUser.role === "admin" &&
        (currentUser.lastName === "Leljak" ||
          currentUser.lastName === "Zaklan") ? (
          <>
            <Grid item xs={12} md={8}>
              <AnalyticsCard />
              <Box sx={{ mt: 2 }}>
                <FunFact />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <TokenBalance />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <AnalyticsCard />
            <Box sx={{ mt: 2 }}>
              <FunFact />
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Below FunFact: Three columns */}
      {/* Left column for AdsPreviewContainer and RecentComments, now wider */}
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <AdsPreviewContainer />
        <Box sx={{ mt: 2 }}>
          <RecentComments
            CommentsList={CommentsList}
            AdsList={AdsList}
            title={"Dashboard"}
          />
        </Box>
      </Grid>

      {/* Middle column for Recent Ads and Charts, adjusted */}
      <Grid item xs={12} sm={6} md={7} lg={6}>
        <AdsTable
          title='Recent Ads'
          rows={rows}
          name={rows.name}
          setFilter={setFilter}
        />
        <Box sx={{ mt: 2 }}>
          <Charts />
        </Box>
      </Grid>

      {/* Right column for ActiveUsers, now narrower */}
      <Grid item xs={12} md={12} lg={2}>
        <ActiveUsers
          users={UsersList}
          clients={ClientsList}
          currentUser={currentUser}
        />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
