import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Typography } from "@mui/material";
import AdReadyLogo from "../images/adready_logo_1.png";
import { useTheme } from "@mui/material/styles";

const AdReadyHelp = ({ backgroundColor }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 2,
        backgroundImage: backgroundColor,
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        minHeight: 151,
      }}
    >
      <CardContent sx={{ width: "100%" }}>
        <Grid container direction='row' spacing={1}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: theme.palette.primary.light,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 3,
                  }}
                >
                  <HelpIcon color='primary' />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: theme.palette.primary.light, fontSize: 14 }}
                  variant='body1'
                  fontWeight='bold'
                >
                  Do you need help?
                </Typography>
                <Typography
                  sx={{ color: theme.palette.primary.light, fontSize: 12 }}
                  variant='body2'
                >
                  Check out our docs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 166,
                height: 32,
                bgcolor: theme.palette.primary.light,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <Typography
                color='primary'
                sx={{ fontWeight: "bold", fontSize: 12 }}
                variant='body1'
              >
                DOCUMENTATION
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={AdReadyLogo}
              alt='AdReadyLogo'
              style={{ maxHeight: "50px" }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdReadyHelp;
