import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ASSETS_URL } from "../../../config";
import {
  deleteManyBackgrounds,
  deleteOneBackground,
} from "../features/backgroundReducer";
import {
  deleteManyProducts,
  deleteOneProduct,
} from "../features/productReducer";
import {
  deleteManyStickers,
  deleteOneSticker,
} from "../features/stickerReducer";
import { deleteManyCtas, deleteOneCta } from "../features/ctaReducer";
import { deleteManyLogos, deleteOneLogo } from "../features/logoReducer";
import { useDeleteRequest } from "../hooks/useDeleteRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteManyFonts, deleteOneFont } from "../features/fontReducer";
import { AnimatePresence, motion } from "framer-motion";
import BrandCardItem from "./BrandCardItem";
import PaginationAppBar from "./PaginationAppBar";
import ShutterStock from "../../../components/ShutterstockImages";
import { TextField, InputAdornment } from "@mui/material"; // Add TextField and InputAdornment
import SearchIcon from "@mui/icons-material/Search";
import ShutterstockImages from "../../../components/ShutterstockImages";
import Masonry from "@mui/lab/Masonry";
import ImageSorter from "../../../components/ImageSorter";
import ImageFilterModal from "../../../components/ImageFilterModal";
import { useTheme } from "@mui/material/styles";
import { deleteManyBadges, deleteOneBadge } from "../features/badgeReducer";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../context/authContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BrandItems = ({
  adId,
  mdValue,
  backgrounds,
  products,
  stickers,
  ctas,
  logos,
  fonts,
  brand,
  badges,
  showCheckbox,
  shutterSearchInput,
  setShutterSearchInput,
  shutterstockImages,
  rightSideBar,
  selectedBrandId,
  onPageChange,
  shutterstockPage,
  shutterstockTotalPages,
  handleShutterstockPrevPage,
  handleShutterstockNextPage,
  setShutterstockPage,
  fetchingImages,
  setSortCriteria,
  setFilters,
  setValues,
  initialLogoBrands,
  setShowImage,
  editStates,
  editingResolution,
  editingProduct,
  uploadFiles,
  values,
}) => {
  const currentUser = useContext(AuthContext);

  // Access the ClientsList from the Redux store
  const ClientsList = useSelector((store) => store.clients);

  // Initialize the isShutterstock state
  const [isShutterstock, setIsShutterstock] = useState(() => {
    // Find the client with the matching ID
    const initialClient = ClientsList.find(
      (client) => client.id === currentUser.currentUser.clientIdUsers
    );
    // Return the initial ShutterstockEnabled value
    return (
      initialClient?.settings?.shutterstock?.isShutterstockEnabled || false
    );
  });

  useEffect(() => {
    // Find the client with the matching ID
    const client = ClientsList.find(
      (client) => client.id === currentUser.currentUser.clientIdUsers
    );

    // Check if Shutterstock is enabled for the client and update state
    const shutterstockEnabled =
      client?.settings?.shutterstock?.isShutterstockEnabled || false;
    setIsShutterstock(shutterstockEnabled);

    console.log(client?.settings?.shutterstock?.isShutterstockEnabled);
  }, [ClientsList, currentUser]); // Re-run the effect when ClientsList or currentUser changes

  const [tabValue, setTabValue] = useState(0);
  const [selectedBackgrounds, setSelectedBackgrounds] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedStickers, setSelectedStickers] = useState({});
  const [selectedCtas, setSelectedCtas] = useState({});
  const [selectedLogos, setSelectedLogos] = useState({});
  const [selectedFonts, setSelectedFonts] = useState({});
  const [selectedBadges, setSelectedBadges] = useState({});

  const [selectAllStates, setSelectAllStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectedBrand, setSelectedBrand] = useState("brand1");
  const [localSearchInput, setLocalSearchInput] = useState(shutterSearchInput);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [page, setPage] = useState(0);
  const itemsPerPage = 20;

  const handleSearchChange = (e) => {
    setLocalSearchInput(e.target.value);
  };

  const handleSearchSubmit = () => {
    setShutterSearchInput(localSearchInput);
  };

  const currentItems = [
    backgrounds,
    products,
    stickers,
    ctas,
    logos,
    fonts,
    badges,
  ][tabValue];
  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const handlePrevPage = (n = 1) => {
    setPage(Math.max(0, page - n));
  };

  const handleNextPage = (n = 1) => {
    const currentItems = [
      backgrounds,
      products,
      stickers,
      ctas,
      logos,
      fonts,
      badges,
    ][tabValue];
    if ((page + n + 1) * itemsPerPage <= currentItems.length) {
      setPage(page + n);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleDeleteRequest = useDeleteRequest();

  const handleDeleteSingle = (tab, imageId, brandId) => {
    const tabApiMap = [
      "backgrounds",
      "products",
      "stickers",
      "ctas",
      "logos",
      "fonts",
      "badges",
    ];
    const deleteOneFuncMap = [
      deleteOneBackground,
      deleteOneProduct,
      deleteOneSticker,
      deleteOneCta,
      deleteOneLogo,
      deleteOneFont,
      deleteOneBadge,
    ];
    const setSelectedFuncMap = [
      setSelectedBackgrounds,
      setSelectedProducts,
      setSelectedStickers,
      setSelectedCtas,
      setSelectedLogos,
      setSelectedFonts,
      setSelectedBadges,
    ];

    handleDeleteRequest(
      tabApiMap[tab],
      imageId,
      deleteOneFuncMap[tab],
      setSelectedFuncMap[tab],
      null,
      brandId
    );
  };

  const handleDeleteMultiple = (brandId) => {
    const tabApiMap = [
      "backgrounds",
      "products",
      "stickers",
      "ctas",
      "logos",
      "fonts",
      "badges",
    ];
    const deleteOneFuncMap = [
      deleteOneBackground,
      deleteOneProduct,
      deleteOneSticker,
      deleteOneCta,
      deleteOneLogo,
      deleteOneFont,
      deleteOneBadge,
    ];
    const deleteManyFuncMap = [
      deleteManyBackgrounds,
      deleteManyProducts,
      deleteManyStickers,
      deleteManyCtas,
      deleteManyLogos,
      deleteManyFonts,
      deleteManyBadges,
    ];
    const setSelectedFuncMap = [
      setSelectedBackgrounds,
      setSelectedProducts,
      setSelectedStickers,
      setSelectedCtas,
      setSelectedLogos,
      setSelectedFonts,
    ];
    const selectedItemsMap = [
      selectedBackgrounds,
      selectedProducts,
      selectedStickers,
      selectedCtas,
      selectedLogos,
      selectedFonts,
      selectedBadges,
    ];

    const imageIds = Object.keys(selectedItemsMap[tabValue]).filter(
      (key) => selectedItemsMap[tabValue][key]
    );

    if (imageIds.length > 0) {
      handleDeleteRequest(
        tabApiMap[tabValue],
        imageIds,
        deleteOneFuncMap[tabValue],
        setSelectedFuncMap[tabValue],
        deleteManyFuncMap[tabValue],
        brandId
      );
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCheckboxToggle = (tab, imageId) => {
    const setSelectedFuncMap = [
      setSelectedBackgrounds,
      setSelectedProducts,
      setSelectedStickers,
      setSelectedCtas,
      setSelectedLogos,
      setSelectedFonts,
      setSelectedBadges,
    ];

    setSelectedFuncMap[tab]((prev) => ({ ...prev, [imageId]: !prev[imageId] }));
  };

  const handleSelectAll = (tab, items, isSelected) => {
    const setSelectedFuncMap = [
      setSelectedBackgrounds,
      setSelectedProducts,
      setSelectedStickers,
      setSelectedCtas,
      setSelectedLogos,
      setSelectedFonts,
      setSelectedBadges,
    ];

    const newSelectedItems = {};
    items.forEach((item) => {
      newSelectedItems[item.id] = isSelected;
    });

    setSelectedFuncMap[tab](newSelectedItems);

    setSelectAllStates((prevState) => {
      const newSelectAllStates = [...prevState];
      newSelectAllStates[tab] = isSelected && items.length > 0;
      return newSelectAllStates;
    });
  };

  const isAllSelected = (tab, items) => {
    const selectedItemsMap = [
      selectedBackgrounds,
      selectedProducts,
      selectedStickers,
      selectedCtas,
      selectedLogos,
      selectedFonts,
      selectedBadges,
    ];

    return items && items.every((item) => selectedItemsMap[tab][item.id]);
  };

  const renderImages = (tab, images) => {
    const selectedItemsMap = [
      selectedBackgrounds,
      selectedProducts,
      selectedStickers,
      selectedCtas,
      selectedLogos,
      selectedFonts,
      selectedBadges,
    ];

    const selectedItems = selectedItemsMap[tab];

    const containerVariant = {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1,
        },
      },
      exit: {
        opacity: 0,
        transition: {
          staggerChildren: 0.1,
        },
      },
    };

    const itemVariant = {
      hidden: { x: "100%", opacity: 0 },
      show: {
        x: "0%",
        opacity: 1,
        transition: {
          type: "spring",
          stiffness: 100,
          damping: 20,
        },
      },
      exit: {
        x: "-100%",
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      },
    };

    // Filter images for brand images
    const filteredImages = images
      ?.filter((image) => image.brandId === brand.id)
      .slice(page * itemsPerPage, (page + 1) * itemsPerPage);

    const brandMapping = {
      brand1: "logoBrand1",
      brand2: "logoBrand2",
      brand3: "logoBrand3",
      brand4: "logoThirdParty",
    };

    const backgroundsTabIndex = tabData.findIndex(
      (tab) => tab.label === "Backgrounds"
    );

    const keyToToggleButtonValue = {
      logoBrand1: "brand1",
      logoBrand2: "brand2",
      logoBrand3: "brand3",
      logoThirdParty: "brand4",
    };

    const renderBrandRadios = () => (
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          mt: 2,
          p: 2,
          maxHeight: 80,
          width: "100%",
        }}
      >
        <ToggleButtonGroup
          value={selectedBrand}
          exclusive
          onChange={(event, newSelection) => {
            if (newSelection !== null) {
              setSelectedBrand(newSelection);
            }
          }}
          aria-label='brand'
        >
          {initialLogoBrands?.map((logoKey) => {
            const toggleButtonValue = keyToToggleButtonValue[logoKey];
            return (
              <ToggleButton key={toggleButtonValue} value={toggleButtonValue}>
                <Typography variant='body2'>
                  {toggleButtonValue.replace("brand", "Brand ") + " LOGO"}
                </Typography>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Card>
    );

    return (
      <Box style={{ overflowX: "hidden" }}>
        <AnimatePresence>
          <motion.div
            variants={containerVariant}
            initial='hidden'
            animate='show'
            exit='exit'
          >
            {isShutterstock && tab === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderColor: "divider",
                  alignItems: "center",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label='navigation tabs'
                >
                  <Tab label='Brand Images' />
                  <Tab label='Shutterstock Images' />
                </Tabs>
                {selectedTab === 1 && (
                  <Box
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ImageSorter onSortChange={setSortCriteria} />
                    <ImageFilterModal onFilterChange={setFilters} />
                  </Box>
                )}
              </Box>
            )}

            {tab === 0 && selectedTab === 1 && isShutterstock && (
              <Box mb={2} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={localSearchInput}
                  onChange={handleSearchChange}
                  placeholder='Search Shutterstock'
                  size='small'
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearchSubmit();
                    }
                  }}
                  InputProps={{
                    style: { height: 40 },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <IconButton onClick={handleSearchSubmit}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}

            <Box maxWidth={rightSideBar ? 650 : "100%"} sx={{ p: 2 }}>
              {tab === 4 && renderBrandRadios()}
              {(tab !== 0 || selectedTab === 0) && (
                <Masonry columns={rightSideBar ? 2 : 4} spacing={1}>
                  {filteredImages?.map((image, index) => (
                    <div key={image.id}>
                      <BrandCardItem
                        rightSideBar={rightSideBar}
                        selectedBrand={selectedBrand}
                        adId={adId}
                        mdValue={mdValue}
                        index={index}
                        image={image}
                        tab={tab}
                        ASSETS_URL={ASSETS_URL}
                        itemVariant={itemVariant}
                        selectedItems={selectedItems}
                        handleCheckboxToggle={handleCheckboxToggle}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        isHovered={isHovered}
                        handleDeleteSingle={handleDeleteSingle}
                        loading={loading}
                        setLoading={setLoading}
                        setValues={setValues}
                        setShowImage={setShowImage}
                        editStates={editStates}
                        editingResolution={editingResolution}
                        editingProduct={editingProduct}
                        uploadFiles={uploadFiles}
                        values={values}
                      />
                    </div>
                  ))}
                </Masonry>
              )}
              {isShutterstock && selectedTab === 1 && (
                <ShutterstockImages
                  selectedBrandId={selectedBrandId}
                  images={shutterstockImages}
                  itemVariant={itemVariant}
                  fetchingImages={fetchingImages}
                />
              )}
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
    );
  };

  const tabData = [
    { label: "Backgrounds", data: backgrounds },
    { label: "Products", data: products },
    { label: "Stickers", data: stickers },
    { label: "CTA", data: ctas },
    { label: "Logo", data: logos },
    { label: "Fonts", data: fonts },
    { label: "Badges", data: badges },
  ];

  return (
    <Box>
      <AppBar
        position='static'
        sx={{
          maxHeight: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
        }}
      >
        <ShutterStock images={shutterstockImages} />

        <Box display='flex' alignItems='center' style={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor='transparent'
            textColor={theme.palette.primary.light}
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              height: "100%",
              "& .MuiTabs-indicator": {
                top: "94%",
                transform: "translateY(-50%)",
              },
            }}
          >
            {tabData.map((tab, index) => (
              <Tab
                sx={{
                  height: 40,
                  pl: 1,
                  fontWeight: tabValue === index ? "bold" : "normal",
                  color:
                    tabValue === index
                      ? theme.palette.primary.light
                      : "#724380",
                }}
                label={
                  <Box display='flex' alignItems='center'>
                    {showCheckbox && (
                      <Checkbox
                        sx={{
                          transform: "scale(0.7)",
                          color:
                            tabValue === index
                              ? theme.palette.primary.light
                              : "#724380",
                        }}
                        checked={isAllSelected(index, tab.data)}
                        onChange={(e) =>
                          handleSelectAll(index, tab.data, e.target.checked)
                        }
                      />
                    )}
                    <Typography variant='body1'>{tab.label}</Typography>
                    {selectAllStates[index] && (
                      <IconButton
                        color='primary'
                        sx={{
                          transform: "scale(0.8)",
                          color: theme.palette.primary.light,
                        }}
                        onClick={() => handleDeleteMultiple(brand.id)} // Replace 'yourBrandIdValue' with the appropriate brandId value
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
      </AppBar>

      <TabPanel value={tabValue} index={0}>
        {renderImages(0, backgrounds)}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {renderImages(1, products)}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {renderImages(2, stickers)}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {renderImages(3, ctas)}
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        {renderImages(4, logos)}
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        {renderImages(5, fonts)}
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        {renderImages(6, badges)}
      </TabPanel>

      {tabValue === 0 && selectedTab === 1 ? (
        <PaginationAppBar
          page={shutterstockPage}
          totalPages={shutterstockTotalPages}
          onPrevPage={handleShutterstockPrevPage}
          onNextPage={handleShutterstockNextPage}
          setShutterstockPage={setShutterstockPage}
        />
      ) : (
        <PaginationAppBar
          page={page}
          totalPages={totalPages}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          setPage={setPage}
        />
      )}
    </Box>
  );
};

export default BrandItems;
