import { createSlice } from "@reduxjs/toolkit";

const logosSlice = createSlice({
  name: "logos",
  initialState: [],
  reducers: {
    addLogo: (state, action) => {
      state.push(action.payload);
    },
    setLogos: (state, action) => {
      const existingLogo = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );

      state.push(...existingLogo);
    },
    editLogo: (state, action) => {
      const logoToUpdate = action.payload;
      const index = state.findIndex((logo) => logo.id === logoToUpdate.id);
      if (index !== -1) {
        state[index] = logoToUpdate; // Update the entire logo object
      }
    },
    deleteOneLogo: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((logo) => logo.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyLogos: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((logo, index) => {
        if (idsToDelete.has(logo.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateLogos: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addLogo,
  setLogos,
  editLogo,
  deleteOneLogo,
  updateLogos,
  deleteManyLogos,
} = logosSlice.actions;
export default logosSlice.reducer;
