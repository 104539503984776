import React from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function AreaActivityChart({ data, mode }) {
  let xDataKey = mode === "user" ? "username" : mode;

  const customTickFormatter = (tickItem) => {
    if (mode === "hour") {
      return `${tickItem}:00`;
    } else if (mode === "day") {
      const date = new Date(tickItem);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else if (mode === "week") {
      return tickItem; // Assume it's already formatted as "Week 35" or similar
    } else {
      return tickItem;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <XAxis dataKey={xDataKey} tickFormatter={customTickFormatter} />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />

        {mode === "user" ? (
          // If it's user mode, only show total actions
          <Area
            type="monotone"
            dataKey="totalActions"
            stroke="#273860"
            fillOpacity={0.6}
            fill="#273860"
          />
        ) : (
          // Otherwise, show individual action types
          <>
            <Area
              type="monotone"
              dataKey="Create"
              stroke="#A06EAF"
              fillOpacity={0.6}
              fill="#A06EAF"
            />
            <Area
              type="monotone"
              dataKey="Update"
              stroke="#80AFDE"
              fillOpacity={0.6}
              fill="#80AFDE"
            />
            <Area
              type="monotone"
              dataKey="Delete"
              stroke="#B1AB9F"
              fillOpacity={0.6}
              fill="#B1AB9F"
            />
            <Area
              type="monotone"
              dataKey="Login"
              stroke="#F0D338"
              fillOpacity={0.6}
              fill="#F0D338"
            />
            <Area
              type="monotone"
              dataKey="Logout"
              stroke="#15254B"
              fillOpacity={0.6}
              fill="#15254B"
            />
            <Area
              type="monotone"
              dataKey="Approve"
              stroke="#8ACC84"
              fillOpacity={0.6}
              fill="#8ACC84"
            />
            <Area
              type="monotone"
              dataKey="Reject"
              stroke="#F37979"
              fillOpacity={0.6}
              fill="#F37979"
            />
            <Area
              type="monotone"
              dataKey="Send"
              stroke="#F2A51D"
              fillOpacity={0.6}
              fill="#F2A51D"
            />
            {/* ... Add other areas for other actions as needed ... */}
          </>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AreaActivityChart;
