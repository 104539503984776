import React from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Typography,
  Box,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"; // For Circle
import CropSquareIcon from "@mui/icons-material/CropSquare"; // For Rectangle
import ShowChartIcon from "@mui/icons-material/ShowChart"; // For Line
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

export default function BannerActionMenu({
  bannerWidth,
  bannerHeight,
  addDynamicText,
  addDynamicBackground,
  addDynamicShape,
  tag,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [shapeMenuAnchorEl, setShapeMenuAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShapeMenuOpen = (event) => {
    setShapeMenuAnchorEl(event.currentTarget);
  };

  const handleShapeMenuClose = () => {
    setShapeMenuAnchorEl(null);
  };

  const handleAddCircle = () => {
    addDynamicShape("circle", `${bannerWidth}x${bannerHeight}`);
    handleShapeMenuClose();
    handleClose();
  };

  const handleAddRectangle = () => {
    addDynamicShape("rectangle", `${bannerWidth}x${bannerHeight}`);
    handleShapeMenuClose();
    handleClose();
  };

  const handleAddLine = () => {
    addDynamicShape("line", `${bannerWidth}x${bannerHeight}`);
    handleShapeMenuClose();
    handleClose();
  };

  const handleAddText = () => {
    addDynamicText(tag, bannerWidth, bannerHeight);
    handleClose();
  };

  const handleAddBackground = () => {
    addDynamicBackground(tag, bannerWidth, bannerHeight);
    handleClose();
  };

  // Add your logic for other menu actions here...

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <LibraryAddOutlinedIcon color='primary' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAddText}>
          <Typography sx={{ fontSize: "0.8rem", color: "primary.main" }}>
            ADD TEXT
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleAddBackground}>
          <Typography sx={{ fontSize: "0.8rem", color: "primary.main" }}>
            ADD BACKGROUND
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Typography sx={{ fontSize: "0.8rem", color: "primary.main" }}>
            ADD IMAGE
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleShapeMenuOpen}>
          <Typography sx={{ fontSize: "0.8rem", color: "primary.main" }}>
            ADD OBJECT
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={shapeMenuAnchorEl}
        keepMounted
        open={Boolean(shapeMenuAnchorEl)}
        onClose={handleShapeMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleAddCircle}>
          <ListItemIcon>
            <RadioButtonUncheckedIcon
              sx={{ fontSize: 16, color: "primary.main", ml: 1.1 }}
              fontSize='small'
            />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleAddRectangle}>
          <ListItemIcon>
            <CropSquareIcon
              sx={{ fontSize: 18, color: "primary.main", ml: 1 }}
              fontSize='small'
            />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleAddLine}>
          <ListItemIcon>
            <RemoveIcon
              sx={{ fontSize: 18, color: "primary.main", ml: 1 }}
              fontSize='small'
            />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Box>
  );
}
