import React, { useState } from "react";
import { Card, Typography, IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { motion } from "framer-motion";
import { updateAdPositionFields } from "../../ads/features/adSlice";
import { useDispatch } from "react-redux";

const LayoutCard = ({
  layout,
  layoutImage,
  handleDelete,
  values,
  existingAd,
  setValues,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleClick = () => {
    const fieldsToUpdate = [
      "headline",
      "subHeadline",
      "disclaimer",
      "product",
      "sticker1",
      "cta",
    ];
    let changes = JSON.parse(JSON.stringify(values.positions)); // Deep copy of positions.

    const positionPropertiesToUpdate = ["x", "y", "textAlign"];

    if (!layout || !layout.layout) {
      return;
    }

    // Parse layout.layout from a string into an object
    let parsedLayout =
      typeof layout.layout === "string"
        ? JSON.parse(layout.layout)
        : layout.layout;

    for (let i = 0; i < fieldsToUpdate.length; i++) {
      const field = fieldsToUpdate[i];

      if (!parsedLayout.hasOwnProperty(field)) {
        continue;
      }

      for (let resolution in parsedLayout[field]) {
        if (!parsedLayout[field][resolution]) {
          continue;
        }

        if (!changes[field][resolution]) changes[field][resolution] = {};

        for (let property of positionPropertiesToUpdate) {
          if (parsedLayout[field][resolution].hasOwnProperty(property)) {
            changes[field][resolution][property] =
              parsedLayout[field][resolution][property];
          }
        }
      }
    }

    setValues((prevValues) => ({
      ...prevValues,
      positions: changes,
    }));
  };

  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Card
        // elevation={3}
        sx={{
          // borderRadius: 2,
          // border: isHovered ? "none" : "1px solid #D9D9D9",
          // position: "relative",
          height: "160px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // maxHeight: 160,
          width: "100%",
          // mb: 2,
          zIndex: -1,
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant='body2' sx={{ mr: 1 }}>
              {isHovered ? layout.title : layout.title.substring(0, 15)}
            </Typography>
          </Box>
        </Box> */}

        <Box style={{ overflow: "hidden" }}>
          <img
            src={layoutImage}
            alt=''
            style={{
              maxHeight: "80%",
              objectFit: "contain",
              width: "100%",
            }}
          />
        </Box>
      </Card>
    </motion.div>
  );
};

export default LayoutCard;
