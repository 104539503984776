import React, { useState, useMemo } from "react";
import { Grid } from "@mui/material";
import AdCard from "./AdCard";
import AdCardNewAd from "./AdCardNewAd";
import { motion } from "framer-motion";
import WizardModal from "./CreateAdWizzard";

// Memoize AdCard component
const MemoizedAdCard = React.memo(AdCard);

export const CurrentAds = ({
  AdsList,
  selectedBrandId,
  isLoading,
  CategoriesList,
  users,
  handleDelete,
  handleDeleteFromChild,
  boxShadow,
}) => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    show: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.2,
      },
    }),
  };

  const [showWizardModal, setShowWizardModal] = useState(false);

  const filteredAds = useMemo(() => {
    return AdsList.filter((ad) =>
      selectedBrandId ? ad.brand_id === selectedBrandId : true
    );
  }, [AdsList, selectedBrandId]);

  const itemStyle = { maxWidth: 394 };

  return (
    <Grid container spacing={2} sx={{ ml: 2 }}>
      {filteredAds.map((ad, index) => {
        const user = users?.find((user) => user.id === ad.uid);
        const approver = users?.find((user) => user.id === ad.approverId);

        return (
          <motion.div
            style={{ padding: 14 }}
            variants={variants}
            initial='hidden'
            animate='show'
            custom={index}
            key={ad.id}
          >
            <Grid item xs={12} sm={6} md={4} style={itemStyle}>
              <MemoizedAdCard
                dashboard={false}
                isLoading={isLoading}
                boxShadow={boxShadow}
                CategoriesList={CategoriesList}
                data={ad}
                id={ad.id}
                userImage={ad.userImage}
                username={user?.firstName}
                approverName={approver?.firstName}
                status={ad.status}
                createdAt={ad.createdAt}
                updatedAt={ad.updatedAt}
                comment={ad.comment}
                route='/edit-ad'
                handleDelete={handleDelete}
                handleDeleteFromChild={handleDeleteFromChild}
                thumbnail={ad.thumbnailCache?.thumbnail}
              />
            </Grid>
          </motion.div>
        );
      })}
      <Grid item xs={12} sm={12} md={6} key='new-ad' style={itemStyle}>
        <AdCardNewAd setShowWizardModal={setShowWizardModal} />

        {showWizardModal && (
          <WizardModal setShowWizardModal={setShowWizardModal} />
        )}
      </Grid>
    </Grid>
  );
};
