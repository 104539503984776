import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  ToggleButton,
  Button,
  Menu,
  MenuItem,
  Card,
  AppBar,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../services/axiosInstance";
import { ACTIONS_URL, ADS_PREVIEW_URL, ADS_URL } from "../config";
import { AuthContext } from "../context/authContext";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import { motion } from "framer-motion";
import ToggleButtonItems from "./ToggleButtonItems";
import TransparencyControl from "../pages/ads/components/TransparencyControl ";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";

const ToggleButtonControl = ({
  onUpdate,
  existingAd,
  showUpdated,
  setValues,
  adsData,
  editingResolution,
  setEditingResolution,
  editStates,
  values,
  handleElementTransparencyUpdate,
  handleLockClick,
}) => {
  const theme = useTheme();

  const editingComponent = useSelector((state) => state.editingComponent);

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Update local state instead of dispatching action
  const handleToggleVisibility = (positionType) => {
    setValues((prevValues) => {
      const updatedVisibility =
        !prevValues.positions[positionType][editingResolution]?.visible;

      let updatedPositions = {
        ...prevValues.positions,
        [positionType]: {
          ...prevValues.positions[positionType],
          [editingResolution]: {
            ...prevValues.positions[positionType][editingResolution],
            visible: updatedVisibility,
          },
        },
      };

      // If editStates["1200x630"] is true, update visibility for all resolutions
      if (editStates["1200x630"]) {
        const resolutions = Object.keys(prevValues.positions[positionType]);
        resolutions.forEach((resolution) => {
          updatedPositions = {
            ...updatedPositions,
            [positionType]: {
              ...updatedPositions[positionType],
              [resolution]: {
                ...updatedPositions[positionType][resolution],
                visible: updatedVisibility,
              },
            },
          };
        });
      }

      return {
        ...prevValues,
        positions: updatedPositions,
      };
    });
  };

  const hexToRGBA = (hex, alpha = 1) => {
    let r = 0,
      g = 0,
      b = 0;

    // 3 digits
    if (hex.length === 4) {
      r = "0x" + hex[1] + hex[1];
      g = "0x" + hex[2] + hex[2];
      b = "0x" + hex[3] + hex[3];

      // 6 digits
    } else if (hex.length === 7) {
      r = "0x" + hex[1] + hex[2];
      g = "0x" + hex[3] + hex[4];
      b = "0x" + hex[5] + hex[6];
    }

    return `rgba(${+r}, ${+g}, ${+b}, ${alpha})`;
  };

  const handleToggleShadow = (
    positionType,
    offsetX = "2px",
    offsetY = "2px",
    blur = "4px",
    spread = "0px",
    color = "#000000",
    toggleShadow,
    alpha = 0.5 // You can adjust the alpha value as needed
  ) => {
    setValues((prevValues) => {
      const currentPosition =
        prevValues?.positions?.[positionType]?.[editingResolution];

      // Hardcoded text element types
      const hardcodedTextElementTypes = [
        "headline",
        "subHeadline",
        "disclaimer",
      ];

      // Determine if the positionType is a hardcoded text element
      const isHardcodedTextElement =
        hardcodedTextElementTypes.includes(positionType);

      // Dynamic text element check
      const isDynamicTextElement = /^text\d+$/.test(positionType);

      // Combining both checks
      const isTextElement = isHardcodedTextElement || isDynamicTextElement;

      // Convert hex color to RGBA
      const rgbaColor = hexToRGBA(color, alpha);

      // Construct the shadow style based on element type
      const shadowStyle = toggleShadow
        ? isTextElement
          ? `${offsetX} ${offsetY} ${blur} ${rgbaColor}` // Format for text shadow
          : `${offsetX} ${offsetY} ${blur} ${spread} ${rgbaColor}` // Format for box shadow
        : "none";

      // Update the appropriate shadow property based on the element type
      const updatedShadowProperty = isTextElement
        ? { textShadow: shadowStyle }
        : { boxShadow: shadowStyle };

      const updatedPositions = {
        ...prevValues.positions,
        [positionType]: {
          ...prevValues.positions[positionType],
          [editingResolution]: {
            ...currentPosition,
            ...updatedShadowProperty, // Apply the appropriate shadow property
          },
        },
      };

      console.log("Updated Positions: ", updatedPositions); // Debugging log

      return {
        ...prevValues,
        positions: updatedPositions,
      };
    });
  };

  const handleToggleBorder = (
    positionType,
    borderThickness = "100px",
    borderType = "solid",
    borderColor = "#000000",
    toggleBorder // New parameter to control border visibility
  ) => {
    setValues((prevValues) => {
      const currentPosition =
        prevValues.positions[positionType][editingResolution];

      // Determine the new border style based on toggleBorder
      let updatedBorderStyle;
      if (toggleBorder) {
        updatedBorderStyle = `${borderThickness} ${borderType} ${borderColor}`;
      } else {
        updatedBorderStyle = "none";
      }

      let updatedPositions = {
        ...prevValues.positions,
        [positionType]: {
          ...prevValues.positions[positionType],
          [editingResolution]: {
            ...currentPosition,
            borderStyle: updatedBorderStyle,
          },
        },
      };

      // Update borderStyle for all resolutions if editStates["1200x630"] is true
      if (editStates["1200x630"]) {
        const resolutions = Object.keys(prevValues.positions[positionType]);
        resolutions.forEach((resolution) => {
          updatedPositions[positionType][resolution].borderStyle =
            updatedBorderStyle;
        });
      }

      return {
        ...prevValues,
        positions: updatedPositions,
      };
    });
  };

  const createLabel = (key) => {
    // Function to capitalize and create a label from the key
    return key
      .split(/(?=[A-Z])/)
      .join(" ")
      .toUpperCase();
  };

  const createItemsFromPositions = (positions) => {
    return Object.keys(positions).map((key) => ({
      key: key,
      label: createLabel(key),
      vizual: createLabel(key),
    }));
  };

  // Example usage
  const items = createItemsFromPositions(values.positions).filter(
    (item) => item.key !== "adType" && !item.key.startsWith("logo")
  );

  return (
    <>
      <AppBar
        position='static'
        color='primary'
        sx={{
          height: 30,
          background: "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
          mt: 1,
        }}
      >
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ ml: 3, mt: 0.5, fontSize: 14 }}
            variant='h6'
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            LAYERS
          </Typography>

          <Grid item>
            <IconButton
              sx={{ color: theme.palette.primary.light, mt: -0.5, mr: 0.3 }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              {drawerOpen ? (
                <KeyboardArrowUpIcon sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ mr: 1 }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      {drawerOpen && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            p: 1,
            mt: 1,
            mb: 1,
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
          }}
        >
          {/* Left Box for Visibility Toggle Buttons */}

          <ToggleButtonItems
            items={items}
            editingResolution={editingResolution}
            setEditingResolution={setEditingResolution}
            adsData={adsData}
            handleToggleVisibility={handleToggleVisibility}
            values={values}
            editingComponent={editingComponent}
            handleElementTransparencyUpdate={handleElementTransparencyUpdate}
            setValues={setValues}
            handleLockClick={handleLockClick}
            handleToggleBorder={handleToggleBorder}
            handleToggleShadow={handleToggleShadow}
            editStates={editStates}
          />

          {/* Right Box for Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: "center",
              // flexWrap: "wrap",
              // width: "100%",
              gap: 2,
            }}
          >
            {/* Button components for Update, Send For Approval */}
          </Box>
        </Card>
      )}

      {drawerOpen && (
        <AppBar
          position='static'
          color='primary'
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: 10,
            mb: 2,
          }}
        >
          {" "}
        </AppBar>
      )}
    </>
  );
};

export default ToggleButtonControl;
