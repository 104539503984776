import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { formatDateToLocaleString } from "../../../helpers/dateHelper";
import { ASSETS_URL } from "../../../config";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const AdsStatusDrawer = ({ data, selectedBrand, userThatCreatedAd }) => {
  const UsersList = useSelector((store) => store.users);

  const [open, setOpen] = useState(false);

  const approver = UsersList?.find((user) => user.id === data.approverId);
  const approverName = approver
    ? `${approver.firstName} ${approver.lastName}`
    : "Unknown";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "approved":
        return (
          <CheckCircleIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
      default:
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
    }
  };

  return (
    <Box>
      <Box>
        <Button
          onClick={handleDrawerOpen}
          sx={{ maxHeight: 30, fontSize: "0.8rem" }}
          variant='text'
        >
          Status
        </Button>
      </Box>
      <Divider
        orientation='vertical'
        flexItem
        sx={{ borderColor: theme.palette.primary.tableLabel }}
      />

      <Drawer anchor='top' open={open} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-around",
            padding: 2,
            pb: 1,
            height: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              TITLE:
            </Typography>
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {data.title}
            </Typography>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              CREATED BY:
            </Typography>
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {userThatCreatedAd && userThatCreatedAd.firstName}
            </Typography>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            {getStatusText(data.status)}
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {`${data.status} by ${approverName}`}
            </Typography>
          </Box>

          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              CREATED AT:
            </Typography>
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {formatDateToLocaleString(data.createdAt)}
            </Typography>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              UPDATED AT:
            </Typography>
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {formatDateToLocaleString(data.updatedAt)}
            </Typography>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <img
              style={{ maxHeight: "20px" }}
              src={`${ASSETS_URL}/${selectedBrand?.brandLogo}`}
              alt='Logo'
            />
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderColor: theme.palette.primary.tableLabel }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography
              variant='body1'
              color='primary'
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              CATEGORY:
            </Typography>
            <Typography
              sx={{ fontSize: "0.7rem" }}
              variant='body1'
              color='text.secondary'
            >
              {data?.categories?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "11px",
            background: "linear-gradient(45deg, #a06eaf, #293e6f)",
          }}
        />
      </Drawer>
    </Box>
  );
};

export default AdsStatusDrawer;
