import React from "react";
import { Box, Button } from "@mui/material";

export default function AdvertisingEtailSelector({
  selectedAdvertisingOption,
  setSelectedAdvertisingOption,
  setValues, // Using setValues to update the entire values object
  values, // You also need the current values for this to work
}) {
  const handleSelectOption = (option) => {
    setSelectedAdvertisingOption(option);

    // Update values object with the new adType
    setValues({
      ...values, // Spread the existing values
      positions: {
        ...values.positions, // Spread the existing positions
        adType: {
          advertising: option === "Advertising",
          etailMarketing: option === "Etail Marketing",
        },
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        mt: 4,
      }}
    >
      <Button
        variant={
          selectedAdvertisingOption === "Advertising" ? "contained" : "outlined"
        }
        onClick={() => handleSelectOption("Advertising")}
        sx={{
          width: "200px",
          bgcolor:
            selectedAdvertisingOption === "Advertising" ? "primary.main" : "",
          "&:hover": {
            bgcolor: "primary.light",
          },
        }}
      >
        Advertising
      </Button>
      <Button
        variant={
          selectedAdvertisingOption === "Etail Marketing"
            ? "contained"
            : "outlined"
        }
        onClick={() => handleSelectOption("Etail Marketing")}
        sx={{
          width: "200px",
          bgcolor:
            selectedAdvertisingOption === "Etail Marketing"
              ? "primary.main"
              : "",
          "&:hover": {
            bgcolor: "primary.light",
          },
        }}
      >
        Etail Marketing
      </Button>
    </Box>
  );
}
