import { ADS_URL } from "../../../config";

import axiosInstance from "../../../services/axiosInstance";

let savedDatafromPostResponse;

export const handlePutRequest = async (
  id,
  values,
  currentUser,
  dispatch,
  editAd,
  currentEditStates
) => {
  try {
    let adsPositions = JSON.parse(JSON.stringify(values.positions)); // Deep clone

    if (currentEditStates["1200x630"] === true) {
      const newHeadlineValue = adsPositions.headline["1200x630"];
      const newSubHeadlineValue = adsPositions.subHeadline["1200x630"];
      const newDisclaimerValue = adsPositions.disclaimer["1200x630"];

      for (let resolution in adsPositions.headline) {
        adsPositions.headline[resolution] = {
          ...adsPositions.headline[resolution],
          headline: newHeadlineValue.headline,
          fontName: newHeadlineValue.fontName,
          textColor: newHeadlineValue.textColor,
          textAlign: newHeadlineValue.textAlign,
        };
      }

      for (let resolution in adsPositions.subHeadline) {
        adsPositions.subHeadline[resolution] = {
          ...adsPositions.subHeadline[resolution],
          subHeadline: newSubHeadlineValue.subHeadline,
          fontName: newSubHeadlineValue.fontName,
          textColor: newSubHeadlineValue.textColor,
          textAlign: newSubHeadlineValue.textAlign,
        };
      }

      for (let resolution in adsPositions.disclaimer) {
        adsPositions.disclaimer[resolution] = {
          ...adsPositions.disclaimer[resolution],
          disclaimer: newDisclaimerValue.disclaimer,
          fontName: newDisclaimerValue.fontName,
          textColor: newDisclaimerValue.textColor,
          textAlign: newDisclaimerValue.textAlign,
        };
      }
    }

    const dataToSend = {
      id,
      headline: values.headline,
      subHeadline: values.subHeadline,
      text2: values.text2,
      backgroundimage: values.backgroundimage,
      productImage: values.productImage,
      logoThirdParty: values.logoThirdParty,
      logoBrand1: values.logoBrand1,
      logoBrand2: values.logoBrand2,
      cat: values.cat,
      email: currentUser.email,
      status: "pending",
      partnership: values.partnership,
      partnershipColor: values.partnershipColor,
      partnershipWidth: values.partnershipWidth,

      positions: adsPositions,
    };

    const response = await axiosInstance.put(`${ADS_URL}/${id}`, dataToSend);

    savedDatafromPostResponse = response.data;

    dispatch(editAd(dataToSend));

    return response.data;
  } catch (err) {
    console.log("Error:", err);
    if (err.response) {
      console.log("Server Response:", err.response);
    }
    throw err;
  }
};
