import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Paper,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AuthContext } from "../context/authContext";
import {
  AccountBalanceWallet,
  ContentCopy,
  Refresh,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const ClientSolanaAccount = () => {
  const { currentUser } = useContext(AuthContext);
  const clientsList = useSelector((store) => store.clients);
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);

  useEffect(() => {
    const clientId = currentUser.clientIdUsers;
    const client = clientsList.find((client) => client.id === clientId);

    if (client) {
      setClientData(client);
      fetchTokenBalance(clientId);
    } else {
      const fetchClientData = async () => {
        try {
          const response = await axios.get(`/api/clients/${clientId}`);
          setClientData(response.data);
          fetchTokenBalance(clientId);
        } catch (error) {
          console.error("Error fetching client data:", error);
          setLoading(false);
        }
      };

      fetchClientData();
    }
  }, [clientsList, currentUser.clientIdUsers]);

  const fetchTokenBalance = async (clientId) => {
    try {
      const response = await axios.get(
        `/api/tokens/client-token-balance/${clientId}`
      );
      setTokenBalance(response.data.balance);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      setLoading(false);
    }
  };

  const refreshClientData = async () => {
    setLoading(true);
    const clientId = currentUser.clientIdUsers;
    try {
      const response = await axios.get(`/api/clients/${clientId}`);
      setClientData(response.data);
      fetchTokenBalance(clientId);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      clientData.data.wallet.solana.solanaPublicKey
    );
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!clientData) {
    return (
      <Container>
        <Typography variant='h6' color='error'>
          Error loading client data
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
        }}
      >
        <Card>
          <CardContent>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Box display='flex' alignItems='center'>
                <AccountBalanceWallet
                  sx={{ mr: 1, color: theme.palette.primary.main }}
                />
                <Typography variant='h5' component='div'>
                  Solana Account Information
                </Typography>
              </Box>
              <Tooltip title='Copy Wallet Address'>
                <IconButton onClick={handleCopy}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
              <Tooltip title='Refresh Data'>
                <IconButton onClick={refreshClientData}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant='body1' component='div'>
              <strong>Wallet Address:</strong>{" "}
              {clientData.data.wallet.solana.solanaPublicKey}
            </Typography>
            <Typography variant='body1' component='div'>
              <strong>Token Balance:</strong>{" "}
              {tokenBalance !== null ? tokenBalance.toFixed(9) : "Loading..."}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default ClientSolanaAccount;
