import React, { useState, useRef } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Grid,
  Paper,
} from "@mui/material";
import { ASSETS_URL } from "../../../config";

const levelOptions = [5, 7, 9];

const camelCaseToWords = (str) => {
  return (
    str
      // Insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // Uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

const BadgeUpdateModal = ({
  badgeId,
  image,
  openBadge,
  handleCloseBadge,
  handleUpdateBadge,
  badgeName,
  setBadgeName,
  badgeDescription,
  setBadgeDescription,
  uploadFiles,
  flags,
  setFlags,
  seriesOptions,
}) => {
  const [selectedSeries, setSelectedSeries] = useState(
    image?.data.selectedSeries || ""
  );

  const [selectedLevel, setSelectedLevel] = useState(
    image.data.selectedLevel || ""
  ); // New state for level

  const fileInputRef = useRef(null);

  const handleChangeSeries = (event) => {
    setSelectedSeries(event.target.value);
  };

  const handleChangeLevel = (event) => {
    // New function to handle level change
    setSelectedLevel(event.target.value);
  };

  const handleFlagChange = (event) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleSubmit = () => {
    handleUpdateBadge(
      badgeId,
      selectedSeries,
      badgeName,
      badgeDescription,
      flags,
      selectedLevel
    );
    handleCloseBadge();
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    await uploadFiles(e, "badges", true, async (uploadedFileName) => {
      // Pass the selectedLevel to handleUpdateBadge if needed
      handleUpdateBadge(
        badgeId,
        selectedSeries,
        badgeName,
        badgeDescription,
        flags,
        uploadedFileName,
        selectedLevel // Include selectedLevel here if needed
      );
    });
  };

  // Define the flags to be displayed for the product
  const productFlags = [
    "processorIntel",
    "processorAMD",
    "microsoft",
    "ubuntu",
    "integratedGraphics",
    "discreteGraphics",
    "positive",
    "aiFeature",
    "etailMarketing",
    "advertising",
  ];

  return (
    <Modal open={openBadge} onClose={handleCloseBadge}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "400px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto", // Enable scrolling
          maxHeight: "90vh", // Set maximum height
        }}
      >
        <TextField
          label='Badge Name'
          variant='outlined'
          fullWidth
          margin='normal'
          value={badgeName || ""}
          onChange={(e) => setBadgeName(e.target.value)}
          size='small'
        />
        <TextField
          label='Badge Description'
          variant='outlined'
          fullWidth
          margin='normal'
          value={badgeDescription || ""}
          onChange={(e) => setBadgeDescription(e.target.value)}
          size='small'
          multiline
          rows={3}
        />
        <FormControl fullWidth margin='dense' size='small'>
          <InputLabel size='small'>Series</InputLabel>
          <Select
            value={selectedSeries}
            label='Series'
            onChange={handleChangeSeries}
            size='small'
          >
            {seriesOptions.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{ fontSize: "0.875rem" }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* New FormControl for Level selection */}
        <FormControl fullWidth margin='dense' size='small'>
          <InputLabel size='small'>Processor Level</InputLabel>
          <Select
            value={selectedLevel}
            label='Processor Level'
            onChange={handleChangeLevel}
            size='small'
          >
            {levelOptions.map((level) => (
              <MenuItem key={level} value={level}>
                {`Level ${level}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, cursor: "pointer" }}
            onClick={handleImageClick}
          >
            <img
              src={`${ASSETS_URL}/${image?.data?.fileName}`}
              alt={badgeName}
              loading='lazy'
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                objectFit: "contain",
              }}
            />
          </Paper>
        </Grid>
        <input
          type='file'
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <FormGroup>
          {Object.keys(flags).map((key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={flags[key]}
                  onChange={handleFlagChange}
                  name={key}
                />
              }
              label={camelCaseToWords(key)}
            />
          ))}
        </FormGroup>
        <Box textAlign='center' marginTop={2}>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Update Badge
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BadgeUpdateModal;
