import React, { useState } from "react";
import {
  ImageList,
  ImageListItem,
  Modal,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSelector } from "react-redux";
import { ASSETS_URL } from "../../../config";

const BadgeSelector = ({
  onBadgeClick,
  showGoldBadge,
  showSilverBadge,
  open,
  handleClose,
}) => {
  const badgesList = useSelector((store) => store.badges);
  const [selectedBadges, setSelectedBadges] = useState([]);

  console.log("Selected Badges: ", selectedBadges); // Log to check the selected badges
  // Then call handleUpdateProduct with these badges

  const handleBadgeSelect = (badge) => {
    if (selectedBadges.includes(badge)) {
      setSelectedBadges(selectedBadges.filter((b) => b.id !== badge.id));
    } else {
      setSelectedBadges([...selectedBadges, badge]);
    }
  };

  const handleConfirmSelection = () => {
    onBadgeClick(selectedBadges);
    handleClose();
  };

  const filteredBadges = badgesList.filter((badge) => {
    if (showGoldBadge) {
      return badge.data.fileName.includes("Gold");
    }
    if (showSilverBadge) {
      return badge.data.fileName.includes("Silver");
    }
    return true;
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: 450,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          backgroundColor: "gray",
        }}
      >
        <ImageList cols={6} gap={2} sx={{ width: "100%", height: "100%" }}>
          {filteredBadges.map((badge) => (
            <ImageListItem
              key={badge.id}
              onClick={() => handleBadgeSelect(badge)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
                margin: 2,
              }}
            >
              <img
                src={`${ASSETS_URL}/${badge.data.fileName}`}
                alt={`Badge ${badge.id}`}
                loading='lazy'
                style={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
              {selectedBadges.includes(badge) && (
                <IconButton
                  color='success'
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              )}
            </ImageListItem>
          ))}
        </ImageList>
        <Box textAlign='center' marginTop={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirmSelection}
          >
            Confirm Selection
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BadgeSelector;
