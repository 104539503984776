import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CurrentAds } from "./CurrentAds";
import { useTheme } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const boxShadow = "2px 2px 25px 2px rgba(0, 0, 0, 0.2)";

export default function ElevatedTabs({
  CategoriesList,
  currentAds,
  users,
  BrandsList,
  handleDelete,
  handleDeleteFromChild,
  isLoading,
}) {
  const [value, setValue] = useState(0);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  const theme = useTheme();

  const brandIds = useMemo(
    () => [...new Set(currentAds.map((ad) => ad.brand_id))],
    [currentAds]
  );

  const allBrands = { id: "all", name: "All" };
  const filteredBrands = useMemo(
    () => [
      allBrands,
      ...BrandsList.filter((brand) => brandIds.includes(brand.id)),
    ],
    [BrandsList, brandIds]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue === 0) {
        setSelectedBrandId(null);
      } else {
        setSelectedBrandId(filteredBrands[newValue].id);
      }
      setValue(newValue);
    },
    [filteredBrands]
  );

  return (
    <Box sx={{ display: "flex", flexGrow: 1, ml: 2 }}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{
          mt: 1,
          borderRight: 1,
          borderColor: "divider",
          "& .MuiTab-root": {
            "&.Mui-selected": {
              background: "linear-gradient(45deg, #a06eaf, #87449b)",
              color: theme.palette.primary.light,
            },
          },
        }}
      >
        {filteredBrands.map((brand, index) => (
          <Tab label={brand.name} key={brand.id} {...a11yProps(index)} />
        ))}
      </Tabs>
      <CurrentAds
        boxShadow={boxShadow}
        AdsList={currentAds}
        selectedBrandId={selectedBrandId}
        isLoading={isLoading}
        CategoriesList={CategoriesList}
        users={users}
        handleDelete={handleDelete}
        handleDeleteFromChild={handleDeleteFromChild}
      />
    </Box>
  );
}
