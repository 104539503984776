import { createSlice, current } from "@reduxjs/toolkit";

// Function for deep equality check
function deepEqual(object1, object2) {
  if (object1 === object2) {
    return true;
  }
  if (
    typeof object1 !== "object" ||
    typeof object2 !== "object" ||
    object1 == null ||
    object2 == null
  ) {
    return false;
  }
  let keys1 = Object.keys(object1);
  let keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(object1[key], object2[key])) {
      return false;
    }
  }
  return true;
}

const adSlice = createSlice({
  name: "ads",
  initialState: [],
  editingComponent: "",

  reducers: {
    addAd: (state, action) => {
      const existingAdIndex = state.findIndex(
        (ad) => ad.id === action.payload.id
      );
      if (existingAdIndex === -1) {
        state.push(action.payload);
      } else {
        console.log(`Ad with ID ${action.payload.id} already exists.`);
      }
    },
    editAd: (state, action) => {
      console.log("editAd action called with payload:", action.payload);

      const existingAdIndex = state.findIndex(
        (ad) => ad.id === action.payload.id
      );

      if (existingAdIndex !== -1) {
        const { id, ...updatedAdFields } = action.payload;
        state[existingAdIndex] = {
          ...state[existingAdIndex],
          ...updatedAdFields,
        };
      } else {
        console.log("Ad not found with id:", action.payload.id);
      }
    },

    deleteOneAd: (state, action) => {
      const index = state.findIndex((ad) => ad.id === action.payload.id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyAds: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      for (let i = state.length - 1; i >= 0; i--) {
        if (idsToDelete.has(state[i].id)) {
          state.splice(i, 1);
        }
      }
    },
    updateAds: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
    updateAdPositionFields: (state, action) => {
      const { id, positionType, fields } = action.payload;
      const existingAd = state.find((ad) => ad.id === id);

      if (!existingAd) {
        return;
      }

      const positions = existingAd.positions;

      if (!positions || !positions[positionType]) {
        return;
      }

      for (let resolution in positions[positionType]) {
        // check if fields contains resolution
        if (fields.hasOwnProperty(resolution)) {
          // update fields of the matching resolution
          positions[positionType][resolution] = {
            ...positions[positionType][resolution],
            ...fields[resolution],
          };
        } else {
          // check if fields need to be updated directly
          for (let field in fields) {
            if (positions[positionType][resolution].hasOwnProperty(field)) {
              positions[positionType][resolution][field] = fields[field];
            }
          }
        }
      }

      existingAd.positions = positions;

      // update the ad in the state
      const index = state.findIndex((ad) => ad.id === id);
      if (index !== -1) {
        state[index] = existingAd;
      } else {
        console.log(`Failed to find ad in state with id ${id}`);
      }
    },
    setApproverId: (state, action) => {
      const existingAd = state.find((ad) => ad.id === action.payload.adId);
      if (existingAd) {
        existingAd.approverId = action.payload.userId;
        existingAd.status = action.payload.status; // Update the status field
      }
    },
    toggleVisibility: (state, action) => {
      const { id, positionType } = action.payload;
      const existingAd = state.find((ad) => ad.id === id);

      if (!existingAd) {
        return;
      }

      const positions = existingAd.positions;

      if (!positions || !positions[positionType]) {
        return;
      }

      for (let resolution in positions[positionType]) {
        positions[positionType][resolution].visible =
          !positions[positionType][resolution].visible;
      }

      existingAd.positions = positions;
    },
    // New reducer to update thumbnail cache
    updateThumbnailCache: (state, action) => {
      const { id, thumbnail, updatedAt } = action.payload;
      const existingAd = state.find((ad) => ad.id === id);

      if (!existingAd) {
        return;
      }

      existingAd.thumbnailCache = {
        thumbnail,
        updatedAt,
      };
    },
    statusAd: (state, action) => {
      const existingAdIndex = state.findIndex(
        (ad) => ad.id === action.payload.id
      );
      if (existingAdIndex !== -1) {
        // Update only the status of the existing ad
        state[existingAdIndex] = {
          ...state[existingAdIndex],
          status: action.payload.status,
        };
      } else {
        console.log("Ad not found with id:", action.payload.id);
      }
    },
  },
  //  UPDATE FOR ALL RESOLUTIONS
});

export const {
  addAd,
  setAds,
  editAd,
  deleteOneAd,
  updateAds,
  deleteManyAds,
  updateAdPositionFields,
  setApproverId,
  // updateAdField,
  toggleVisibility,
  updateThumbnailCache,
  statusAd,
} = adSlice.actions;

export default adSlice.reducer;
