import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import AdCard from "../../ads/components/AdCard";
import { SocketContext } from "../../../context/SocketContext ";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useTheme } from "@mui/material/styles";

const AdsPreviewContainer = () => {
  const AdsList = useSelector((store) => store.ads);
  const UsersList = useSelector((store) => store.users);
  const socket = useContext(SocketContext);

  const theme = useTheme();

  const CategoriesList = useSelector((store) => store.categories);
  const [pendingAds, setPendingAds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [triggerEffect, setTriggerEffect] = useState(false);

  socket.on("notification", (data) => {
    setTriggerEffect((prev) => !prev);
  });

  useEffect(() => {
    const pending = AdsList.filter((ad) => ad.status === "pending");
    setPendingAds(pending);
  }, [AdsList, triggerEffect]);

  const nextAd = () => {
    if (currentIndex < pendingAds.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousAd = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",

            gap: 2, // Added a gap for space between text and icons
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: theme.palette.primary.tableLabelMain,
              pt: 2,
              position: "relative",
              "&:after": {
                content: '""',
                position: "absolute",
                left: 0,
                width: "100%",
                backgroundImage:
                  "linear-gradient(90deg, #F37979 0%, #A06EAF 100%)",
              },
            }}
          >
            {pendingAds.length === 0 ? "No pending Ads" : "Pending Ads"}
          </Box>
          <Box sx={{ mt: 2 }}>
            <IconButton onClick={previousAd} disabled={currentIndex === 0}>
              <ArrowBackIosIcon sx={{ pl: 1, height: 14 }} />
            </IconButton>
            <IconButton
              onClick={nextAd}
              disabled={currentIndex === pendingAds.length - 1}
            >
              <ArrowForwardIosIcon sx={{ pr: 1, height: 14 }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid #D9D9D9",
            my: 1,
          }}
        />
      </CardContent>
      {pendingAds[currentIndex] && (
        <Box key={pendingAds[currentIndex].id}>
          {(() => {
            const ad = pendingAds[currentIndex];
            const user = UsersList?.find((user) => user.id === ad.uid);
            const approver = UsersList?.find(
              (user) => user.id === ad.approverId
            );

            return (
              <AdCard
                key={ad.id} // Adding a unique key attribute
                dashboard={true}
                data={ad}
                CategoriesList={CategoriesList}
                nextAd={nextAd}
                id={ad.id}
                previousAd={previousAd}
                userImage={ad.userImage}
                username={user?.firstName}
                approverName={approver?.firstName}
                status={ad.status}
                createdAt={ad.createdAt}
                updatedAt={ad.updatedAt}
                comment={ad.comment}
                route='/edit-ad'
                thumbnail={ad?.thumbnailCache?.thumbnail}
              />
            );
          })()}
        </Box>
      )}
    </Card>
  );
};

export default AdsPreviewContainer;
