import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { ACTIONS_URL, CLIENTS_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { editClient } from "../clients/features/clientSlice";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const currentUser = useContext(AuthContext);
  const ClientsList = useSelector((store) => store.clients);

  const [isAIEnabled, setIsAIEnabled] = useState(false);
  const [isShutterstockEnabled, setIsShutterstockEnabled] = useState(false);

  const [aiApiKey, setAiApiKey] = useState("");
  const [shutterstockApiKey, setShutterstockApiKey] = useState("");

  const dispatch = useDispatch();

  const clientId = currentUser?.currentUser?.clientIdUsers;

  useEffect(() => {
    // Find the client with the matching ID
    const client = ClientsList.find((c) => c.id === clientId);

    console.log(client);

    // Check if the client has a data object and update the state
    if (client?.data) {
      setIsAIEnabled(client.data.AIEnabled || false);
      setIsShutterstockEnabled(client.data.ShutterstockEnabled || false);
      setAiApiKey(client.data.AIApiKey || "");
      setShutterstockApiKey(client.data.ShutterstockApiKey || "");
    }
  }, [ClientsList, currentUser]);

  const handleAIToggle = (event) => {
    setIsAIEnabled(event.target.checked);
  };

  const handleShutterstockToggle = (event) => {
    setIsShutterstockEnabled(event.target.checked);
  };

  const handleAiApiKeyChange = (event) => {
    setAiApiKey(event.target.value);
  };

  const handleShutterstockApiKeyChange = (event) => {
    setShutterstockApiKey(event.target.value);
  };

  const updateClientSettings = async () => {
    const payload = {
      data: {
        AIEnabled: isAIEnabled,
        AIApiKey: aiApiKey,
        ShutterstockEnabled: isShutterstockEnabled,
        ShutterstockApiKey: shutterstockApiKey,
      },
    };

    try {
      const response = await axiosInstance.put(
        `${CLIENTS_URL}/${clientId}`,
        payload
      );
      console.log("Settings updated", response.data);

      // Assuming response.data contains the updated client except the data object
      const updatedClient = {
        ...response.data,
        data: payload.data,
      };

      dispatch(editClient(updatedClient));

      // Log action for auditing purposes
      await axiosInstance.post(ACTIONS_URL, {
        actionType: "Update",
        objectType: "Client",
        userId: currentUser?.currentUser.id,
        clientId: currentUser?.currentUser.clientIdUsers,
        content: response.data, // Assuming response.data contains the updated client
      });
    } catch (error) {
      console.error("Error updating settings", error);
    }
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ pl: 10, pr: 2, pt: 2 }}>
        <Grid item xs={12} container alignItems='center' spacing={2}>
          <Grid item xs={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAIEnabled}
                  onChange={handleAIToggle}
                  size='small' // Makes the switch smaller
                />
              }
              label='AI'
              sx={{
                width: "100%",
                "& .MuiTypography-body1": { fontSize: "0.875rem" },
              }} // Smaller label font size
            />
          </Grid>
          <Grid item xs={4}>
            {isAIEnabled && (
              <TextField
                label='AI API Key'
                variant='outlined'
                fullWidth
                size='small' // Makes the TextField smaller
                InputProps={{
                  sx: { fontSize: "0.875rem", padding: "2px" }, // Smaller font size and padding inside the TextField
                }}
                InputLabelProps={{
                  sx: { fontSize: "0.875rem" }, // Smaller label size for the TextField
                }}
                value={aiApiKey} // Control the input with state
                onChange={handleAiApiKeyChange} // Handle changes
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems='center' spacing={2}>
          <Grid item xs={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={isShutterstockEnabled}
                  onChange={handleShutterstockToggle}
                  size='small' // Makes the switch smaller
                />
              }
              label='Shutterstock'
              sx={{
                width: "100%",
                "& .MuiTypography-body1": { fontSize: "0.875rem" },
              }} // Smaller label font size
            />
          </Grid>
          <Grid item xs={4}>
            {isShutterstockEnabled && (
              <TextField
                label='Shutterstock API Key'
                variant='outlined'
                fullWidth
                size='small' // Makes the TextField smaller
                InputProps={{
                  sx: { fontSize: "0.875rem", padding: "2px" }, // Smaller font size and padding inside the TextField
                }}
                InputLabelProps={{
                  sx: { fontSize: "0.875rem" }, // Smaller label size for the TextField
                }}
                value={shutterstockApiKey} // Control the input with state
                onChange={handleShutterstockApiKeyChange} // Handle changes
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 10, pr: 2, pt: 2 }}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              onClick={updateClientSettings}
              sx={{ mt: 2 }} // Add some margin at the top
            >
              Update Settings
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
