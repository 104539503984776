export const resolutions = [
  "160x600",
  "300x600",
  "300x250",
  "600x829",
  "728x90",
  "320x50",
  "875x225",
  "960x676",
  "970x250",
  "1200x628",
  "1200x630",
  "1080x1080",
  "1200x1200",
  "1080x1920",
  "1900x620",
];
