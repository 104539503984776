import React, { useState, useCallback, memo, useEffect } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import { useDispatch } from "react-redux";
import { setEditingComponent } from "../pages/ads/features/editingComponentSlices";
import TransparencyControl from "../pages/ads/components/TransparencyControl ";
import CropDinIcon from "@mui/icons-material/CropDin";
import BorderControl from "../pages/ads/components/BorderControl ";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShadowControl from "../pages/ads/components/ShadowControl ";
import ShadowIcon from "../images/square-shadow.png";
import QueueIcon from "@mui/icons-material/Queue";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setZIndexes } from "../pages/ads/features/zIndexesSlice";
import { useTheme } from "@mui/material/styles";

const ToggleButtonRow = memo(
  ({
    item,
    isSelected,
    isDisabled,
    isVisible,
    isEditable,
    handleRowClick,
    handleVisibilityToggle,
    handleLockClick,
    handleElementTransparencyUpdate,
    editingResolution,
    values,
    setValues,
    handleToggleBorder,
    handleToggleShadow,
  }) => {
    const [borderMenuAnchorEl, setBorderMenuAnchorEl] = useState(null);
    const [shadowMenuAnchorEl, setShadowMenuAnchorEl] = useState(null);
    const isBorderMenuOpen = Boolean(borderMenuAnchorEl);
    const isShadowMenuOpen = Boolean(shadowMenuAnchorEl);

    const theme = useTheme();

    const isBorderSet =
      values?.positions?.[item.key]?.[editingResolution]?.borderStyle;

    const borderIconColor =
      isBorderSet && isBorderSet !== "none" ? "primary" : "disabled";

    const handleBorderIconClick = (event) => {
      event.stopPropagation();
      const currentBorderStyle =
        values?.positions?.[item.key]?.[editingResolution]?.borderStyle;
      const shouldEnableBorder =
        !currentBorderStyle || currentBorderStyle === "none";

      if (shouldEnableBorder) {
        handleToggleBorder(item.key, "1px", "solid", "#000000", true);
      } else {
        handleToggleBorder(item.key, "none", "", "", false);
      }
    };

    const handleMenuClick = (event) => {
      setBorderMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setBorderMenuAnchorEl(null);
    };

    const hardcodedTextElementTypes = ["headline", "subHeadline", "disclaimer"];

    const isHardcodedTextElement = hardcodedTextElementTypes.includes(item.key);

    const isDynamicTextElement = /^text\d+$/.test(item.key);

    const isTextElement = isHardcodedTextElement || isDynamicTextElement;

    const currentShadowStyle = isTextElement
      ? values.positions[item.key]?.[editingResolution]?.textShadow
      : values.positions[item.key]?.[editingResolution]?.boxShadow;

    const shadowIconColor =
      currentShadowStyle && currentShadowStyle !== "none"
        ? "primary"
        : "disabled";

    const handleShadowIconClick = (event) => {
      event.stopPropagation();
      const isTextElement = isHardcodedTextElement || isDynamicTextElement;
      const currentShadowStyle = isTextElement
        ? values.positions[item.key]?.[editingResolution]?.textShadow
        : values.positions[item.key]?.[editingResolution]?.boxShadow;

      if (currentShadowStyle === "none" || !currentShadowStyle) {
        handleToggleShadow(
          item.key,
          "2px",
          "2px",
          "4px",
          "0px",
          "rgba(0, 0, 0, 0.2)",
          true
        );
      } else {
        handleToggleShadow(
          item.key,
          "none",
          "none",
          "none",
          "none",
          "none",
          false
        );
      }
    };

    const handleShadowMenuClick = (event) => {
      event.stopPropagation();
      setShadowMenuAnchorEl(event.currentTarget);
    };

    const handleShadowMenuClose = () => {
      setShadowMenuAnchorEl(null);
    };

    return (
      <Box
        onClick={() => handleRowClick(item.key)}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: isSelected
            ? theme.palette.primary.selected
            : "transparent",
          p: 0.2,
          cursor: "pointer",
          width: "100%",
          height: 35,
        }}
      >
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleVisibilityToggle(item.key, event);
          }}
          disabled={isDisabled}
          sx={{
            color: isVisible ? "primary.main" : theme.palette.primary.disabled,
          }}
        >
          {isVisible ? (
            <VisibilityIcon sx={{ fontSize: 14 }} />
          ) : (
            <VisibilityOffIcon sx={{ fontSize: 14 }} />
          )}
        </IconButton>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleLockClick(item.key, isEditable, event);
          }}
          disabled={isDisabled}
          sx={{
            color: !isEditable
              ? "primary.main"
              : theme.palette.primary.disabled,
            ml: -1,
          }}
        >
          {isEditable ? (
            <LockOpenIcon sx={{ fontSize: 14 }} />
          ) : (
            <LockIcon sx={{ fontSize: 14 }} />
          )}
        </IconButton>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: isVisible ? "bold" : "normal",
            color: isVisible ? "primary.main" : theme.palette.primary.disabled,
            width: 90,
          }}
        >
          {item.label}
        </Typography>
        <TransparencyControl
          editingComponent={item.key}
          editingResolution={editingResolution}
          values={values}
          handleElementTransparencyUpdate={handleElementTransparencyUpdate}
        />
        <IconButton
          onClick={handleBorderIconClick}
          disabled={isDisabled}
          sx={{ color: "primary.main" }}
        >
          <CropDinIcon color={borderIconColor} sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          onClick={handleMenuClick}
          disabled={isDisabled}
          sx={{ color: "primary.main", ml: -2 }}
        >
          <ArrowDropDownIcon sx={{ fontSize: 26 }} />
        </IconButton>
        <Menu
          anchorEl={borderMenuAnchorEl}
          open={isBorderMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem>
            <BorderControl
              editingComponent={item.key}
              editingResolution={editingResolution}
              handleToggleBorder={handleToggleBorder}
              values={values}
            />
          </MenuItem>
        </Menu>

        <IconButton
          onClick={handleShadowIconClick}
          disabled={isDisabled}
          sx={{ ml: -2 }}
        >
          <QueueIcon color={shadowIconColor} sx={{ fontSize: 18 }} />
        </IconButton>

        <IconButton
          onClick={handleShadowMenuClick}
          disabled={isDisabled}
          sx={{ color: "primary.main", ml: -1.5 }}
        >
          <ArrowDropDownIcon sx={{ fontSize: 26 }} />
        </IconButton>
        <Menu
          anchorEl={shadowMenuAnchorEl}
          open={isShadowMenuOpen}
          onClose={handleShadowMenuClose}
        >
          <MenuItem onClick={handleShadowMenuClose}>
            <ShadowControl
              editingComponent={item.key}
              editingResolution={editingResolution}
              handleToggleShadow={handleToggleShadow}
              values={values}
            />
          </MenuItem>
        </Menu>
      </Box>
    );
  }
);

function ToggleButtonItems({
  items,
  editingResolution,
  values,
  handleToggleVisibility,
  handleElementTransparencyUpdate,
  handleLockClick,
  handleToggleBorder,
  isBorderSet,
  editingComponent,
  handleToggleShadow,
  setValues,
  setEditingResolution,
  editStates,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const isDisabled = !editingResolution;

  const [isReadyForDraggable, setIsReadyForDraggable] = useState(false);

  const dispatch = useDispatch();
  const [orderedItems, setOrderedItems] = useState(items);

  useEffect(() => {
    if (!editingResolution) return;

    const initializedItems = items.map((item, index) => ({
      ...item,
      zIndex: values.positions[item.key]?.[editingResolution]?.zIndex || 0,
    }));

    const sortedItems = initializedItems
      .filter((item) => item.key !== "resolutions")
      .sort((a, b) => b.zIndex - a.zIndex);

    setOrderedItems(sortedItems);
  }, [items, values, editingResolution]);

  useEffect(() => {
    if (editingResolution && orderedItems.length > 0) {
      setIsReadyForDraggable(true);
    }
  }, [orderedItems, editingResolution]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const newItems = Array.from(orderedItems);
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);

      setOrderedItems(newItems);

      const newValues = JSON.parse(JSON.stringify(values));

      newItems.forEach((item, index) => {
        const itemKey = item.key;

        if (editStates["1200x630"]) {
          Object.keys(newValues.positions[itemKey]).forEach((resolution) => {
            if (typeof newValues.positions[itemKey][resolution] !== "object") {
              newValues.positions[itemKey][resolution] = {};
            }
            newValues.positions[itemKey][resolution].zIndex =
              newItems.length - index;
          });
        } else {
          if (
            typeof newValues.positions[itemKey][editingResolution] !== "object"
          ) {
            newValues.positions[itemKey][editingResolution] = {};
          }
          newValues.positions[itemKey][editingResolution].zIndex =
            newItems.length - index;
        }
      });

      setValues(newValues);
    },
    [orderedItems, values, editingResolution, setValues, editStates]
  );

  const handleRowClick = useCallback(
    (key) => {
      setSelectedItem(key);
      dispatch(setEditingComponent(key));
    },
    [dispatch]
  );

  const handleVisibilityToggle = useCallback(
    (key, event) => {
      event.stopPropagation();
      setSelectedItem(key);
      dispatch(setEditingComponent(key));
      handleToggleVisibility(key);
    },
    [dispatch, handleToggleVisibility]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={editingComponent}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                p: 0.2,
                width: "100%",
              }}
            >
              <Typography
                sx={{ width: 90, fontSize: "0.8rem", color: "primary.main" }}
              ></Typography>
              <Typography
                sx={{
                  flex: "1 1 auto",
                  minWidth: 0,
                  fontSize: "0.7rem",
                  color: "primary.main",
                  ml: 7,
                }}
              >
                Opacity
              </Typography>
              <Typography
                sx={{
                  flex: "1 1 auto",
                  minWidth: 0,
                  fontSize: "0.7rem",
                  color: "primary.main",
                  ml: 0,
                }}
              >
                Border
              </Typography>
              <Typography
                sx={{
                  flex: "1 1 auto",
                  minWidth: 0,
                  fontSize: "0.7rem",
                  color: "primary.main",
                  ml: 0,
                }}
              >
                Shadow
              </Typography>
            </Box>

            {isReadyForDraggable &&
              orderedItems.map((item, index) => {
                if (!editingResolution || !item) {
                  return null;
                }

                const isVisible =
                  values?.positions[item.key]?.[editingResolution]?.visible;
                const isEditable =
                  values?.positions[item.key]?.[editingResolution]?.editable;

                const uniqueKey = `${item.key}-${editingResolution}`;
                const draggableId = `draggable-${uniqueKey}`;

                return (
                  <Draggable
                    key={uniqueKey}
                    draggableId={draggableId}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <ToggleButtonRow
                          item={item}
                          isSelected={item.key === editingComponent}
                          isDisabled={isDisabled}
                          isVisible={isVisible}
                          isEditable={isEditable}
                          handleRowClick={handleRowClick}
                          handleVisibilityToggle={handleVisibilityToggle}
                          handleLockClick={handleLockClick}
                          handleElementTransparencyUpdate={
                            handleElementTransparencyUpdate
                          }
                          editingResolution={editingResolution}
                          values={values}
                          handleToggleBorder={handleToggleBorder}
                          handleToggleShadow={handleToggleShadow}
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ToggleButtonItems;
