import React from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  FormControlLabel,
  Box,
  Popover,
} from "@mui/material";
import { useState, useRef } from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch } from "react-redux";
import { setEditingComponent } from "../pages/ads/features/editingComponentSlices";

const DownloadMenu = ({
  anchorEl,
  handleClose,
  handleCheckboxChange,
  handleDownload,
  selectedResolutions,
  networkTags,
}) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const clickedButtonRef = useRef(null);

  const dispatch = useDispatch();

  const iconMapping = {
    Facebook: <FacebookIcon color='primary' />,
    Instagram: <InstagramIcon color='primary' />,
    LinkedIn: <LinkedInIcon color='primary' />,
    Web: <LanguageIcon color='primary' />,
    All: null, // No icon for "All"
  };

  const isAnyResolutionSelected = () => {
    return Object.values(selectedResolutions).some((value) => value);
  };

  const areAllResolutionsSelected = () => {
    return networkTags[selectedNetwork]?.every(
      (resolution) => selectedResolutions[resolution]
    );
  };

  const handleSelectAll = (event) => {
    const shouldSelect = !areAllResolutionsSelected();

    const allResolutions = networkTags[selectedNetwork].reduce((acc, curr) => {
      acc[curr] = shouldSelect;
      return acc;
    }, {});

    for (let resolution in allResolutions) {
      handleCheckboxChange({
        target: {
          name: resolution,
          checked: allResolutions[resolution],
        },
      });
    }
  };

  const handleClearSelection = () => {
    for (let resolution of networkTags[selectedNetwork]) {
      handleCheckboxChange({
        target: {
          name: resolution,
          checked: false,
        },
      });
    }
  };

  const handleNetworkClick = (network, event) => {
    setSelectedNetwork(network);
    setPopoverAnchorEl(clickedButtonRef.current);
  };

  const handleMenuItemDownload = async () => {
    setPopoverAnchorEl(null); // Close the side menu (Popover)
    if (handleDownload) {
      await handleDownload();
    }
  };

  return (
    <Box sx={{ mr: -3 }}>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.keys(networkTags).map((network) => (
          <MenuItem
            key={network}
            onClick={(event) => handleNetworkClick(network, event)}
            ref={clickedButtonRef}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "primary.main",
              }}
            >
              {iconMapping[network] && (
                <Box sx={{ pr: 1 }}>{iconMapping[network]}</Box>
              )}
              {network}
            </Box>
          </MenuItem>
        ))}
      </Menu>

      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {selectedNetwork && (
          <>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: "primary.main" }}
                    checked={areAllResolutionsSelected()}
                    onChange={handleSelectAll}
                  />
                }
                label={
                  <ListItemText
                    primary='Select All'
                    sx={{ color: "primary.main" }}
                  />
                }
              />
            </MenuItem>
            {networkTags[selectedNetwork].map((resolution) => (
              <MenuItem key={`${selectedNetwork}-${resolution}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: "primary.main" }}
                      checked={selectedResolutions[resolution] || false}
                      onChange={handleCheckboxChange}
                      name={resolution}
                    />
                  }
                  label={
                    <ListItemText
                      primary={resolution}
                      sx={{ color: "primary.main" }}
                    />
                  }
                />
              </MenuItem>
            ))}
            <MenuItem onClick={handleMenuItemDownload}>
              <Box display='flex' justifyContent='center' width='100%'>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={!isAnyResolutionSelected()}
                >
                  Download
                </Button>
              </Box>
            </MenuItem>

            {isAnyResolutionSelected() && (
              <MenuItem onClick={handleClearSelection}>
                <Button variant='text' color='primary'>
                  Clear Selection
                </Button>
              </MenuItem>
            )}
          </>
        )}
      </Popover>
    </Box>
  );
};

export default DownloadMenu;
