import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Tooltip,
  CircularProgress,
  Typography,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Masonry from "@mui/lab/Masonry";
import { motion } from "framer-motion";
import axiosInstance from "../services/axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import { addBackground } from "../pages/brands/features/backgroundReducer";
import { AuthContext } from "../context/authContext";

const ShutterstockImages = ({
  images,
  itemVariant,
  selectedBrandId,
  fetchingImages,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const brandsList = useSelector((store) => store.brands);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    setLoadedImagesCount(0);
  }, [images]);

  const getBrandNameById = (brandId) => {
    const brand = brandsList.find((b) => b.id === brandId);
    return brand ? brand.name : "DefaultBrand";
  };

  const downloadHighResImage = async (imgId, brandId) => {
    setLoading(true);
    const brandName = getBrandNameById(brandId);
    const type = "backgrounds";

    try {
      const response = await axiosInstance.post("shutterstock/purchase", {
        imageId: imgId,
        brandId: brandId,
        brand: brandName,
        type: type,
        clientId: currentUser.clientIdUsers, // Ensure client ID is passed
      });

      if (response.data && response.data.background) {
        dispatch(addBackground(response.data.background));
      }
    } catch (err) {
      console.error("Error purchasing image:", err);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {(loading || fetchingImages) && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            {loading ? "Downloading image..." : "Loading..."}
          </Typography>
        </Box>
      )}
      <Masonry columns={2} spacing={2}>
        {images.map((imgData) => {
          const imgUrl = imgData.assets.preview_1000
            ? imgData.assets.preview_1000.url
            : imgData.assets.preview.url;

          return (
            <Box key={imgData.id} sx={{ ml: 2 }}>
              <Tooltip
                title={imgData.description || "No description available"}
              >
                <motion.div
                  variants={itemVariant}
                  whileHover={{ scale: 1.05 }}
                  onClick={() =>
                    downloadHighResImage(imgData.id, selectedBrandId)
                  }
                >
                  <img
                    src={imgUrl}
                    alt={`shutterstock-img-${imgData.id}`}
                    loading='lazy'
                    onLoad={() =>
                      setLoadedImagesCount((prevCount) => prevCount + 1)
                    }
                    style={{
                      width: "100%",
                      display: "block",
                      cursor: "pointer",
                    }}
                  />
                </motion.div>
              </Tooltip>
            </Box>
          );
        })}
      </Masonry>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message='Download complete'
        action={
          <React.Fragment>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
        ContentProps={{
          sx: { backgroundColor: "#8ACC84" },
        }}
      />
    </Box>
  );
};

export default ShutterstockImages;
