import React, { useEffect, useState } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

const TextAlignmentButtons = ({
  updateStyle,
  values,
  editingComponent,
  editingResolution,
}) => {
  const [textAlign, setTextAlign] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.textAlign ||
      "left"
  );

  useEffect(() => {
    const newTextAlign =
      values?.positions?.[editingComponent]?.[editingResolution]?.textAlign ||
      "left";
    setTextAlign(newTextAlign);
  }, [values, editingComponent, editingResolution]);

  const handleAlignmentChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setTextAlign(newAlignment);
      updateStyle("textAlign", newAlignment);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ToggleButtonGroup
      value={textAlign}
      exclusive
      onChange={handleAlignmentChange}
      aria-label='text alignment'
      sx={{
        "& .MuiToggleButton-root": {
          padding: isMobile ? "4px" : isTablet ? "5px" : "6px",
          "& .MuiSvgIcon-root": {
            fontSize: isMobile ? "0.8rem" : isTablet ? "0.9rem" : "1rem",
          },
        },
      }}
    >
      <ToggleButton value='left' aria-label='left aligned'>
        <FormatAlignLeftIcon />
      </ToggleButton>
      <ToggleButton value='center' aria-label='centered'>
        <FormatAlignCenterIcon />
      </ToggleButton>
      <ToggleButton value='right' aria-label='right aligned'>
        <FormatAlignRightIcon />
      </ToggleButton>
      <ToggleButton value='justify' aria-label='justified'>
        <FormatAlignJustifyIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TextAlignmentButtons;
