import { Box, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: 3,
      }}
    >
      <SentimentVeryDissatisfiedIcon
        sx={{ fontSize: "5rem", color: "grey.500" }}
      />
      <Typography variant="h4" color="textSecondary">
        Oopsie Daisies!
      </Typography>
      <Typography variant="h2" color="#f37979">
        404 Page Not Found
      </Typography>
      <Typography variant="h6" color="textSecondary">
        🛸 We've looked everywhere, even in another galaxy!
      </Typography>
      <Typography variant="body1">
        Sorry, but the page you're looking for seems to have vanished into thin
        air!
      </Typography>
    </Box>
  );
};
