import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AdChip from "./AdChip";
import { AuthContext } from "../../../context/authContext";
import axiosInstance from "../../../services/axiosInstance";
import { ACTIONS_URL, ADS_URL, AI_URL } from "../../../config";
import { addAd } from "../features/adSlice";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ResolutionDropdownWizzard from "./ResolutionDropdownWizzard";
import { useTheme } from "@mui/material/styles";
import { Select, MenuItem } from "@mui/material";
import { keyframes } from "@mui/system";
import TextGenerationComponent from "./TextGenerationComponent";
import ProductSelector from "./ProductSelector ";
import BackgroundsSelector from "./BackgroundsSelector";
import AdvertisingEtailSelector from "./AdvertisingEtailSelector";

export default function WizardModal({ setShowWizardModal }) {
  const BrandsList = useSelector((store) => store.brands);
  const CategoriesList = useSelector((store) => store.categories);
  const ClientsList = useSelector((store) => store.clients);
  const TemplatesList = useSelector((store) => store.templates);

  const { currentUser } = useContext(AuthContext);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [isAIEnabled, setIsAIEnabled] = useState(false);

  const [isAmdBrand, setIsAmdBrand] = useState(false);
  const [selectedAdvertisingOption, setSelectedAdvertisingOption] =
    useState("");

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const currentClient = ClientsList?.find(
      (client) => client.id === currentUser.clientIdUsers
    );
    if (currentClient && currentClient?.settings?.ai?.AIEnabled !== undefined) {
      setIsAIEnabled(currentClient.settings.ai.AIEnabled);
    } else {
      setIsAIEnabled(false); // default to false if AIEnabled is not defined
    }
    console.log("currentUser", currentClient);
  }, [ClientsList, currentUser]);

  console.log("currentUser", isAIEnabled);

  const handlePostRequest = async (e) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Checking wallet balance...");

      const response = await axiosInstance.post(ADS_URL, {
        title: values.title,
        brand_id: +values.brand_id,
        category_id: +values.category_id,
        template_id: +values.template_id,
        headline: values.headline,
        subHeadline: values.subHeadline,
        disclaimer: values.disclaimer,
        backgroundimage: values.backgroundimage,
        productImage: values.productImage,
        logoBrand1: values.logoBrand1,
        logoBrand2: values.logoBrand2,
        logoBrand3: values.logoBrand3,
        logoThirdParty: values.logoThirdParty,
        sticker1: values.sticker1,
        sticker2: values.sticker2,
        sticker3: values.sticker3,
        call_to_action: values.call_to_action,
        positions: values.positions,
        status: "pending",
        uid: +currentUser.id,
        approverId: null,
      });

      const savedDatafromPostResponse = response.data;
      dispatch(
        addAd({
          id: savedDatafromPostResponse.id,
          title: savedDatafromPostResponse.title,
          headline: savedDatafromPostResponse.headline,
          subHeadline: savedDatafromPostResponse.subHeadline,
          backgroundimage: savedDatafromPostResponse.backgroundimage,
          productImage: savedDatafromPostResponse.productImage,
          logoBrand1: savedDatafromPostResponse.logoBrand1,
          logoBrand2: savedDatafromPostResponse.logoBrand2,
          logoBrand3: savedDatafromPostResponse.logoBrand3,
          status: savedDatafromPostResponse.status,
          cat: savedDatafromPostResponse.cat,
          createdAt: savedDatafromPostResponse.createdAt,
          updatedAt: savedDatafromPostResponse.updatedAt,
          positions: savedDatafromPostResponse.positions,
          createdBy: savedDatafromPostResponse.createdBy,
          brand: savedDatafromPostResponse.brand,
          brand_id: savedDatafromPostResponse.brand_id,
          uid: savedDatafromPostResponse.uid,
          currentUser: currentUser,
          approverId: savedDatafromPostResponse.approverId,
        })
      );

      setLoadingMessage("Deducting 10 tokens from balance for ad creation...");

      await axiosInstance.post(ACTIONS_URL, {
        actionType: "Create", // Using enum value "Create"
        objectType: "Ad", // Using objectType "Ad"
        userId: currentUser.id,
        adId: response.data.id,
        clientId: currentUser.clientIdUsers,
        adTitle: values.title,
        content: response.data,
      });

      setLoadingMessage("Ad created successfully!");
      setTimeout(() => {
        setShowWizardModal(false);
        setIsLoading(false);
        setLoadingMessage("");
      }, 2000); // Close the modal after a short delay
    } catch (err) {
      console.log(err);
      setLoadingMessage(
        "Error: Insufficient funds in your wallet, ad cannot be created."
      );
      setIsLoading(false);
      setErrorDialogOpen(true); // Open the error dialog
    }
  };

  const logosData = [
    {
      id: 1,
      name: "Branding",
      type: "1rd",
    },
    {
      id: 2,
      name: "Co-branding",
      type: "2rd",
    },
    {
      id: 3,
      name: "3 Partners",
      type: "3rd",
    },
    {
      id: 4,
      name: "4 Partners",
      type: "4rd",
    },
  ];

  const [step, setStep] = useState(0); // Start from 0
  const [brand, setBrand] = useState("");
  const [logo, setLogo] = useState("");
  const [category, setCategory] = useState("");
  const [adData, setAdData] = useState({
    brand: "",
    logosData: "",
    category: "",
    title: "",
  });

  const [selectedWizzardResolutions, setSelectedWizzardResolutions] = useState(
    []
  );

  const updateValues = (newValues) => {
    setValues({ ...values, ...newValues });
  };

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      positions: {
        ...prevValues.positions,
        resolutions: selectedWizzardResolutions,
      },
    }));
  }, [selectedWizzardResolutions]);

  const [values, setValues] = useState({
    title: "",
    brand_id: "",
    category_id: "",
    template_id: "",
    headline: "",
    subHeadline: "",
    disclaimer: "",
    backgroundimage: "",
    productImage: "",
    logoBrand1: "",
    logoBrand2: "",
    logoBrand3: "",
    logoThirdParty: "",
    sticker1: "",
    sticker2: "",
    sticker3: "",
    template_type: "",
    call_to_action: "",
    status: "",
    type: "",
    uid: currentUser.id,
    positions: {},
  });

  console.log("Positions", values?.positions);

  useEffect(() => {
    updateValues(adData);
  }, [adData]);

  const handleData = async (value, label, id) => {
    let numericId;

    if (["Brand", "Category", "Logo"].includes(label)) {
      numericId = parseInt(id, 10);

      if (isNaN(numericId)) {
        console.error("Invalid ID");
        return;
      }
    }

    if (label === "Brand") {
      // Set isAmdBrand to true if the selected brand is 'Amd'
      setIsAmdBrand(value === "Amd");

      console.log("Step: Inside Brand Logic");
      setAdData((prevState) => ({
        ...prevState,
        brand: value,
        brand_id: numericId,
      }));
      setValues((prevState) => ({
        ...prevState,
        brand_id: numericId,
      }));
    } else if (label === "Category") {
      setAdData((prevState) => ({
        ...prevState,
        category: value,
        category_id: numericId,
      }));
      setValues((prevState) => ({
        ...prevState,
        category_id: numericId,
      }));
    } else if (label === "Logo") {
      const selectedLogo = logosData.find((logo) => logo.name === value);

      if (selectedLogo) {
        setAdData((prevState) => ({
          ...prevState,
          logosData: value,
          category_id: numericId,
        }));
        const typeToFind = selectedLogo.type;

        const layout = TemplatesList.find(
          (template) => template.type === typeToFind
          // && template.brand_id === adData.brand_id
        );
        if (layout && layout.id) {
          console.log("this is layout", layout.id);
          setValues((prevState) => ({
            ...prevState,
            template_id: layout.id,
            positions: layout.positions,
          }));
        } else {
          console.error("Template or template ID not found!");
        }
      }
    } else if (label === "Ad name") {
      setAdData((prevState) => ({
        ...prevState,
        title: value,
      }));
      setValues((prevState) => ({
        ...prevState,
        title: value,
      }));
    }
  };

  // console.log(selectedProduct);

  // Modify totalSteps to include the AdvertisingEtailSelector step conditionally
  const totalSteps = isAIEnabled ? (isAmdBrand ? 9 : 8) : isAmdBrand ? 8 : 7;

  // Modified handleNext to accommodate the change in total steps
  const handleNext = () => {
    if (step < totalSteps - 1) {
      setStep(step + 1);
    } else {
      handleFinish(); // Call handleFinish if it's the last step
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleFinish = () => {
    handlePostRequest();
    setSelectedLanguage("");
  };
  const adiData = {
    productName: "Dell Inspiron",
  };

  // handleLanguageChange only updates the language state
  const handleLanguageChange = async (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);

    // Ensure all required fields are set before making the API call
    if (!selectedProduct || !adData.brand || !adData.category) {
      console.error("Missing required fields:", {
        productName: selectedProduct?.data?.productName,
        brand: adData.brand,
        category: adData.category,
      });
      return;
    }

    try {
      setIsLoading(true); // Start loading
      const response = await axiosInstance.post(`${AI_URL}/generate-ad-text`, {
        clientId: currentUser.clientIdUsers,
        language,
        brand: adData.brand,
        category: adData.category,
        productName: selectedProduct.data.productName,
        productDescription: selectedProduct.data.productDescription,
      });

      const { headline, subHeadline, disclaimer } = response.data;

      setValues((prevValues) => ({
        ...prevValues,
        headline,
        subHeadline,
        disclaimer,
      }));
    } catch (error) {
      console.error("Error generating text:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const fadeInOut = keyframes`
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  `;

  // Additional animation to simulate a bubbling effect
  const bubble = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  `;

  // console.log(selectedProduct);

  return (
    <>
      <Dialog
        open
        maxWidth='lg'
        sx={{
          minWidth: 1200,
          padding: 4,
        }}
      >
        <DialogContent sx={{ minWidth: 1000, padding: 4 }}>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
              }}
            >
              <CircularProgress />
              <Typography
                sx={{
                  mt: 2,
                  animation: `${fadeInOut} 3s infinite, ${bubble} 1.5s infinite`,
                }}
              >
                {loadingMessage}
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: theme.palette.primary.tableLabelMain,
                    minWidth: 350,
                    position: "relative",
                    "&::after": {
                      content: '""',
                      display: "block",
                      width: "22%",
                      height: "2px",
                      position: "absolute",
                      bottom: -1,
                      left: 0,
                      background:
                        "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
                    },
                  }}
                >
                  Create Ad
                </Typography>

                <IconButton
                  sx={{ position: "relative", top: -5, right: -10 }}
                  color='inherit'
                  onClick={() => setShowWizardModal(false)}
                >
                  <CloseIcon color='primary' sx={{ fontSize: 18 }} />
                </IconButton>
              </Box>
              <Divider sx={{ mb: 4 }} />
              <Stepper
                activeStep={step}
                sx={{ mb: 6, ml: -1 }}
                orientation='horinzontal'
              >
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Ad name
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Brand
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Logo
                    </Typography>
                  </StepLabel>
                </Step>
                {isAmdBrand && (
                  <Step>
                    <StepLabel>Type</StepLabel>
                  </Step>
                )}
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Background
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Product
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Category
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                    <Typography sx={{ fontSize: 15 }} color='primary'>
                      Ad Resolutions
                    </Typography>
                  </StepLabel>
                </Step>
                {isAIEnabled && (
                  <Step>
                    <StepLabel StepIconProps={{ style: { fontSize: 20 } }}>
                      <Typography sx={{ fontSize: 15 }} color='primary'>
                        Language
                      </Typography>
                    </StepLabel>
                  </Step>
                )}
              </Stepper>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flexGrow: 1, width: "50%" }}>
                  {step === 0 && (
                    <TextField
                      fullWidth
                      label='Ad name'
                      variant='standard'
                      value={adData.adName}
                      onChange={(e) => handleData(e.target.value, "Ad name")}
                      sx={{
                        mb: 4,
                        height: 40,
                        "& input": {
                          color: "primary.main",
                        },
                      }}
                    />
                  )}
                  {step === 1 && (
                    <AdChip
                      initialName={brand}
                      data={handleData}
                      label={"Brand"}
                      List={BrandsList}
                    />
                  )}
                  {step === 2 && (
                    <AdChip
                      initialName={logo}
                      data={handleData}
                      label={"Logo"}
                      List={logosData}
                    />
                  )}
                  {isAmdBrand && step === 3 && (
                    <AdvertisingEtailSelector
                      selectedAdvertisingOption={selectedAdvertisingOption}
                      setSelectedAdvertisingOption={
                        setSelectedAdvertisingOption
                      }
                      values={values}
                      setValues={setValues}
                    />
                  )}
                  {step === (isAmdBrand ? 4 : 3) && (
                    <BackgroundsSelector
                      selectedBrand={values.brand_id}
                      setValues={setValues}
                      values={values}
                    />
                  )}
                  {step === (isAmdBrand ? 5 : 4) && (
                    <ProductSelector
                      selectedBrand={values.brand_id}
                      selectedProduct={selectedProduct}
                      setSelectedProduct={setSelectedProduct}
                      setValues={setValues}
                      values={values}
                    />
                  )}
                  {step === (isAmdBrand ? 6 : 5) && (
                    <AdChip
                      initialName={category}
                      data={handleData}
                      label={"Category"}
                      List={CategoriesList}
                    />
                  )}
                  {step ===
                    (isAIEnabled ? totalSteps - 2 : isAmdBrand ? 7 : 6) && (
                    <ResolutionDropdownWizzard
                      selectedWizzardResolutions={selectedWizzardResolutions}
                      setSelectedWizzardResolutions={
                        setSelectedWizzardResolutions
                      }
                    />
                  )}
                  {isAIEnabled && step === totalSteps - 1 && (
                    <Box sx={{ mb: 4 }}>
                      <Select
                        fullWidth
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          height: 40,
                          "& .MuiSelect-select": {
                            color: "primary.main",
                          },
                        }}
                      >
                        <MenuItem value='' disabled>
                          Select Language
                        </MenuItem>
                        <MenuItem value='English'>English</MenuItem>
                        <MenuItem value='Spanish'>Spanish</MenuItem>
                        <MenuItem value='French'>French</MenuItem>
                      </Select>
                    </Box>
                  )}
                  <DialogActions
                    sx={{
                      justifyContent: "flex-start",
                      gap: 1,
                      ml: -0.7,
                      mt: 0,
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ fontSize: 14, color: theme.typography.fontColor }}
                      >
                        {
                          {
                            0: adData?.title ? (
                              <>
                                {"You've entered "}
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.typography.fontColor,
                                  }}
                                >
                                  "{adData.title}"
                                </span>
                                {" as the name for your advertisement."}
                              </>
                            ) : (
                              "Enter a unique name for your advertisement."
                            ),
                            1: adData?.brand ? (
                              <>
                                {"You've selected "}
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.typography.fontColor,
                                  }}
                                >
                                  "{adData.brand}"
                                </span>
                                {" as the brand this ad will represent."}
                              </>
                            ) : (
                              "Select the brand that this ad will represent."
                            ),
                            [isAmdBrand ? 2 : 2]: adData?.logosData ? (
                              <>
                                {"You've selected "}
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: theme.typography.fontColor,
                                  }}
                                >
                                  {
                                    {
                                      Branding: '"Branding"',
                                      "Co-branding": '"Co-branding"',
                                      "3 Partners": '"3 Partners"',
                                      "4 Partners": '"4 Partners"',
                                    }[adData?.logosData]
                                  }
                                </span>
                                {
                                  {
                                    Branding:
                                      ", which means only you will be represented.",
                                    "Co-branding":
                                      ", which implies one partner will be represented.",
                                    "3 Partners":
                                      ", which means two partners will be represented.",
                                    "4 Partners":
                                      ", indicating three partners will be represented.",
                                  }[adData?.logosData]
                                }
                              </>
                            ) : (
                              "Choose the number of partners that will be represented in the ad."
                            ),
                            [isAmdBrand ? 3 : 3]: isAmdBrand
                              ? `You selected '${selectedAdvertisingOption}'.`
                              : null,
                            [isAmdBrand ? 4 : 4]:
                              "Choose the product that you want to promote.",
                            [isAmdBrand ? 5 : 5]:
                              "Choose the background for your advertisement.",
                            [isAmdBrand ? 6 : 6]: adData?.category ? (
                              <>
                                {"You've selected "}
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.typography.fontColor,
                                  }}
                                >
                                  {adData.category}
                                </span>
                                {" as the category under which your ad falls."}
                              </>
                            ) : (
                              "Select the category under which your ad falls."
                            ),
                          }[step]
                        }
                      </Typography>
                    </Box>
                    <Button
                      onClick={handleBack}
                      variant='outlined'
                      sx={{ height: 28, minWidth: 70 }}
                      disabled={step === 0}
                    >
                      Back
                    </Button>
                    {step < totalSteps - 1 ? (
                      <Button
                        onClick={handleNext}
                        variant='contained'
                        color='primary'
                        sx={{ height: 28, minWidth: 70 }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        onClick={handleFinish}
                        variant='contained'
                        color='primary'
                        sx={{ height: 28, minWidth: 70 }}
                        disabled={!selectedLanguage && isAIEnabled}
                      >
                        Finish
                      </Button>
                    )}
                  </DialogActions>
                </Box>
              </Box>
            </>
          )}
          {isAIEnabled && step === totalSteps - 1 && (
            <TextGenerationComponent
              selectedProduct={selectedProduct}
              adData={adData}
              adiData={adiData}
              setIsLoading={setIsLoading}
              selectedLanguage={selectedLanguage}
              setValues={setValues}
            />
          )}
        </DialogContent>
        <Dialog
          open={errorDialogOpen}
          onClose={() => setErrorDialogOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogContent>
            <Typography variant='body1'>
              Error: Insufficient funds in your wallet, ad cannot be created.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
}
