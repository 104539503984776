import React, { useState } from "react";
import {
  AppBar,
  Card,
  Grid,
  IconButton,
  ToggleButton,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StandardSelectInfo from "./StandardSelectInfo";
import StandardSelectCorner from "./StandardSelectCorner";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";

const StandardSelectDrawer = ({
  partnership,
  handlePartnershipClick,
  adsData,
  values,
  setLogoCornersConfig,
  setValues,
  initialLogoBrands,
  existingAd,
}) => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("StandardSelectInfo");

  const [showInfoPage, setShowInfoPage] = useState(true);
  const [alignment, setAlignment] = useState("off");

  const handleCheckboxChange = (isChecked) => {
    setShowInfoPage(!isChecked);
  };

  const nextPage = () => {
    setCurrentPage("StandardSelectCorner");
  };

  const previousPage = () => {
    setCurrentPage("StandardSelectInfo");
  };

  return (
    <>
      <AppBar
        position='static'
        color='primary'
        sx={{
          height: 30,
          background: "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
        }}
      >
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ ml: 3, mt: 0.5, fontSize: 14 }}
            variant='h6'
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            BRAND STANDARD
          </Typography>

          <Grid item>
            <IconButton
              sx={{ color: theme.palette.primary.light, mt: -0.5, mr: 0.3 }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              {drawerOpen ? (
                <KeyboardArrowUpIcon sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ mr: 1 }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      {drawerOpen && (
        <>
          {showInfoPage && (
            <StandardSelectInfo
              onCheckboxChange={handleCheckboxChange}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          )}
          {!showInfoPage && (
            <StandardSelectCorner
              partnership={partnership}
              handlePartnershipClick={handlePartnershipClick}
              setLogoCornersConfig={setLogoCornersConfig}
              values={values}
              adsData={adsData}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              setValues={setValues}
              initialLogoBrands={initialLogoBrands}
              existingAd={existingAd}
            />
          )}
          <AppBar
            position='static'
            color='primary'
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: 10,
              mb: 2,
            }}
          >
            {" "}
          </AppBar>
        </>
      )}
    </>
  );
};

export default StandardSelectDrawer;
