import React, { useState, useEffect } from "react";
import { Box, TextField, useTheme, useMediaQuery, Grid } from "@mui/material";
import AlignmentButtons from "./AlignmentButtons";
import AiIconsGroup from "./AiIconsGroup";

const PositionDimensionControls = ({
  editingComponent,
  editingResolution,
  setValues,
  values,
  bannerHeight,
  bannerWidth,
  handleElementDragResize,
  handleClick,
  editStates,
}) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotation: 0,
  });

  useEffect(() => {
    const newPos = values.positions?.[editingComponent]?.[editingResolution];
    setPosition({
      x: newPos && newPos.x !== undefined ? String(newPos.x) : 0,
      y: newPos && newPos.y !== undefined ? String(newPos.y) : 0,
      width: newPos && newPos.width !== undefined ? String(newPos.width) : 0,
      height: newPos && newPos.height !== undefined ? String(newPos.height) : 0,
      rotation:
        newPos && newPos.rotation !== undefined ? String(newPos.rotation) : 0,
    });
  }, [editingComponent, editingResolution, values]);

  const handleChange = (prop) => (event) => {
    const newValue = +event.target.value !== "" ? event.target.value : 0;
    setPosition({ ...position, [prop]: newValue });
    setValues((prevState) => ({
      ...prevState,
      positions: {
        ...prevState.positions,
        [editingComponent]: {
          ...prevState.positions[editingComponent],
          [editingResolution]: {
            ...prevState.positions[editingComponent][editingResolution],
            [prop]: +newValue,
          },
        },
      },
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Grid
      container
      spacing={1}
      sx={{
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "flex-start" : "center",
        mr: 2,
        flexWrap: isTablet ? "wrap" : "nowrap",
      }}
    >
      <Grid item xs={12} sm={"auto"}>
        <AiIconsGroup
          values={values}
          setValues={setValues}
          editingComponent={editingComponent}
          editingResolution={editingResolution}
          onClick={handleClick}
          editStates={editStates}
          alt='AI Robot'
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <AlignmentButtons
          editingComponent={editingComponent}
          editingResolution={editingResolution}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
          handleElementDragResize={handleElementDragResize}
          values={values}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <TextField
          label='X'
          type='number'
          size='small'
          sx={{
            ...textFieldStyle,
            width: isMobile || isTablet ? "100%" : "65px",
          }}
          value={position.x}
          onChange={handleChange("x")}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <TextField
          label='Y'
          type='number'
          size='small'
          sx={{
            ...textFieldStyle,
            width: isMobile || isTablet ? "100%" : "65px",
          }}
          value={position.y}
          onChange={handleChange("y")}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <TextField
          label='Width'
          type='number'
          size='small'
          sx={{
            ...textFieldStyle,
            width: isMobile || isTablet ? "100%" : "65px",
          }}
          value={position.width}
          onChange={handleChange("width")}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <TextField
          label='Height'
          type='number'
          size='small'
          sx={{
            ...textFieldStyle,
            width: isMobile || isTablet ? "100%" : "65px",
          }}
          value={position.height}
          onChange={handleChange("height")}
        />
      </Grid>
      <Grid item xs={12} sm={"auto"}>
        <TextField
          label='Rotation'
          type='number'
          size='small'
          sx={{
            ...textFieldStyle,
            width: isMobile || isTablet ? "100%" : "65px",
          }}
          value={position.rotation}
          onChange={handleChange("rotation")}
        />
      </Grid>
    </Grid>
  );
};

const textFieldStyle = {
  "& .MuiInputBase-root": {
    fontSize: "0.7rem",
    height: "30px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.7rem",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "0.7rem",
  },
};

export default PositionDimensionControls;
