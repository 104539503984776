import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function LineActivityChart({ data, mode }) {
  let xDataKey = mode === "user" ? "username" : mode;

  const customTickFormatter = (tickItem) => {
    if (mode === "hour") {
      return `${tickItem}:00`;
    } else if (mode === "day") {
      const date = new Date(tickItem);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else if (mode === "week") {
      return tickItem; // Assume it's already formatted as "Week 35" or similar
    } else {
      return tickItem;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <XAxis dataKey={xDataKey} tickFormatter={customTickFormatter} />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />

        {mode === "user" ? (
          // If it's user mode, only show total actions
          <Line type="monotone" dataKey="totalActions" stroke="#273860" />
        ) : (
          // Otherwise, show individual action types
          <>
            <Line dataKey="Create" stroke="#A06EAF" />
            <Line dataKey="Update" stroke="#80AFDE" />
            <Line dataKey="Delete" stroke="#B1AB9F" />
            <Line dataKey="Login" stroke="#F0D338" />
            <Line dataKey="Logout" stroke="#15254B" />
            <Line dataKey="Approve" stroke="#8ACC84" />
            <Line dataKey="Reject" stroke="#F37979" />
            <Line dataKey="Send" stroke="#F2A51D" />
            {/* ... Add other lines for other actions as needed ... */}
          </>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineActivityChart;
