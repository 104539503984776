import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";

const CreateCampaignModal = ({
  open,
  onClose,
  selectedPostId,
  selectedPostImage,
  selectedPostCaption,
  selectedPostDate,
  selectedPostDimensions,
  selectedPlatform,
  generatedImage,
}) => {
  const [campaignName, setCampaignName] = useState("");
  const [campaignObjective, setCampaignObjective] = useState("");
  const [campaignLocation, setCampaignLocation] = useState("");
  const [campaignInterests, setCampaignInterests] = useState([]);
  const [specialAdCategories, setSpecialAdCategories] = useState([]);
  const [adSetName, setAdSetName] = useState("");
  const [adSetBudget, setAdSetBudget] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [locations, setLocations] = useState([]);
  const [interests, setInterests] = useState([]);
  const [publishImmediately, setPublishImmediately] = useState(false);
  const [placements, setPlacements] = useState([]);

  const currentUser = useContext(AuthContext);

  useEffect(() => {
    console.log(
      "Selected post from image in CreateCampaignModal",
      selectedPostImage
    );
  }, [selectedPostImage]);

  useEffect(() => {
    const fetchObjectives = async () => {
      if (currentUser?.currentUser?.clientIdUsers) {
        try {
          const response = await axios.get("/api/facebook/objectives", {
            params: { clientId: currentUser?.currentUser?.clientIdUsers },
          });
          setObjectives(response.data.objectives || []);
        } catch (error) {
          console.error("Failed to fetch objectives:", error);
        }
      }
    };

    const fetchTargetingOptions = async () => {
      try {
        const [locationsResponse, interestsResponse] = await Promise.all([
          axios.get("/api/facebook/targeting_options", {
            params: {
              clientId: currentUser?.currentUser?.clientIdUsers,
              type: "location",
              query: "un",
            },
          }),
          axios.get("/api/facebook/targeting_options", {
            params: {
              clientId: currentUser?.currentUser?.clientIdUsers,
              type: "interest",
              query: "movie",
            },
          }),
        ]);
        setLocations(locationsResponse.data.data || []);
        setInterests(interestsResponse.data.data || []);
      } catch (error) {
        console.error("Failed to fetch targeting options:", error);
      }
    };

    if (open) {
      fetchObjectives();
      fetchTargetingOptions();
    }
  }, [open, currentUser]);

  const handleCreateCampaign = async () => {
    setIsCreating(true);
    try {
      await axios.post("/api/facebook/create_campaign", {
        postId: selectedPostId,
        imageUrl: selectedPostImage || generatedImage, // Pass the imageUrl here
        campaignName,
        campaignObjective,
        campaignLocation,
        campaignInterests,
        specialAdCategories,
        adSetName,
        adSetBudget,
        startTime,
        endTime,
        publishImmediately,
        clientId: currentUser?.currentUser?.clientIdUsers,
        placements: selectedPlatform, // Use selected platforms
      });
      console.log("Campaign created successfully");
      onClose();
    } catch (error) {
      console.error("Failed to create campaign:", error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Create Campaign</DialogTitle>
      <DialogContent>
        {selectedPostImage ? (
          <Box sx={{ maxWidth: 800, mx: "auto", mb: 3 }}>
            <Box
              component='img'
              src={selectedPostImage || ""}
              alt='Selected Post'
              sx={{
                width: "100%",
                height: "auto",
                display: "block",
                mx: "auto",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              {selectedPostDate && (
                <Typography variant='caption' color='textSecondary'>
                  {new Date(selectedPostDate).toLocaleString()}
                </Typography>
              )}
              <Typography
                variant='body2'
                color='textPrimary'
                component='p'
                sx={{ fontWeight: "bold", mx: 2 }}
              >
                {selectedPostCaption || "No caption provided"}
              </Typography>
              {selectedPostDimensions && (
                <Typography variant='caption' color='textSecondary'>
                  Dimensions: {selectedPostDimensions.width} x{" "}
                  {selectedPostDimensions.height}
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          generatedImage && (
            <Box sx={{ maxWidth: 800, mx: "auto", mb: 3 }}>
              <Box
                component='img'
                src={generatedImage}
                alt='Generated'
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  mx: "auto",
                }}
              />
            </Box>
          )
        )}

        <Typography variant='h6' gutterBottom>
          Campaign Setup
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            label='Campaign Name'
            fullWidth
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Campaign Objective</InputLabel>
            <Select
              value={campaignObjective}
              onChange={(e) => setCampaignObjective(e.target.value)}
              label='Campaign Objective'
            >
              {objectives.map((objective) => (
                <MenuItem key={objective.value} value={objective.value}>
                  {objective.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Location</InputLabel>
            <Select
              value={campaignLocation}
              onChange={(e) => setCampaignLocation(e.target.value)}
              label='Location'
            >
              {locations.map((location) => (
                <MenuItem key={location.key} value={location.key}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Interests</InputLabel>
            <Select
              multiple
              value={campaignInterests}
              onChange={(e) => setCampaignInterests(e.target.value)}
              label='Interests'
            >
              {interests.map((interest) => (
                <MenuItem key={interest.id} value={interest.id}>
                  {interest.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Special Ad Categories</InputLabel>
            <Select
              multiple
              value={specialAdCategories}
              onChange={(e) => setSpecialAdCategories(e.target.value)}
              label='Special Ad Categories'
            >
              <MenuItem value='NONE'>None</MenuItem>
              <MenuItem value='HOUSING'>Housing</MenuItem>
              <MenuItem value='EMPLOYMENT'>Employment</MenuItem>
              <MenuItem value='CREDIT'>Credit</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Placements</InputLabel>
            <Select
              multiple
              value={placements}
              onChange={(e) => setPlacements(e.target.value)}
              label='Placements'
            >
              <MenuItem value='facebook'>Facebook</MenuItem>
              <MenuItem value='instagram'>Instagram</MenuItem>
              <MenuItem value='messenger'>Messenger</MenuItem>
              <MenuItem value='audience_network'>Audience Network</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={publishImmediately}
              onChange={(e) => setPublishImmediately(e.target.checked)}
              color='primary'
            />
          }
          label='Publish Immediately'
          sx={{ mb: 2 }}
        />

        <Divider sx={{ my: 3 }} />

        <Typography variant='h6' gutterBottom>
          Ad Set Setup
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            label='Ad Set Name'
            fullWidth
            value={adSetName}
            onChange={(e) => setAdSetName(e.target.value)}
          />
          <TextField
            label='Ad Set Budget'
            fullWidth
            type='number'
            value={adSetBudget}
            onChange={(e) => setAdSetBudget(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Start Time'
              value={startTime}
              onChange={(newValue) => setStartTime(newValue)}
              renderInput={(props) => <TextField {...props} />}
            />
            <DateTimePicker
              label='End Time'
              value={endTime}
              onChange={(newValue) => setEndTime(newValue)}
              renderInput={(props) => <TextField {...props} />}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          onClick={handleCreateCampaign}
          variant='contained'
          color='primary'
          disabled={isCreating}
        >
          {isCreating ? <CircularProgress size={24} /> : "Create Campaign"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCampaignModal;
