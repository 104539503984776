import React, { useContext, useState } from "react";
import {
  Card,
  Typography,
  Divider,
  Avatar,
  TextField,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import axiosInstance from "../../../services/axiosInstance";
import { AI_URL } from "../../../config";
import { AuthContext } from "../../../context/authContext";

const AITextAssistantCard = ({
  values,
  setValues,
  editingComponent,
  editingResolution,
  editStates,
}) => {
  const theme = useTheme();

  const currentUser = useContext(AuthContext);

  console.log(currentUser);

  // Check if the editingComponent is valid
  const isValidEditingComponent =
    ["headline", "subHeadline", "disclaimer"].includes(editingComponent) ||
    editingComponent?.startsWith("text");

  const [query, setQuery] = useState("");

  const handleRefineText = async () => {
    if (!isValidEditingComponent) {
      return; // Don't proceed if editingComponent is invalid
    }

    try {
      let updatedValues = { ...values };
      const componentKey = editingComponent;
      const resolutionKey = editingResolution;

      // Determine the source text for refinement
      const sourceText = editStates["1200x630"]
        ? updatedValues.positions[componentKey]["1200x630"]?.[componentKey] ||
          ""
        : updatedValues.positions[componentKey][resolutionKey]?.[
            componentKey
          ] || "";

      if (sourceText) {
        const response = await axiosInstance.post(`${AI_URL}/refine`, {
          clientId: currentUser.currentUser.clientIdUsers, // Include clientId in the request body
          text: sourceText,
          query: query,
        });

        if (response.data && response.data.refinedText) {
          // Update all resolutions or just the current one based on the editState
          const resolutionsToUpdate = editStates["1200x630"]
            ? Object.keys(updatedValues.positions[componentKey])
            : [resolutionKey];

          resolutionsToUpdate.forEach((resKey) => {
            updatedValues.positions[componentKey][resKey] =
              updatedValues.positions[componentKey][resKey] || {};
            updatedValues.positions[componentKey][resKey][componentKey] =
              response.data.refinedText;
          });

          setValues(updatedValues);
        } else {
          console.error(`Unexpected response format for refining text`);
        }
      }
    } catch (error) {
      console.error("Error refining text:", error);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    handleRefineText();
  };

  return (
    <Card sx={{ width: "100%", height: "100%", padding: 2, bgcolor: "white" }}>
      <Typography variant='h6' sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
        AI-powered Text Generator
      </Typography>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Avatar sx={{ height: 30, width: 30, mr: 2, fontSize: 14 }}>AI</Avatar>
        <Typography
          color='textPrimary'
          sx={{
            fontSize: 14,
            color: isValidEditingComponent ? "inherit" : "red", // Change color to red if invalid
          }}
        >
          {isValidEditingComponent ? (
            <>
              Hi! <br />
              What do you want to do with your {editingComponent}?
            </>
          ) : (
            "Please select the text that you want me to change!"
          )}
        </Typography>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          onChange={handleInputChange}
          fullWidth
          label='Enter your query'
          variant='outlined'
          value={query}
          disabled={!isValidEditingComponent} // Disable input if invalid
          sx={{ mr: 2 }}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={!isValidEditingComponent} // Disable button if invalid
          sx={{
            backgroundImage: isValidEditingComponent ? "primary" : "none", // Change button style if invalid
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundImage: isValidEditingComponent
                ? "linear-gradient(to right, #48b1bf, #06beb6)"
                : "none",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Card>
  );
};

export default AITextAssistantCard;
