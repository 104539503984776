import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress, Button, IconButton, Grid } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import SelectCategory from "../../components/SelectCategory";
import AddIcon from "@mui/icons-material/Add";
import AdDialog from "../ads/components/AdDialog";
import PageNumbers from "../../components/PageNumbers";

import {
  deleteOneTemplate,
  updateTemplates,
} from "../templates/features/templateSlice";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import LayoutPreview from "./components/LayoutPreview";
import {
  ACTIONS_URL,
  ASSETS_URL,
  TEMPLATES_URL,
  USERS_URL,
} from "../../config";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import axiosInstance from "../../services/axiosInstance";
import axios from "axios";
import { SocketContext } from "../../context/SocketContext ";

export default function Templates(props) {
  const socket = useContext(SocketContext);

  const dispatch = useDispatch();
  // const { isLoading, getTemplates } = useAds();
  const TemplatesList = useSelector((store) => store.templates);
  const [templatesState, setTemplatesState] = useState(TemplatesList);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [users, setUsers] = useState();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isListView, setIsListView] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTemplates = TemplatesList?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(TemplatesList?.length / itemsPerPage);

  function handleCategoryChange(category) {
    setSelectedCategory(category);
  }

  // const getTemplates = async () => {
  //   setIsLoading(true);
  //   try {
  //     const headers = {
  //       clientIdUsers: Number(currentUser?.clientIdUsers), // Ensure it's a number
  //       user_id: Number(currentUser?.id), // Ensure it's a number
  //     };


  //     const res = await axios.get(TEMPLATES_URL, { headers });
  //     dispatch(updateTemplates(res.data));
  //   } catch (error) {
  //     console.error("Client: Error fetching templates:", error);
  //     // Optionally, you can handle errors or dispatch an error action here.
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getTemplates();
  // }, []);

  // useEffect(() => {
  //   setIsLoading(false);
  //   setTemplatesState(TemplatesList);
  // }, [TemplatesList]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axiosInstance.get(USERS_URL);
        setUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUsers();
  }, []);

  // SANITIZE user-generated HTML content to prevent cross-site scripting (XSS) attacks.
  // const headline = DOMPurify.sanitize(post.headline);

  // EDIT AND DELETE BUTTONS
  const actionsDetailButton = (params) => {
    return (
      <strong>
        <IconButton
          onClick={() => handleDelete(params.row)} // Add onClick event to DeleteIcon
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(`/edit-template/${params.row.id}`, {
              state: { userData: params.row },
              socket,
            });
          }}
        >
          <EditIcon color="primary" aria-label="edit" />
        </IconButton>
        <Button
          sx={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.05)" }}
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate(`/template/${params.row.id}`);
          }}
        >
          VIEW
        </Button>
      </strong>
    );
  };

  // DELETE AD FUNCTION

  const handleDelete = async (row) => {
    const id = JSON.stringify(row.id);
    const adTitle = row.title;

    confirmDialog(
      `Do you really want to delete "${adTitle}" TEMPLATE, created at ${formatDateToLocaleString(
        row.createdAt
      )} ?`,
      async () => {
        try {
          // Add the post action here
          await axiosInstance
            .post(ACTIONS_URL, {
              actionType: "Delete",
              objectType: "Template",
              userId: currentUser.id,
              clientId: currentUser.clientIdUsers,
              content: row,
            })
            .then((response) => {
              // do something with the response
            })
            .catch((error) => {
              // handle the error
            });

          // Perform the delete action after the post action

          dispatch(deleteOneTemplate({ id: row.id }));
          await axiosInstance.delete(`${TEMPLATES_URL}/${id}`);
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      // headerClassName: "super-app-theme--header",
      width: 70,
      editable: false,
      flex: 0.05,
    },

    {
      field: "createdBy",
      headerName: "Created by",
      // width: 150,
      flex: 0.15,
      editable: true,
      renderCell: ({ row }) => {
        if (!Array.isArray(users)) return "Unknown";
        const user = users.find((user) => user.id === row.uid);
        return <p>{user ? user.username : "Unknown"}</p>;
      },
    },
    {
      field: "title",
      // headerClassName: "super-app-theme--header",
      headerName: "Title",
      width: 130,
      editable: true,
    },
    {
      field: "type",
      // headerClassName: "super-app-theme--header",
      headerName: "Type",
      width: 130,
      editable: true,
    },
    {
      field: "description",
      // headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 130,
      editable: true,
    },
    {
      field: "headline",
      // headerClassName: "super-app-theme--header",
      headerName: "Headline",
      width: 130,
      editable: true,
    },
    {
      field: "subHeadline",
      // headerClassName: "super-app-theme--header",
      headerName: "Sub-headline",
      width: 130,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      type: "date",
      flex: 0.2,
      renderCell: (params) => {
        const formattedDate = formatDateToLocaleString(params.row.createdAt);
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "updateAt",
      headerName: "Updated at",
      type: "date",
      flex: 0.2,
      renderCell: (params) => {
        const formattedDate = formatDateToLocaleString(params.row.updatedAt);
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      flex: 0.1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      editable: true,
      renderCell: actionsDetailButton,
    },
  ];

  return (
    <>
      {isListView ? (
        <Box>
          <ConfirmDialog2 />
          <Box
            sx={{
              paddingTop: 5,
              paddingLeft: 15,
              paddingRight: 10,
              height: 800,
              width: "100%",

              border: "none",
              "& .MuiGrid-root": {
                "& .MuiPaper-root": {
                  backgroundColor: "blue",
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: 2,
              }}
            >
              <SelectCategory onCategoryChange={handleCategoryChange} />

              <Box>
                <IconButton onClick={() => setIsListView(false)}>
                  <GridViewIcon />
                </IconButton>
              </Box>
              <AdDialog />

              <Button
                sx={{ width: 110, height: 40 }}
                onClick={() => <AdDialog />}
                variant="outlined"
              >
                Create AD
              </Button>
            </Box>

            {isLoading && <LinearProgress />}
            {selectedCategory !== "all" ? (
              <DataGrid
                rows={TemplatesList?.filter(
                  (templateState) => templateState?.cat === selectedCategory
                )}
                sx={{
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.10)",
                  "& .MuiDataGrid-columnHeaders": {
                    background: "#a06eaf",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "#ffffff",
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput":
                    {
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    },
                }}
                key={TemplatesList.id}
                rowHeight={80}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                loading={isLoading}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // onStateChange={() => {}}
                checkboxSelection
                experimentalFeatures={{ newEditingApi: true }}
                // other props for the Datagrid component
              />
            ) : (
              <DataGrid
                sx={{
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.10)",
                  "& .MuiDataGrid-columnHeaders": {
                    background: "#a06eaf",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "#ffffff",
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput":
                    {
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    },
                }}
                key={TemplatesList?.id}
                rowHeight={80}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                loading={isLoading}
                rows={TemplatesList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                onStateChange={() => {}}
                checkboxSelection
                experimentalFeatures={{ newEditingApi: true }}
              />
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Grid2 sx={{ pt: 2, pl: 10 }} container spacing={2}>
            <ConfirmDialog2 />

            <Grid2
              xs={12}
              sm={12}
              md={12}
              item
              sx={{ mb: -2 }}
              display="flex"
              justifyContent="space-between"
              m={2}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  variant="text"
                  startIcon={<NavigateBeforeIcon />}
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Prev
                </Button>
                <Button
                  variant="text"
                  endIcon={<NavigateNextIcon />}
                  disabled={
                    currentPage ===
                    Math.ceil(TemplatesList?.length / itemsPerPage)
                  }
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                </Button>
                <PageNumbers
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </Box>
              <Box sx={{ pr: 5 }}>
                <IconButton onClick={() => navigate("/add-template")}>
                  <AddIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => setIsListView(true)}>
                  <FormatListBulletedIcon />
                </IconButton>
              </Box>
            </Grid2>

            <Grid container spacing={2}>
              {currentTemplates?.map((template) => (
                <Grid item key={template.id}>
                  {users?.find((user) => user.id === template.uid) && (
                    <LayoutPreview
                      id={template.id}
                      thumbnail={`${ASSETS_URL}/${template?.thumbnail}`}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid2>
        </>
      )}
    </>
  );
}
