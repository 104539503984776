import React from "react";
import { Cell } from "recharts";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const COLOR_MAP = {
  Create: "#A06EAF",
  Update: "#80AFDE",
  Delete: "#B1AB9F",
  Login: "#F0D338",
  Logout: "#15254B",
  Approve: "#8ACC84",
  Reject: "#F37979",
  Send: "#F2A51D",
};

const USER_COLORS_DESCENDING = [
  "#8ACC84",
  "#A06EAF",
  "#80AFDE",
  "#B1AB9F",
  "#15254B",
  "#F2A51D",
  "#F0D338",
  "#F37979",
];

function BarActivityChart({ data, mode }) {
  let xDataKey = mode;
  if (mode === "user") xDataKey = "username";

  const customTickFormatter = (tickItem) => {
    if (mode === "hour") {
      return `${tickItem}:00`;
    } else if (mode === "day") {
      const date = new Date(tickItem);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } else if (mode === "week") {
      return tickItem; // Directly return the tickItem as it's already formatted as "Week 35"
    } else {
      return tickItem;
    }
  };

  let colorAssignedData = [];

  if (mode === "user") {
    const activeUsers = data.filter((d) => d.totalActions > 0);
    const sortedUsers = [...activeUsers].sort(
      (a, b) => b.totalActions - a.totalActions
    );

    colorAssignedData = sortedUsers.map((user, index, array) => {
      if (index === array.length - 1) {
        return {
          ...user,
          color: "#F37979",
        };
      }
      return {
        ...user,
        color: USER_COLORS_DESCENDING[index] || "#F37979",
      };
    });
  } else {
    colorAssignedData = data;
  }

  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart data={colorAssignedData}>
        <XAxis dataKey={xDataKey} tickFormatter={customTickFormatter} />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke='#f5f5f5' />
        {mode === "user" ? (
          <Bar
            dataKey='totalActions'
            isAnimationActive={false}
            activeFill='#777777'
          >
            {colorAssignedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        ) : (
          Object.entries(COLOR_MAP).map(([key, color]) => (
            <Bar key={key} dataKey={key} fill={color} activeFill='#777777' />
          ))
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarActivityChart;
