export const countryToLanguageMapping = {
  Afghanistan: { language: "Pashto", code: "AF" },
  Albania: { language: "Albanian", code: "AL" },
  Bosnia: { language: "Bosnian", code: "BA" },
  China: { language: "Chinese", code: "CN" },
  Croatia: { language: "Croatian", code: "HR" },
  Denmark: { language: "Danish", code: "DK" },
  France: { language: "French", code: "FR" },
  Germany: { language: "German", code: "DE" },
  Greece: { language: "Greek", code: "GR" },
  India: { language: "Hindi", code: "IN" }, // Note: India has many languages, I've used Hindi as it's the most spoken one.
  Iran: { language: "Persian", code: "IR" },
  Iraq: { language: "Arabic", code: "IQ" },
  Italy: { language: "Italian", code: "IT" },
  Lithuania: { language: "Lithuanian", code: "LT" },
  Macedonia: { language: "Macedonian", code: "MK" },
  Norway: { language: "Norwegian", code: "NO" },
  Portugal: { language: "Portuguese", code: "PT" },
  Russia: { language: "Russian", code: "RU" },
  Serbia: { language: "Serbian", code: "RS" },
  Spain: { language: "Spanish", code: "ES" },
  Sweden: { language: "Swedish", code: "SE" },
  Thailand: { language: "Thai", code: "TH" },
  Ukraine: { language: "Ukrainian", code: "UA" },
  Vietnam: { language: "Vietnamese", code: "VN" },
};
