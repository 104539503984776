import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography, Divider } from "@mui/material";
import BannerDynamic from "../banners/pages/BannerDynamic";
import GradientLoader from "../../../components/GradientLoader";
import BannerActionMenu from "../banners/pages/components/BannerActionMenu";
import { useTheme } from "@mui/material/styles";

const MemoizedBannerDynamic = React.memo(BannerDynamic);

function Banners({
  values,
  updatePosition,
  showProduct,
  showHeadline,
  showSticker1,
  showSubheadline,
  showCta,
  showDisclaimer,
  positions,
  ad160x600Ref,
  ad320x50Ref,
  ad300x250Ref,
  ad300x600Ref,
  ad600x829Ref,
  ad728x90Ref,
  ad875x225Ref,
  ad960x676Ref,
  ad970x250Ref,
  ad1080x1080Ref,
  ad1080x1920Ref,
  ad1200x1200Ref,
  ad1200x628Ref,
  ad1200x630Ref,
  ad1900x620Ref,
  handleRightSidebarToggle,
  setEditingResolution,
  networkTags,
  currentNetwork,
  currentPage,
  bannersPerPage,
  editable,
  backgroundScale,
  setValues,
  editingResolution,
  bannerIsLoading,
  isLoading,
  showImage,
  setShowImage,
  editStates,
  handleElementDragResize,
  updateTextDimensions,
  state,
  isResizeMode,
  selectedBrand,
}) {
  const adsData = useSelector((store) => store?.adsData) || [];

  const [dynamicTexts, setDynamicTexts] = useState([]);
  const theme = useTheme();

  const [zoomLevel, setZoomLevel] = useState(1); // Control zoom level
  const zoomContainerRef = useRef(null); // Ref for the zoom container

  const addDynamicText = (resolution, bannerWidth, bannerHeight) => {
    const newText = `text${dynamicTexts.length + 1}`;
    setDynamicTexts([...dynamicTexts, newText]);

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      if (!updatedValues.positions[newText]) {
        updatedValues.positions[newText] = {};
      }

      if (editStates && editStates["1200x630"]) {
        // Iterate over each key in values.positions
        Object.keys(values.positions).forEach((positionKey) => {
          // Iterate over each resolution for the current position
          Object.keys(values.positions[positionKey]).forEach((res) => {
            if (!updatedValues.positions[newText][res]) {
              updatedValues.positions[newText][res] = {
                x: bannerWidth / 2,
                y: bannerHeight / 2,
                visible: "true",
                editable: true,
                fontName: values.positions.subHeadline[resolution].fontName,
                fontSize:
                  values.positions.subHeadline[resolution].fontSize * 0.7,
                fontWeight: 400,
                letterSpacing:
                  values.positions.subHeadline[resolution].letterSpacing,
                lineHeight: values.positions.subHeadline[resolution].lineHeight,
                textAlign: values.positions.subHeadline[resolution].textAlign,
                textColor: values.positions.subHeadline[resolution].textColor,
                [newText]: `New ${newText} is waiting for someone`,
                zIndex: 9999,
              };
            }
          });
        });
      } else {
        // Original logic for a single resolution
        if (!updatedValues.positions[newText][resolution]) {
          updatedValues.positions[newText][resolution] = {
            x: bannerWidth / 2,
            y: bannerHeight / 2,
            visible: "true",
            editable: true,
            fontName: values.positions.subHeadline[resolution].fontName,
            fontSize: values.positions.subHeadline[resolution].fontSize * 0.7,
            fontWeight: 400,
            letterSpacing:
              values.positions.subHeadline[resolution].letterSpacing,
            lineHeight: values.positions.subHeadline[resolution].lineHeight,
            textAlign: values.positions.subHeadline[resolution].textAlign,
            textColor: values.positions.subHeadline[resolution].textColor,
            [newText]: `New ${newText} is waiting for someone`,
            zIndex: 9999,
          };
        }
      }

      return updatedValues;
    });
  };

  const addDynamicBackground = (resolution) => {
    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));

      // Find next available background key
      let backgroundIndex = 1;
      while (updatedValues.positions[`background${backgroundIndex}`]) {
        backgroundIndex++;
      }
      const newBackgroundKey = `background${backgroundIndex}`;

      // Initialize new background if not already existing
      updatedValues.positions[newBackgroundKey] =
        updatedValues.positions[newBackgroundKey] || {};

      if (editStates["1200x630"] === true) {
        // Iterate over each resolution in the array and set background properties
        updatedValues.positions.resolutions.forEach((res) => {
          const [width, height] = res.split("x").map(Number);
          updatedValues.positions[newBackgroundKey][res] = {
            color: "#B3B3B3",
            editable: true,
            height, // Set height from each resolution
            imageUrl: "default.png",
            showImage: false,
            visible: true,
            width, // Set width from each resolution
            x: 0,
            y: 0,
            opacity: "100%",
            zIndex: 999,
          };
        });
      } else {
        // Split resolution into width and height
        const [resWidth, resHeight] = resolution.split("x").map(Number);

        // For a specific resolution
        updatedValues.positions[newBackgroundKey][resolution] = {
          color: "#B3B3B3",
          editable: true,
          height: resHeight,
          imageUrl: "default.png",
          showImage: false,
          visible: true,
          width: resWidth,
          x: 0,
          y: 0,
          opacity: "100%",
          zIndex: 999,
        };
      }

      return updatedValues;
    });

    setShowImage((prevShowImage) => {
      let updatedShowImage = { ...prevShowImage };

      if (editStates["1200x630"] === true) {
        for (const res in values.positions.background1) {
          updatedShowImage[res] = false; // Initialize to false for all resolutions
        }
      } else {
        updatedShowImage[resolution] = false;
      }

      return updatedShowImage;
    });

    setEditingResolution(resolution);
  };

  const addDynamicShape = (shapeType, resolution) => {
    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));

      // Split resolution to get width and height
      const [resWidth, resHeight] = resolution.split("x").map(Number);

      // Check for the highest existing shape number
      let maxShapeNumber = 0;
      Object.keys(updatedValues.positions).forEach((key) => {
        if (key.startsWith(shapeType)) {
          const shapeNumber = parseInt(key.replace(shapeType, ""), 10);
          if (shapeNumber > maxShapeNumber) {
            maxShapeNumber = shapeNumber;
          }
        }
      });

      // Generate new key for the shape
      const newShapeKey = `${shapeType}${maxShapeNumber + 1}`;

      // Initialize positions and dimensions for the new shape at the given resolution
      updatedValues.positions[newShapeKey] =
        updatedValues.positions[newShapeKey] || {};
      updatedValues.positions[newShapeKey][resolution] = {
        x: 50, // Default position x
        y: 50, // Default position y
        // Define width and height based on shape type
        width: shapeType === "line" ? resWidth / 2 : resWidth / 2, // Length for line, half of resolution width otherwise
        height:
          shapeType === "circle"
            ? resWidth / 2
            : shapeType === "line"
            ? 1
            : resHeight / 2, // Radius for circle, stroke width for line, half of resolution height for rectangle
        color: "#73D8FF", // Default color
        visible: true,
        editable: true,
        zIndex: 5,
      };

      // Here you can add logic to update specific properties if needed

      return updatedValues;
    });
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey && zoomContainerRef.current.contains(event.target)) {
        event.preventDefault(); // Prevent default scrolling and zooming behaviors
        const delta = event.deltaY * 0.001;
        // Simplify the calculation by focusing only on the zoom factor
        const newZoomLevel = zoomLevel * (1 - delta);

        // Maintain a consistent transform-origin
        zoomContainerRef.current.style.transformOrigin = "center top";

        // Update zoom level within constraints
        setZoomLevel(Math.max(0.5, Math.min(newZoomLevel, 3)));
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, [zoomLevel]);

  const selectedNetworkTags = useMemo(() => {
    return networkTags[currentNetwork] || "web";
  }, [networkTags, currentNetwork]);

  const uniqueNetworkTags = useMemo(() => {
    return [...new Set(selectedNetworkTags)];
  }, [selectedNetworkTags]);

  const slicedNetworkTags = useMemo(() => {
    return uniqueNetworkTags.slice(
      currentPage * bannersPerPage,
      (currentPage + 1) * bannersPerPage
    );
  }, [uniqueNetworkTags, currentPage, bannersPerPage]);

  const commonProps = useMemo(
    () => ({
      data: values,
      adsData,
      setEditingResolution,
      handleRightSidebarToggle,
      updatePosition,
      visibility: {
        showProduct,
        showHeadline,
        showSubheadline,
        showSticker1,
        showCta,
        showDisclaimer,
      },
      positions,
      editable,
      values,
      backgroundScale,
      setValues,
      editingResolution,
      bannerIsLoading,
      isLoading,
      dynamicTexts,
      setDynamicTexts,
      showImage,
      setShowImage,
      handleElementDragResize,
      updateTextDimensions,
      state,
      isResizeMode,
      selectedBrand,
      editStates,
    }),
    [
      values,
      adsData,
      setEditingResolution,
      handleRightSidebarToggle,
      updatePosition,
      showProduct,
      showHeadline,
      showSubheadline,
      showSticker1,
      showCta,
      showDisclaimer,
      positions,
      editable,
      backgroundScale,
      setValues,
      editingResolution,
      bannerIsLoading,
      isLoading,
      dynamicTexts,
      showImage,
      setShowImage,
      handleElementDragResize,
      updateTextDimensions,
      state,
      isResizeMode,
      selectedBrand,
      editStates,
    ]
  );

  const refMap = {
    "160x600": ad160x600Ref,
    "320x50": ad320x50Ref,
    "300x250": ad300x250Ref,
    "300x600": ad300x600Ref,
    "600x829": ad600x829Ref,
    "728x90": ad728x90Ref,
    "875x225": ad875x225Ref,
    "960x676": ad960x676Ref,
    "970x250": ad970x250Ref,
    "1080x1080": ad1080x1080Ref,
    "1080x1920": ad1080x1920Ref,
    "1200x1200": ad1200x1200Ref,
    "1200x628": ad1200x628Ref,
    "1200x630": ad1200x630Ref,
    "1900x620": ad1900x620Ref,
  };

  return (
    <Grid container sx={{ position: "relative", overflow: "hidden" }}>
      <div
        ref={zoomContainerRef}
        style={{
          width: "100%",
          transform: `scale(${zoomLevel})`,
          transition: "transform 0.2s",
          transformOrigin: "center top", // Ensure this is set initially as well
        }}
      >
        {slicedNetworkTags.map((tag) => {
          const [bannerWidth, bannerHeight] = tag.split("x").map(Number);
          return (
            <Grid
              item
              xs={12}
              key={tag}
              sx={{ minHeight: "fit-content", mt: 5 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <BannerActionMenu
                    bannerWidth={bannerWidth}
                    bannerHeight={bannerHeight}
                    addDynamicText={addDynamicText}
                    addDynamicBackground={addDynamicBackground}
                    addDynamicShape={addDynamicShape}
                    tag={tag}
                  />
                  <Divider
                    orientation='vertical'
                    variant='middle'
                    flexItem
                    sx={{ bgcolor: "primary.main", display: "flex", ml: 1.5 }}
                  />
                  <Typography sx={{ ml: 2 }} color='primary'>
                    {tag}
                  </Typography>
                </Box>
                <MemoizedBannerDynamic
                  {...commonProps}
                  ref={refMap[tag]}
                  bannerWidth={bannerWidth}
                  bannerHeight={bannerHeight}
                  resolution={tag}
                />
              </Box>
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
}

export default Banners;
