import { FormControlLabel, Switch, Typography } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";

function EditingResolutionSwitch({ editStates, setEditStates }) {
  const [state, setState] = useState({
    checked: editStates["1200x630"],
  });

  const handleChange = (event) => {
    const checked = event.target.checked;
    setState({ ...state, [event.target.name]: checked });

    // Update the parent state
    setEditStates((prevState) => ({
      ...prevState,
      "1200x630": checked,
    }));
  };

  useEffect(() => {
    setState({ checked: editStates["1200x630"] });
  }, [editStates]);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={state.checked}
          onChange={handleChange}
          name='checked'
          size='small'
        />
      }
      label={
        <Typography variant='body2' sx={{ fontSize: "0.8rem" }}>
          {state.checked ? "editing all" : "editing resolution"}
        </Typography>
      }
    />
  );
}

export default EditingResolutionSwitch;
