import React from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CampaignIcon from "@mui/icons-material/Campaign";
import CreateIcon from "@mui/icons-material/Create";

const ActionSelection = ({ selectedPlatform, setActionType }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setActionType("post")} color='primary'>
            <SendIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant='caption' align='center'>
            Post to {selectedPlatform.join(", ")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setActionType("campaign_from_post")}
            color='primary'
          >
            <CampaignIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant='caption' align='center'>
            Create Campaign from Current Post
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setActionType("campaign")} color='primary'>
            <CreateIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant='caption' align='center'>
            Create Campaign
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ActionSelection;
