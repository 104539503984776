// editingComponentSlices.js
import { createSlice } from "@reduxjs/toolkit";

const editingComponentSlices = createSlice({
  name: "editingComponent",
  initialState: "headline",
  reducers: {
    setEditingComponent: (state, action) => action.payload,
  },
});

export const { setEditingComponent } = editingComponentSlices.actions;

export default editingComponentSlices.reducer;
