import React, { useEffect, useState } from "react";
import AMD from "../../../../images/AMD_multiplier.svg"; // Path to your SVG file

const PartnershipBoxAmd = ({ values, resolution }) => {
  const [svgContent, setSvgContent] = useState(null);
  const logoBrand1Data = values?.positions?.logoBrand1?.[resolution];
  const margin = logoBrand1Data?.width * 0.2385;
  const partnershipColor = values?.partnershipColor || "#000"; // Default to black if color is not defined

  useEffect(() => {
    fetch(AMD)
      .then((response) => response.text())
      .then((text) => {
        // Replace the fill color of all paths in the SVG
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(text, "image/svg+xml");
        const paths = svgDoc.querySelectorAll("path");
        paths.forEach((path) => {
          path.setAttribute("fill", partnershipColor);
        });
        const serializedSvg = new XMLSerializer().serializeToString(svgDoc);
        setSvgContent(serializedSvg);
      });
  }, [partnershipColor]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={{
        position: "absolute",
        zIndex: 999,
        left:
          logoBrand1Data?.selectedCorner === "topLeft" ||
          logoBrand1Data?.selectedCorner === "bottomLeft"
            ? `${logoBrand1Data?.width + 1.5 * margin}px`
            : `${logoBrand1Data?.x - 1 * margin}px`,
        top:
          logoBrand1Data?.selectedCorner === "bottomLeft"
            ? `${parseFloat(logoBrand1Data?.y) + 0.75 * margin}px`
            : logoBrand1Data?.selectedCorner === "bottomRight"
            ? `${parseFloat(logoBrand1Data?.y) + 0.75 * margin}px`
            : margin * 1.75,
        width: margin / 2,
        height: "auto",
      }}
    />
  );
};

export default PartnershipBoxAmd;
