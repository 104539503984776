import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const SuperLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 140, // Adjust as needed
      }}
    >
      <CircularProgress />
      <Typography
        sx={{
          marginTop: 2,
          animation: "blink 1.2s infinite",
        }}
      >
        Loading Image...
      </Typography>
      <style>
        {`@keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }`}
      </style>
    </Box>
  );
};

export default SuperLoader;
