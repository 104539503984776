import React, { useState } from "react";
import { ReactComponent as RobotIcon } from "../../../images/robot.svg";
import { ReactComponent as TextIcon } from "../../../images/text.svg";

import { ReactComponent as ImageIcon } from "../../../images/image.svg";
import { Box, IconButton, Paper } from "@mui/material";
import AITextAssistantCard from "./AITextAssistantCard";
import AIImageAssistantCard from "./AIImageAssistantCard ";

const AiIconsGroup = ({
  values,
  setValues,
  editingComponent,
  editingResolution,
  editStates,
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openImageComponent, setOpenImageComponent] = useState(false);

  const handleRobotIconClick = () => {
    setShowIcons(!showIcons);
    setOpenChat(false);
    setOpenImageComponent(false);
  };

  const handleTextIconClick = () => {
    // If the chat is already open, we close it along with the icons.
    // Otherwise, we open the chat and ensure the icons remain visible.
    if (openChat) {
      setOpenChat(false);
      setShowIcons(false);
    } else {
      setOpenChat(true);
      setOpenImageComponent(false);
      setShowIcons(true);
    }
  };

  const handleImageIconClick = () => {
    // Similar toggle functionality for the image component.
    if (openImageComponent) {
      setOpenImageComponent(false);
      setShowIcons(false);
    } else {
      setOpenImageComponent(true);
      setOpenChat(false);
      setShowIcons(true);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        position: "relative",
      }}
    >
      <IconButton
        onClick={handleRobotIconClick}
        sx={{
          borderRadius: 0,
          width: 40,
          height: 40,
        }}
      >
        <RobotIcon style={{ width: 40, height: 40 }} />
      </IconButton>
      {showIcons && (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <IconButton
            onClick={handleTextIconClick}
            sx={{ justifyContent: "center" }}
          >
            <TextIcon style={{ fill: "blue" }} />
          </IconButton>
          <IconButton
            onClick={handleImageIconClick}
            sx={{ justifyContent: "center" }}
          >
            <ImageIcon style={{ fill: "blue" }} />
          </IconButton>
        </Box>
      )}
      {openChat && (
        <Paper
          sx={{
            position: "absolute",
            top: "100%", // Position the top of the Paper right below the icons
            left: -30, // Align with the left side of the parent Box
            width: 350, // Adjust width as necessary
            height: 250, // Height for the chat component
            bgcolor: "background.paper",
            overflow: "hidden",
            zIndex: 4, // Ensure it's above other items
          }}
        >
          <AITextAssistantCard
            values={values}
            setValues={setValues}
            editingComponent={editingComponent}
            editingResolution={editingResolution}
            editStates={editStates}
          />
        </Paper>
      )}
      {openImageComponent && (
        <Paper
          sx={{
            position: "absolute",
            top: "100%", // Position the top of the Paper right below the icons
            left: -30, // Align with the left side of the parent Box
            width: 350, // Adjust width as necessary
            height: "auto", // Height for the image component
            bgcolor: "background.paper",
            overflow: "hidden",
            zIndex: 1, // Ensure it's above other items
          }}
        >
          <AIImageAssistantCard
            values={values}
            setValues={setValues}
            editingComponent={editingComponent}
            editingResolution={editingResolution}
            editStates={editStates}
          />
        </Paper>
      )}
    </Box>
  );
};

export default AiIconsGroup;
