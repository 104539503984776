import html2canvas from "html2canvas";
import JSZip from "jszip";

const downloadAllImages = async (components, onProgressUpdate) => {
  const zip = new JSZip();
  const scale = 2; // Scale factor for better quality

  const captureComponent = async (filename, component) => {
    if (!component.current || !(component.current instanceof HTMLElement)) {
      console.error("Invalid component ref:", filename);
      return;
    }

    const highResCanvas = await html2canvas(component.current, {
      useCORS: true,
      allowTaint: true,
      scale,
    });

    // Create a new canvas with original dimensions.
    const canvas = document.createElement("canvas");
    canvas.width = highResCanvas.width / scale;
    canvas.height = highResCanvas.height / scale;
    const ctx = canvas.getContext("2d");

    // Draw the high-res capture onto this canvas, effectively scaling it down.
    ctx.imageSmoothingEnabled = true;
    ctx.drawImage(highResCanvas, 0, 0, canvas.width, canvas.height);

    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    if (blob) {
      const buffer = await blob.arrayBuffer();
      zip.file(filename, buffer);
    } else {
      console.error("Failed to capture component:", filename);
    }
  };

  const totalComponents = Object.keys(components).length;
  let processedComponents = 0;

  for (const [filename, component] of Object.entries(components)) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    await captureComponent(filename, component);
    processedComponents++;
    const progressPercentage = (processedComponents / totalComponents) * 100;
    onProgressUpdate(progressPercentage);
  }

  const zipBlob = await zip.generateAsync({ type: "blob" });
  const url = URL.createObjectURL(zipBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "images.zip";
  link.click();
  URL.revokeObjectURL(url);
};

const downloadImage = async (componentToCapture) => {
  const [filename, component] = Object.entries(componentToCapture)[0];

  const highResCanvas = await html2canvas(component.current, {
    useCORS: true,
    allowTaint: true,
    scale: 2,
  });

  // Create a new canvas with original dimensions.
  const canvas = document.createElement("canvas");
  canvas.width = highResCanvas.width / 2;
  canvas.height = highResCanvas.height / 2;
  const ctx = canvas.getContext("2d");

  // Draw the high-res capture onto this canvas, effectively scaling it down.
  ctx.imageSmoothingEnabled = true;
  ctx.drawImage(highResCanvas, 0, 0, canvas.width, canvas.height);

  const blob = await new Promise((resolve) =>
    canvas.toBlob(resolve, "image/png")
  );

  return blob;
};

export { downloadAllImages, downloadImage };
