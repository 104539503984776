import React, { useContext, useState } from "react";
import {
  Card,
  Typography,
  Divider,
  Avatar,
  TextField,
  Box,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import axiosInstance from "../../../services/axiosInstance";
import { AI_URL, ASSETS_URL } from "../../../config";
import SuperLoader from "../components/SuperLoader";
import EditAiImageModal from "./EditAiImageModal";
import { AuthContext } from "../../../context/authContext";

const AIImageAssistantCard = ({
  values,
  setValues,
  editingComponent,
  editingResolution,
  editStates,
}) => {
  const currentUser = useContext(AuthContext);
  const clientId = currentUser?.currentUser?.clientIdUsers;

  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [imageQuery, setImageQuery] = useState("");

  const handleGenerateImage = async () => {
    if (!query) {
      console.error("Prompt query is missing.");
      return;
    }

    if (!clientId) {
      console.error("Client ID is missing.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${AI_URL}/generate-image`, {
        clientId, // Include clientId in the request body
        prompt: query, // Use the query state variable here
      });
      if (response.data && response.data.length > 0) {
        setImages(response.data.map((img) => img.fileName));
      } else {
        console.error("No images returned from the server");
      }
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    handleGenerateImage();
  };

  const handleImageClick = (fileName) => {
    setValues((prevValues) => {
      const newPositions = JSON.parse(JSON.stringify(prevValues.positions));

      if (editStates["1200x630"]) {
        Object.keys(newPositions.background).forEach((resolution) => {
          newPositions.background[resolution].imageUrl = fileName;
        });
      } else {
        newPositions.background[editingResolution].imageUrl = fileName;
      }

      return { ...prevValues, positions: newPositions };
    });
  };

  const handleEditClick = () => {
    if (images.length > 0) {
      setSelectedImage(images[0]); // Set the first image as the selected image for editing
      setIsEditModalOpen(true);
    }
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleEditSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`${AI_URL}/generate-image`, {
        prompt: imageQuery,
        clientId,
      });
      if (response.data && response.data.length > 0) {
        setSelectedImage(response.data[0].fileName);
      } else {
        console.error("No images returned from the server");
      }
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditComplete = (data) => {
    setSelectedImage(data.editedFileName);
    setImages((prevImages) =>
      prevImages.map((img) =>
        img === selectedImage ? data.editedFileName : img
      )
    );
    setIsEditModalOpen(false);
  };

  return (
    <Card sx={{ width: "100%", height: "100%", padding: 2, bgcolor: "white" }}>
      <Typography variant='h6' sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
        AI-powered Image Generator
      </Typography>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Avatar sx={{ height: 30, width: 30, mr: 2, fontSize: 14 }}>AI</Avatar>
        <Typography color='textPrimary' sx={{ fontSize: 14 }}>
          Hi! <br />
          What images would you like to generate?
        </Typography>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          onChange={handleInputChange}
          fullWidth
          label='Enter your prompt'
          variant='outlined'
          value={query}
          sx={{ mr: 2 }}
        />
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={isLoading}
          sx={{
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#06beb6",
            },
          }}
        >
          Generate
        </Button>
        <IconButton
          onClick={handleEditClick}
          disabled={images.length === 0}
          sx={{ ml: 1 }}
        >
          <Edit />
        </IconButton>
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {images.map((fileName, index) => (
          <Grid item xs={12} key={index} sx={{ position: "relative" }}>
            <img
              src={`${ASSETS_URL}/${fileName}`}
              alt={`Generated ${index}`}
              style={{ width: "100%", height: "auto" }}
              onClick={() => handleImageClick(fileName)}
            />
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <SuperLoader />
              </Box>
            )}
          </Grid>
        ))}
        {isLoading && images.length === 0 && (
          <Grid item xs={12}>
            <SuperLoader />
          </Grid>
        )}
      </Grid>
      {selectedImage && (
        <EditAiImageModal
          open={isEditModalOpen}
          onClose={handleEditModalClose}
          imageUrl={`${ASSETS_URL}/${selectedImage}`}
          prompt={imageQuery}
          setPrompt={setImageQuery}
          onRegenerate={handleEditSubmit}
          isLoading={isLoading}
          onEditComplete={handleEditComplete}
          clientId={clientId}
        />
      )}
    </Card>
  );
};

export default AIImageAssistantCard;
