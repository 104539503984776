// alignmentHelpers.js

export const toCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, (match, group1) => {
      return group1.toUpperCase();
    })
    .replace(/^\w/, (match) => {
      return match.toLowerCase();
    });
};

export const centerElementHorizontally = (
  editingComponent,
  resolution,
  values,
  bannerWidth,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);

  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;
  const centeredX = (bannerWidth - elementWidth) / 2;

  handleElementDragResize(
    null,
    {
      x: centeredX,
      y: values.positions[itemCamelCase]?.[resolution].y || 0,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};

export const centerElementVertically = (
  editingComponent,
  resolution,
  values,
  bannerHeight,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);
  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;
  const centeredY = (bannerHeight - elementHeight) / 2;

  handleElementDragResize(
    null,
    {
      x: values.positions[itemCamelCase]?.[resolution].x || 0,
      y: centeredY,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};

export const alignElementTop = (
  editingComponent,
  resolution,
  values,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);
  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;

  handleElementDragResize(
    null,
    {
      x: values.positions[itemCamelCase]?.[resolution].x || 0,
      y: 0,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};

export const alignElementBottom = (
  editingComponent,
  resolution,
  values,
  bannerHeight,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);
  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;

  const alignedY = bannerHeight - elementHeight;

  handleElementDragResize(
    null,
    {
      x: values.positions[itemCamelCase]?.[resolution].x || 0,
      y: alignedY,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};

export const alignElementLeft = (
  editingComponent,
  resolution,
  values,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);
  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;

  handleElementDragResize(
    null,
    {
      x: 0,
      y: values.positions[itemCamelCase]?.[resolution].y || 0,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};

export const alignElementRight = (
  editingComponent,
  resolution,
  values,
  bannerWidth,
  handleElementDragResize
) => {
  const itemCamelCase = toCamelCase(editingComponent);
  let elementWidth = values.positions[itemCamelCase]?.[resolution].width || 200;
  let elementHeight =
    values.positions[itemCamelCase]?.[resolution].height || 200;

  const alignedX = bannerWidth - elementWidth;

  handleElementDragResize(
    null,
    {
      x: alignedX,
      y: values.positions[itemCamelCase]?.[resolution].y || 0,
      width: elementWidth,
      height: elementHeight,
    },
    itemCamelCase,
    false,
    resolution
  );
};
