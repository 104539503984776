import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Grid, Button } from "@mui/material";

const ShadowControl = ({
  handleToggleShadow,
  editingComponent,
  editingResolution,
  values,
}) => {
  const [offsetX, setOffsetX] = useState("2px");
  const [offsetY, setOffsetY] = useState("2px");
  const [blur, setBlur] = useState("4px");
  const [spread, setSpread] = useState("0px");
  const [color, setColor] = useState("rgba(0, 0, 0, 0.2)");
  const [isTextElement, setIsTextElement] = useState(false);

  useEffect(() => {
    // Check if the editing component is a text element
    const textElements = [
      "headline",
      "subHeadline",
      "disclaimer",
      "text1",
      "text2",
      "text3",
      "text4",
    ];
    const isText = textElements.includes(editingComponent);
    setIsTextElement(isText);

    // Get the appropriate shadow property based on the element type
    const shadowProp = isText
      ? values.positions[editingComponent][editingResolution]?.textShadow
      : values.positions[editingComponent][editingResolution]?.boxShadow;

    if (shadowProp && shadowProp !== "none") {
      const parts = shadowProp.split(" ");
      setOffsetX(parts[0]);
      setOffsetY(parts[1]);
      setBlur(parts[2]);
      if (!isText) {
        // For non-text elements, include spread
        setSpread(parts[3]);
        setColor(parts[4]);
      } else {
        // For text elements, skip spread
        setColor(parts[3]);
      }
    }
  }, [editingComponent, editingResolution, values.positions]);

  const appendPxIfNeeded = (value) => {
    if (!isNaN(value) && !value.includes("px")) {
      return value + "px";
    }
    return value;
  };

  const updateShadow = () => {
    console.log("Updating shadow with:", {
      offsetX,
      offsetY,
      blur,
      spread,
      color,
    });
    handleToggleShadow(
      editingComponent,
      appendPxIfNeeded(offsetX),
      appendPxIfNeeded(offsetY),
      appendPxIfNeeded(blur),
      isTextElement ? null : appendPxIfNeeded(spread), // Exclude spread for text elements
      color,
      true
    );
  };

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Box sx={{ padding: 2, width: 250 }} onClick={handleClick}>
      <Typography variant='h6' sx={{ marginBottom: 2 }}>
        Shadow Controls
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Offset X'
            value={offsetX}
            onChange={handleChange(setOffsetX)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Offset Y'
            value={offsetY}
            onChange={handleChange(setOffsetY)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Blur'
            value={blur}
            onChange={handleChange(setBlur)}
            fullWidth
          />
        </Grid>
        {!isTextElement && ( // Hide spread field for text elements
          <Grid item xs={12} sm={6}>
            <TextField
              label='Spread'
              value={spread}
              onChange={handleChange(setSpread)}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label='Color'
            type='color'
            value={color}
            onChange={handleChange(setColor)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' color='primary' onClick={updateShadow}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShadowControl;
