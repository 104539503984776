import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Oval } from "react-loader-spinner";
import { useTheme } from "@mui/material/styles";

function GradientLoader() {
  const theme = useTheme();
  const words = ["Ready!", "Steady!", "AdReady!"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 650);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <AnimatePresence>
          <motion.div
            key={words[index]}
            initial={{ opacity: 0, x: 5, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: -5, y: 0 }}
          >
            <svg width='200' height='50' xmlns='http://www.w3.org/2000/svg'>
              <defs>
                <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                  <stop
                    offset='0%'
                    stop-color={theme.palette.loader.firstColor}
                  />
                  <stop
                    offset='100%'
                    stop-color={theme.palette.loader.secondColor}
                  />
                </linearGradient>
              </defs>
              <text
                fill='url(#gradient)'
                x='50%'
                y='50%'
                alignment-baseline='middle'
                text-anchor='middle'
                font-size='32'
              >
                {words[index]}
              </text>
            </svg>
          </motion.div>
        </AnimatePresence>
      </div>
      <Oval
        type='Oval'
        color={theme.palette.loader.firstColor}
        secondaryColor={theme.palette.loader.secondColor}
        height={80}
        width={80}
      />
    </div>
  );
}

export default GradientLoader;
