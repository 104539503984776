const updatePosition = (prevState, newPosition, resolution, type) => {
  const { positions, ...rest } = prevState;
  const {
    product,
    sticker1,
    headline,
    subHeadline,
    disclaimer,
    cta,
    background,
    text1,
    text2,
    text3,
    text4,
    text5, // Added text1, text2, text3, text4, text5
    ...positionsRest
  } = positions;

  let newPositionType;
  let positionTypeRest;

  const typeMapper = {
    product,
    headline,
    subHeadline,
    sticker1,
    disclaimer,
    cta,
    background,
    text1,
    text2,
    text3,
    text4,
    text5, // Added text1, text2, text3, text4, text5
  };

  if (typeMapper[type]) {
    newPositionType = typeMapper[type];
    delete typeMapper[type];
    positionTypeRest = typeMapper;
  } else {
    throw new Error(`Unsupported type: ${type}`);
  }

  const updatedState = {
    ...rest,
    positions: {
      ...positionsRest,
      [type]: {
        ...newPositionType,
        [resolution]: {
          ...newPositionType[resolution],
          x: newPosition.x,
          y: newPosition.y,
          width: newPosition.width,
          height: newPosition.height,
        },
      },
      ...positionTypeRest,
    },
  };
  return { updatedState };
};

export { updatePosition };
