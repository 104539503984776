import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const StyleToggleButtons = ({
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  isUnderline,
  setIsUnderline,
  isStrikethrough,
  setIsStrikethrough,
  isSuperscript,
  setIsSuperscript,
  setValues,
  fontName,
  editingComponent,
  editingResolution,
  isBulletList,
  setIsBulletList,
  setIsOrderedList,
  isOrderedList,
}) => {
  const updateFontNameForStyle = (bold, italic) => {
    let [baseFont, ...styles] = fontName.split("-");
    const boldIndex = styles.indexOf("Bold");
    if (bold && boldIndex === -1) {
      styles.push("Bold");
    } else if (!bold && boldIndex !== -1) {
      styles.splice(boldIndex, 1);
    }
    const italicIndex = styles.indexOf("Italic");
    if (italic && italicIndex === -1) {
      styles.push("Italic");
    } else if (!italic && italicIndex !== -1) {
      styles.splice(italicIndex, 1);
    }
    return [baseFont, ...styles].join("-");
  };

  const handleStyleChange = (event, newStyles) => {
    setIsUnderline(newStyles.includes("underline"));
    setIsStrikethrough(newStyles.includes("strikethrough"));
    setIsSuperscript(newStyles.includes("superscript"));

    let textDecorationValue = [];
    if (newStyles.includes("underline")) textDecorationValue.push("underline");
    if (newStyles.includes("strikethrough"))
      textDecorationValue.push("line-through");
    if (newStyles.includes("superscript"))
      textDecorationValue.push("superscript");

    updateStyle("textDecoration", textDecorationValue.join(" "));

    const bulletListIsActive = newStyles.includes("bulletList");
    const orderedListIsActive = newStyles.includes("orderedList");

    if (bulletListIsActive !== isBulletList) {
      setIsBulletList(bulletListIsActive);
      updateStyle("isBulletList", bulletListIsActive);

      if (bulletListIsActive && isOrderedList) {
        setIsOrderedList(false);
        updateStyle("isOrderedList", false);
      }
    }

    if (orderedListIsActive !== isOrderedList) {
      setIsOrderedList(orderedListIsActive);
      updateStyle("isOrderedList", orderedListIsActive);

      if (orderedListIsActive && isBulletList) {
        setIsBulletList(false);
        updateStyle("isBulletList", false);
      }
    }
  };

  const updateStyle = (property, value) => {
    console.log(`Updating style - Property: ${property}, Value: ${value}`);
    setValues((prevState) => {
      const updatedState = {
        ...prevState,
        positions: {
          ...prevState.positions,
          [editingComponent]: {
            ...prevState.positions[editingComponent],
            [editingResolution]: {
              ...prevState.positions[editingComponent][editingResolution],
              [property]: value,
            },
          },
        },
      };
      console.log(
        "Updated state:",
        updatedState.positions[editingComponent][editingResolution]
      );
      return updatedState;
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ToggleButtonGroup
      value={[
        isBold ? "bold" : "",
        isItalic ? "italic" : "",
        isUnderline ? "underline" : "",
        isStrikethrough ? "strikethrough" : "",
        isSuperscript ? "superscript" : "",
        isBulletList ? "bulletList" : "",
        isOrderedList ? "orderedList" : "",
      ].filter(Boolean)}
      onChange={handleStyleChange}
      aria-label='text formatting'
      exclusive={false}
      sx={{
        "& .MuiToggleButton-root": {
          padding: isMobile ? "4px" : isTablet ? "5px" : "6px",
          "& .MuiSvgIcon-root": {
            fontSize: isMobile ? "0.8rem" : isTablet ? "0.9rem" : "1rem",
          },
        },
      }}
    >
      <ToggleButton
        value='underline'
        aria-label='underline'
        selected={isUnderline}
      >
        <FormatUnderlinedIcon />
      </ToggleButton>
      <ToggleButton
        value='strikethrough'
        aria-label='strikethrough'
        selected={isStrikethrough}
      >
        <FormatStrikethroughIcon />
      </ToggleButton>
      <ToggleButton
        value='superscript'
        selected={isSuperscript}
        aria-label='superscript'
      >
        <SuperscriptIcon />
      </ToggleButton>
      <ToggleButton
        value='bulletList'
        selected={isBulletList}
        aria-label='bullet list'
      >
        <FormatListBulletedIcon />
      </ToggleButton>

      <ToggleButton
        value='orderedList'
        selected={isOrderedList}
        aria-label='ordered list'
      >
        <FormatListNumberedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default StyleToggleButtons;
