import React, { useEffect, useState } from "react";
// import "../ads/AdsSingle.scss";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AdsViewSidebar from "./components/AdsViewSidebar";
import AdView from "./AdView";

function AdSingle(props) {
  const AdsList = useSelector((store) => store.ads);
  const BrandsList = useSelector((store) => store.brands);
  const UsersList = useSelector((store) => store.users);

  const [ad, setAd] = useState({});
  const [open, setOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();
  const handleToggle = () => {
    setOpen(!open);
  };

  const userThatCreatedAd = UsersList.find((user) => user.id === ad.uid);

  if (userThatCreatedAd) {
  } else {
    console.log("User not found");
  }

  const adId = location.pathname.split("/")[2];

  useEffect(() => {
    const foundAd = AdsList.find((ad) => ad.id === Number(adId));
    if (foundAd !== undefined) {
      setAd(foundAd);
    } else {
      // handle error case where ad with adId is not found in AdsList
      console.log("Ad is not found ");
    }
  }, [AdsList, adId]);

  useEffect(() => {
    const findBrandById = (id) => {
      const matchedBrand = BrandsList.find((brand) => brand.id === ad.brand_id);
      return matchedBrand;
    };

    if (ad && ad.id) {
      const matchedBrand = findBrandById(ad.id);
      setSelectedBrand(matchedBrand);
    }
  }, [ad, BrandsList]);

  return (
    <Grid container spacing={0}>
      {" "}
      {/* Spacing for a gap between components */}
      {/* Box containing flex and AdsViewSidebar */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            mb:-6
          }}
        ></Box>

        <AdsViewSidebar
          socket={props.socket}
          user={props.user}
          data={ad}
          open={isSidebarOpen}
          handleDrawerToggle={handleDrawerToggle}
          category={ad.category_id}
          positions={ad.positions}
        />
      </Grid>
      {/* AdView component */}
      <Grid item xs={12}>
        <AdView
          selectedBrand={selectedBrand}
          userThatCreatedAd={userThatCreatedAd}
          handleDrawerToggle={handleDrawerToggle}
          isSidebarOpen={isSidebarOpen}
        />
      </Grid>
    </Grid>
  );
}

export default AdSingle;
