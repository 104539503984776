import React, { useContext, useEffect } from "react";
import {
  Outlet,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Dashboard from "../../dashboard/Dashboard";
import Templates from "../../templates/Templates";
import AddTemplate from "../../templates/AddTemplate";
import TemplateEditor from "../../templates/TemplateEditor";
import Users from "../../users/Users";
import UserProfile from "../../users/UserProfile";
import Clients from "../../clients/Clients";
import Brands from "../../brands/Brands";
import AddBrand from "../../brands/AddBrand";
import EditBrand from "../../brands/EditBrand";
import Categories from "../../categories/Categories";
import TemplateSingle from "../../templates/TemplateSingle";
import SignUpSide from "../../SignUp";
import SignInSide from "../../SignIn";
import AdEditor from "../../ads/AdEditor";
import Ads from "../../ads/Ads";
import AdSingle from "../../ads/AdsSingle";
import { AuthContext } from "../../../context/authContext";
import BrandsSingle from "../../brands/BrandSingle";
import Navbar from "../../../components/Navbar";
import NotificationSnackbar from "../../../notifications/features/NotificationSnackbar";
import { NotFound } from "../../../components/NotFound ";
import Settings from "../../settings/Settings";
import Crypto from "../../crypto/Crypto"; // Import the new Crypto component

const AppRouter = ({
  socket,
  currentUser,
  handleToggleDarkMode,
  darkMode,
  isLoading,
  setIsLoading,
}) => {
  const Layout = () => (
    <>
      <Navbar
        // socket={socket}
        user={currentUser}
        onToggleDarkMode={handleToggleDarkMode}
        darkMode={darkMode}
      />
      <NotificationSnackbar />
      <Outlet />
    </>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
      if (!currentUser && pathname !== "/signIn") {
        navigate("/signIn");
      }
    }, [currentUser, navigate, pathname]);

    return currentUser ? <Component {...rest} /> : null;
  };

  const routesConfig = {
    creator: [
      {
        path: "/",
        element: (
          <Dashboard
            setIsloading={setIsLoading}
            isLoading={isLoading}
            socket={socket}
          />
        ),
      },
      {
        path: "/ads",
        element: <Ads socket={socket} />,
      },
      {
        path: "/ad/:id",
        element: <AdSingle socket={socket} />,
      },
      {
        path: "/edit-ad/:id",
        element: (
          <AdEditor
            // socket={socket}
            user={currentUser}
          />
        ),
      },
      {
        path: "/templates",
        element: <Templates socket={socket} />,
      },
      {
        path: "/user-profile",
        element: <UserProfile socket={socket} />,
      },
      {
        path: "/crypto",
        element: <Crypto socket={socket} />, // Add Crypto route for creator
      },
    ],
    manager: [
      {
        path: "/",
        element: (
          <Dashboard
            setIsloading={setIsLoading}
            isLoading={isLoading}
            socket={socket}
          />
        ),
      },
      {
        path: "/ads",
        element: <Ads socket={socket} />,
      },
      {
        path: "/edit-ad/:id",
        element: <AdEditor user={currentUser} />,
      },
      {
        path: "/user-profile",
        element: <UserProfile socket={socket} />,
      },
      {
        path: "/brand/:id",
        element: <BrandsSingle socket={socket} user={currentUser} />,
      },
      { path: "/users", element: <Users socket={socket} /> },
      {
        path: "/brands",
        element: <Brands socket={socket} />,
      },
      {
        path: "/ad/:id",
        element: <AdSingle socket={socket} />,
      },
      {
        path: "/crypto",
        element: <Crypto socket={socket} />, // Add Crypto route for manager
      },
    ],
    admin: [
      {
        path: "/",
        element: (
          <Dashboard
            setIsloading={setIsLoading}
            isLoading={isLoading}
            socket={socket}
          />
        ),
      },
      {
        path: "/templates",
        element: <Templates socket={socket} />,
      },
      {
        path: "/ads",
        element: <Ads socket={socket} />,
      },
      {
        path: "/edit-ad/:id",
        element: <AdEditor socket={socket} user={currentUser} />,
      },
      {
        path: "/add-template",
        element: <AddTemplate socket={socket} user={currentUser} />,
      },
      { path: "/users", element: <Users socket={socket} user={currentUser} /> },
      {
        path: "/user-profile",
        element: <UserProfile socket={socket} user={currentUser} />,
      },
      ...(currentUser?.clientName === "AdReady"
        ? [
            {
              path: "/clients",
              element: <Clients socket={socket} user={currentUser} />,
            },
          ]
        : []),
      {
        path: "/brands",
        element: <Brands socket={socket} user={currentUser} />,
      },
      {
        path: "/add-brand",
        element: <AddBrand socket={socket} user={currentUser} />,
      },
      {
        path: "/edit-brand/:id",
        element: <EditBrand socket={socket} user={currentUser} />,
      },
      {
        path: "/brand/:id",
        element: <BrandsSingle socket={socket} user={currentUser} />,
      },
      {
        path: "/categories",
        element: <Categories socket={socket} user={currentUser} />,
      },
      {
        path: "/ad/:id",
        element: <AdSingle socket={socket} user={currentUser} />,
      },
      {
        path: "/settings",
        element: <Settings socket={socket} user={currentUser} />,
      },
      {
        path: "/crypto",
        element: <Crypto socket={socket} />, // Add Crypto route for admin
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  };

  const getRoutesForRole = (role) => {
    return routesConfig[role] || [];
  };

  return createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute component={Layout} />,
      children: getRoutesForRole(currentUser?.role),
    },
    {
      path: "/register",
      element: <SignUpSide />,
    },
    {
      path: "/signIn",
      element: <SignInSide />,
    },
  ]);
};

export default AppRouter;
