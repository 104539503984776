export function formatDateToLocaleString(dateString) {
  try {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date string:", dateString);
      return ""; // Return an empty string or a default value for invalid dates
    }

    const formattedDate = new Intl.DateTimeFormat("hr-HR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);

    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return ""; // Return an empty string or a default value in case of an error
  }
}
