import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "clients",
  initialState: [],
  reducers: {
    // Adds a new client to the state
    addClient: (state, action) => {
      const index = state.findIndex(
        (client) => client.id === action.payload.id
      );
      if (index === -1) {
        state.push(action.payload);
      } else {
        // You can choose to do nothing or update the existing client
        console.error(`Client with ID ${action.payload.id} already exists.`);
      }
    },
    // Updates an existing client's details
    editClient: (state, action) => {
      const { id, ...clientData } = action.payload;
      const existingClientIndex = state.findIndex((client) => client.id === id);
      if (existingClientIndex !== -1) {
        state[existingClientIndex] = {
          ...state[existingClientIndex],
          ...clientData,
          data: {
            ...state[existingClientIndex].data,
            ...clientData.data,
          },
        };
      }
    },

    // Deletes a client by id
    deleteOneClient: (state, action) => {
      const index = state.findIndex(
        (client) => client.id === action.payload.id
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    // Updates the entire state with a new list of clients
    updateClients: (state, action) => {
      return [...action.payload];
    },
    // Additional reducers can be added here if needed
  },
});

export const {
  addClient,
  setClients,
  editClient,
  deleteOneClient,
  updateClients,
} = clientSlice.actions;

export default clientSlice.reducer;
