import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { useDispatch } from "react-redux";
import { setBrands } from "./features/brandsReducer";
import { ACTIONS_URL, BRANDS_URL, UPLOAD_MULTIPLE_URL } from "../../config";
import axios from "axios";
import axiosInstance from "../../services/axiosInstance";

const AddBrand = () => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    brandLogo: "",
    backgrounds: [],
    products: [],
    industry: "", // Add industry to the state
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleImageChange = async (e) => {
    e.preventDefault();

    if (!values.name) {
      setSnackbarOpen(true);
      return;
    }

    if (e.target.files && e.target.files[0]) {
      const files = Array.from(e.target.files);
      const formData = new FormData();

      formData.append("brand", values.name); // Ensure the brand name is appended correctly
      formData.append("type", "brandLogo"); // Set the type to "brandLogo"
      formData.append("clientId", currentUser.clientIdUsers); // Include clientId if necessary

      files.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await axios.post(UPLOAD_MULTIPLE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const fileNames = response.data.map((file) => file.fileName);

        setValues((prevState) => ({
          ...prevState,
          brandLogo: fileNames[0], // Assuming single file upload
        }));
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Submit the brand
      const response = await axiosInstance.post(`${BRANDS_URL}`, {
        ...values,
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers, // send clientId when creating brand
      });
      dispatch(setBrands({ res: [response.data] }));

      // Log the action
      await axios.post(ACTIONS_URL, {
        actionType: "Create",
        objectType: "Brand",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers, // Include the clientId from currentUser
        content: response.data, // Include the brand data as content
      });

      navigate("/brands");
    } catch (error) {
      console.error(error);
    }
  };

  const getIndustries = () => {
    return [
      "Agriculture",
      "Automotive",
      "Banking",
      "Biotechnology",
      "Construction",
      "Consumer Goods",
      "Education",
      "Energy",
      "Entertainment",
      "Fashion",
      "Finance",
      "Food & Beverage",
      "Healthcare",
      "Hospitality",
      "Information Technology",
      "Insurance",
      "Manufacturing",
      "Media",
      "Mining",
      "Pharmaceuticals",
      "Real Estate",
      "Retail",
      "Telecommunications",
      "Transportation",
      "Utilities",
    ];
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box component='form' onSubmit={handleSubmit} sx={{ pl: 8, m: 3 }}>
      <Typography variant='h4' gutterBottom>
        Add New Brand
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Brand Name'
            name='name'
            onChange={handleChange}
            required
            value={values.name}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Description'
            multiline
            rows={4}
            name='description'
            onChange={handleChange}
            required
            value={values.description}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel htmlFor='image-upload'>Upload Logo</InputLabel>
            <Input
              id='image-upload'
              type='file'
              onChange={handleImageChange}
              sx={{ display: "none" }}
              inputProps={{ accept: "image/*" }}
              disabled={!values.name}
            />
            <Button
              fullWidth
              variant='contained'
              component='label'
              htmlFor='image-upload'
              sx={{ mt: 1 }}
              startIcon={<PhotoCamera />}
              disabled={!values.name}
            >
              Upload Logo
            </Button>
          </FormControl>
          {values.brandLogo && (
            <Box mt={2} display='flex' justifyContent='center'>
              <img
                alt='Brand Logo'
                src={values.brandLogo}
                style={{ width: 100, height: 100 }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel>Industry</InputLabel>
            <Select
              name='industry'
              value={values.industry}
              onChange={handleChange}
              label='Industry'
              required
            >
              {getIndustries().map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant='contained' color='primary' type='submit'>
            Add Brand
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='warning'
          sx={{ width: "100%" }}
        >
          Please enter the brand name before uploading a logo.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddBrand;
