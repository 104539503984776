import React, { useState, useRef } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ASSETS_URL } from "../../../config";

const LogoUpdateModal = ({
  image,
  logoId,
  openLogoModal,
  handleCloseLogoModal,
  handleUpdateLogo,
  logoName,
  setLogoName,
  logoDescription,
  setLogoDescription,
  flags,
  setFlags,
  uploadFiles,
  seriesOptions,
}) => {
  const [selectedSeries, setSelectedSeries] = useState(
    image.data.selectedSeries || ""
  );

  const fileInputRef = useRef(null);

  const handleChangeSeries = (event) => {
    setSelectedSeries(event.target.value);
  };

  const handleFlagChange = (event) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleSubmit = () => {
    handleUpdateLogo(logoId, selectedSeries, logoName, logoDescription, flags);
    handleCloseLogoModal();
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    await uploadFiles(e, "logos", true, async (uploadedFileName) => {
      // After uploading, call handleUpdateLogo with the new filename
      handleUpdateLogo(
        logoId,
        selectedSeries,
        logoName,
        logoDescription,
        flags,
        uploadedFileName
      );
    });
  };

  return (
    <Modal open={openLogoModal} onClose={handleCloseLogoModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "600px",
          maxHeight: "80vh", // Set maximum height for scrolling
          overflow: "auto", // Enable scrolling
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <TextField
          label='Name'
          variant='outlined'
          fullWidth
          margin='normal'
          value={logoName}
          onChange={(e) => setLogoName(e.target.value)}
          size='small'
        />
        <TextField
          label='Description'
          variant='outlined'
          fullWidth
          margin='normal'
          value={logoDescription}
          onChange={(e) => setLogoDescription(e.target.value)}
          size='small'
          multiline
          rows={6}
        />
        <FormControl fullWidth margin='dense' size='small'>
          <InputLabel size='small'>Series</InputLabel>
          <Select
            value={selectedSeries}
            label='Series'
            onChange={handleChangeSeries}
            size='small'
          >
            {seriesOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormGroup>
            {Object.keys(flags).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={flags[key]}
                    onChange={handleFlagChange}
                    name={key}
                  />
                }
                label={key.charAt(0).toUpperCase() + key.slice(1)}
              />
            ))}
          </FormGroup>
          <Grid item xs={4}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
              onClick={handleImageClick}
            >
              <img
                src={`${ASSETS_URL}/${image?.data?.fileName}`}
                alt={logoName}
                loading='lazy'
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                  backgroundColor: !flags.positive ? "#808080" : "transparent",
                }}
              />
            </Paper>
          </Grid>

          <input
            type='file'
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
        <Box textAlign='center' marginTop={2}>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Update Logo
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoUpdateModal;
