import React, { useEffect, useState, useContext, useRef } from "react";
import { useTheme } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider } from "@mui/material";
import { formatNotification } from "../helpers/formatNotifications";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { NOTIFICATIONS_URL } from "../config";
import axiosInstance from "../services/axiosInstance";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import {
  deleteNotification,
  setNotifications,
} from "../notifications/features/notificationSlice";
import { useNavigate } from "react-router-dom";

const NavBarNotificationsMenu = ({ notifications }) => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const UsersList = useSelector((store) => store.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unseenNotifications, setUnseenNotifications] = useState(0);
  const [notificationsClicked, setNotificationsClicked] = useState(false); // Add this state variable
  const prevNotificationsCount = useRef(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredNotifications = notifications.filter((notification) => {
    // Filter out notifications for "Login" and "Logout" actions
    if (
      notification.action?.actionType === "Login" ||
      notification.action?.actionType === "Logout"
    )
      return false;

    // Filter out notifications where currentUser.id is equal to action.userId
    if (notification.action?.userId === currentUser.id) return false;

    if (Array.isArray(notification.userNotifications)) {
      // Check if userNotifications is an array
      const userNotification = notification.userNotifications.find(
        (userNotif) =>
          userNotif.status === "NotDelivered" && // Matching "NotDelivered" status
          notification.action?.clientId === currentUser.clientIdUsers // Using clientIdUsers
      );
      return !!userNotification;
    }
    return false; // Return false if userNotifications is not an array
  });

  // Function to count notifications for the current user
  const countNotifications = () => filteredNotifications.length;

  // Function to count notifications for the current user
  const currentNotificationsCount = countNotifications();

  // Use useEffect to listen to changes in the notifications array
  useEffect(() => {
    if (
      currentNotificationsCount > prevNotificationsCount.current ||
      !notificationsClicked
    ) {
      // If there are new notifications or notifications have not been clicked
      setUnseenNotifications(currentNotificationsCount);
    }
    prevNotificationsCount.current = currentNotificationsCount; // Update the previous count
  }, [filteredNotifications, notificationsClicked, currentNotificationsCount]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    // Optionally, you can reset the unseen notifications counter when closing the menu
    setUnseenNotifications(0);
    setNotificationsClicked(true);

    // Add the logic to mark notifications as read here
    try {
      // Mark notifications as read for the current user
      const updateNotificationsResponse = await axiosInstance.put(
        `${NOTIFICATIONS_URL}/read/${currentUser.id}`
      );

      // Dispatch the updated notifications to the Redux store
      dispatch(setNotifications(updateNotificationsResponse.data));
    } catch (error) {
      console.error("An error occurred while updating notifications:", error);
    }
  };

  const handleNotificationClick = (notification) => {
    const actionType = notification.action?.actionType;
    const objectType = notification.action?.objectType;
    const objectId =
      notification.action?.adId ||
      notification.action?.userId ||
      notification.action?.brandId ||
      notification.action?.categoryId;

    const adIdInNotification = notification.action?.content?.ad_id;

    // Check if the notification action type is "Delete"
    if (actionType === "Delete") {
      // Dispatch action to delete the notification
      dispatch(deleteNotification({ id: notification.id }));
    } else {
      switch (objectType) {
        case "Ad":
          navigate(`/edit-ad/${objectId}`);
          break;
        case "User":
          navigate(`/users`);
          break;
        case "Brand":
          navigate(`/brands/`);
          break;
        case "Client":
          navigate(`/clients/`);
          break;
        case "Category":
          navigate(`/categories/`);
          break;
        case "Message":
          navigate(`/edit-ad/${adIdInNotification}`, {
            state: {
              openSidebar: true,
              activeTab: "Messages", // Indicating which tab should be active
            },
          });
          break;
        default:
          // Handle other types or do nothing
          break;
      }
    }
    // Do not close the menu
  };

  return (
    <Box>
      <Box onClick={handleClick} color='inherit'>
        <Badge
          badgeContent={unseenNotifications > 0 ? unseenNotifications : null}
          color='error'
        >
          <NotificationsIcon />
        </Badge>
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {filteredNotifications
          .slice()
          .reverse()
          .map((notification, index) => {
            // Reverse the filteredNotifications array
            const user = UsersList.find(
              (user) => user.id === notification.action.userId // Using action.userId
            );
            const initials =
              user && user.firstName && user.lastName
                ? user.firstName.charAt(0).toUpperCase() +
                  user.lastName.charAt(0).toUpperCase()
                : "";

            return (
              <div key={notification.id}>
                <MenuItem
                  onClick={() => handleNotificationClick(notification)}
                  sx={{ width: 300 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 2,
                    }}
                  >
                    <Avatar
                      type='square'
                      sx={{
                        backgroundColor:
                          theme.palette.primary.avatarBackgroundColor,
                        width: 30,
                        height: 30,
                        fontSize: 12,
                      }}
                    >
                      {initials}
                    </Avatar>
                  </Box>
                  <ListItemText
                    primary={
                      <Typography
                        variant='body2'
                        sx={{
                          color: theme.typography.fontColor,
                          fontWeight: 600,
                          lineHeight: 0.8,
                        }} // Added lineHeight
                      >
                        {user ? user.firstName : "Unknown"} -{" "}
                        {formatNotification(
                          notification.action.actionType,
                          notification.action.objectType,
                          notification.action.targetUserId,
                          currentUser.id
                        )}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant='caption'
                        sx={{
                          color: theme.typography.fontColor,
                          fontWeight: 400,
                          lineHeight: 1.2,
                        }} // Added lineHeight
                      >
                        {new Date(notification.timestamp).toLocaleString()}
                      </Typography>
                    }
                  />
                </MenuItem>
                {index < filteredNotifications.length - 1 && (
                  <Divider variant='middle' />
                )}
              </div>
            );
          })}
      </Menu>
    </Box>
  );
};

export default NavBarNotificationsMenu;
