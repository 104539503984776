import { createSlice } from "@reduxjs/toolkit";

const brandSlice = createSlice({
  name: "brands",
  initialState: [],
  reducers: {
    addBrand: (state, action) => {
      const existingBrandIndex = state.findIndex(
        (brand) => brand.id === action.payload.id
      );
      if (existingBrandIndex === -1) {
        state.push(action.payload);
      } else {
        console.log(`Brand with id ${action.payload.id} already exists.`);
      }
    },

    setBrands: (state, action) => {
      const existingBrand = action.payload.res.filter(
        (brand) => !state.some((b) => b.id === brand.id)
      );
      state.push(...existingBrand);
    },
    editBrand: (state, action) => {
      const { id, name, description, brandLogo, data } = action.payload;
      const existingBrand = state.find((brand) => brand.id === id);
      if (existingBrand) {
        existingBrand.name = name;
        existingBrand.description = description;
        existingBrand.brandLogo = brandLogo;
        existingBrand.data = data; // Ensure the data field is updated
      }
    },

    deleteOneBrand: (state, action) => {
      const { id } = action.payload;
      return state.filter((brand) => brand.id !== id);
    },

    deleteManyBrands: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((brand) => !idsToDelete.has(brand.id));
    },
    updateBrands: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addBrand,
  setBrands,
  editBrand,
  deleteOneBrand,
  updateBrands,
  deleteManyBrands,
} = brandSlice.actions;
export default brandSlice.reducer;
