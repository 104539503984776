import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import BrandItems from "./BrandItems";
import { Box, Tab, Tabs } from "@mui/material";
import axiosInstance from "../../../services/axiosInstance";
import { AuthContext } from "../../../context/authContext";

const BrandsTabs = ({
  existingAd,
  mdValue,
  rightSideBar,
  setValues,
  initialLogoBrands,
  setShowImage,
  editStates,
  editingResolution,
  editingProduct,
  values,
}) => {
  const {
    backgrounds: backgroundsFromStore,
    products: productsFromStore,
    badges: badgesFromStore,
    stickers: stickersFromStore,
    ctas: ctasFromStore,
    logos: logosFromStore,
    fonts: fontsFromStore,
    brands: brandsList,
  } = useSelector((state) => state);

  // const brandsList = useSelector((store) => store.brands);
  const backgroundsList = useSelector((store) => store.backgrounds);
  const { currentUser } = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [shutterstockImages, setShutterstockImages] = useState([]);
  const [shutterSearchInput, setShutterSearchInput] = useState("");

  const [shutterstockPage, setShutterstockPage] = useState(0);
  const [shutterstockTotalPages, setShutterstockTotalPages] = useState(0);
  const shutterstockItemsPerPage = 20;
  const [fetchingImages, setFetchingImages] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("popular"); // Default to "Popular"

  const [filters, setFilters] = useState({
    image_type: [],
    orientation: "",
    people_number: "",
    ai_search: false,
    ai_objective: "",
    ai_industry: "",
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const filterParams = (params) => {
      const cleanParams = {};
      Object.keys(params).forEach((key) => {
        if (
          params[key] !== undefined &&
          params[key] !== "" &&
          params[key].length !== 0
        ) {
          cleanParams[key] = params[key];
        }
      });
      return cleanParams;
    };

    const fetchImages = async () => {
      const clientId = currentUser?.clientIdUsers;
      if (!clientId) {
        console.error("Client ID is required");
        setError("Client ID is required.");
        return;
      }

      setFetchingImages(true);
      try {
        const response = await axiosInstance.get("shutterstock/search", {
          params: filterParams({
            query: shutterSearchInput,
            page: shutterstockPage + 1, // API page starts from 1
            per_page: shutterstockItemsPerPage,
            sort: sortCriteria.toLowerCase(),
            clientId: clientId, // Include the clientId in the request
            ...filters,
          }),
        });

        if (response.data && response.data.data) {
          setShutterstockImages(response.data.data);

          // Calculate the total pages based on the total_count from the API response.
          const totalPages = Math.ceil(
            response.data.total_count / shutterstockItemsPerPage
          );
          setShutterstockTotalPages(totalPages);
        }
      } catch (err) {
        console.error("Error fetching images from Shutterstock:", err);
        setError("Failed to fetch images from Shutterstock.");
      } finally {
        setFetchingImages(false);
      }
    };

    fetchImages();
  }, [
    shutterSearchInput,
    shutterstockPage,
    sortCriteria,
    filters,
    currentUser,
  ]);

  const handleShutterstockPrevPage = () => {
    setShutterstockPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleShutterstockNextPage = () => {
    setShutterstockPage((prevPage) => prevPage + 1);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (!brandsList || brandsList.length === 0) {
    return null;
  }

  if (selectedTab < 0 || selectedTab >= brandsList.length) {
    return null;
  }

  const selectedBrand = brandsList[selectedTab];
  const updatedBackgrounds = backgroundsList.filter(
    (bg) => bg.brandId === selectedBrand.id
  );

  const brandItemsProps = {
    adId: existingAd.id,
    mdValue,
    selectedBrandId: selectedBrand.id,
    backgrounds: updatedBackgrounds, // Use the updated backgrounds directly
    products: productsFromStore,
    stickers: stickersFromStore,
    ctas: ctasFromStore,
    logos: logosFromStore,
    fonts: fontsFromStore,
    badges: badgesFromStore,
    brand: selectedBrand,
    shutterSearchInput,
    setShutterSearchInput,
    shutterstockImages,
    rightSideBar,
    shutterstockPage,
    shutterstockTotalPages,
    handleShutterstockPrevPage,
    handleShutterstockNextPage,
    setShutterstockPage,
    fetchingImages,
    setSortCriteria,
    setFilters,
    setValues,
    initialLogoBrands,
    setShowImage,
    editStates,
    editingResolution,
    editingProduct,
    values,
  };

  return (
    <Box>
      <Tabs
        sx={{ mb: 1, maxWidth: "95%" }}
        value={selectedTab}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {brandsList.map((brand, index) => {
          // // Log the brand id and name
          // console.log(`Brand ID: ${brand.id}, Brand Name: ${brand.name}`);

          return <Tab key={brand.id} label={brand.name} />;
        })}
      </Tabs>

      <BrandItems {...brandItemsProps} />
    </Box>
  );
};

export default BrandsTabs;
