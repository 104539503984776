import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../context/authContext";
import { Grid } from "@mui/material";
import UserCard from "../../components/UserCard";
import AdReadyHelp from "../../components/AdReadyHelp";
import { useDispatch, useSelector } from "react-redux";
import AddAndEditModal from "../../components/AddAndEditModal";

import RecentComments from "../dashboard/components/RecentComments";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import AdsTable from "../../components/Table";
import axios from "axios";
import { USERS_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { editUser } from "./features/userSlice";
import { useTheme } from "@mui/material/styles";

export default function UserProfile() {
  const AdsList = useSelector((store) => store.ads);
  const UsersList = useSelector((store) => store.users);
  const BrandsList = useSelector((store) => store.brands);
  const CommentsList = useSelector((store) => store.comments);
  const ClientsList = useSelector((store) => store.clients);

  const theme = useTheme();

  const [numberOfAds, setNumberOfAds] = useState(0);
  const [pendingAds, setPendingAds] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loggedInUserFromList, setLoggedInUserFromList] = useState(null);

  const [values, setValues] = useState({
    id: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    role: "",
    clientId: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const foundUser = UsersList.find((user) => user.id === currentUser.id);
    setLoggedInUserFromList(foundUser);
    if (foundUser) {
      setValues({
        id: foundUser.id || "",
        firstName: foundUser.firstName || "",
        lastName: foundUser.lastName || "",
        username: foundUser.username || "",
        email: foundUser.email || "",
        password: foundUser.password || "",
        role: foundUser.role || "",
        clientId: foundUser.clientIdUsers || "",
      });
    }
  }, [UsersList, currentUser]);

  useEffect(() => {
    let currentUserAds = AdsList.filter((ad) => ad.uid === currentUser.id);

    if (filter !== "all") {
      currentUserAds = currentUserAds.filter((ad) => ad.status === filter);
    }

    const newRows = currentUserAds.map((ad) => {
      const user = UsersList.find((user) => user.id === ad.uid);
      const createdBy = user ? user.username : "Unknown";

      const brand = BrandsList.find((brand) => brand.id === ad.brand_id);
      const brandName = brand ? brand.name : "Unknown";

      const approver = UsersList.find((user) => user.id === ad.approverId);
      const approverName = approver ? approver.firstName : "Unknown";

      return {
        id: ad.id,
        title: ad.title,
        brand: brandName,
        createdBy,
        created: formatDateToLocaleString(ad.createdAt),
        updated: formatDateToLocaleString(ad.updatedAt),
        status: ad.status,
        approverName: approverName,
      };
    });

    setRows(newRows);
  }, [AdsList, UsersList, BrandsList, currentUser, filter]); // filter is added as a dependency

  useEffect(() => {
    // Filter the AdsList to only include ads from the current user
    const currentUserAds = AdsList.filter((ad) => ad.uid === currentUser.id);
    setNumberOfAds(currentUserAds.length);
    // Filter the currentUserAds to only include ads that are "pending"
    const filterPendingAds = currentUserAds.filter(
      (ad) => ad.status === "pending"
    );
    setPendingAds(filterPendingAds);
  }, [AdsList, currentUser.id]);

  const cardColors = {
    item1: theme.typography.fontColor,
    item2: theme.typography.fontColor,
    item3: theme.typography.fontColor,
  };

  const handlePutRequest = async () => {
    try {
      const response = await axios.put(`${USERS_URL}/${currentUser.id}`, {
        id: currentUser.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        username: values.username,
        password: values.password,
        role: values.role,
        clientIdUsers: values.clientId,
      });

      const responseData = response.data;

      dispatch(
        editUser({
          id: responseData.user.id,
          firstName: responseData.user.firstName,
          lastName: responseData.user.lastName,
          email: responseData.user.email,
          username: responseData.user.username,
          password: responseData.user.password,
          role: responseData.user.role,
          clientIdUsers: responseData.user.clientIdUsers,
          createdAt: responseData.user.createdAt,
        })
      );

      // Uncomment and refine the below code if needed for actions and socket emissions
      /*
      await axios.post(ACTIONS_URL, {
        action: userUpdated,
        firstName: currentUser.firstName,
        adTitle: firstName,
        createdAt: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      });
  
      props.socket?.emit("sendCommentNotification", {
        id: responseData.id,
        senderName: currentUser,
        input: firstName,
        action: userUpdated,
        time,
        type: type,
      });
      */
    } catch (err) {
      console.error(
        "Error updating the user:",
        err.response ? err.response.data : err.message
      );
    }
  };

  const handleEditUser = async () => {
    try {
      await handlePutRequest();
      navigate("/users");
    } catch (err) {
      console.error("Error in handleEditUser:", err);
    }
  };

  const backgroundColor = "linear-gradient(45deg, #A06EAF 30%, #F37979 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
      <Grid item xs={12} md={7}>
        <UserCard
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfAds={numberOfAds}
          pendingAds={pendingAds}
          cardHeader={"Dashboard"}
          cardHeader1={"Your profile"}
          backgroundColor={backgroundColor}
          rows={rows}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      {/* Full page width container for AddAndEditModal, RecentComments, and TableComponent */}
      <Grid item xs={12}>
        <Grid sx={{ mt: -6 }} container spacing={2}>
          <Grid item xs={12} sm={5}>
            <AddAndEditModal
              handleEditUser={handleEditUser}
              values={values}
              setValues={setValues}
              currentUser={loggedInUserFromList}
              clients={ClientsList}
              userTitle={"User profile"}
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <Grid container>
              <Grid item xs={12}>
                <AdsTable
                  title='Your Recent Ads'
                  rows={rows}
                  name={rows.name}
                  setFilter={setFilter}
                />
              </Grid>
              <Grid item xs={12}>
                <RecentComments CommentsList={CommentsList} AdsList={AdsList} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
