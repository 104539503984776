import { determineLogoOrientation } from "../helpers/determineLogoOrientation";

export const gigabyteStandardCalculation = (
  xRes,
  yRes,
  selectedCorner,
  selectedBaseCorner,
  element,
  type,
  sameCornerAsLogoBrand1,
  logoBrand1Values = { height: null, width: null },
  partnership
) => {
  if (
    !element ||
    typeof element.width === "undefined" ||
    typeof element.height === "undefined"
  ) {
    throw new Error(`Invalid element provided: ${JSON.stringify(element)}`);
  }

  const standardHeight = (xRes + yRes) * 0.0225;
  const aspectRatio = element.width / element.height;
  const standardWidth = standardHeight * aspectRatio;

  const elementClone = JSON.parse(JSON.stringify(element));
  elementClone.orientation = determineLogoOrientation(element);

  let logoBrand1calculatedHeight = standardHeight;
  let logoBrand1calculatedWidth = standardWidth;

  // Ensure minimum height for logoBrand1 is 22px
  if (type === "logoBrand1") {
    if (logoBrand1calculatedHeight < 22) {
      logoBrand1calculatedHeight = 22;
      logoBrand1calculatedWidth = logoBrand1calculatedHeight * aspectRatio;
    }
  }

  let otherLogoCalculatedHeight = standardHeight;
  let otherLogoCalculatedWidth = standardWidth;

  const partnershipAdjustment =
    (2 * logoBrand1Values.height - otherLogoCalculatedHeight) / 2;

  if (type !== "logoBrand1") {
    if (element.orientation === "horizontal") {
      otherLogoCalculatedHeight = standardHeight;
      otherLogoCalculatedWidth = otherLogoCalculatedHeight * aspectRatio;
    } else if (
      element.orientation === "vertical" ||
      element.orientation === "box"
    ) {
      otherLogoCalculatedHeight = 2 * standardHeight;
      otherLogoCalculatedWidth = otherLogoCalculatedHeight * aspectRatio;
    }
  }

  let position = { x: element.x, y: element.y };
  if (type === "logoBrand1") {
    logoBrand1Values.height = logoBrand1calculatedHeight;
    logoBrand1Values.width = logoBrand1calculatedWidth;

    position = { ...selectedBaseCorner };
    switch (selectedCorner) {
      case "topLeft":
        position.x += logoBrand1calculatedHeight;
        if (partnership) {
          position.y =
            logoBrand1calculatedHeight + logoBrand1calculatedHeight / 2;
        } else {
          position.y += logoBrand1calculatedHeight;
        }
        break;
      case "topRight":
        position.x =
          xRes - logoBrand1calculatedWidth - logoBrand1calculatedHeight;
        if (partnership) {
          position.y =
            logoBrand1calculatedHeight + logoBrand1calculatedHeight / 2;
        } else {
          position.y += logoBrand1calculatedHeight;
        }
        break;
      case "bottomLeft":
        position.x += logoBrand1calculatedHeight;
        if (partnership) {
          position.y =
            yRes -
            2 * logoBrand1calculatedHeight -
            logoBrand1calculatedHeight / 2;
        } else {
          position.y = yRes - 2 * logoBrand1calculatedHeight;
        }
        break;
      case "bottomRight":
        position.x =
          xRes - logoBrand1calculatedWidth - logoBrand1calculatedHeight;
        if (partnership) {
          position.y =
            yRes -
            2 * logoBrand1calculatedHeight -
            logoBrand1calculatedHeight / 2;
        } else {
          position.y = yRes - 2 * logoBrand1calculatedHeight;
        }
        break;
      default:
        break;
    }
  } else if (sameCornerAsLogoBrand1 && partnership) {
    const { height: logoBrand1Height, width: logoBrand1Width } =
      logoBrand1Values;

    switch (selectedCorner) {
      case "topLeft":
        position.x =
          selectedBaseCorner.x +
          logoBrand1Width +
          2 * logoBrand1Height +
          2 * partnershipAdjustment;
        position.y =
          selectedBaseCorner.y + logoBrand1Height + partnershipAdjustment;
        break;
      case "topRight":
        position.x =
          selectedBaseCorner.x -
          2 * logoBrand1Height -
          logoBrand1Width -
          otherLogoCalculatedWidth -
          2 * partnershipAdjustment;
        position.y =
          selectedBaseCorner.y + logoBrand1Height + partnershipAdjustment;
        break;
      case "bottomLeft":
        position.x =
          selectedBaseCorner.x +
          logoBrand1Width +
          2 * logoBrand1Height +
          logoBrand1Height;
        position.y =
          yRes -
          logoBrand1Height -
          otherLogoCalculatedHeight -
          partnershipAdjustment;
        break;
      case "bottomRight":
        position.x =
          xRes -
          2 * logoBrand1Height -
          logoBrand1Width -
          otherLogoCalculatedWidth -
          2 * partnershipAdjustment;

        position.y =
          yRes -
          logoBrand1Height -
          otherLogoCalculatedHeight -
          partnershipAdjustment;

        break;
      default:
        break;
    }
  } else if (sameCornerAsLogoBrand1) {
    const { height: logoBrand1Height, width: logoBrand1Width } =
      logoBrand1Values;

    switch (selectedCorner) {
      case "topLeft":
        position.x =
          selectedBaseCorner.x + logoBrand1Width + 2 * logoBrand1Height;
        position.y = selectedBaseCorner.y + logoBrand1Height;
        break;
      case "topRight":
        position.x =
          selectedBaseCorner.x -
          2 * logoBrand1Height -
          logoBrand1Width -
          otherLogoCalculatedWidth;
        position.y = selectedBaseCorner.y + logoBrand1Height;
        break;
      case "bottomLeft":
        position.x =
          selectedBaseCorner.x + logoBrand1Width + 2 * logoBrand1Height;
        position.y = yRes - logoBrand1Height - otherLogoCalculatedHeight;
        break;
      case "bottomRight":
        position.x =
          xRes -
          2 * logoBrand1Height -
          logoBrand1Width -
          otherLogoCalculatedWidth;
        position.y = yRes - logoBrand1Height - otherLogoCalculatedHeight;
        break;
      default:
        break;
    }
  } else {
    switch (selectedCorner) {
      case "topLeft":
        position.x = logoBrand1calculatedHeight;
        position.y = logoBrand1calculatedHeight;
        break;
      case "topRight":
        position.x =
          xRes - logoBrand1calculatedHeight - otherLogoCalculatedWidth;
        position.y = logoBrand1calculatedHeight;
        break;
      case "bottomLeft":
        position.x = logoBrand1calculatedHeight;
        position.y =
          yRes - logoBrand1calculatedHeight - otherLogoCalculatedHeight;
        break;
      case "bottomRight":
        position.x =
          xRes - logoBrand1calculatedHeight - otherLogoCalculatedWidth;
        position.y =
          yRes - logoBrand1calculatedHeight - otherLogoCalculatedHeight;
        break;
      default:
        break;
    }
  }

  return {
    height:
      type === "logoBrand1"
        ? logoBrand1calculatedHeight
        : otherLogoCalculatedHeight,
    width:
      type === "logoBrand1"
        ? logoBrand1calculatedWidth
        : otherLogoCalculatedWidth,
    position,
    selectedCornerPosition: selectedBaseCorner,
    logoBrand1Values,
  };
};
