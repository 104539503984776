import { amdStandardCalculation } from "../utils/amdStandardCalculation";
import { gigabyteStandardCalculation } from "../utils/gigabyteStandardCalculation";
import { intelStandardCalculation } from "../utils/intelStandardCalculation";
import { microsoftStandardCalculation } from "../utils/microsoftStandardCalculation";

// Create a mapping of brand names to their respective calculation functions
export const brandCalculationMapping = {
  Amd: amdStandardCalculation,
  Microsoft: microsoftStandardCalculation,
  Intel: intelStandardCalculation,
  Gigabyte: gigabyteStandardCalculation,
  // Add more brands here as needed
};
