import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../images/adready_logo_1.png";

// ICONS
import DashboardIcon from "@mui/icons-material/Dashboard";
import MailIcon from "@mui/icons-material/Mail";
import FaceIcon from "@mui/icons-material/Face";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DvrIcon from "@mui/icons-material/Dvr";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Avatar from "@mui/material/Avatar";
import NavBarNotificationMenu from "../components/NavBarNotificationsMenu";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CategoryIcon from "@mui/icons-material/Category";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { AccountBalanceWallet } from "@mui/icons-material";

import Tooltip from "@mui/material/Tooltip";
// import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { Button, Menu, MenuItem } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../notifications/features/notificationSlice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "linear-gradient(45deg, #293e6f, #a06eaf)", // add this line
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { currentUser, logout } = useContext(AuthContext);
  const clients = useSelector((state) => state.clients);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const NotificationsList = useSelector((store) => store.notifications);

  const currentClient = clients?.find(
    (client) => client.id === currentUser?.clientIdUsers
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    props.socket?.on("notification", (data) => {
      console.log("notificationsDarta", data);
      dispatch(addNotification(data));
    });
  }, [props.socket, dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pages = [
    // <Link className="link" to="/?cat=esg">
    //   ESG
    // </Link>,
    // <Link className="link" to="/?cat=csg">
    //   CSG
    // </Link>,
    // <Link className="link" to="/?cat=dncp">
    //   DNCP
    // </Link>,
  ];

  const settings = [
    {
      id: "profile",
      component: (
        <Link className='link' to='/user-profile'>
          <span>Profile</span>
        </Link>
      ),
    },
    { id: "account", component: "Account" },
    { id: "dashboard", component: "Dashboard" },
    {
      id: "logout",
      component: (
        <Link className='link' to='/signIn'>
          <span onClick={logout}>Logout</span>
        </Link>
      ),
    },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const roleConfig = {
    creator: ["Dashboard", "Ads", "Settings"],
    manager: ["Dashboard", "Brands", "Users", "Ads", "Crypto", "Settings"],
    admin: [
      "Dashboard",
      "Inbox",
      ...(currentUser?.clientName === "AdReady" ? ["Clients"] : []),
      "Brands",
      "Categories",
      "Users",
      "Ads",
      "Templates",
      "Crypto",
      "Settings",
    ],
  };

  const itemsList = [
    {
      text: "Dashboard",
      icon: <DashboardIcon color='primary' />,
      onClick: () => navigate("/"),
    },
    {
      text: "Inbox",
      icon: <MailIcon color='primary' />,
      onClick: () => navigate("/"),
    },
    {
      text: "Clients",
      icon: <FaceIcon color='primary' />,
      onClick: () => navigate("/clients"),
    },
    {
      text: "Brands",
      icon: <BrandingWatermarkIcon color='primary' />,
      onClick: () => navigate("/brands"),
    },
    {
      text: "Categories",
      icon: <CategoryIcon color='primary' />,
      onClick: () => navigate("/categories"),
    },
    {
      text: "Users",
      icon: <PeopleAltIcon color='primary' />,
      onClick: () => navigate("/users"),
    },

    {
      text: "Ads",
      icon: <DvrIcon color='primary' />,
      onClick: () => navigate("/ads"),
    },
    {
      text: "Templates",
      icon: <WallpaperIcon color='primary' />,
      onClick: () => navigate("/templates"),
    },
    {
      text: "Crypto",
      icon: <AccountBalanceWallet color='primary' />,
      onClick: () => navigate("/crypto"),
    },
    {
      text: "Settings",
      icon: <SettingsApplicationsIcon color='primary' />,
      onClick: () => navigate("/settings"),
    },
  ];

  // Create a mapping from item text to the actual item
  const itemsMapping = itemsList.reduce((acc, item) => {
    acc[item.text] = item;
    return acc;
  }, {});

  const filteredItems = (roleConfig[currentUser?.role] || []).map(
    (text) => itemsMapping[text]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Container maxWidth=''>
          <Toolbar disableGutters>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center'>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <img
              src={Logo}
              alt='AdReady Logo'
              style={{ width: "4%", height: "4%" }}
            />

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: theme.palette.primary.light,
                    display: "block",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <IconButton onClick={props.onToggleDarkMode} color='inherit'>
              {props.darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {/* <Typography>{`user : ${currentUser?.firstName} ${currentUser?.lastName}`}</Typography> */}
            <Typography>{`${currentClient?.name} `}</Typography>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                size='large'
                aria-label='show new notifications'
                color='inherit'
              >
                <NavBarNotificationMenu notifications={NotificationsList} />
              </IconButton>
              <Tooltip title='Open settings'>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      alt='Remy Sharp'
                      src={currentUser?.img}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      paddingLeft: 2,
                      color: theme.palette.primary.light,
                    }}
                    variant='h6'
                  >
                    {currentUser?.username}
                  </Typography>
                </Box>
              </Tooltip>

              <Menu
                sx={{
                  mt: "45px",
                }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.id} onClick={handleCloseUserMenu}>
                    <Typography textAlign='center'>
                      {setting.component}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {filteredItems.map((item) => {
            const { text, icon, onClick } = item;
            return (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={onClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        <Divider />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}
