import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IconButton, Grid } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import { ACTIONS_URL, CLIENTS_URL } from "../../config";
import {
  deleteOneClient,
  addClient,
  editClient,
} from "../clients/features/clientSlice";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import ClientsTable from "../../components/Table";
import AdReadyHelp from "../../components/AdReadyHelp";
import UserCard from "../../components/UserCard";
import AddAndEditModal from "../../components/AddAndEditModal";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";

export default function Clients(props) {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const ClientsList = useSelector((store) => store.clients);
  const [clientState, setClientsState] = useState(ClientsList);
  const [showClientModal, setShowClientModal] = useState(false);
  const [userTitle, setUserTitle] = useState(""); // Define userTitle in the state
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    role: "",
    clientId: "",
    phone: "",
    type: "",
    name: "",
    address: "",
    website: "",
    data: { countries: [] },
  };

  const [values, setValues] = useState(initialValues);

  const handlePostRequest = async () => {
    try {
      const payload = {
        type: values.type,
        name: values.name,
        address: values.address,
        phone: values.phone,
        email: values.email,
        website: values.website,
        data: { countries: values.countries },
      };

      const response = await axios.post(CLIENTS_URL, payload);
      const {
        id,
        type,
        name,
        address,
        phone,
        email,
        website,
        data,
        createdAt,
        updatedAt,
      } = response.data;

      await axios.post(ACTIONS_URL, {
        actionType: "Create",
        objectType: "Client",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        content: response.data,
      });

      dispatch(
        addClient({
          id,
          type,
          name,
          address,
          phone,
          email,
          website,
          data,
          createdAt: createdAt || null,
          updatedAt: updatedAt || null,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddClient = async (e) => {
    if (
      values.type &&
      values.name &&
      values.address &&
      values.phone &&
      values.email &&
      values.website
    ) {
      try {
        await handlePostRequest();
        setTimeout(() => {
          navigate("/clients");
        }, 500);
      } catch (err) {
        console.error("Error while creating client:", err);
      }
    }
  };

  useEffect(() => {
    const transformedClients = ClientsList.map((client) => {
      return {
        id: client?.id,
        name: client?.name,
        address: client?.address,
        email: client?.email,
        phone: client?.phone,
        created: formatDateToLocaleString(client?.createdAt),
        updated: formatDateToLocaleString(client?.updatedAt),
        website: client?.website,
      };
    });
    setClientsState(transformedClients);
  }, [ClientsList]);

  const handlePutRequest = async () => {
    try {
      const body = {
        id: values.id,
        name: values.name,
        address: values.address,
        phone: values.phone,
        email: values.email,
        website: values.website,
        type: values.type,
        data: { countries: values.countries },
      };

      const response = await axios.put(`${CLIENTS_URL}/${values.id}`, body);
      const updatedClient = response.data;

      dispatch(editClient(updatedClient));

      await axiosInstance.post(ACTIONS_URL, {
        actionType: "Update",
        objectType: "Client",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        content: updatedClient,
      });
    } catch (err) {
      console.error(
        "Error updating the client:",
        err.response ? err.response.data : err
      );
    }
  };

  const handleEditClient = async () => {
    try {
      await handlePutRequest();
      navigate("/clients");
    } catch (err) {
      console.error("Error in handleEditClient:", err);
    }
  };

  const handleEditUser = () => {
    // Define the user edit functionality here
    console.log("Edit user functionality to be implemented");
  };

  const actionsDetailButton = (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <IconButton onClick={() => handleDelete(params)} aria-label='delete'>
          <DeleteIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setValues({
              ...params,
              data: params.data || { countries: [] },
              type: params.type || "",
              countries: params.data?.countries || [],
            });
            setUserTitle("Edit Client"); // Set the userTitle to "Edit Client"
            setShowClientModal(true);
          }}
          aria-label='edit'
        >
          <EditIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    );
  };

  const handleDelete = async (row) => {
    const id = +row.id;
    const name = row.name;

    confirmDialog(
      `Do you really want to delete "${name}" CLIENT, created at ${row.created} ?`,
      async () => {
        try {
          await axiosInstance
            .post(ACTIONS_URL, {
              actionType: "Delete",
              objectType: "Client",
              userId: currentUser.id,
              clientId: id,
              content: { id: id.toString(), name: name },
            })
            .catch((error) => {
              console.error("Error with POST request:", error);
            });

          dispatch(deleteOneClient({ id: id }));
          await axios.delete(`${CLIENTS_URL}/${id}`);
        } catch (err) {
          console.error("Error in the confirmDialog callback:", err);
        }
      }
    );
  };

  const cardColors = {
    item1: "#A8D28F",
    item2: "#777777",
    item3: "#777777",
  };

  const backgroundColor = "linear-gradient(45deg, #BAE0A4 30%, #80AFDE 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
      <Grid item xs={12} md={7}>
        <ConfirmDialog2 />
        <UserCard
          rows={clientState}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfItems={clientState.length}
          cardHeader={"Clients"}
          backgroundColor={backgroundColor}
          values={values}
          setValues={setValues}
          addClient={handleAddClient}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      <Grid item xs={12}>
        <ClientsTable
          title='Clients'
          rows={clientState}
          name={clientState.name}
          actionsDetailButton={actionsDetailButton}
        />
      </Grid>
      <AddAndEditModal
        backgroundColor={backgroundColor}
        handleAddEntity={handleAddClient} // Use handleAddClient for adding a client
        handleEditEntity={handleEditClient} // Use handleEditClient for editing a client
        handleEditUser={handleEditUser} // Ensure handleEditUser is also passed
        values={values}
        setValues={setValues}
        currentUser={currentUser}
        userTitle={userTitle} // Dynamic userTitle based on the context
        open={showClientModal}
        setOpen={setShowClientModal}
        clients={ClientsList}
      />
    </Grid>
  );
}
