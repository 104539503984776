import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { motion, useAnimation } from "framer-motion";

export const VoiceCommand = ({
  setLanguageFromVoice,
  onCommandDetected,
  voiceCommandStatus,
  setVoiceCommandStatus,
  language,
  isLoadingOpenAi,
}) => {
  const handleVoiceInput = () => {
    setVoiceCommandStatus("listening");

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("Your browser does not support the SpeechRecognition API.");
      setVoiceCommandStatus("idle");
      return;
    }

    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      console.log("Voice activated, start speaking...");
    };

    recognition.onresult = (event) => {
      setVoiceCommandStatus("translating");
      const resultIndex = event.resultIndex;
      const transcript = event.results[resultIndex][0].transcript;
      parseVoiceCommand(transcript);
    };

    recognition.onend = () => {
      setVoiceCommandStatus("idle");
    };

    recognition.onerror = (event) => {
      console.error("Recognition error:", event.error);
      setVoiceCommandStatus("idle");
    };

    recognition.start();
  };

  const parseVoiceCommand = (command) => {
    const match = command.match(/translate to (\w+)/i);
    if (match && match[1]) {
      const desiredLanguage =
        match[1].charAt(0).toUpperCase() + match[1].slice(1);
      setLanguageFromVoice(desiredLanguage); // This should update the `language` state in the parent component
      onCommandDetected(desiredLanguage); // This is where you call `handleTranslate` with the detected language
    } else {
      console.error("Could not extract language from voice command.");
    }
  };

  return (
    <>
      {voiceCommandStatus === "idle" && !isLoadingOpenAi && (
        <Button onClick={handleVoiceInput}>
          <MicIcon sx={{ height: 20, fontSize: "1rem" }} /> TRANSLATE WITH AI
        </Button>
      )}
      {voiceCommandStatus === "listening" && (
        <Typography color='primary' sx={{ fontSize: "0.8rem" }}>
          ...please tell me which language
        </Typography>
      )}
      {isLoadingOpenAi && (
        <Typography color='primary'>...translating to {language}</Typography>
      )}
    </>
  );
};
