import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

export default function NavTabs({ onCategoryChange }) {
  const theme = useTheme();

  const CategoriesList = useSelector((store) => store.categories);
  const [selectedCategory, setSelectedCategory] = useState("all");

  function handleButtonClick(value) {
    setSelectedCategory(value);
    onCategoryChange(value);
  }

  return (
    <Box sx={{ zIndex: 2, width: "100%", display: "flex", gap: 1 }}>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          color: theme.palette.third.main,
          mt: 1,
        }}
      >
        CATEGORIES:
      </Typography>
      <Button
        onClick={() => handleButtonClick("all")}
        variant='text'
        sx={{
          fontSize: 12,
          fontWeight: 600,
          color:
            selectedCategory === "all" ? theme.typography.fontColor : "#9E9E9E",
        }}
      >
        All
      </Button>
      {CategoriesList.map((category) => (
        <Button
          key={category.id}
          onClick={() => handleButtonClick(category.id)}
          variant='text'
          sx={{
            fontSize: 12,
            fontWeight: 600,
            color:
              selectedCategory === "all"
                ? "theme.typography.fontColor"
                : "#9E9E9E",
          }}
          component={Link}
          to={`/ads?cat=${category.id}`}
        >
          {category.name}
        </Button>
      ))}
    </Box>
  );
}
