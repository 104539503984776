import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import SortIcon from "@mui/icons-material/Sort";

function ImageSorter({ onSortChange }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (criteria) => {
    if (onSortChange) {
      onSortChange(criteria);
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="sort images"
        aria-controls="sort-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "primary.main" }}
      >
        <SortIcon sx={{ mt: 1, fontSize: "1.2rem" }} />
      </IconButton>

      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSort("popular")}>Popular</MenuItem>
        <MenuItem onClick={() => handleSort("newest")}>Newest</MenuItem>
        <MenuItem onClick={() => handleSort("relevance")}>Relevance</MenuItem>
        <MenuItem onClick={() => handleSort("random")}>Random</MenuItem>
      </Menu>
    </div>
  );
}

export default ImageSorter;
