import axios from "axios";
import { REFRESH_TOKEN_URL, ACTIONS_URL } from "../config";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

let isRefreshing = false;

// Helper function to check if refresh token cookie exists
const hasRefreshTokenCookie = () => {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith("refresh_token="));
};

axiosInstance.interceptors.request.use(async (config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the failing request is the refresh token endpoint itself
    if (originalRequest.url === REFRESH_TOKEN_URL) {
      localStorage.removeItem("user");
      window.location.href = "/signIn";
      return Promise.reject(error);
    }

    // Check for 401 or 403 error
    if (error.response.status === 401 || error.response.status === 403) {
      // If the error is from ACTIONS_URL, handle differently
      if (originalRequest.url === ACTIONS_URL) {
        console.error(
          "Failed to record action due to insufficient permissions."
        );
        return Promise.reject(error); // Reject without retrying
      }

      // If no refresh token cookie is found or we're already refreshing
      if (!hasRefreshTokenCookie() || isRefreshing) {
        console.error("No refresh token cookie found or already refreshing.");

        // Remove user from local storage and redirect to sign in
        localStorage.removeItem("user");
        window.location.href = "/signIn";
        return Promise.reject(error);
      }

      isRefreshing = true;

      try {
        const refreshTokenResponse = await axios.post(REFRESH_TOKEN_URL);
        originalRequest.headers.Authorization = `Bearer ${refreshTokenResponse.data.accessToken}`;

        // Reset the isRefreshing flag
        isRefreshing = false;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Error while refreshing token", refreshError);

        // Reset the isRefreshing flag
        isRefreshing = false;

        localStorage.removeItem("user");
        window.location.href = "/signIn";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
