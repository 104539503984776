import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { setUsers } from "../pages/users/features/userSlice";
import { ACTIONS_URL, LOGOUT_URL, SIGNIN_URL } from "../config";
import axiosInstance, {
  hasRefreshTokenCookie,
} from "../services/axiosInstance";
import { SocketContext } from "./SocketContext ";

// Create the Auth context
export const AuthContext = createContext();

// Auth Context Provider
export const AuthContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  // Get the user from local storage or set to null
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [onlineUsers, setOnlineUsers] = useState([]);

  // Define actions state
  const [actions, setActions] = useState([]);

  const signIn = async (inputs) => {
    try {
      const res = await axiosInstance.post(SIGNIN_URL, inputs);
      if (res.data) {
        // Check if the response data is available
        setCurrentUser(res.data);

        // Save an action to the server when a user signs in
        const actionData = {
          userId: res.data.id, // Assuming the user ID is in the response
          clientId: res.data.clientIdUsers,
          actionType: "Login", // Make sure this value matches one of the enum values in your schema
          objectType: "User", // Specify the object type being acted upon (e.g., "User")
          content: res.data, // Use the response data
          // Other necessary fields here, such as description, targetUserId, etc.
          if (socket) {
            socket.emit("userLogin", res.data);
          }
          
        };

        await axiosInstance.post(ACTIONS_URL, actionData); // Assuming ACTIONS_URL is the URL for the actions API
      } else {
        console.error("No data in signIn response");
      }
    } catch (error) {
      console.error("Failed to sign in:", error);
    }
  };

  // Logout function
  const logout = async () => {
    try {
      // Save an action to the server when a user logs out
      const actionData = {
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        actionType: "Logout",
        objectType: "User",
        content: currentUser,
        // Other necessary fields here
      };

      await axiosInstance.post(ACTIONS_URL, actionData); // Log the action first

      await axiosInstance.post(LOGOUT_URL); // Then call the actual logout endpoint

      setCurrentUser(null); // Clear the current user data

      if (socket) {
        socket.emit("userLogout", currentUser);

        setOnlineUsers((prevOnlineUsers) =>
          prevOnlineUsers.filter((id) => id !== currentUser.id)
        );
      }

      dispatch(setUsers([]));
    } catch (error) {
      console.error("Failed to logout:", error);
      window.location.href = "/signIn";
    }
  };

  // Effect to set user to local storage when currentUser changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  // Function to get Actions
  const getActions = async () => {
    try {
      const res = await axiosInstance.get(ACTIONS_URL);
      setActions(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Effect to fetch actions on mount
  useEffect(() => {
    getActions();
  }, []);



  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn,
        logout,
        actions,
        setActions,
        onlineUsers,
        setOnlineUsers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
