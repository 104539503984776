import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "categories",
  initialState: [],
  reducers: {
    addCategory: (state, action) => {
      const existingCategoryIndex = state.findIndex(
        (category) => category.id === action.payload.id
      );
      if (existingCategoryIndex === -1) {
        state.push(action.payload);
      } else {
        console.log(`Category with ID ${action.payload.id} already exists.`);
      }
    },

    setCategories: (state, action) => {
      const existingCategory = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );

      state.push(...existingCategory);
    },
    editCategory: (state, action) => {
      const { id, name, description } = action.payload;
      const existingCategory = state.find((category) => category.id === id);
      if (existingCategory) {
        existingCategory.name = name;
        existingCategory.description = description;
      }

      // state.push(...existingCategory);
    },
    deleteOneCategory: (state, action) => {
      const { id } = action.payload;

      const existingCategory = state.find((category) => category.id === id);
      if (existingCategory) {
        return state.filter((category) => category.id !== id);
      }
      return state;
    },

    deleteManyCategories: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((category, index) => {
        if (idsToDelete.has(category.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateCategory: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addCategory,
  setCategories,
  editCategory,
  deleteOneCategory,
  updateCategory,
  deleteManyCategories,
} = categorySlice.actions;
export default categorySlice.reducer;
