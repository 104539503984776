import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import PageNumbers from "../../components/PageNumbers";
import { deleteOneAd } from "../ads/features/adSlice";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ElevatedTabs from "./components/ElavatedTabs";
import { ACTIONS_URL, ADS_URL, USERS_URL } from "../../config";
import AdsTable from "../../components/Table";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import AdReadyHelp from "../../components/AdReadyHelp";
import UserCard from "../../components/UserCard";
import { deleteCommentsByAdId } from "./features/commentSlice";
import { updateUsers } from "../users/features/userSlice";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";
import { useTheme } from "@mui/material/styles";
import ListCampaigns from "./campaign/ListCampaign";

export default function Ads(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const AdsList = useSelector((store) => store.ads);
  const BrandsList = useSelector((store) => store.brands);
  const CategoriesList = useSelector((store) => store.categories);
  const UsersList = useSelector((store) => store.users);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isListView, setIsListView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentAds = AdsList.slice(startIndex, endIndex);
  const totalPages = Math.ceil(AdsList.length / itemsPerPage);
  const [filter, setFilter] = useState("all");
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [campaignTabIndex, setCampaignTabIndex] = useState(0);

  const createNewRows = (AdsList, UsersList) => {
    return AdsList.map((ad) => {
      const approver = UsersList?.find((user) => user.id === ad?.approverId);
      return {
        id: ad?.id,
        title: ad?.title,
        brand: ad?.brand,
        createdBy: ad?.createdBy,
        createdAt: formatDateToLocaleString(ad?.createdAt),
        updatedAt: formatDateToLocaleString(ad?.updatedAt),
        status: ad?.status,
        approverId: ad?.approverId,
        approverName: approver ? approver.firstName : "Not Found",
      };
    });
  };

  const socket = useContext(SocketContext);

  const userRole = currentUser.role;
  useEffect(() => {
    let updatedFilteredAdsList = [...AdsList];
    if (selectedCategory !== "all") {
      updatedFilteredAdsList = updatedFilteredAdsList.filter(
        (ad) => ad.category_id === selectedCategory
      );
    }
    if (
      userRole === "admin" ||
      userRole === "manager" ||
      userRole === "creator"
    ) {
      if (filter !== "all") {
        updatedFilteredAdsList = updatedFilteredAdsList.filter(
          (ad) => ad.status === filter
        );
      }
    }
    const currentAdsInPage = updatedFilteredAdsList.slice(startIndex, endIndex);
    const newRows = createNewRows(currentAdsInPage, UsersList);
    setRows(newRows);
  }, [AdsList, filter, currentUser, selectedCategory, currentPage, UsersList]);

  function handleCategoryChange(category) {
    setSelectedCategory(category);
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchUsers = async () => {
      try {
        const usersResponse = await axiosInstance.get(USERS_URL);
        dispatch(updateUsers(usersResponse.data));
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const actionsDetailButton = (params) => (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      <IconButton onClick={() => handleDelete(params)} aria-label='delete'>
        <DeleteIcon color='primary' sx={{ fontSize: 20 }} />
      </IconButton>
      <IconButton
        onClick={() => {
          navigate(`/edit-ad/${params.id}`, {
            state: { userData: params },
          });
        }}
      >
        <EditIcon color='primary' sx={{ fontSize: 20 }} aria-label='edit' />
      </IconButton>
      <IconButton
        onClick={() => {
          navigate(`/ad/${params.id}`, {
            state: { userData: params },
            socket,
          });
        }}
      >
        <VisibilityIcon
          color='primary'
          sx={{ fontSize: 20 }}
          aria-label='edit'
        />
      </IconButton>
    </Box>
  );

  const handleDeleteFromChild = (adData) => {
    const row = {
      id: adData.id,
      title: adData.title,
      date: adData.date,
    };
    handleDelete(row);
  };

  const handleDelete = async (row) => {
    const id = JSON.stringify(row.id);
    const adTitle = row.title;
    confirmDialog(
      `Do you really want to delete "${adTitle}" AD, created at ${formatDateToLocaleString(
        row.createdAt
      )} ?`,
      async () => {
        try {
          const payload = {
            actionType: "Delete",
            objectType: "Ad",
            userId: currentUser.id,
            adId: id,
            clientId: currentUser.clientIdUsers,
            adTitle: adTitle,
          };
          await axios.post(ACTIONS_URL, payload);
          await axios
            .delete(`${ADS_URL}/${id}`)
            .then((response) => {
              if (response.data.status === "success") {
                dispatch(deleteCommentsByAdId({ adId: row.id }));
                dispatch(deleteOneAd({ id: row.id }));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  const cardColors = {
    item1: theme.palette.primary.itemColorAds,
    item2: "#777777",
    item3: "#777777",
  };

  const backgroundColor = "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)";

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleCampaignTabChange = (event, newValue) => {
    setCampaignTabIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        centered
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label='Ads' />
        <Tab label='Campaign' />
      </Tabs>
      {tabIndex === 0 && (
        <>
          {isListView ? (
            <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
              <ConfirmDialog2 />
              <Grid item xs={12} md={7}>
                <UserCard
                  rows={AdsList}
                  cardColors={cardColors}
                  currentUser={currentUser}
                  numberOfItems={AdsList.length}
                  cardHeader={"Ads"}
                  backgroundColor={backgroundColor}
                  pendingAds={2}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <AdReadyHelp backgroundColor={backgroundColor} />
              </Grid>
              <Grid item xs={12}>
                <AdsTable
                  title='Ads'
                  rows={rows}
                  name={rows.name}
                  setFilter={setFilter}
                  actionsDetailButton={actionsDetailButton}
                  onCategoryChange={handleCategoryChange}
                  setIsListView={setIsListView}
                  cardColors={cardColors}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid sx={{ pt: 2, pl: 10 }} container spacing={1}>
              <ConfirmDialog2 />
              <Grid
                xs={12}
                sm={12}
                md={12}
                m={2}
                item
                sx={{ mb: -2 }}
                display='flex'
                justifyContent='space-between'
              >
                <Box sx={{ display: "flex", flexDirection: "row", pb: 2 }}>
                  <PageNumbers
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                </Box>
                <Button
                  variant='text'
                  startIcon={<NavigateBeforeIcon />}
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Prev
                </Button>
                <Button
                  variant='text'
                  endIcon={<NavigateNextIcon />}
                  disabled={
                    currentPage === Math.ceil(AdsList.length / itemsPerPage)
                  }
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                </Button>
                <Box>
                  <IconButton onClick={() => setIsListView(true)}>
                    <FormatListBulletedIcon />
                  </IconButton>
                </Box>
              </Grid>
              <ElevatedTabs
                isLoading={isLoading}
                handleDeleteFromChild={handleDeleteFromChild}
                handleDelete={handleDelete}
                currentAds={currentAds}
                users={UsersList}
                BrandsList={BrandsList}
                CategoriesList={CategoriesList}
              />
            </Grid>
          )}
        </>
      )}
      {tabIndex === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={campaignTabIndex}
              onChange={handleCampaignTabChange}
              aria-label='Vertical tabs example'
              sx={{
                mt: 1,
                borderRight: 1,
                borderColor: "divider",
                minWidth: 120,
                "& .MuiTab-root": {
                  minWidth: 120,
                  "&.Mui-selected": {
                    background: "linear-gradient(45deg, #a06eaf, #87449b)",
                    color: theme.palette.primary.light,
                  },
                },
              }}
            >
              <Tab label='Facebook' />
              <Tab label='Instagram' />
              <Tab label='LinkedIn' />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box sx={{ flexGrow: 1, p: 1 }}>
              {campaignTabIndex === 0 && <ListCampaigns />}
              {campaignTabIndex === 1 && (
                <Typography>Instagram campaigns coming soon...</Typography>
              )}
              {campaignTabIndex === 2 && (
                <Typography>LinkedIn campaigns coming soon...</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
