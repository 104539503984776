import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Box,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BrandLogoTypeMenu from "./BrandLogoTypeMenu";
import ProductUpdateModal from "../../ads/components/ProductUpdateModal";
import { BRANDS_URL } from "../../../config";
import axiosInstance from "../../../services/axiosInstance";
import BadgeUpdateModal from "../../ads/components/BadgeUpdateModal ";
import { editBadge } from "../features/badgeReducer";
import { useDispatch } from "react-redux";
import { editProduct } from "../features/productReducer";
import LogoUpdateModal from "../../ads/components/LogoUpdateModal";
import BackgroundUpdateModal from "../../ads/components/BackgroundUpdateModal"; // Import BackgroundUpdateModal
import { editLogo } from "../features/logoReducer";
import axios from "axios";
import { editBackground } from "../features/backgroundReducer";

const BrandCardItem = ({
  selectedBrand,
  image,
  tab,
  ASSETS_URL,
  selectedItems,
  handleCheckboxToggle,
  handleDeleteSingle,
  itemVariant,
  rightSideBar,
  setValues,
  editStates,
  editingResolution,
  setShowImage,
  editingProduct,
  uploadFiles,
  values,
}) => {
  let fontName = ""; // Initialize the fontName variable
  let fontFace = ""; // Initialize the fontFace variable

  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBadgeModalOpen, setIsBadgeModalOpen] = useState(false);
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const [isBackgroundModalOpen, setIsBackgroundModalOpen] = useState(false); // New state for background modal

  const [productName, setProductName] = useState("");
  const [badgeName, setBadgeName] = useState("");
  const [logoName, setLogoName] = useState("");
  const [backgroundName, setBackgroundName] = useState(""); // New state for background name

  const [productDescription, setProductDescription] = useState("");
  const [badgeDescription, setBadgeDescription] = useState("");
  const [logoDescription, setLogoDescription] = useState("");
  const [backgroundDescription, setBackgroundDescription] = useState(""); // New state for background description

  const [flags, setFlags] = useState({
    processorIntel: image.data.flags?.processorIntel ?? false,
    processorAMD: image.data.flags?.processorAMD ?? false,
    microsoft: image.data.flags?.microsoft ?? false,
    ubuntu: image.data.flags?.ubuntu ?? false,
    advertising: image.data.flags?.advertising ?? false,
    etailMarketing: image.data.flags?.etailMarketing ?? false,
    main: image.data.flags?.main ?? false,
    primary: image.data.flags?.primary ?? false,
    secondary: image.data.flags?.secondary ?? false,
    integratedGraphics: image.data.flags?.integrated ?? false,
    discreteGraphics: image.data.flags?.discrete ?? false,
    family: image.data.flags?.family ?? false,
    segment: image.data.flags?.segment ?? false,
    positive: image.data.flags?.positive ?? false,
    aiFeature: image.data.flags?.aiFeature ?? false,
  });

  const seriesOptions = [
    { id: 1, name: "AMD ADVANTAGE" },
    { id: 2, name: "AMD ATHLON" },
    { id: 3, name: "AMD ATHLON 7000" },
    { id: 4, name: "AMD RYZEN 3000" },
    { id: 5, name: "AMD RYZEN 5000" },
    { id: 6, name: "AMD RYZEN 6000" },
    { id: 7, name: "AMD RYZEN 7000" },
    { id: 8, name: "AMD RADEON RX 6000" },
    { id: 9, name: "AMD RADEON RX 7000" },
  ];

  const handleUpdateProduct = async (
    productId,
    selectedSeries,
    productName,
    productDescription,
    flags,
    newFileName,
    newBadges,
    newLogos,
    layout, // This now refers to the full layout object
    layoutTitle // New parameter to receive the layout title
  ) => {
    const data = {
      selectedSeries,
      productName,
      productDescription,
      flags,
      fileName: newFileName || image.data.fileName,
      width: image.data.width,
      height: image.data.height,
      badges: newBadges ? newBadges : image.data.badges,
      logos: newLogos ? newLogos : image.data.logos,
      layout, // No change here, the full layout object is still handled
      layoutTitle, // Newly added field to handle layout title separately
    };

    try {
      const response = await axiosInstance.put(
        `${BRANDS_URL}/products/${productId}`,
        {
          id: productId,
          data,
        }
      );

      dispatch(editProduct(response.data));
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleUpdateLogo = async (
    logoId,
    selectedSeries,
    logoName,
    logoDescription,
    flags,
    newFileName // Add this parameter for the new file name
  ) => {
    const data = {
      selectedSeries,
      logoName,
      logoDescription,
      flags,
      fileName: newFileName || image.data.fileName, // Use newFileName if provided
      width: image.data.width,
      height: image.data.height,
    };

    try {
      const response = await axiosInstance.put(
        `${BRANDS_URL}/logos/${logoId}`,
        {
          id: logoId,
          data,
        }
      );

      dispatch(editLogo(response.data));
    } catch (error) {
      console.error("Error updating logo:", error);
    }
  };

  const handleUpdateBadge = async (
    badgeId,
    selectedSeries,
    badgeName,
    badgeDescription,
    flags,
    selectedLevel, // Include selectedLevel in the function signature
    newFileName = null // Optional parameter for the new file name
  ) => {
    const data = {
      selectedSeries,
      selectedLevel, // Include selectedLevel in the data payload
      name: badgeName,
      description: badgeDescription,
      fileName: newFileName || image.data.fileName,
      width: image.data.width,
      height: image.data.height,
      flags,
    };

    try {
      const response = await axiosInstance.put(
        `${BRANDS_URL}/badges/${badgeId}`,
        {
          id: badgeId,
          data,
        }
      );

      console.log("Badge updated successfully:", response.data);

      dispatch(editBadge(response.data));
    } catch (error) {
      console.error("Error updating badge:", error);
    }
  };

  const handleUpdateBackground = async (
    backgroundId,
    backgroundName,
    backgroundDescription,
    flags,
    newFileName
  ) => {
    const data = {
      name: backgroundName,
      description: backgroundDescription,
      flags,
      fileName: newFileName || image.data.fileName,
      width: image.data.width,
      height: image.data.height,
    };

    try {
      await axiosInstance.put(`${BRANDS_URL}/backgrounds/${backgroundId}`, {
        id: backgroundId,
        data,
      });

      dispatch(editBackground({ id: backgroundId, data }));
    } catch (error) {
      console.error("Error updating background:", error);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openBadgeModal = () => setIsBadgeModalOpen(true);
  const closeBadgeModal = () => setIsBadgeModalOpen(false);

  const openLogoModal = () => setIsLogoModalOpen(true);
  const closeLogoModal = () => setIsLogoModalOpen(false);

  const openBackgroundModal = () => setIsBackgroundModalOpen(true);
  const closeBackgroundModal = () => setIsBackgroundModalOpen(false);

  // Font preview
  if (tab === 5) {
    fontName = image?.data.fileName.split(".")[0]; // Extract the font name from the filename

    // Create dynamic @font-face rule
    fontFace = `
        @font-face {
            font-family: '${fontName}';
            src: url('${ASSETS_URL}/${image.data.fileName}');
        }
      `;
  }

  const handleClickForProduct = () => {
    // Set the product details in the modal's state
    setProductName(image.data.productName || "");
    setProductDescription(image.data.productDescription || "");

    // Open the modal
    openModal();
  };

  const handleClickForLogo = () => {
    // Set the product details in the modal's state
    setLogoName(image.data.logoName || "");
    setLogoDescription(image.data.logoDescription || "");

    // Open the modal
    openLogoModal();
  };

  const handleClickForBadge = () => {
    // Set the product details in the modal's state
    setBadgeName(image.data.name || "");
    setBadgeDescription(image.data.description || "");

    // Open the modal
    openBadgeModal();
  };

  const handleClickForBackground = () => {
    // Set the background details in the modal's state
    setBackgroundName(image.data.backgroundName || "");
    setBackgroundDescription(image.data.backgroundDescription || "");

    // Open the modal
    openBackgroundModal();
  };

  const brandMapping = {
    brand1: "logoBrand1",
    brand2: "logoBrand2",
    brand3: "logoBrand3",
    brand4: "logoThirdParty",
  };

  const handleClick = () => {
    const tabTypeMap = {
      0: "background",
      1: "product",
      2: "sticker1",
      3: "call_to_action",
      4: brandMapping[selectedBrand], // Assuming this is where logos should be
      5: "badge",
      6: "fontName",
    };

    const field = tabTypeMap[tab];
    if (field) {
      const imageUrlValue = image.data.fileName
        ? image.data.fileName
        : image.data;
      let baseProperties = {
        imageUrl: imageUrlValue,
        x: image.data.x,
        y: image.data.y,
        type: Object.values(brandMapping).includes(field)
          ? image.data.type
          : undefined,
      };

      let baseWidth = image.data.width;
      let baseHeight = image.data.height;

      if (!(baseWidth && baseHeight)) {
        console.log("Width or Height is not defined.");
        return;
      }

      baseProperties.width = baseWidth;
      baseProperties.height = baseHeight;

      setValues((prevValues) => {
        const updatedPositions = JSON.parse(
          JSON.stringify(prevValues.positions)
        );

        Object.entries(updatedPositions[field] || {}).forEach(
          ([res, value]) => {
            const [resWidth, resHeight] = res.split("x").map(Number);
            let { x, y } = value;
            const propertiesToUpdate = { ...baseProperties };

            // Skip resizing for the background
            if (field !== "background") {
              let scalingFactor = 1;
              let maxDimension =
                resWidth > resHeight ? resHeight * 0.7 : resWidth * 0.7;

              if (resWidth > resHeight) {
                // Landscape orientation
                if (baseHeight > maxDimension) {
                  scalingFactor = maxDimension / baseHeight;
                }
              } else {
                // Portrait or square orientation
                if (baseWidth > maxDimension) {
                  scalingFactor = maxDimension / baseWidth;
                }
              }

              propertiesToUpdate.width = baseWidth * scalingFactor;
              propertiesToUpdate.height = baseHeight * scalingFactor;
            }

            // Adjust x position if necessary, unless it's the background
            if (
              field !== "background" &&
              x + propertiesToUpdate.width > resWidth
            ) {
              x = resWidth - propertiesToUpdate.width;
            }

            // Adjust y position if necessary, unless it's the background
            if (
              field !== "background" &&
              y + propertiesToUpdate.height > resHeight
            ) {
              y = resHeight - propertiesToUpdate.height;
            }

            updatedPositions[field][res] = {
              ...updatedPositions[field][res],
              ...propertiesToUpdate,
              x,
              y,
              showImage: true,
            };
          }
        );

        return {
          ...prevValues,
          positions: updatedPositions,
        };
      });

      if (field === "background") {
        setShowImage((prevShowImage) => {
          const updatedShowImage = { ...prevShowImage };

          if (editStates && editStates["1200x630"] === true) {
            for (let resolution in prevShowImage) {
              updatedShowImage[resolution] = true;
            }
          } else {
            updatedShowImage[editingResolution] = true;
          }

          return updatedShowImage;
        });
      }
    }
  };

  // Determine which handleClick function to use based on the 'editingProduct' prop
  const handleImageClick = () => {
    if (rightSideBar) {
      console.log("Image clicked:", image.data);
    }
    if (editingProduct && tab === 1) {
      handleClickForProduct();
    } else if (editingProduct && tab === 4) {
      handleClickForLogo();
    } else if (editingProduct && tab === 6) {
      handleClickForBadge();
    } else if (editingProduct && tab === 0) {
      handleClickForBackground();
    } else {
      handleClick();
    }

    // Check if flags are defined before attempting to access them
    if (image.data.flags) {
      const { positive } = image.data.flags;
      console.log("Positive flag value:", positive);

      // Update text colors based on the positive or negative flag
      setValues((prevValues) => {
        const updatedPositions = { ...prevValues.positions };
        const textColor = positive ? "black" : "white";
        console.log("Updating text colors to:", textColor);

        const textFields = [
          "headline",
          "subHeadline",
          "disclaimer",
          "text1",
          "text2",
        ];

        textFields.forEach((field) => {
          if (updatedPositions[field]) {
            Object.keys(updatedPositions[field]).forEach((res) => {
              updatedPositions[field][res] = {
                ...updatedPositions[field][res],
                textColor,
              };
            });
          }
        });

        return {
          ...prevValues,
          positions: updatedPositions,
        };
      });

      // Check if productData and logos are defined before attempting to access them
      const productData = values.positions.productData;
      if (productData) {
        const productLogos = productData.logos;
        if (productLogos && Array.isArray(productLogos)) {
          console.log("Product logos found:", productLogos);

          const findMatchingLogo = (logos, positive, adType) => {
            for (let logo of logos) {
              const logoFlags = logo.data.flags;
              console.log("Checking logo:", logo);

              if (
                (logoFlags.advertising === adType.advertising ||
                  logoFlags.etailMarketing === adType.etailMarketing) &&
                logoFlags.positive === positive
              ) {
                console.log(
                  "Matching advertising or etailMarketing flag and positive flag found"
                );
                return logo;
              }
            }
            console.log("No matching logo found");
            return null;
          };

          setValues((prevValues) => {
            const selectedLogo = findMatchingLogo(
              productLogos,
              positive,
              prevValues.positions.adType
            );

            if (selectedLogo) {
              console.log("Selected logo for update:", selectedLogo);
              const updatedPositions = { ...prevValues.positions };

              if (updatedPositions.logoBrand1) {
                Object.keys(updatedPositions.logoBrand1).forEach((res) => {
                  updatedPositions.logoBrand1[res] = {
                    ...updatedPositions.logoBrand1[res],
                    imageUrl: selectedLogo.data.fileName,
                    width: selectedLogo.data.width,
                    height: selectedLogo.data.height,
                  };
                });
              }

              return {
                ...prevValues,
                positions: updatedPositions,
              };
            } else {
              console.log("No logo selected for updating positions");
              return prevValues;
            }
          });
        } else {
          console.log("Product logos are not available or not an array");
        }
      } else {
        console.log("Product data is not available");
      }
    }
  };

  // console.log("valuuuuuues", values?.positions);

  useEffect(() => {
    // Create a new style element
    const styleElement = document.createElement("style");

    // Set style element inner text to be the font-face rule
    styleElement.innerText = fontFace;

    // Append style element to head
    document.head.appendChild(styleElement);

    // Clean up on component unmount
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [fontFace]);

  return (
    <motion.div variants={itemVariant} whileHover={{ scale: 1.05 }}>
      {rightSideBar ? (
        // If rightSideBar is true, only display the image
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <img
              src={`${ASSETS_URL}/${
                image.data.fileName ? image.data.fileName : image.data
              }`}
              alt='brand'
              style={{
                maxHeight: "200px", // Adjust maximum height as needed
                objectFit: "contain",
                width: "100%",
                margin: "auto", // Center the image if it's smaller than the container
              }}
              onClick={handleImageClick}
            />
            <Typography
              sx={{
                fontSize: "0.8rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px", // Adjust as needed
                "&:hover": {
                  overflow: "visible",
                  whiteSpace: "normal",
                },
                textAlign: "center", // Center align the text
              }}
              title={image?.data?.productName}
            >
              {image?.data?.productName}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        // If rightSideBar is false, display the full content
        <Box sx={{ width: "100%" }}>
          <Card
            elevation={3}
            sx={{
              height: "auto",
              width: 250,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* Checkbox on the left */}
              <Checkbox
                sx={{ transform: "scale(0.7)" }}
                checked={selectedItems[image.id] || false}
                onChange={() => handleCheckboxToggle(tab, image.id)}
                color='primary'
              />

              {/* Text in the middle */}
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px", // Adjust as needed
                  "&:hover": {
                    overflow: "visible",
                    whiteSpace: "normal",
                  },
                  flexGrow: 1, // Allows the text to take up the available space
                  textAlign: "center", // Center align the text
                  mx: 2, // Add margin on both sides
                }}
                title={image?.data?.productName}
              >
                {image?.data?.productName}
              </Typography>

              {/* Include the LogoDropdown component */}
              {/* {tab === 4 && showDropdown && (
                <BrandLogoTypeMenu imageId={image.id} />
              )} */}

              {/* Delete Icon on the right */}
              <IconButton onClick={() => handleDeleteSingle(tab, image.id)}>
                <DeleteIcon color='primary' sx={{ transform: "scale(0.8)" }} />
              </IconButton>
            </Box>

            <Box style={{ overflow: "hidden" }}>
              {tab !== 5 ? (
                <img
                  onClick={handleImageClick}
                  src={`${ASSETS_URL}/${
                    image.data.fileName ? image.data.fileName : image.data
                  }`}
                  alt='brand'
                  style={{
                    maxHeight: "100%",
                    objectFit: "contain",
                    width: "100%",
                    backgroundColor: !flags.positive
                      ? "#808080"
                      : "transparent",
                  }}
                />
              ) : (
                <Box
                  style={{
                    display: "flex",
                    fontFamily: `${tab === 5 ? fontName : "initial"}`,
                    fontSize: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Sample Text
                </Box>
              )}
            </Box>
          </Card>
        </Box>
      )}
      {/* Render the modal */}
      <ProductUpdateModal
        productId={image.id}
        open={isModalOpen}
        handleClose={closeModal}
        handleUpdate={handleUpdateProduct}
        productName={productName}
        setProductName={setProductName}
        productDescription={productDescription}
        setProductDescription={setProductDescription}
        flags={flags}
        setFlags={setFlags}
        image={image}
        uploadFiles={uploadFiles}
        handleUpdateProduct={handleUpdateProduct}
        seriesOptions={seriesOptions}
      />
      <BadgeUpdateModal
        badgeId={image.id}
        openBadge={isBadgeModalOpen}
        handleCloseBadge={closeBadgeModal}
        handleUpdateBadge={handleUpdateBadge}
        badgeName={badgeName}
        setBadgeName={setBadgeName}
        badgeDescription={badgeDescription}
        setBadgeDescription={setBadgeDescription}
        flags={flags}
        setFlags={setFlags}
        image={image}
        uploadFiles={uploadFiles}
        seriesOptions={seriesOptions}
      />
      <LogoUpdateModal
        logoId={image.id}
        openLogoModal={isLogoModalOpen}
        handleCloseLogoModal={closeLogoModal}
        handleUpdateLogo={handleUpdateLogo}
        logoName={logoName}
        setLogoName={setLogoName}
        logoDescription={logoDescription}
        setLogoDescription={setLogoDescription}
        flags={flags}
        setFlags={setFlags}
        image={image}
        uploadFiles={uploadFiles}
        seriesOptions={seriesOptions}
      />
      <BackgroundUpdateModal // Render the BackgroundUpdateModal
        backgroundId={image.id}
        openBackgroundModal={isBackgroundModalOpen}
        handleCloseBackgroundModal={closeBackgroundModal}
        handleUpdateBackground={handleUpdateBackground}
        backgroundName={backgroundName}
        setBackgroundName={setBackgroundName}
        backgroundDescription={backgroundDescription}
        setBackgroundDescription={setBackgroundDescription}
        flags={flags}
        setFlags={setFlags}
        image={image}
        uploadFiles={uploadFiles}
        categoryOptions={seriesOptions} // Assuming categoryOptions is similar to seriesOptions
      />
    </motion.div>
  );
};

export default BrandCardItem;
