import { updateComments } from "../ads/features/commentSlice";
import {
  addAd,
  deleteOneAd,
  editAd,
  statusAd,
  updateAds,
} from "../ads/features/adSlice";
import { updateLayouts } from "../ads/features/layoutSlice";
import {
  addUser,
  deleteOneUser,
  editUser,
  updateUsers,
} from "../users/features/userSlice";
import { updateTemplates } from "../templates/features/templateSlice";
import {
  addCategory,
  deleteOneCategory,
  editCategory,
  updateCategory,
} from "../categories/features/categoriesReducer";
import {
  addBrand,
  deleteOneBrand,
  editBrand,
  updateBrands,
} from "../brands/features/brandsReducer";
import {
  addClient,
  deleteOneClient,
  editClient,
  updateClients,
} from "../clients/features/clientSlice";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/authContext";
import { useContext, useEffect, useState } from "react";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import "../../style.scss";
import shadows from "@mui/material/styles/shadows";
import axios from "axios";
import {
  CLIENTS_URL,
  BRANDS_URL,
  CATEGORIES_URL,
  TEMPLATES_URL,
  USERS_URL,
  LAYOUTS_URL,
  ADS_URL,
  COMMENTS_URL,
} from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";
import AppRouter from "./components/AppRoutes";
import { addNotification } from "../../notifications/features/notificationSlice";

const mainLight = "#a06eaf";

const lightTheme = createTheme({
  shadows: shadows.map(() => "black"),
  palette: {
    mode: "light",

    primary: {
      mode: "light",
      main: mainLight,
      light: "#FFFFFF",
      dark: "#2077a0",
      contrastText: "#fff",
      tableLabelMain: "#3C3C3C",
      tableLabel: "#A06EAF",
      tableBorderColor: "#D9D9D9",
      approve: "#8ACC84",
      reject: "#F37979",
      editorToolbarBackground: "#f0f0f0",
      itemColor: "#DBA1AA",
      itemColorAds: "#80AFDE",
      avatarBackgroundColor: "#BDBDBD",
      selected: "#e0e0e0",
      disabled: "#A9A9A9",
      divider: "#999999",
      messagesBackground: "#F1F7FF",
      messagesBackground1: "#F8F8F8",
      messagesText: "#585858",
    },
    secondary: {
      main: "#f14d69",
      light: "#f37087",
      dark: "#a83549",
    },
    loader: {
      firstColor: "#AE6DFE",
      secondColor: "#F660AB",
    },
    third: {
      main: "#80AFDE",
      light: "#80AFDE",
      dark: "#a83549",
    },
    approveIcons: {
      approve: "#8acc84",
      rejected: "#f37979",
      pending: "#f2a51d",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Source Sans Pro",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontColorHeadline: "#3C3C3C",
    fontColor: "#777777",
    fontColorCard: "#8E8E8E",
    fontWeightBold: 700,
  },
  formTextField: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      mode: "light",
      main: mainLight,
      light: "#FFFFFF",
      dark: "#2077a0",
      contrastText: "#fff",
      tableLabelMain: "#3C3C3C",
      tableLabel: "#A06EAF",
      tableBorderColor: "#D9D9D9",
      approve: "#8ACC84",
      reject: "#F37979",
      editorToolbarBackground: "#f0f0f0",
      itemColor: "#DBA1AA",
      itemColorAds: "#80AFDE",
      avatarBackgroundColor: "#BDBDBD",
      selected: "#e0e0e0",
      disabled: "#A9A9A9",
      divider: "#999999",
      messagesBackground: "#F1F7FF",
      messagesBackground1: "#F8F8F8",
      messagesText: "#585858",
    },
    secondary: {
      main: "#f14d69",
      light: "#f37087",
      dark: "#a83549",
    },
    loader: {
      firstColor: "#AE6DFE",
      secondColor: "#F660AB",
    },
    third: {
      main: "#80AFDE",
      light: "#80AFDE",
      dark: "#a83549",
    },
    approveIcons: {
      approve: "#8acc84",
      rejected: "#f37979",
      pending: "#f2a51d",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Source Sans Pro",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontColorHeadline: "#3C3C3C",
    fontColor: "#777777",
    fontColorCard: "#8E8E8E",
    fontWeightBold: 700,
  },
  formTextField: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
});

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { currentUser } = useContext(AuthContext);
  // const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const socket = useContext(SocketContext);

  // Move all the socket logic to the top so it's one of the first things that gets established

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {});

      socket.on("connect_error", (error) => {
        console.log("Error connecting to socket server:", error);
      });

      return () => {
        socket.off("connect");
        socket.off("connect_error");
      };
    }
  }, [socket]);

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // const fetchDataBasedOnNotification = async (objectType) => {
  //   switch (objectType) {
  //     // case "Client":
  //     //   await getClients();
  //     //   break;
  //     case "Brand":
  //       await getBrands();
  //       break;
  //     case "Category":
  //       await getCategories();
  //       break;
  //     case "Template":
  //       await getTemplates();
  //       break;
  //     // case "User":
  //     //   await getUsers();
  //     //   break;
  //     case "Layout":
  //       await getLayouts();
  //       break;
  //     case "Message":
  //       await getComments();
  //       break;
  //     case "Ad":
  //     // await getAds();
  //     // break;
  //     default:
  //       console.warn(`Unknown objectType: ${objectType}`);
  //   }
  // };

  // Sending credentials with every request
  axios.defaults.withCredentials = true;
  const clientIdUsers = parseInt(currentUser?.clientIdUsers, 10);

  const getClients = async () => {
    const { data: res } = await axiosInstance.get(CLIENTS_URL);
    dispatch(updateClients(res));
  };

  const getBrands = async () => {
    try {
      const { data: res } = await axiosInstance.get(BRANDS_URL, {
        params: {
          clientId: clientIdUsers,
        },
      });

      // Ensure each brand has a 'data' field, even if it comes as null from the backend
      const updatedBrands = res.map((brand) => ({
        ...brand,
        data: brand.data || {}, // Ensure 'data' field is always an object
      }));

      dispatch(updateBrands(updatedBrands));
    } catch (error) {
      console.error("Failed to fetch brands:", error);
    }
  };

  const getCategories = async () => {
    const { data: res } = await axiosInstance.get(CATEGORIES_URL, {
      params: {
        clientId: clientIdUsers,
      },
    });
    dispatch(updateCategory(res));
  };

  const getTemplates = async () => {
    const userId = parseInt(currentUser?.id, 10);

    if (isNaN(clientIdUsers) || isNaN(userId)) {
      console.error("Client: Invalid client ID or user ID");
      return;
    }

    const headers = {
      "Client-Id-Users": clientIdUsers,
      "User-Id": userId,
    };

    const { data: res } = await axiosInstance.get(TEMPLATES_URL, { headers });
    dispatch(updateTemplates(res));
  };

  const getUsers = async () => {
    const { data: res } = await axiosInstance.get(`${USERS_URL}/join`);

    const filteredUsers = res.filter(
      (user) => user.clientIdUsers === currentUser.clientIdUsers
    );

    dispatch(updateUsers(filteredUsers));
  };

  const getLayouts = async () => {
    const { data: res } = await axiosInstance.get(LAYOUTS_URL);

    dispatch(updateLayouts(res));
  };

  const getComments = async () => {
    try {
      const { data: res } = await axiosInstance.get(COMMENTS_URL, {
        headers: {
          clientIdUsers: currentUser?.clientIdUsers,
        },
      });
      dispatch(updateComments(res));
    } catch (error) {
      console.error(error);
    }
  };

  const getAds = async () => {
    const userId = parseInt(currentUser?.id, 10);
    const role = currentUser?.role;

    if (isNaN(clientIdUsers) || isNaN(userId)) {
      console.error("Ads: Invalid client ID or user ID");
      return;
    }

    const headers = {
      clientIdUsers: clientIdUsers,
      user_id: userId,
      role: role,
    };

    try {
      const { data: res } = await axiosInstance.get(ADS_URL, { headers });
      dispatch(updateAds(res));
    } catch (error) {
      console.error("Error in axios request:", error);
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([
        getClients(),
        getBrands(),
        getCategories(),
        getTemplates(),
        getUsers(),
        getLayouts(),
        getComments(),
        getAds(),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  // POPRAVITI SOCKET REFRESH

  useEffect(() => {
    socket.on("notification", (notification) => {
      const actionType = notification.action?.actionType;
      const objectType = notification.action?.objectType;
      console.log("Notification for", notification);

      dispatch(addNotification(notification));

      if (objectType === "Ad") {
        const adId = notification.action.adId;
        switch (actionType) {
          case "Create":
            const newAd = notification.action.content;
            dispatch(addAd(newAd));
            break;
          case "Update":
            const updatedAd = notification.action.content;
            dispatch(editAd({ id: adId, ...updatedAd }));
            break;
          case "Approve":
            dispatch(
              statusAd({
                id: adId,
                status: "approved",
              })
            );
            break;
          case "Reject":
            dispatch(
              statusAd({
                id: adId,
                status: "rejected",
              })
            );
            break;
          case "Delete":
            dispatch(deleteOneAd({ id: adId }));
            break;
          default:
            console.log(`Unhandled action type: ${actionType}`);
            break;
        }
      } else if (objectType === "User") {
        const userId = notification.action.userId;
        switch (actionType) {
          case "Create":
            const newUser = notification.action.content;
            dispatch(addUser(newUser));
            break;
          case "Update":
            const updatedUser = notification.action.content;
            dispatch(editUser({ id: userId, ...updatedUser }));
            break;
          case "Delete":
            dispatch(deleteOneUser({ id: userId }));
            break;
          default:
            console.log(`Unhandled action type: ${actionType}`);
            break;
        }
      } else if (objectType === "Client") {
        const clientId = notification.action.clientId;
        switch (actionType) {
          case "Create":
            const newClient = notification.action.content;
            dispatch(addClient(newClient));
            break;
          case "Update":
            const updatedClient = notification.action.content;
            dispatch(editClient({ id: clientId, ...updatedClient }));
            break;
          case "Delete":
            dispatch(deleteOneClient({ id: clientId }));
            break;
          default:
            console.log(`Unhandled action type: ${actionType}`);
            break;
        }
      } else if (objectType === "Brand") {
        const clientId = notification.action.clientId;
        switch (actionType) {
          case "Create":
            const newClient = notification.action.content;
            dispatch(addBrand(newClient));
            break;
          case "Edit":
            const updatedClient = notification.action.content;
            dispatch(editBrand({ id: clientId, ...updatedClient }));
            break;
          case "Delete":
            dispatch(deleteOneBrand({ id: clientId }));
            break;
          default:
            console.log(`Unhandled action type: ${actionType}`);
            break;
        }
      } else if (objectType === "Category") {
        switch (actionType) {
          case "Create":
            const newCategory = notification.action.content;
            dispatch(addCategory(newCategory));
            break;
          case "Update":
            const updatedCategory = notification.action.content;
            dispatch(
              editCategory({ id: updatedCategory.id, ...updatedCategory })
            );
            break;
          case "Delete":
            const deletedCategoryId = notification.action.content.id;
            dispatch(deleteOneCategory({ id: deletedCategoryId }));
            break;
          default:
            console.log(`Unhandled action type: ${actionType}`);
            break;
        }
      }

      // if (objectType) {
      //   fetchDataBasedOnNotification(objectType);
      // }
    });

    return () => {
      socket.off("notification");
    };
  }, [dispatch, socket]);

  const router = AppRouter({
    socket,
    currentUser,
    handleToggleDarkMode,
    darkMode,
    // isLoading,
    // setIsLoading,
  });
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <RouterProvider router={router} user={currentUser} />
    </ThemeProvider>
  );
}

export default App;
