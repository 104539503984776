import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Box,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import axiosInstance from "../../services/axiosInstance";
import { ACTIONS_URL } from "../../config";
import { useSelector } from "react-redux";
import { AuthContext } from "../../context/authContext";
import UserActivityChart from "./components/BarActivityChart";
import LineActivityChart from "./components/LineActivityChart";
import AreaActivityChart from "./components/AreaActivityChart";
import PieActivityChart from "./components/PieActivityChart";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { useTheme } from "@mui/material/styles";

function Charts() {
  const UsersList = useSelector((store) => store.users);
  const { currentUser } = useContext(AuthContext);
  const theme = useTheme();

  const [chartData, setChartData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(currentUser.id);
  const [chartType, setChartType] = useState("bar");
  const [mode, setMode] = useState("month"); // Default mode
  const [selectedDate, setSelectedDate] = useState(new Date());

  Date.prototype.getWeek = function () {
    const onejan = new Date(this.getFullYear(), 0, 1);
    const millisecsInDay = 86400000;
    const daysForFirstWeek =
      onejan.getDay() - 1 === -1 ? 6 : onejan.getDay() - 1;
    const dayOfYear = (this - onejan) / millisecsInDay;
    return Math.ceil((dayOfYear + daysForFirstWeek) / 7);
  };

  const transformData = (rawData) => {
    const transformedData = {};

    if (["month", "week", "day", "hour"].includes(mode)) {
      rawData.forEach((entry) => {
        let label;
        const date = new Date(entry.date);
        switch (mode) {
          case "day":
            label = date.toLocaleDateString();
            break;
          case "week":
            label = `Week ${date.getWeek()}`; // You'd need a getWeek function
            break;
          case "hour":
            label = `${date.getHours()}:00 - ${date.getHours() + 1}:00`;
            break;
          default:
            label = date.toLocaleString("default", { month: "short" });
        }

        if (!transformedData[label]) {
          transformedData[label] = { [mode]: label };
        }

        if (!transformedData[label][entry.actionType]) {
          transformedData[label][entry.actionType] = 0;
        }

        transformedData[label][entry.actionType] += entry.count;
      });
    } else if (mode === "user") {
      rawData.forEach((entry) => {
        const user = UsersList.find((u) => u.id === +entry.userId);
        const username = user
          ? `${user.firstName} ${user.lastName}`
          : "Unknown";

        if (!transformedData[username]) {
          transformedData[username] = { username, totalActions: 0 };
        }

        transformedData[username].totalActions += entry.count;
      });
    }

    return Object.values(transformedData);
  };

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        let endpoint;
        if (mode === "user") {
          endpoint = `${ACTIONS_URL}/aggregated?mode=user`;
        } else {
          let dateQuery = "";
          switch (mode) {
            case "hour":
            case "day":
              dateQuery = `&date=${selectedDate.toISOString().split("T")[0]}`;
              break;
            case "week":
              dateQuery = `&year=${selectedDate.getFullYear()}`;
              break;
            default:
              break;
          }
          endpoint = `${ACTIONS_URL}/aggregated?userId=${selectedUserId}&mode=${mode}${dateQuery}`;
        }

        const { data } = await axiosInstance.get(endpoint);
        const transformed = transformData(data);
        setChartData(transformed);
      } catch (error) {
        console.error("Failed to fetch aggregated data:", error);
      }
    };

    fetchChartData();
  }, [selectedUserId, mode, selectedDate]); //

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        p: 2,
        gap: 2,
      }}
    >
      <Typography
        variant='h6'
        sx={{
          textAlign: "center",
          marginBottom: 2,
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {chartData.length ? "User Activity" : "No User Activity"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: mode === "month" ? "space-between" : "center",
          alignItems: "center",
          width: "100%",
          gap: 2,
        }}
      >
        <FormControl variant='outlined' sx={{ width: "20%", height: "40px" }}>
          <Select
            value={mode}
            onChange={handleModeChange}
            sx={{
              height: "40px",
              padding: "5px 0",
              color: theme.typography.fontColorCard,
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            <MenuItem
              sx={{
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 400,
              }}
              value='month'
            >
              Month
            </MenuItem>
            <MenuItem
              sx={{
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 400,
              }}
              value='week'
            >
              Week
            </MenuItem>
            <MenuItem
              sx={{
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 400,
              }}
              value='day'
            >
              Day
            </MenuItem>
            <MenuItem
              sx={{
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 400,
              }}
              value='hour'
            >
              Hour
            </MenuItem>
            <MenuItem
              sx={{
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 400,
              }}
              value='user'
            >
              Users
            </MenuItem>
          </Select>
        </FormControl>
        {["hour", "day", "week"].includes(mode) && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              sx={{
                width: "20%",
                "& .MuiInputBase-input": {
                  color: theme.typography.fontColorCard,
                  fontSize: 14,
                  fontWeight: 600,
                },
                "& .MuiSvgIcon-root": {
                  color: theme.typography.fontColorCard,
                },
              }}
              openTo='day'
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
              label='Date'
              slotProps={{ textField: { size: "small" } }}
              orientation='landscape'
            />
          </LocalizationProvider>
        )}
        {["month", "week", "day", "hour"].includes(mode) && (
          <FormControl variant='outlined' sx={{ width: "20%", height: "40px" }}>
            <Select
              value={selectedUserId}
              onChange={handleUserChange}
              displayEmpty
              sx={{
                height: "40px",
                padding: "5px 0",
                color: theme.typography.fontColorCard,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {UsersList.map((user) => (
                <MenuItem
                  sx={{
                    height: "40px",
                    padding: "5px 0",
                    color: theme.typography.fontColorCard,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                  key={user.id}
                  value={user.id}
                >
                  {user.firstName} {user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Tabs
        value={chartType}
        onChange={(e, newValue) => setChartType(newValue)}
      >
        <Tab value='bar' label='Bar Chart' />
        <Tab value='line' label='Line Chart' />
        <Tab value='area' label='Area Chart' />
        <Tab value='pie' label='Pie Chart' />
      </Tabs>

      {chartType === "bar" && chartData.length ? (
        <UserActivityChart data={chartData} mode={mode} />
      ) : chartType === "line" && chartData.length ? (
        <LineActivityChart data={chartData} mode={mode} />
      ) : chartType === "area" && chartData.length ? (
        <AreaActivityChart data={chartData} mode={mode} />
      ) : chartType === "pie" && chartData.length ? (
        <PieActivityChart data={chartData} mode={mode} />
      ) : null}
    </Card>
  );
}

export default Charts;
