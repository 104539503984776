import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Card, Button } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { ASSETS_URL } from "../config";
import { motion, AnimatePresence } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./MicrosoftStandardSelectedCornerTransitions.css";
import { useTheme } from "@mui/material/styles";

const StandardSelectCorner = ({
  partnership,
  handlePartnershipClick,
  drawerOpen,
  values,
  logoCornersConfig,
  setLogoCornersConfig,
  setValues,
  initialLogoBrands,
  existingAd,
}) => {
  const theme = useTheme();
  const imageHeight = "60px";

  const [logoBrands, setLogoBrands] = useState(initialLogoBrands); // State for available logos
  const [selectedLogo, setSelectedLogo] = useState(logoBrands[0] || null);
  const [logoCornersConfigHistory, setLogoCornersConfigHistory] = useState([]);
  const [showConfiguredCard, setShowConfiguredCard] = useState(false);
  const [firstAnimationCompleted, setFirstAnimationCompleted] = useState(false);
  const [secondAnimationStarted, setSecondAnimationStarted] = useState(false);
  const [alignment, setAlignment] = useState(partnership ? "on" : "off");

  const getImagePath = (logoBrand) => {
    const resolution = "1200x630"; // Assuming you want to get the image path for this resolution
    const imageUrl = values?.positions?.[logoBrand]?.[resolution]?.imageUrl;
    return imageUrl ? `${ASSETS_URL}/${imageUrl}` : null;
  };

  const handleToggle = (newAlignment) => {
    if (alignment !== newAlignment) {
      setAlignment(newAlignment);

      if (newAlignment === "off") {
        handlePartnershipClick(true);
      } else {
        handlePartnershipClick(false);
      }
    }
  };

  useEffect(() => {
    if (logoBrands.length === 0) {
      const timer = setTimeout(() => {
        setShowConfiguredCard(true);
      }, 550); // Adjust delay here according to your animation duration
      return () => clearTimeout(timer);
    }
  }, [logoBrands]);

  const handleCornerClick = (position) => {
    const newPosition = ["topLeft", "topRight", "bottomLeft", "bottomRight"][
      position
    ];

    // Original logic to save the history
    setLogoCornersConfigHistory((prevHistory) => [
      ...prevHistory,
      { config: { ...logoCornersConfig }, selectedLogo, logoBrands },
    ]);

    // Update the values.positions.[selectedLogo] for all resolutions with key selectedCorner
    setValues((prevValues) => {
      // Deep copy of prevValues
      const updatedValues = JSON.parse(JSON.stringify(prevValues));

      if (updatedValues.positions && updatedValues.positions[selectedLogo]) {
        for (let resolution in updatedValues.positions[selectedLogo]) {
          if (
            typeof updatedValues.positions[selectedLogo][resolution] ===
              "object" &&
            updatedValues.positions[selectedLogo][resolution] !== null
          ) {
            updatedValues.positions[selectedLogo][resolution].selectedCorner =
              newPosition;
          }
        }
      }

      return updatedValues;
    });

    // Your original logic to update the logoCornersConfig, logoBrands, and selectedLogo
    setLogoCornersConfig((prevState) => ({
      ...prevState,
      [selectedLogo]: newPosition,
    }));

    const logoIndex = logoBrands.indexOf(selectedLogo);
    if (logoIndex !== -1) {
      const newLogoBrands = [...logoBrands];
      newLogoBrands.splice(logoIndex, 1);
      setLogoBrands(newLogoBrands);
      if (newLogoBrands.length > 0) {
        setSelectedLogo(newLogoBrands[0]);
      } else {
        setSelectedLogo(null);
      }
    }
  };

  const handleStepBack = () => {
    if (logoCornersConfigHistory.length > 0) {
      const lastHistoryItem =
        logoCornersConfigHistory[logoCornersConfigHistory.length - 1];

      // Set the old config, selectedLogo, and logoBrands from the history
      setLogoCornersConfig(lastHistoryItem.config);
      setSelectedLogo(lastHistoryItem.selectedLogo);
      setLogoBrands(lastHistoryItem.logoBrands);

      // Remove the last item from the history
      setLogoCornersConfigHistory((prevHistory) =>
        prevHistory.slice(0, prevHistory.length - 1)
      );
    }
  };

  const translateLogoKey = (key) => {
    switch (key) {
      case "logoBrand1":
        return "Brand 1 logo";
      case "logoBrand2":
        return "Brand 2 logo";
      case "logoBrand3":
        return "Brand 3 logo";
      case "logoBrand4":
        return "Third party logo";
      default:
        return key;
    }
  };

  const getCurrentMessage = () => {
    return logoBrands.length > 0 ? (
      selectedLogo ? (
        <>
          <Typography color='primary' variant='string' sx={{ fontSize: 10 }}>
            Please select{" "}
          </Typography>
          <Typography
            color={theme.palette.third.main}
            variant='string'
            sx={{ fontSize: 10 }}
          >
            {translateLogoKey(selectedLogo)}
          </Typography>
          <Typography color='primary' variant='string' sx={{ fontSize: 10 }}>
            <br />
            corner position
          </Typography>
        </>
      ) : (
        <>
          <Typography color='primary' variant='string' sx={{ fontSize: 10 }}>
            Please choose corner position
            <br />
            for the leading logo
          </Typography>
        </>
      )
    ) : null;
  };

  const handleFirstAnimationComplete = () => {
    setFirstAnimationCompleted(true);
  };

  const handleSecondAnimationComplete = () => {};

  useEffect(() => {
    if (firstAnimationCompleted) {
      setSecondAnimationStarted(true);
    }
  }, [logoBrands, firstAnimationCompleted]);

  return (
    <>
      {drawerOpen && (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            minHeight: 300,
            p: 0,
          }}
        >
          {showConfiguredCard && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
              }}
            >
              <motion.div
                className='config-card-enter'
                initial={{ scale: 0 }} // start small
                animate={{ scale: 1 }} // end big
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                <Button
                  variant='outlined'
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: imageHeight,
                    m: 2,
                    cursor: "pointer",
                    textAlign: "center",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    textColor: theme.palette.primary.light,
                  }}
                >
                  <Typography
                    variant='subHeadline2'
                    color='primary'
                    sx={{ fontSize: 12 }}
                  >
                    {existingAd?.brand} standard is configured!
                  </Typography>
                </Button>
              </motion.div>
            </Box>
          )}

          {logoBrands.length !== 0 && (
            <Button
              variant='text'
              // item
              xs={6}
              sx={{
                width: "100%",
                height: "60px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "row",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                mt: 2,
                mb: 2,
                // mb: 4,
                // borderRadius: 3,
              }}
            >
              <Typography sx={{ pt: 0 }} color='primary' variant='string'>
                {getCurrentMessage()}
              </Typography>
              {logoBrands.length !== 0 && (
                <Button
                  sx={{ height: 25, fontSize: 10 }}
                  variant='contained'
                  onClick={handleStepBack}
                  disabled={logoCornersConfigHistory.length === 0}
                  endIcon={<ArrowBackIcon />}
                >
                  Go back
                </Button>
              )}
            </Button>
          )}
          <Grid container direction='row' sx={{ height: "100%" }}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  // mt: 2,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // p: 2,
                }}
              >
                <AnimatePresence>
                  {logoBrands.map((logoBrand, index) => {
                    const imagePath = getImagePath(logoBrand);

                    // Check if the string ends with a common image extension
                    const isImage = /\.(jpeg|jpg|png|gif|bmp|webp)$/.test(
                      imagePath
                    );

                    if (!isImage) return null; // Skip non-image paths
                    if (!(values.positions[logoBrand] || imagePath))
                      return null; // Current functionality

                    return (
                      <motion.div
                        key={logoBrand}
                        layout
                        initial={{ x: -100, opacity: 0 }}
                        animate={
                          secondAnimationStarted
                            ? { x: 0, opacity: 1 }
                            : { x: -100, opacity: 0 }
                        }
                        exit={{
                          x: -150,
                          opacity: 0,
                          transition: { duration: 0.5 },
                        }}
                        transition={{
                          type: "spring",
                          damping: 15,
                          stiffness: 100,
                          delay: index * 0.2,
                        }}
                        onAnimationComplete={handleSecondAnimationComplete}
                      >
                        <Card
                          variant='outlined'
                          color='primary'
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "160px",
                            height: "80px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            height: 60,
                            opacity: selectedLogo === logoBrand ? 1 : 0.2,
                          }}
                        >
                          <img
                            src={imagePath}
                            alt={`Logo ${logoBrand}`}
                            style={{
                              padding: 10,
                              maxWidth: "60%",
                              maxHeight: "100%",
                            }}
                          />
                        </Card>
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </Card>
            </Grid>

            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                ml: 3,
                mb: 8,
              }}
            >
              <AnimatePresence>
                {logoBrands.length !== 0 && (
                  <Grid container spacing={7}>
                    {[
                      { Component: ArrowOutwardIcon, rotation: "-90deg" }, // Top left arrow
                      { Component: ArrowOutwardIcon, rotation: "0deg" }, // Top right arrow
                      { Component: ArrowOutwardIcon, rotation: "-180deg" }, // Bottom left arrow
                      { Component: ArrowOutwardIcon, rotation: "-270deg" }, // Bottom right arrow
                    ].map(({ Component, rotation }, index) => (
                      <Grid item xs={6} key={index}>
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          initial={{ scale: 0, y: -50 }}
                          animate={{ scale: 1, y: 0 }}
                          transition={{
                            type: "spring",
                            damping: 15,
                            stiffness: 100,
                            delay: index * 0.2,
                          }}
                          exit={{ scale: 0, y: -50 }}
                          onAnimationComplete={handleFirstAnimationComplete}
                        >
                          <Button
                            variant='outlined'
                            sx={{
                              mb: -6,
                              width: "60px",
                              height: "60px", // Adjust the height value as needed
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCornerClick(index)}
                          >
                            <Component
                              color='primary'
                              style={{ transform: `rotate(${rotation})` }}
                            />
                          </Button>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </AnimatePresence>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StandardSelectCorner;
