import { createSlice } from "@reduxjs/toolkit";

const ctaSlice = createSlice({
  name: "ctas",
  initialState: [],
  reducers: {
    addCta: (state, action) => {
      state.push(action.payload);
    },
    setCtas: (state, action) => {
      const existingCta = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );

      state.push(...existingCta);
    },
    editCta: (state, action) => {
      const { id, name, description } = action.payload;
      const existingCta = state.find((cta) => cta.id === id);
      if (existingCta) {
        existingCta.name = name;
        existingCta.description = description;
      }
    },
    deleteOneCta: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((cta) => cta.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyCtas: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((cta, index) => {
        if (idsToDelete.has(cta.id)) {
          state.splice(index, 1);
        }
      });
    },

    updateCtas: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addCta,
  setCtas,
  editCta,
  deleteOneCta,
  updateCtas,
  deleteManyCtas,
} = ctaSlice.actions;
export default ctaSlice.reducer;
