import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Box,
  Divider,
  Tooltip,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PsychologyIcon from "@mui/icons-material/Psychology";
import axiosInstance from "../../../services/axiosInstance";
import { useTheme } from "@mui/material/styles";

function FunFact() {
  const [facts, setFacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = 1;
  const apiKey = "kvhB3jCFzVtXeTImTvYJwg==GxykYDxhT5bn5Vjl"; // Replace with your actual API key
  const theme = useTheme();

  const fetchFacts = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `https://api.api-ninjas.com/v1/facts?limit=${limit}`,
      headers: {
        "X-Api-Key": apiKey,
        "Content-Type": "application/json",
      },
      withCredentials: false, // Added this line
    })
      .then((response) => {
        setFacts(response.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFacts();
  }, [apiKey]);

  return (
    <Card
      sx={{
        width: "100%",
        height: 60.8,
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          width: "100%",
          pt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
              width: 35,
              height: 35,
              bgcolor: "#8ACC84",
              borderRadius: 1,
              position: "relative",
            }}
          >
            <PsychologyIcon
              sx={{
                width: 25,
                height: 25,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
              }}
            />
          </Box>
          <Typography
            variant='body2'
            sx={{ fontSize: 18, fontWeight: "bold", color: "#2D3748" }}
          >
            Fun Fact
          </Typography>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            sx={{ height: 36, alignSelf: "center" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ml: 1,
            flex: 3,
            position: "relative",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Tooltip title={facts.fact} arrow>
              <Typography
                variant='body1'
                sx={{
                  fontSize: 16,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  color: theme.typography.fontColor,
                  fontWeight: "regular",
                  pr: 5, // Padding right
                }}
              >
                {facts.fact}
              </Typography>
            </Tooltip>
          )}
          <Box
            onClick={fetchFacts}
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
            }}
          >
            <RestartAltIcon color='primary' />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FunFact;
