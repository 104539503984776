import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { resolutions } from "../helpers/resolutions";

function ResolutionDropdownWizzard({
  selectedWizzardResolutions,
  setSelectedWizzardResolutions,
}) {
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedWizzardResolutions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedWizzardResolutions(resolutions);
    } else {
      setSelectedWizzardResolutions([]);
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      <FormControl sx={{ width: "100%", mb: 2 }}>
        <InputLabel id='resolution-select-label' sx={{ mt: -1 }}>
          Resolution
        </InputLabel>
        <Select
          labelId='resolution-select-label'
          id='resolution-select'
          multiple
          value={selectedWizzardResolutions}
          onChange={handleChange}
          input={
            <OutlinedInput
              id='select-multiple-chip'
              label='Resolution'
              sx={{
                height: 40,
                color:
                  selectedWizzardResolutions?.length > 0
                    ? "primary.main"
                    : "text.primary",
                "& input": {
                  color:
                    selectedWizzardResolutions?.length > 0
                      ? "primary.main"
                      : "text.primary",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    selectedWizzardResolutions?.length > 0
                      ? "primary.main"
                      : "text.primary",
                },
              }}
            />
          }
          renderValue={(selected) => selected.join(", ")} // Change this to just join selected values with a comma
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
        >
          <MenuItem value='all'>
            <Checkbox
              checked={
                selectedWizzardResolutions?.length === resolutions.length
              }
              onChange={handleSelectAll}
            />
            <ListItemText primary='Select All' />
          </MenuItem>
          {resolutions.map((resolution) => (
            <MenuItem key={resolution} value={resolution}>
              <Checkbox
                checked={selectedWizzardResolutions?.indexOf(resolution) > -1}
              />
              <ListItemText primary={resolution} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ResolutionDropdownWizzard;
