// Helper function to exclude objects based on values.type in "AdTemplate"
export const excludeObjectsFromLayout = (layout, type) => {
  let excludedKeys = [];
  switch (type) {
    case "1rd":
      excludedKeys = ["logoBrand2", "logoBrand3", "logoThirdParty"];
      break;
    case "2rd":
      excludedKeys = ["logoBrand3", "logoThirdParty"];
      break;
    case "3rd":
      excludedKeys = ["logoThirdParty"];
      break;
    case "4rd":
      excludedKeys = [];
      break;
    default:
      break;
  }

  // Create a new layout object excluding the specified keys
  const newLayout = { ...layout };
  excludedKeys.forEach((key) => {
    delete newLayout[key];
  });

  return newLayout;
};
