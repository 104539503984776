import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

function GradientOutlineButton({ data }) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "inline-flex",
        position: "absolute",
        top: 322,
        left: 286,
        width: 75,
        height: 27,
        borderRadius: 1.2, // equal to Button's border radius
        backgroundImage: "linear-gradient(to right, #F37979, #A06EAF)",
        overflow: "hidden",
      }}
    >
      <Button
        onClick={() => {
          navigate(`/ad/${data.id}`, {
            // state: { userData: params },
            // socket: props.socket,
          });
        }}
        variant='contained'
        startIcon={<VisibilityIcon color='primary' />}
        sx={{
          fontWeight: 600,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          position: "relative",
          zIndex: 1,
          color: theme.typography.fontColor,

          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 1, // create a 2px border
            left: 1, // create a 2px border
            bottom: 1, // create a 2px border
            right: 1, // create a 2px border
            borderRadius: 1,
            backgroundColor: theme.palette.primary.light,
            zIndex: -1,
          },
        }}
      >
        VIEW
      </Button>
    </Box>
  );
}

export default GradientOutlineButton;
