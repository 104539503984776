import { UPLOAD_URL } from "../../../config";

export const handleImageUpload = (
  imageType,
  e,
  setValues,
  setSelectedImage
) => {
  e.preventDefault();
  const input = document.createElement("input");
  input.type = "file";

  input.onchange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }

    // Create a new FileReader instance
    const reader = new FileReader();

    reader.onload = (event) => {
      // Create new image element
      let img = new Image();

      img.onload = () => {
        // Use `naturalWidth` and `naturalHeight` properties to get image's original width and height
        const imageWidth = img.naturalWidth;
        const imageHeight = img.naturalHeight;

        const formData = new FormData();
        formData.append("file", file);

        fetch(UPLOAD_URL, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            setValues((prevState) => {
              const updatedState = { ...prevState };

              switch (imageType) {
                case "background":
                  updatedState.backgroundimage = response.fileName;
                  updatedState.backgroundImageWidth = imageWidth; // add width
                  updatedState.backgroundImageHeight = imageHeight; // add height
                  break;
                case "product":
                  updatedState.productImage = response.fileName;
                  updatedState.productImageWidth = imageWidth; // add width
                  updatedState.productImageHeight = imageHeight; // add height
                  break;
                case "client":
                  updatedState.logoThirdParty = response.fileName;
                  updatedState.logoThirdPartyWidth = imageWidth; // add width
                  updatedState.logoThirdPartyHeight = imageHeight; // add height
                  break;
                case "brand1":
                  updatedState.logoBrand1 = response.fileName;
                  updatedState.logoBrand1Width = imageWidth; // add width
                  updatedState.logoBrand1Height = imageHeight; // add height

                  // Update the positions for brand1
                  for (const resolution in updatedState.positions.logoBrand1) {
                    updatedState.positions.logoBrand1[resolution].width =
                      imageWidth;
                    updatedState.positions.logoBrand1[resolution].height =
                      imageHeight;
                  }
                  break;
                case "brand2":
                  updatedState.logoBrand2 = response.fileName;
                  updatedState.logoBrand2Width = imageWidth; // add width
                  updatedState.logoBrand2Height = imageHeight; // add height
                  break;
                default:
                  break;
              }
              return updatedState;
            });
            setSelectedImage(response.fileName);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      img.src = event.target.result;
    };

    // This will trigger the onload function above after reading the file
    reader.readAsDataURL(file);
  };
  input.click();
};
