import { determineLogoOrientation } from "../helpers/determineLogoOrientation";

export const amdStandardCalculation = (
  xRes,
  yRes,
  selectedCorner,
  selectedBaseCorner,
  element,
  type,
  sameCornerAsLogoBrand1,
  logoBrand1Values = { height: null, width: null },
  partnership
) => {
  if (
    !element ||
    typeof element.width === "undefined" ||
    typeof element.height === "undefined"
  ) {
    throw new Error(`Invalid element provided: ${JSON.stringify(element)}`);
  }

  // Determine the banner orientation based on xRes and yRes
  let bannerOrientation;
  if (xRes > yRes) {
    bannerOrientation = "horizontal";
  } else if (yRes > xRes) {
    bannerOrientation = "vertical";
  } else {
    bannerOrientation = "box"; // assuming equal or near-equal dimensions imply a box orientation
  }

  // Calculate standardWidth based on banner orientation
  let standardWidth =
    bannerOrientation === "horizontal" || bannerOrientation === "box"
      ? xRes * 0.15
      : yRes * 0.15;

  const aspectRatio = element.width / element.height;
  const standardHeight = standardWidth / aspectRatio;

  const elementClone = JSON.parse(JSON.stringify(element));
  elementClone.orientation = bannerOrientation;

  const logoBrand1calculatedWidth = standardWidth;
  const logoBrand1calculatedHeight = standardHeight;

  let otherLogoCalculatedWidth = standardWidth;
  let otherLogoCalculatedHeight = standardHeight;

  const margin = logoBrand1calculatedWidth * 0.2385;

  const partnershipAdjustment =
    (logoBrand1calculatedHeight - otherLogoCalculatedHeight) / 2;

  if (type !== "logoBrand1") {
    // Set the height of other logos to match the height of logoBrand1
    otherLogoCalculatedHeight = margin;

    // Calculate the width based on the aspect ratio of the current logo element, using the matched height
    otherLogoCalculatedWidth = otherLogoCalculatedHeight * aspectRatio;

    // Determine a minimum width for the logo if necessary, for example, not narrower than 10% of the banner width
    const minimumWidth = xRes * 0.1;

    // Ensure the logo's calculated width is not less than the minimum width
    otherLogoCalculatedWidth = Math.max(otherLogoCalculatedWidth, minimumWidth);

    // Condition for horizontal banner orientation
    if (
      bannerOrientation === "horizontal" &&
      Math.abs(aspectRatio - 1) <= 0.1
    ) {
      otherLogoCalculatedWidth = otherLogoCalculatedHeight; // Set the width equal to the height for a box shape
    }

    // Additional condition for box banner orientation
    if (bannerOrientation === "box" || "vertical") {
      // For a box banner orientation, the width should be adjusted only if the aspect ratio is nearly 1
      if (Math.abs(aspectRatio - 1) <= 0.1) {
        otherLogoCalculatedWidth = otherLogoCalculatedHeight; // Maintain the box shape
      }
      // No need for an else condition here since other aspect ratios should already be handled by the width calculation
    }
  }

  let position = { x: element.x, y: element.y };
  if (type === "logoBrand1") {
    logoBrand1Values.height = logoBrand1calculatedHeight;
    logoBrand1Values.width = logoBrand1calculatedWidth;

    position = { ...selectedBaseCorner };
    switch (selectedCorner) {
      case "topLeft":
        position.x += margin;
        if (partnership) {
          position.y = margin;
        } else {
          position.y += margin;
        }
        break;
      case "topRight":
        position.x = xRes - logoBrand1calculatedWidth - margin;
        if (partnership) {
          position.y = margin;
        } else {
          position.y += margin;
        }
        break;
      case "bottomLeft":
        position.x += margin;
        if (partnership) {
          position.y = yRes - logoBrand1calculatedHeight - margin;
        } else {
          position.y = yRes - logoBrand1calculatedHeight - margin;
        }
        break;
      case "bottomRight":
        position.x = xRes - logoBrand1calculatedWidth - margin;
        if (partnership) {
          position.y = yRes - logoBrand1calculatedHeight - margin;
        } else {
          position.y = yRes - logoBrand1calculatedHeight - margin;
        }
        break;
      default:
        break;
    }
  } else if (sameCornerAsLogoBrand1 && partnership) {
    const {
      height: logoBrand1calculatedHeight,
      width: logoBrand1calculatedWidth,
    } = logoBrand1Values;

    switch (selectedCorner) {
      case "topLeft":
        position.x = logoBrand1calculatedWidth + 2.5 * margin;
        position.y = margin;
        break;
      case "topRight":
        position.x =
          selectedBaseCorner.x -
          2.5 * margin -
          logoBrand1calculatedWidth -
          otherLogoCalculatedWidth -
          2 * partnershipAdjustment;
        position.y = margin;

        break;
      case "bottomLeft":
        position.x = logoBrand1calculatedWidth + 2.5 * margin;
        position.y = selectedBaseCorner.y - margin - otherLogoCalculatedHeight;
        break;
      case "bottomRight":
        position.x =
          selectedBaseCorner.x -
          2.5 * margin -
          logoBrand1calculatedWidth -
          otherLogoCalculatedWidth -
          2 * partnershipAdjustment;
        position.y = selectedBaseCorner.y - margin - otherLogoCalculatedHeight;
        break;
      default:
        break;
    }
  } else if (sameCornerAsLogoBrand1) {
    const {
      height: logoBrand1calculatedHeight,
      width: logoBrand1calculatedWidth,
    } = logoBrand1Values;
    switch (selectedCorner) {
      case "topLeft":
        position.x = logoBrand1calculatedWidth + 2 * margin;
        position.y = margin;
        break;
      case "topRight":
        position.x =
          selectedBaseCorner.x -
          2 * margin -
          logoBrand1calculatedWidth -
          otherLogoCalculatedWidth;
        position.y = margin;
        break;
      case "bottomLeft":
        position.x = logoBrand1calculatedWidth + 2 * margin;
        position.y = selectedBaseCorner.y - margin - otherLogoCalculatedHeight;
        break;
      case "bottomRight":
        position.x =
          xRes -
          2 * margin -
          logoBrand1calculatedWidth -
          otherLogoCalculatedWidth;
        position.y = selectedBaseCorner.y - margin - otherLogoCalculatedHeight;
        break;
      default:
        break;
    }
  } else {
    switch (selectedCorner) {
      case "topLeft":
        position.x = margin;
        position.y = margin;
        break;
      case "topRight":
        position.x = xRes - margin - otherLogoCalculatedWidth;
        position.y = margin;
        break;
      case "bottomLeft":
        position.x = margin;
        position.y = yRes - margin - otherLogoCalculatedHeight;
        break;
      case "bottomRight":
        position.x = xRes - margin - otherLogoCalculatedWidth;
        position.y = yRes - margin - otherLogoCalculatedHeight;
        break;
      default:
        break;
    }
  }

  return {
    height:
      type === "logoBrand1"
        ? logoBrand1calculatedHeight
        : otherLogoCalculatedHeight,
    width:
      type === "logoBrand1"
        ? logoBrand1calculatedWidth
        : otherLogoCalculatedWidth,
    position,
    selectedCornerPosition: selectedBaseCorner,
    logoBrand1Values,
  };
};
