import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { formatNotification } from "../../helpers/formatNotifications";
import { useTheme } from "@mui/material/styles";

const NotificationSnackbar = () => {
  const allNotifications = useSelector((store) => store.notifications);
  const UsersList = useSelector((store) => store.users);
  const { currentUser } = useContext(AuthContext);
  const theme = useTheme();

  const [open, setOpen] = useState(true);
  const [shownNotifications, setShownNotifications] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const NotificationsList = allNotifications.filter(
    (notification) =>
      notification.userNotifications?.[0]?.status === "NotDelivered" &&
      !shownNotifications.includes(notification.id)
  );

  useEffect(() => {
    if (NotificationsList.length > 0) {
      setOpen(true);
    }
  }, [NotificationsList]);

  const handleAlertClose = (notificationId) => {
    handleClose();
    setShownNotifications([...shownNotifications, notificationId]);
  };

  const notification = NotificationsList[0];

  if (!notification?.action) {
    return null; // Do not render the component if there's no valid notification
  }

  const severity =
    notification.action.actionType === "Reject" ? "error" : "success";

  const userFromAction = UsersList.find(
    (user) => user.id === notification.action.userId
  );

  const formattedMessage = formatNotification(
    notification.action.actionType,
    notification.action.objectType,
    notification.action.targetUserId,
    currentUser?.id
  );

  const displayName =
    currentUser.id === notification.action.userId
      ? "You"
      : userFromAction
      ? userFromAction.firstName
      : "Unknown";

  const displayMessage = `${displayName} - ${formattedMessage}`;

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={() => handleAlertClose(notification?.id)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ width: "100%" }}
    >
      <Alert
        onClose={() => handleAlertClose(notification?.id)}
        severity={severity}
        variant='filled'
        sx={{
          bgcolor:
            severity === "error"
              ? theme.palette.primary.reject
              : theme.palette.primary.approve,
        }}
      >
        {displayMessage}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
