import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { addLayout, updateLayout } from "../features/layoutSlice";

const SaveLayoutModal = ({ open, handleClose, values }) => {
  const layoutsList = useSelector((state) => state.layouts);

  const [selectedLayout, setSelectedLayout] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveAsNew, setSaveAsNew] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLayout && !saveAsNew) {
      const layout = layoutsList.find((layout) => layout.id === selectedLayout);
      if (layout) {
        setTitle(layout.title);
        setType(layout.type);
      }
    }
  }, [selectedLayout, layoutsList, saveAsNew]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const newLayout = {
        title,
        type,
        layout: values && values.positions ? values.positions : {}, // Check if values and values.positions exist
        brandId: 1, // Placeholder for brandId
      };

      let response;
      if (selectedLayout && !saveAsNew) {
        response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/layouts/${selectedLayout}`,
          newLayout
        );
        dispatch(updateLayout(response.data));
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/layouts`,
          newLayout
        );
        dispatch(addLayout(response.data));
      }
      console.log("Layout saved", response.data);

      handleClose();
    } catch (error) {
      console.error("Error saving layout", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography variant='h6' component='h2' sx={{ fontSize: 18, mb: 2 }}>
          {selectedLayout && !saveAsNew ? "Update Layout" : "Save Layout"}
        </Typography>
        <FormControl fullWidth margin='normal' size='small'>
          <InputLabel>Select Layout</InputLabel>
          <Select
            value={selectedLayout}
            onChange={(e) => setSelectedLayout(e.target.value)}
            label='Select Layout'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {layoutsList.map((layout) => (
              <MenuItem key={layout.id} value={layout.id}>
                {layout.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={saveAsNew}
              onChange={(e) => setSaveAsNew(e.target.checked)}
              color='primary'
            />
          }
          label='Save as New Layout'
        />
        <TextField
          label='Title'
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          margin='normal'
          size='small'
        />
        <TextField
          label='Type'
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
          margin='normal'
          size='small'
        />
        <Box sx={{ position: "relative", mt: 2 }}>
          <Button
            onClick={handleSave}
            variant='contained'
            color='primary'
            disabled={loading}
            fullWidth
            sx={{ height: 36, fontSize: 14 }}
          >
            {loading ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveLayoutModal;
