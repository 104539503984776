import React, { useState, useEffect, useContext } from "react";
import BrandItems from "./components/BrandItems";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import { addBackground } from "./features/backgroundReducer";
import { addProduct } from "./features/productReducer";
import { addSticker } from "./features/stickerReducer";
import { addCta } from "./features/ctaReducer";
import { addLogo } from "./features/logoReducer";
import { addFont } from "./features/fontReducer";
import FileUploadGrid from "./components/FileUploadGrid";
import { getImageDimensions } from "./helpers/getImageDimensions";
import { Box } from "@mui/material";
import { BRANDS_URL, UPLOAD_MULTIPLE_URL } from "../../config";
import { useFetchBrandData } from "../../components/hooks/useFetchBrandData";
import EditBrand from "./EditBrand";
import { addBadge } from "./features/badgeReducer";
import { AuthContext } from "../../context/authContext";

const BrandsSingle = ({}) => {
  const {
    backgrounds: backgroundsFromStore,
    products: productsFromStore,
    badges: badgesFromStore,
    stickers: stickersFromStore,
    ctas: ctasFromStore,
    logos: logosFromStore,
    fonts: fontsFromStore,
    brands: brandsList,
  } = useSelector((state) => state);

  console.log("Badges from store:", badgesFromStore);
  const dispatch = useDispatch();
  const { id } = useParams();

  const editingProduct = true;

  //custom hook to fetch data
  useFetchBrandData(id);

  const [uploadProgress, setUploadProgress] = useState(-1);
  const [brand, setBrand] = useState(null);
  const [values, setValues] = useState({
    name: "",
    description: "",
    brandLogo: "",
    backgrounds: [],
    products: [],
    badges: [],
    stickers: [],
    ctas: [],
    logos: [],
    fonts: [],
  });
  const [shutterstockImages, setShutterstockImages] = useState([]);
  const [shutterSearchInput, setShutterSearchInput] = useState("");
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get("shutterstock/search", {
          params: {
            query: shutterSearchInput, // Send the search input value as a query parameter
          },
        });

        if (response.data && response.data.data) {
          setShutterstockImages(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching images from Shutterstock:", err);
        setError("Failed to fetch images from Shutterstock.");
      }
    };

    fetchImages();
  }, [shutterSearchInput]);

  const autoCropImage = (image, callback) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Log original dimensions
      console.log(
        `Original Dimensions - Width: ${image.width}, Height: ${image.height}`
      );

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      const imageData = context.getImageData(
        0,
        0,
        image.width,
        image.height
      ).data;
      let x1 = image.width;
      let y1 = image.height;
      let x2 = 0;
      let y2 = 0;

      for (let y = 0; y < image.height; y++) {
        for (let x = 0; x < image.width; x++) {
          const index = (y * image.width + x) * 4;
          const alpha = imageData[index + 3];

          if (alpha > 0) {
            if (x < x1) x1 = x;
            if (x > x2) x2 = x;
            if (y < y1) y1 = y;
            if (y > y2) y2 = y;
          }
        }
      }

      const croppedWidth = x2 - x1;
      const croppedHeight = y2 - y1;

      // Log cropped dimensions
      console.log(
        `Cropped Dimensions - Width: ${croppedWidth}, Height: ${croppedHeight}`
      );

      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = croppedWidth;
      canvas.height = croppedHeight;
      context.drawImage(
        image,
        x1,
        y1,
        croppedWidth,
        croppedHeight,
        0,
        0,
        croppedWidth,
        croppedHeight
      );

      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          console.error("Failed to create blob from canvas");
          resolve(null);
        }
      }, "image/png");
    });
  };

  const uploadFiles = async (e, field, isUpdate = false, callback = null) => {
    console.log("Upload files triggered", field);
    e.preventDefault();

    if (e.target.files) {
      console.log("Files exist");
      const files = Array.from(e.target.files);
      const formData = new FormData();

      formData.append("brand", brand.name);
      formData.append("type", field);
      formData.append("clientId", currentUser.clientIdUsers); // Include clientId

      const dimensionsArray = [];

      const promises = files.map((file, index) => {
        return new Promise(async (resolve) => {
          if (field !== "fonts") {
            const reader = new FileReader();
            reader.onloadend = () => {
              const img = new Image();
              img.src = reader.result;

              img.onload = async () => {
                const croppedBlob = await autoCropImage(img);
                const croppedFile = new File([croppedBlob], file.name, {
                  type: file.type,
                });

                dimensionsArray[index] = {
                  width: img.naturalWidth,
                  height: img.naturalHeight,
                };

                formData.append("files", croppedFile);
                resolve();
              };
            };
            reader.readAsDataURL(file);
          } else {
            formData.append("files", file);
            resolve();
          }
        });
      });

      await Promise.all(promises);

      setUploadProgress(0);

      try {
        console.log("About to post data");

        const response = await axiosInstance.post(
          UPLOAD_MULTIPLE_URL,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress);
            },
          }
        );

        console.log("Response from server:", response.data);

        const fileNames = response.data.map((file) => file.fileName);

        setValues((prevState) => ({
          ...prevState,
          [field]: fileNames,
        }));

        if (!isUpdate) {
          for (const [index, fileName] of fileNames.entries()) {
            let imageData = {
              data: {
                fileName: fileName,
              },
              brandId: brand.id,
            };

            if (field !== "fonts") {
              const dimensions = dimensionsArray[index];
              imageData.data.width = dimensions.width;
              imageData.data.height = dimensions.height;
            }

            switch (field) {
              case "backgrounds":
                const backgroundResponse = await axiosInstance.post(
                  `${BRANDS_URL}/backgrounds`,
                  imageData
                );
                dispatch(addBackground(backgroundResponse.data));
                break;

              case "products":
                const productResponse = await axiosInstance.post(
                  `${BRANDS_URL}/products`,
                  imageData
                );
                dispatch(addProduct(productResponse.data));
                break;

              case "stickers":
                const stickerResponse = await axiosInstance.post(
                  `${BRANDS_URL}/stickers`,
                  imageData
                );
                dispatch(addSticker(stickerResponse.data));
                break;

              case "ctas":
                const ctaResponse = await axiosInstance.post(
                  `${BRANDS_URL}/ctas`,
                  imageData
                );
                dispatch(addCta(ctaResponse.data));
                break;

              case "logos":
                const logoResponse = await axiosInstance.post(
                  `${BRANDS_URL}/logos`,
                  imageData
                );
                dispatch(addLogo(logoResponse.data));
                break;

              case "fonts":
                const fontResponse = await axiosInstance.post(
                  `${BRANDS_URL}/fonts`,
                  imageData
                );
                dispatch(addFont(fontResponse.data));
                break;

              case "badges":
                if (!isUpdate) {
                  const badgeResponse = await axiosInstance.post(
                    `${BRANDS_URL}/badges`,
                    imageData
                  );
                  dispatch(addBadge(badgeResponse.data));
                }
                break;

              default:
                break;
            }
          }
        }

        // Execute callback if provided
        if (callback && typeof callback === "function") {
          callback(fileNames[0]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUploadProgress(-1);
      }
    }
  };

  useEffect(() => {
    const selectedBrand = brandsList.find(
      (brandItem) => brandItem.id === parseInt(id)
    );
    setBrand(selectedBrand);
  }, [brandsList, id]);

  return (
    <Box sx={{ pt: 2, pl: 10, pr: 5 }}>
      {brand ? (
        <>
          <EditBrand brand={brand} />
        </>
      ) : (
        <p>Loading brand...</p>
      )}

      <FileUploadGrid
        uploadProgress={uploadProgress}
        uploadFiles={uploadFiles}
        values={values}
      />
      {brand ? (
        <>
          <BrandItems
            backgrounds={backgroundsFromStore}
            products={productsFromStore}
            badges={badgesFromStore}
            stickers={stickersFromStore}
            ctas={ctasFromStore}
            logos={logosFromStore}
            fonts={fontsFromStore}
            brand={brand}
            showCheckbox={true}
            shutterSearchInput={shutterSearchInput}
            setShutterSearchInput={setShutterSearchInput}
            shutterstockImages={shutterstockImages}
            editingProduct={editingProduct}
            uploadFiles={uploadFiles}
          />
        </>
      ) : (
        <p>Loading brand images...</p>
      )}
    </Box>
  );
};

export default BrandsSingle;
