// Function to determine logo orientation
export function determineLogoOrientation(logo) {
  if (logo.width > logo.height) {
    // Horizontal orientation when width is greater than height
    return "horizontal";
  } else if (logo.height > logo.width) {
    // Vertical orientation when height is greater than width
    return "vertical";
  } else {
    // Box orientation when width and height are equal
    return "box";
  }
}
