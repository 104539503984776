import { createSlice } from "@reduxjs/toolkit";

const stickerSlice = createSlice({
  name: "stickers",
  initialState: [],
  reducers: {
    addSticker: (state, action) => {
      state.push(action.payload);
    },
    setStickers: (state, action) => {
      const existingSticker = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );

      state.push(...existingSticker);
    },
    editSticker: (state, action) => {
      const { id, name, description } = action.payload;
      const existingSticker = state.find((sticker) => sticker.id === id);
      if (existingSticker) {
        existingSticker.name = name;
        existingSticker.description = description;
      }
    },
    deleteOneSticker: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((sticker) => sticker.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyStickers: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((stickers, index) => {
        if (idsToDelete.has(stickers.id)) {
          state.splice(index, 1);
        }
      });
    },

    updateStickers: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addSticker,
  setStickers,
  editSticker,
  deleteOneSticker,
  updateStickers,
  deleteManyStickers,
} = stickerSlice.actions;
export default stickerSlice.reducer;
