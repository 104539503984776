import React from "react";
import {
  Menu,
  MenuItem,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "@mui/material";
import { CompactPicker } from "react-color";

const PartnershipComponent = ({
  values,
  handleColorChange,
  setValues,
  anchorEl,
  open,
  onClose,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom", // Aligns the top of the Menu with the bottom of the anchorEl
        horizontal: "center", // Aligns the center of the Menu with the center of the anchorEl
      }}
      transformOrigin={{
        vertical: "top", // Aligns the top of the Menu with the anchorOrigin vertical point
        horizontal: "center", // Aligns the center of the Menu with the anchorOrigin horizontal point
      }}
    >
      <MenuItem disableRipple>
        <CompactPicker
          color={values.partnershipColor}
          onChange={handleColorChange}
        />
      </MenuItem>

      <MenuItem disableRipple>
        <Typography
          color='primary'
          gutterBottom
          sx={{ fontSize: "0.8rem", mr: 1 }}
        >
          Divider Width
        </Typography>
        <ToggleButtonGroup
          value={values.partnershipWidth}
          exclusive
          onChange={(event, newWidth) => {
            if (newWidth !== null) {
              setValues((prevValues) => ({
                ...prevValues,
                partnershipWidth: newWidth,
              }));
            }
          }}
          aria-label='divider width'
        >
          <ToggleButton
            color='primary'
            value={1}
            aria-label='1px'
            sx={{ padding: "5px 10px", fontSize: "0.75rem", minWidth: "30px" }}
          >
            1 px
          </ToggleButton>
          <ToggleButton
            color='primary'
            value={2}
            aria-label='2px'
            sx={{ padding: "5px 10px", fontSize: "0.75rem", minWidth: "30px" }}
          >
            2 px
          </ToggleButton>
          <ToggleButton
            color='primary'
            value={3}
            aria-label='3px'
            sx={{ padding: "5px 10px", fontSize: "0.75rem", minWidth: "30px" }}
          >
            3 px
          </ToggleButton>
        </ToggleButtonGroup>
      </MenuItem>
    </Menu>
  );
};

export default PartnershipComponent;
