import axios from "axios";
import { LAYOUTS_URL } from "../../../config";

export const formatPositionsData = (figmaData, logoCornersConfig) => {
  const positions = {};

  figmaData.forEach(({ resolutionName, elements }) => {
    elements.forEach((element) => {
      if (!positions[element.elementName]) {
        positions[element.elementName] = {};
      }

      let baseElementData;
      let height = +element.height;
      let width = +element.width;

      baseElementData = {
        x: +element.relativePosition.x,
        y: +element.relativePosition.y,
        visible: "true",
        editable: true,
      };

      if (element.type === "TEXT") {
        // Replacing "\n" with "<br>"
        const formattedCharacters = element.characters.replace(/\n/g, "<br>");

        positions[element.elementName][resolutionName] = {
          ...baseElementData,
          fontSize: +element.fontSize,
          fontName: element.fontFamily,
          fontWeight: element.fontWeight,
          lineHeight: +element.lineHeight / +element.fontSize,
          letterSpacing: element.lineSpacing,
          textAlign: element.textAlign,
          [element.elementName]: formattedCharacters,
          textColor: element.color,
        };
      } else if (element.type === "RECTANGLE") {
        let imageUrl;

        switch (element.elementName) {
          case "background":
            imageUrl = "background.png";
            break;
          case "logoBrand1":
            imageUrl = "logoBrand1.png";
            break;
          case "logoBrand2":
            imageUrl = "logoBrand2.png";
            break;
          case "logoBrand3":
            imageUrl = "logoBrand3.png";
            break;
          case "logoThirdParty":
            imageUrl = "logoThirdParty.png";
            break;
          case "product":
            imageUrl = "product1.png";
            break;
          case "sticker1":
            imageUrl = "sticker1.png";
            break;
          case "cta":
            imageUrl = "cta.png";
            break;
          default:
            imageUrl = null; // you can set a default value here if needed
        }

        positions[element.elementName][resolutionName] = {
          ...baseElementData,
          height,
          width,
          imageUrl,
          visible: true,
          selectedCorner: logoCornersConfig,
        };
      }
    });
  });

  axios
    .put(`${LAYOUTS_URL}/${27}`, { layout: positions })
    .then((response) => {})
    .catch((error) => {
      console.error("Error updating layout:", error);
    });

  return positions;
};
