import axiosInstance from "../../../services/axiosInstance";

// Helper function to find an element by ID
function findElementById(document, elementId) {
  if (document.id === elementId) {
    return document;
  }

  if (document.children) {
    for (const child of document.children) {
      const foundElement = findElementById(child, elementId);
      if (foundElement) {
        return foundElement;
      }
    }
  }

  return null;
}

// Helper function to find an element by name
function findElementByName(document, elementName) {
  if (document.name === elementName) {
    return document;
  }

  let foundElement = null;
  if (document.children) {
    for (const child of document.children) {
      foundElement = findElementByName(child, elementName);
      if (foundElement) {
        break;
      }
    }
  }

  return foundElement;
}

export async function getFigmaImageData(nodeId, accessToken, fileKey) {
  try {
    const response = await axiosInstance.get(
      `https://api.figma.com/v1/images/${fileKey}?ids=${nodeId}`,
      {
        headers: {
          "X-Figma-Token": accessToken,
        },
      }
    );
    return response.data.images[nodeId];
  } catch (error) {
    console.error(error);
  }
}

export async function getFigmaData(clientId, handleProgress) {
  const response = await axiosInstance.get(`/clients/${clientId}`);
  const { figma } = response.data.settings;

  const accessToken = figma.figmaAccessToken;
  const fileKey = figma.figmaFileKey;

  const figmaData_1 = await new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(
        `https://api.figma.com/v1/files/${fileKey}`,
        {
          headers: {
            "X-Figma-Token": accessToken,
          },
          withCredentials: false,
        }
      );

      const fileData = response.data;
      const document = fileData.document;
      console.log("Figma response", document);

      const resolutionIds = [
        "685:2", // 320x50
        "685:12", // 300x250
        "685:25", // 160x600
        "685:38", // 300x600
        "685:51", // 600x829
        "685:65", // 728x90
        "685:76", // 875x225
        "685:89", // 960x676
        "685:102", // 970x250
        "685:115", // 1900x620
        "685:128", // 1080x1920
        "685:142", // 1080x1080
        "685:155", // 1200x1200
        "685:168", // 1200x630
        "685:181", // 1200x628
      ];

      const desiredNames = [
        "headline",
        "subHeadline",
        "product",
        "disclaimer",
        "background",
        "logoBrand1",
        "logoBrand2",
        "logoBrand3",
        "logoThirdParty",
        "sticker1",
        "cta",
      ];

      const figmaData = [];

      const totalItems = resolutionIds.length * desiredNames.length;
      let processedItems = 0;

      for (const resolutionId of resolutionIds) {
        const resolution = findElementById(document, resolutionId);
        if (resolution) {
          const resolutionData = {
            resolutionName: resolution.name,
            elements: [],
          };

          for (const desiredName of desiredNames) {
            const desiredElement = findElementByName(resolution, desiredName);

            if (desiredElement) {
              const relativeX =
                desiredElement.absoluteBoundingBox.x -
                resolution.absoluteBoundingBox.x;
              const relativeY =
                desiredElement.absoluteBoundingBox.y -
                resolution.absoluteBoundingBox.y;

              const elementData = {
                elementName: desiredName,
                id: desiredElement.id,
                name: desiredElement.name,
                visible: desiredElement.visible,
                type: desiredElement.type,
                relativePosition: { x: relativeX, y: relativeY },
              };

              if (desiredElement.type === "TEXT") {
                elementData.characters = desiredElement.characters;

                if (desiredElement.style) {
                  elementData.fontFamily = desiredElement.style.fontFamily;
                  elementData.fontSize = desiredElement.style.fontSize;
                  elementData.lineHeight = desiredElement.style.lineHeightPx;
                  elementData.lineSpacing = desiredElement.style.letterSpacing;
                  elementData.textAlign =
                    desiredElement.style.textAlignHorizontal;
                  elementData.fontWeight = desiredElement.style.fontWeight;

                  if (desiredElement.fills && desiredElement.fills.length > 0) {
                    const { r, g, b, a } = desiredElement.fills[0].color;
                    elementData.color = `rgba(${Math.round(
                      r * 255
                    )}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, ${a})`;
                  }
                } else {
                  console.warn(
                    "Style is not defined for this element:",
                    desiredElement
                  );
                }
              } else if (desiredElement.type === "RECTANGLE") {
                elementData.width = desiredElement.absoluteBoundingBox.width;
                elementData.height = desiredElement.absoluteBoundingBox.height;
                try {
                  const imageUrl = await getFigmaImageData(
                    desiredElement.id,
                    accessToken,
                    fileKey
                  );
                  elementData.imageUrl = imageUrl;
                } catch (error) {
                  console.error("Error getting image data:", error);
                }
              }

              resolutionData.elements.push(elementData);

              processedItems++;
              handleProgress(
                processedItems,
                totalItems,
                elementData,
                resolutionData
              );
            }
          }

          figmaData.push(resolutionData);
        }
      }
      resolve(figmaData);
    } catch (error_1) {
      reject(error_1);
    }
  });
  return figmaData_1;
}
