import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Grid,
  Paper,
  FormGroup,
} from "@mui/material";
import { ASSETS_URL } from "../../../config";

const BackgroundUpdateModal = ({
  image,
  backgroundId,
  openBackgroundModal,
  handleCloseBackgroundModal,
  handleUpdateBackground,
  uploadFiles,
}) => {
  const [backgroundName, setBackgroundName] = useState("");
  const [backgroundDescription, setBackgroundDescription] = useState("");
  const [flags, setFlags] = useState({ positive: false, negative: false });

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (image) {
      setBackgroundName(image.data.name || "");
      setBackgroundDescription(image.data.description || "");
      setFlags({
        positive: image.data.flags?.positive || false,
        negative: image.data.flags?.negative || false,
      });
    }
  }, [image]);

  const handleFlagChange = (event) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleNameChange = (event) => {
    setBackgroundName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setBackgroundDescription(event.target.value);
  };

  const handleSubmit = () => {
    handleUpdateBackground(
      backgroundId,
      backgroundName,
      backgroundDescription,
      flags
    );
    handleCloseBackgroundModal();
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    await uploadFiles(e, "backgrounds", true, async (uploadedFileName) => {
      handleUpdateBackground(
        backgroundId,
        backgroundName,
        backgroundDescription,
        flags,
        uploadedFileName
      );
    });
  };

  return (
    <Modal open={openBackgroundModal} onClose={handleCloseBackgroundModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "600px",
          maxHeight: "80vh",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <TextField
          label='Name'
          variant='outlined'
          fullWidth
          margin='normal'
          value={backgroundName}
          onChange={handleNameChange}
          size='small'
        />
        <TextField
          label='Description'
          variant='outlined'
          fullWidth
          margin='normal'
          value={backgroundDescription}
          onChange={handleDescriptionChange}
          size='small'
          multiline
          rows={6}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={flags.positive}
                  onChange={handleFlagChange}
                  name='positive'
                />
              }
              label='Positive'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={flags.negative}
                  onChange={handleFlagChange}
                  name='negative'
                />
              }
              label='Negative'
            />
          </FormGroup>
          <Grid item xs={4}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
              onClick={handleImageClick}
            >
              <img
                src={`${ASSETS_URL}/${image?.data?.fileName}`}
                alt={backgroundName}
                loading='lazy'
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                  backgroundColor: !flags.positive ? "#808080" : "transparent",
                }}
              />
            </Paper>
          </Grid>

          <input
            type='file'
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </Box>
        <Box textAlign='center' marginTop={2}>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Update Background
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BackgroundUpdateModal;
