import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid"; // Import the flip icon
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

const ImageControls = ({ updateStyle }) => {
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [shearX, setShearX] = useState(0);
  const [shearY, setShearY] = useState(0);

  const updateTransform = () => {
    updateStyle(
      "transform",
      `scaleX(${flipHorizontal ? -1 : 1}) scaleY(${
        flipVertical ? -1 : 1
      }) skewX(${shearX}deg) skewY(${shearY}deg)`
    );
  };

  const handleFlipHorizontal = () => {
    setFlipHorizontal((prev) => !prev);
    updateTransform();
  };

  const handleFlipVertical = () => {
    setFlipVertical((prev) => !prev);
    updateTransform();
  };

  const handleShearXChange = (event, newValue) => {
    setShearX(newValue);
    updateTransform();
  };

  const handleShearYChange = (event, newValue) => {
    setShearY(newValue);
    updateTransform();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 2, height: 28, mt: 0.2 }}>
        <Button
          variant='outlined'
          onClick={handleFlipHorizontal}
          startIcon={<FlipCameraAndroidIcon />}
        >
          H
        </Button>
        <Button
          variant='outlined'
          onClick={handleFlipVertical}
          startIcon={<FlipCameraAndroidIcon />}
        >
          V
        </Button>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant='body2' sx={{ fontSize: "0.7rem" }}>
          Shear X:
        </Typography>
        <Slider
          size='small'
          sx={{ minWidth: 60 }}
          value={shearX}
          onChange={handleShearXChange}
          step={1}
          min={-45}
          max={45}
          valueLabelDisplay='auto'
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant='body2' sx={{ fontSize: "0.7rem" }}>
          Shear Y:
        </Typography>
        <Slider
          size='small'
          sx={{ minWidth: 60 }}
          value={shearY}
          onChange={handleShearYChange}
          step={1}
          min={-45}
          max={45}
          valueLabelDisplay='auto'
        />
      </Box>
    </Box>
  );
};

export default ImageControls;
