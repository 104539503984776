import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const SharePost = ({
  data,
  selectedResolution,
  handleResolutionChange,
  shareText,
  setShareText,
  isGenerating,
  generatedImage,
  isPublishing,
  handleSubmit,
}) => {
  const [scheduledDate, setScheduledDate] = useState(null);

  const handleDateChange = (date) => {
    setScheduledDate(date);
  };

  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Resolution</InputLabel>
        <Select
          value={selectedResolution}
          onChange={handleResolutionChange}
          label='Resolution'
        >
          {data?.positions?.resolutions?.map((resolution) => (
            <MenuItem key={resolution} value={resolution}>
              {resolution}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label='Write your caption'
        fullWidth
        multiline
        rows={4}
        value={shareText}
        onChange={(e) => setShareText(e.target.value)}
        variant='outlined'
        sx={{ mb: 2 }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label='Schedule Date & Time'
          value={scheduledDate}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField fullWidth sx={{ mb: 2 }} {...params} />
          )}
        />
      </LocalizationProvider>
      {isGenerating && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {generatedImage && (
        <Box sx={{ position: "relative" }}>
          <Box
            component='img'
            src={generatedImage}
            alt='Generated Ad Preview'
            sx={{
              width: "100%",
              height: "auto",
              mt: 2,
              filter: isPublishing ? "blur(4px)" : "none",
            }}
          />
          {isPublishing && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default SharePost;
