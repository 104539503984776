import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Select,
  MenuItem,
  Divider,
  Button,
  TextField,
  Chip,
  Dialog,
  DialogContent,
} from "@mui/material";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { AuthContext } from "../context/authContext";

const ClientModalComponent = ({
  userTitle,
  values,
  setValues,
  clients,
  open,
  setOpen,
  backgroundColor,
  handleInputChange,
  formChanged,
  setFormChanged,
  handleAddEntity,
  handleEditEntity,
}) => {
  const { currentUser } = useContext(AuthContext);

  const theme = useTheme();
  const [countries, setCountries] = useState(values?.data?.countries || []);

  //   console.log(currentUser);

  useEffect(() => {
    if (open) {
      if (userTitle === "Edit Client") {
        const selectedClient = clients.find(
          (client) => client?.id === values.id
        );

        if (selectedClient) {
          setValues((prev) => ({
            ...prev,
            countries: selectedClient?.data?.countries || [],
            type: selectedClient?.type || "",
            data: selectedClient?.data || { countries: [] },
          }));
          setCountries(selectedClient?.data?.countries || []);
        }
      } else if (userTitle === "Add Client") {
        setCountries([]);
      }
    }
  }, [open, userTitle, values?.id, clients, setValues]);

  const handleClose = () => {
    setOpen(false);
  };

  const sectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    borderBottom: "1px solid #D9D9D9",
  };

  const textStyle = {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.typography.fontColor,
  };

  const inputStyle = {
    width: "50%",
    color: theme.typography.fontColor,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: "700px" }, p: 0 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Card sx={{ px: 4, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}>
          <CardContent>
            <Box
              sx={{
                ...sectionStyle,
                fontSize: 18,
                fontWeight: "bold",
                color: theme.typography.fontColorHeadline,
                pt: 3.9,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: theme.typography.fontColorHeadline,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: -4,
                    height: "2px",
                    backgroundImage: backgroundColor,
                  },
                }}
              >
                {userTitle}
              </Typography>
              <Divider orientation='horizontal' flexItem />
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Client Name</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={values?.name || ""}
                onChange={(e) => handleInputChange(e, "name")}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Countries</Typography>
              <Select
                multiple
                value={values?.countries || []}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    countries: e.target.value,
                  }))
                }
                sx={{
                  ...inputStyle,
                  color: theme.typography.fontColor,
                  fontWeight: "normal",
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {["USA", "Canada", "UK", "Germany"].map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Client Type</Typography>
              <Select
                value={values?.type || ""}
                onChange={(e) => handleInputChange(e, "type")}
                sx={inputStyle}
              >
                <MenuItem value='brand'>Brand</MenuItem>
                <MenuItem value='company'>Company</MenuItem>
                <MenuItem value='agency'>Agency</MenuItem>
              </Select>
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Address</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={values?.address || ""}
                onChange={(e) => handleInputChange(e, "address")}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Phone</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={values?.phone || ""}
                onChange={(e) => handleInputChange(e, "phone")}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Email</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={values?.email || ""}
                onChange={(e) => handleInputChange(e, "email")}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>

            <Box sx={sectionStyle}>
              <Typography sx={textStyle}>Website</Typography>
              <TextField
                sx={inputStyle}
                variant='standard'
                value={values?.website || ""}
                onChange={(e) => handleInputChange(e, "website")}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: theme.typography.fontColor,
                    fontWeight: "normal",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                disabled={!formChanged}
                variant='contained'
                color='primary'
                size='small'
                style={{ color: theme.palette.primary.light }}
                onClick={() => {
                  if (userTitle.includes("Edit Client")) {
                    handleEditEntity(); // Call handleEditEntity for editing a client
                  } else if (userTitle.includes("Add Client")) {
                    handleAddEntity(); // Call handleAddEntity for adding a client
                  }
                }}
              >
                {userTitle.includes("Edit") ? "UPDATE" : "ADD"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ClientModalComponent;
