import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { LinearProgress, Button, IconButton, Grid } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
// import useUsers from "./hooks/useUsers";
import { useDispatch, useSelector } from "react-redux";
import {
  setUsers,
  deleteOneUser,
  updateUsers,
  addUser,
  editUser,
} from "./features/userSlice";
import EditIcon from "@mui/icons-material/Edit";
import { updateClients } from "../clients/features/clientSlice";
import { ACTIONS_URL, AUTH_URL, CLIENTS_URL, USERS_URL } from "../../config";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import UsersTable from "../../components/Table";
import UserCard from "../../components/UserCard";
import AdReadyHelp from "../../components/AdReadyHelp";
import AddAndEditModal from "../../components/AddAndEditModal";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";
import { useTheme } from "@mui/material/styles";

export default function Users(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const socket = useContext(SocketContext);
  const theme = useTheme();

  // const { isLoading } = useUsers();
  const UsersList = useSelector((store) => store.users);
  const ClientsList = useSelector((store) => store.clients);

  const [usersState, setUsersState] = useState(UsersList);

  const { currentUser, actions } = useContext(AuthContext);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);

  const [editingUser, setEditingUser] = useState(null);

  const inititalValues = {
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    username: currentUser?.username,
    email: currentUser?.email,
    password: "",
    role: currentUser?.role,
    clientId: currentUser?.clientIdUsers,
    company: currentUser?.clientName,
  };

  const [values, setValues] = useState(inititalValues);

  const handleChipSelect = (selectedValue) => {
    setValues({
      ...values,
      clientId: selectedValue,
    });
  };

  const handlePostRequest = async () => {
    try {
      const response = await axios.post(AUTH_URL, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        username: values.username,
        password: values.password,
        role: values.role,
        clientIdUsers: values.clientId,
      });

      if (response.data) {
        const {
          id,
          firstName,
          lastName,
          email,
          username,
          password,
          role,
          createdAt,
          updatedAt,
          clientName,
          clientIdUsers,
        } = response.data.user;
        dispatch(
          addUser({
            id,
            firstName,
            lastName,
            email,
            username,
            password,
            role,
            clientIdUsers,
            clientName,
            createdAt,
            updatedAt,
          })
        );

        await axiosInstance.post(ACTIONS_URL, {
          actionType: "Create",
          objectType: "User",
          userId: currentUser.id,
          clientId: clientIdUsers,
          content: response.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddUser = async (e) => {
    if (
      values.firstName &&
      values.lastName &&
      values.email &&
      values.username &&
      values.password &&
      values.role &&
      values.clientId
    ) {
      try {
        await handlePostRequest();

        navigate("/users");
      } catch (err) {
        console.error("Error while creating user:", err);
      }
    }
  };

  useEffect(() => {
    const transformedUsers = UsersList.map((user) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        username: user.username,
        role: user.role,
        clientName: user.clientName,
        created: formatDateToLocaleString(user.createdAt),
        updated: formatDateToLocaleString(user.updatedAt),
        status: user.status,
      };
    });

    setUsersState(transformedUsers);
  }, [UsersList]);

  console.log(currentUser);

  // EDIT USER
  const handlePutRequest = async () => {
    try {
      const response = await axios.put(`${USERS_URL}/${values.id}`, {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        username: values.username,
        password: values.password,
        role: values.role,
        clientIdUsers: values.clientId,
      });

      if (response.data) {
        const {
          id,
          firstName,
          lastName,
          email,
          username,
          password,
          role,
          createdAt,
          updatedAt,
          clientName,
          clientIdUsers,
        } = response.data.user;

        dispatch(
          editUser({
            id,
            firstName,
            lastName,
            email,
            username,
            password,
            role,
            clientIdUsers,
            clientName,
            createdAt,
            updatedAt,
          })
        );

        // Posting an action after user update
        await axiosInstance.post(ACTIONS_URL, {
          actionType: "Update",
          objectType: "User",
          userId: currentUser.id,
          clientId: currentUser.clientIdUsers,
          content: {
            id,
            firstName,
            lastName,
            email,
            username,
            password,
            role,
            clientIdUsers,
            clientName,
            createdAt,
            updatedAt,
          },
        });
      }
    } catch (err) {
      console.error(
        "Error updating the user:",
        err.response ? err.response.data : err.message
      );
    }
  };

  const handleEditUser = async () => {
    try {
      await handlePutRequest();
      navigate("/users");
    } catch (err) {
      console.error("Error in handleEditUser:", err);
    }
  };

  // useEffect(() => {
  //   socket.on("getCommentNotification", (data) => {
  //     console.log(data);
  //     setNotifications((prev) => [...prev, data]);
  //   });

  //   // getUsers();
  // }, [socket]);

  // EDIT AND DELETE BUTTONS
  const actionsDetailButton = (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <IconButton onClick={() => handleDelete(params)} aria-label='delete'>
          <DeleteIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setValues({
              ...params,
              clientId: params.clientIdUsers, // if `clientIdUsers` is the key you want
            });
            setEditingUser(params);
            setShowUserModal(true);
          }}
          aria-label='edit'
        >
          <EditIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    );
  };

  // DELETE USER
  const handleDelete = async (row) => {
    const id = row.id;
    const userName = row.firstName;

    confirmDialog(
      `Do you really want to delete "${userName}", created at ${row.created} ?`,
      async () => {
        try {
          const deleteResponse = await axios.delete(`${USERS_URL}/${id}`);

          if (deleteResponse.data) {
            dispatch(deleteOneUser({ id: row.id }));

            await axiosInstance.post(ACTIONS_URL, {
              actionType: "Delete",
              objectType: "User",
              userId: currentUser.id,
              clientId: deleteResponse?.data.deletedUser.clientIdUsers,
              content: deleteResponse.data,
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  const cardColors = {
    item1: theme.palette.primary.reject,
    item2: theme.typography.fontColor,
    item3: theme.typography.fontColor,
  };

  const backgroundColor = "linear-gradient(45deg, #F37979 30%, #273860 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
      <ConfirmDialog2 />

      <Grid item xs={12} md={7}>
        <UserCard
          values={values}
          setValues={setValues}
          handleAddUser={handleAddUser}
          ClientsList={ClientsList}
          rows={usersState}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfItems={usersState.length}
          cardHeader={"Users"}
          backgroundColor={backgroundColor}
          pendingAds={2}
          handleChipSelect={handleChipSelect}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      <Grid item xs={12}>
        <UsersTable
          title='Users'
          rows={usersState}
          name={usersState.name}
          actionsDetailButton={actionsDetailButton}
        />
      </Grid>

      <AddAndEditModal
        backgroundColor={backgroundColor}
        handleEditUser={handleEditUser}
        values={values}
        setValues={setValues}
        currentUser={currentUser}
        userTitle={"Edit User"} // Determine if editing or adding
        open={showUserModal}
        setOpen={setShowUserModal}
        clients={ClientsList}
        onSelect={handleChipSelect}
        handleAddUser={handleAddUser}
      />
    </Grid>
  );
}
