import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  Slider,
  TextField,
  InputAdornment,
  Card,
  Typography,
} from "@mui/material";
import {
  ChromePicker,
  CirclePicker,
  CompactPicker,
  SketchPicker,
} from "react-color";
import { debounce } from "lodash";
import ColorInfoCard from "./ColorInfoCard";

const ColorSettingComponent = ({
  setValues,
  editingResolution,
  editStates,
  editingComponent,
}) => {
  const [singleColor, setSingleColor] = useState({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  });
  const [gradientColors, setGradientColors] = useState({
    start: "#ffffff",
    end: "#ffffff",
  });
  const [settingGradientColor, setSettingGradientColor] = useState(null);
  const [gradientType, setGradientType] = useState("linear");

  const [gradientDegree, setGradientDegree] = useState(0);

  const [colorPercentage, setColorPercentage] = useState(100);
  // New state to track which text element is being edited
  const [editingText, setEditingText] = useState("headline");

  const getGradientString = () => {
    const { start, end } = gradientColors;
    switch (gradientType) {
      case "linear":
        return `linear-gradient(${gradientDegree}deg, ${start}, ${end} ${colorPercentage}%)`;
      case "diagonal":
        return `linear-gradient(45deg, ${start}, ${end} ${colorPercentage}%)`;
      case "radial":
        return `radial-gradient(circle, ${start}, ${end} ${colorPercentage}%)`;
      default:
        return "";
    }
  };

  const updateColor = () => {
    const colorString = `rgba(${singleColor.r}, ${singleColor.g}, ${singleColor.b}, ${singleColor.a})`;

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      const { positions } = updatedValues;

      // Use editingComponent to determine which element to update
      if (!positions[editingComponent]) positions[editingComponent] = {};

      const updateTextColor = (resolution) => {
        // Check if the component is a hardcoded text element or matches the pattern 'text' followed by numbers
        const isTextElement =
          ["headline", "subHeadline", "disclaimer"].includes(
            editingComponent
          ) || /^text\d+$/.test(editingComponent);

        if (isTextElement) {
          if (!positions[editingComponent][resolution]) {
            positions[editingComponent][resolution] = {};
          }
          positions[editingComponent][resolution].textColor = colorString;
        } else {
          // For non-text components, update the color property
          positions[editingComponent][resolution] = {
            ...positions[editingComponent][resolution],
            color: colorString,
          };
        }
      };

      if (editStates["1200x630"]) {
        for (const res in positions[editingComponent]) {
          updateTextColor(res);
        }
      } else {
        updateTextColor(editingResolution);
      }

      return updatedValues;
    });
  };

  const updateGradient = () => {
    const gradientString = getGradientString();

    setValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      const { positions } = updatedValues;

      // Use editingComponent to determine which element to update
      if (!positions[editingComponent]) positions[editingComponent] = {};

      const updateTextColor = (resolution) => {
        // Check if the component is a hardcoded text element or matches the pattern 'text' followed by numbers
        const isTextElement =
          ["headline", "subHeadline", "disclaimer"].includes(
            editingComponent
          ) || /^text\d+$/.test(editingComponent);

        if (isTextElement) {
          if (!positions[editingComponent][resolution]) {
            positions[editingComponent][resolution] = {};
          }
          positions[editingComponent][resolution].textColor = gradientString;
        } else {
          // For non-text components, update the color property
          positions[editingComponent][resolution] = {
            ...positions[editingComponent][resolution],
            color: gradientString,
          };
        }
      };

      if (editStates["1200x630"]) {
        for (const res in positions[editingComponent]) {
          updateTextColor(res);
        }
      } else {
        updateTextColor(editingResolution);
      }

      return updatedValues;
    });
  };

  const handleColorChange = (color) => {
    const colorToSet =
      settingGradientColor === "start" || settingGradientColor === "end"
        ? color.hex
        : color.rgb;

    if (settingGradientColor) {
      setGradientColors((prevColors) => ({
        ...prevColors,
        [settingGradientColor]: colorToSet,
      }));
    } else {
      setSingleColor(color.rgb);
    }
  };

  const handleDegreeChange = (event) => {
    const value = event.target.value;
    if (
      value === "" ||
      (!isNaN(parseInt(value, 10)) &&
        parseInt(value, 10) >= -180 &&
        parseInt(value, 10) <= 180)
    ) {
      setGradientDegree(value);
    }
  };

  useEffect(() => {
    if (settingGradientColor) {
      updateGradient();
    }
  }, [gradientType, gradientColors, gradientDegree, colorPercentage]);

  useEffect(() => {
    if (settingGradientColor) {
      updateGradient();
    }
  }, [gradientType, gradientColors]);

  useEffect(() => {
    if (!settingGradientColor) {
      updateColor();
    }
  }, [singleColor]);

  const currentColor = settingGradientColor
    ? gradientColors[settingGradientColor]
    : singleColor;

  return (
    <Box
      sx={{
        mt: 2,
        // ml: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Top Section */}
      <Box sx={{ marginBottom: 3, display: "flex" }}>
        <Card
          sx={{
            // marginRight: 1,
            width: 370,
            display: "flex",
            flexDirection: "column",
            // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          }}
        >
          {/* CHROME PICKER Side */}
          <Box
            sx={{
              display: "flex",
              mt: 2,
              ml: 6,
              alignItems: "center",
              width: "100%",
            }}
          >
            <SketchPicker
              color={currentColor}
              onChange={handleColorChange}
              styles={{
                default: {
                  picker: {
                    boxShadow: "none",
                  },
                },
              }}
            />
          </Box>

          {/* Left Side */}
          <Box sx={{ width: "100%" }}>
            {/* <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
              <CompactPicker
                color={currentColor}
                onChange={handleColorChange}
              />
            </Box> */}
            <FormControl
              fullWidth
              variant='outlined'
              sx={{
                p: 2,
                height: "fit-content",
                color: "primary.main",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  pt: 1,
                }}
              >
                <Select
                  value={gradientType}
                  onChange={(e) => setGradientType(e.target.value)}
                  sx={{
                    // padding: "4px 2px 4px 8px",
                    height: 30,
                    lineHeight: "1.5",
                    color: "primary.main",
                    width: "40%",
                    fontSize: 12,
                  }}
                >
                  <MenuItem
                    value='linear'
                    sx={{ color: "primary.main", fontSize: 12 }}
                  >
                    Linear Gradient
                  </MenuItem>
                  <MenuItem
                    value='radial'
                    sx={{ color: "primary.main", fontSize: 12 }}
                  >
                    Radial Gradient
                  </MenuItem>
                </Select>

                <TextField
                  color='primary'
                  value={gradientDegree}
                  onChange={handleDegreeChange}
                  label='Degrees'
                  variant='outlined'
                  type='number'
                  inputProps={{
                    min: "-180",
                    max: "180",
                    step: "1",
                    style: { MozAppearance: "textfield" }, // Removes the spinner buttons for Firefox
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "primary.main",
                      padding: "8px 14px",
                      fontSize: "12px",
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          // Removes the spinner buttons for Webkit browsers like Chrome and Safari
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "30px",
                      width: "80px", // Reduced width
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>°</InputAdornment>
                    ),
                  }}
                />

                <TextField
                  color='primary'
                  value={colorPercentage}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (
                      newValue === "" ||
                      (!isNaN(parseInt(newValue, 10)) &&
                        parseInt(newValue, 10) >= 0 &&
                        parseInt(newValue, 10) <= 100)
                    ) {
                      setColorPercentage(newValue);
                    }
                  }}
                  label='Percentage'
                  variant='outlined'
                  type='number'
                  inputProps={{
                    min: "0",
                    max: "100",
                    step: "1",
                    style: { MozAppearance: "textfield" }, // Removes the spinner buttons for Firefox
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "primary.main",
                      padding: "8px 14px",
                      fontSize: "12px",
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          // Removes the spinner buttons for Webkit browsers like Chrome and Safari
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "30px",
                      width: "80px", // Reduced width
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>%</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormControl>
          </Box>
          {/* Conditional Gradient Preview */}
          {(gradientColors.start !== "#ffffff" ||
            gradientColors.end !== "#ffffff") && (
            <Box
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* Gradient Preview Box */}
              <Box
                sx={{
                  flex: 1,
                  padding: 2,
                  background: getGradientString(),
                  mb: 1,
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              // width: 505,
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            {["start", "end"].map((type) => (
              <Button
                key={type}
                variant={
                  settingGradientColor === type ? "contained" : "outlined"
                }
                onClick={() => setSettingGradientColor(type)}
                sx={{
                  width: 103,
                  height: 30,
                  fontSize: "12px", // Set the font size to 12px
                  backgroundColor:
                    settingGradientColor === type
                      ? gradientColors[type]
                      : "transparent",
                }}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)} color
              </Button>
            ))}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ColorSettingComponent;
