import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "@mui/material";

function ImageFilterModal({ onFilterChange }) {
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    image_type: [],
    orientation: "",
    people_number: "",
    ai_search: false,
    ai_objective: "",
    ai_industry: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (onFilterChange) {
      onFilterChange(filters);
    }
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleOpen} sx={{ color: "primary.main" }}>
        <FilterListIcon sx={{ mt: 1, fontSize: "1.2rem" }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="filter-modal-title">Filter Images</h2>
          {/* Image Type */}
          {/* <ToggleButtonGroup
            value={filters.image_type}
            onChange={(_, newFilters) =>
              handleFilterChange("image_type", newFilters)
            }
            aria-label="image type"
            sx={{ mt: 2 }}
          >
            <ToggleButton value="photo" aria-label="photo">
              Photo
            </ToggleButton>
            <ToggleButton value="vector" aria-label="vector">
              Vector
            </ToggleButton>
            <ToggleButton value="illustration" aria-label="illustration">
              Illustration
            </ToggleButton>
          </ToggleButtonGroup> */}
          {/* Orientation */}
          <ToggleButtonGroup
            value={filters.orientation}
            exclusive
            onChange={(_, newValue) =>
              handleFilterChange("orientation", newValue)
            }
            aria-label="orientation"
            sx={{ mt: 2 }}
          >
            <ToggleButton value="horizontal" aria-label="horizontal">
              Horizontal
            </ToggleButton>
            <ToggleButton value="vertical" aria-label="vertical">
              Vertical
            </ToggleButton>
          </ToggleButtonGroup>
          {/* People Number */}
          <TextField
            label="Number of People"
            type="number"
            value={filters.people_number}
            onChange={(e) =>
              handleFilterChange("people_number", e.target.value)
            }
            fullWidth
            sx={{ mt: 2 }}
          />
          {/* AI Search */}
          {/* <FormControlLabel
            control={
              <Switch
                checked={filters.ai_search}
                onChange={(e) =>
                  handleFilterChange("ai_search", e.target.checked)
                }
              />
            }
            label="Use AI Search"
            sx={{ mt: 2 }}
          />

          {/* AI Objective */}
          {/* <TextField
            label="AI Objective"
            value={filters.ai_objective}
            onChange={(e) => handleFilterChange("ai_objective", e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          /> */}
          {/* AI Industry */}
          {/* <TextField
            label="AI Industry"
            value={filters.ai_industry}
            onChange={(e) => handleFilterChange("ai_industry", e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />{" "} */}
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            {/* <IconButton onClick={handleSubmit} color="primary">
              <FilterListIcon />
            </IconButton> */}
            <Button variant="outlined" onClick={handleSubmit} color="primary">
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ImageFilterModal;
