export const getUserData = (values) => [
  { label: "ID", value: values?.id, property: "id" },
  { label: "First name", value: values?.firstName, property: "firstName" },
  { label: "Last name", value: values?.lastName, property: "lastName" },
  { label: "Username", value: values?.username, property: "username" },
  { label: "Password", value: values?.password || "", property: "password" },
  { label: "Email", value: values?.email, property: "email" },
];

export const getClientData = (values) => [
  {
    label: "Type",
    value: values?.type,
    property: "type",
    options: [
      { label: "Brand", value: "brand" },
      { label: "Company", value: "company" },
      { label: "Agency", value: "agency" },
    ],
  },
  { label: "Name", value: values?.name, property: "name" },
  { label: "Address", value: values?.address, property: "address" },
  { label: "Phone", value: values?.phone, property: "phone" },
  { label: "Email", value: values?.email, property: "email" },
  { label: "Website", value: values?.website, property: "website" },
];

export const getCategoryData = (values) => [
  { label: "ID", value: values?.id, property: "id" },
  { label: "Name", value: values?.name, property: "name" },
  { label: "Description", value: values?.description, property: "description" },
];
