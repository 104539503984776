import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DvrIcon from "@mui/icons-material/Dvr";
import WorkIcon from "@mui/icons-material/Work";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

function AnalyticsCard() {
  const Clients = useSelector((store) => store.clients);
  const UsersList = useSelector((store) => store.users);
  const BrandsList = useSelector((store) => store.brands);

  const theme = useTheme();

  // const [numberOfClients, setNumberOfClients] = useState(0);
  // const [numberOfUsers, setNumberOfUsers] = useState(0);
  // const [numberOfBrands, setNumberOfBrands] = useState(0);

  // useEffect(() => {
  //   setNumberOfClients(Clients.length);
  //   setNumberOfUsers(UsersList.length);
  //   setNumberOfBrands(BrandsList.length);
  // }, [Clients]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Card
          sx={{ height: 60.8, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              pt: 3,
            }}
          >
            <Box>
              <Typography
                variant='body2'
                sx={{ fontSize: 12, fontWeight: "bold", color: "#A0AEC0" }}
              >
                Number of Clients
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#2D3748", fontSize: 18, fontWeight: "bold" }}
                  component='span'
                >
                  {Clients?.length}
                </Typography>
                {/* <Typography
                  component="span"
                  sx={{ color: "#8ACC84", fontSize: 14, marginLeft: 1 }}
                >
                  +55
                </Typography> */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 35,
                height: 35,
                bgcolor: "#A06EAF",
                borderRadius: 1,
                position: "relative",
              }}
            >
              <WorkIcon
                sx={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card
          sx={{ height: 60.8, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              pt: 3,
            }}
          >
            <Box>
              <Typography
                variant='body2'
                sx={{ fontSize: 12, fontWeight: "bold", color: "#A0AEC0" }}
              >
                Number of Users
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#2D3748", fontSize: 18, fontWeight: "bold" }}
                  component='span'
                >
                  {UsersList?.length}
                </Typography>
                {/* <Typography
                  component="span"
                  sx={{ color: "#F37979", fontSize: 14, marginLeft: 1 }}
                >
                  -2
                </Typography> */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 35,
                height: 35,
                bgcolor: "#F37979",
                borderRadius: 1,
                position: "relative",
              }}
            >
              <PeopleAltIcon
                sx={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card
          sx={{ height: 60.8, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              pt: 3,
            }}
          >
            <Box>
              <Typography
                variant='body2'
                sx={{ fontSize: 12, fontWeight: "bold", color: "#A0AEC0" }}
              >
                Number of Brands
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "#2D3748", fontSize: 18, fontWeight: "bold" }}
                  component='span'
                >
                  {BrandsList?.length}
                </Typography>
                {/* <Typography
                  component="span"
                  sx={{ color: "#8ACC84", fontSize: 14, marginLeft: 1 }}
                >
                  +3
                </Typography> */}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 35,
                height: 35,
                bgcolor: "#80AFDE",
                borderRadius: 1,
                position: "relative",
              }}
            >
              <WorkspacePremiumIcon
                sx={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AnalyticsCard;
