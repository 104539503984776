import { createSlice } from "@reduxjs/toolkit";

const fontsSlice = createSlice({
  name: "fonts",
  initialState: [],
  reducers: {
    addFont: (state, action) => {
      state.push(action.payload);
    },
    setFonts: (state, action) => {
      const existingFont = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );
      state.push(...existingFont);
    },

    editFont: (state, action) => {
      const { id, name, description } = action.payload;
      const existingFont = state.find((font) => font.id === id);
      if (existingFont) {
        existingFont.name = name;
        existingFont.description = description;
      }
    },
    deleteOneFont: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((font) => font.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyFonts: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((font, index) => {
        if (idsToDelete.has(font.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateFonts: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addFont,
  setFonts,
  editFont,
  deleteOneFont,
  updateFonts,
  deleteManyFonts,
} = fontsSlice.actions;
export default fontsSlice.reducer;
