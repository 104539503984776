import React from "react";
import { Box, FormControl, TextField, InputLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Flag from "react-world-flags";
import { countryToLanguageMapping } from "../helpers/languages";

const LanguageDropdown = ({ language, setLanguage }) => {
  const languages = Object.entries(countryToLanguageMapping);
  const selectedLanguageOption = languages.find(
    ([, langObj]) => langObj.language === language
  );

  return (
    <FormControl
      variant='outlined'
      size='small'
      sx={{
        minWidth: 120,
        "& .MuiOutlinedInput-root": {
          height: "30.5px",
          minHeight: "30.5px",
          fontSize: "0.8rem",
        },
        "& .MuiInputLabel-root": {
          fontSize: "0.8rem",
        },
        "& .MuiAutocomplete-root": {
          // Additional styles if needed for Autocomplete component
        },
        "& .MuiSvgIcon-root": {
          fontSize: "1rem",
        },
      }}
    >
      <InputLabel shrink={true}>Language</InputLabel>
      <Autocomplete
        id='language-autocomplete'
        options={languages}
        getOptionLabel={(option) =>
          option && option[1] ? option[1].language : ""
        }
        value={selectedLanguageOption || null}
        onChange={(event, newValue) => {
          if (newValue && newValue[1]) {
            setLanguage(newValue[1].language);
          } else {
            setLanguage(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label='Language'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {selectedLanguageOption && (
                    <Flag code={selectedLanguageOption[1].code} height='18' />
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            sx={{
              fontSize: "0.8rem", // Adjust the font size for the input field
              "& .MuiOutlinedInput-input": {
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box {...props} display='flex' alignItems='center'>
            <Flag code={option[1].code} height='14' />
            <Box ml={1} fontSize='0.8rem'>
              {option[1].language}
            </Box>
          </Box>
        )}
      />
    </FormControl>
  );
};

export default LanguageDropdown;
