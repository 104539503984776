import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";

// Memoized debounce function using useCallback
const useDebouncedCallback = (func, delay) => {
  return useCallback(debounce(func, delay), [func, delay]);
};

const TransparencyControl = ({
  editingComponent,
  editingResolution,
  handleElementTransparencyUpdate,
  values,
}) => {
  const [transparency, setTransparency] = useState("");

  useEffect(() => {
    const opacity =
      values.positions?.[editingComponent]?.[editingResolution]?.opacity || "0";
    setTransparency(opacity);
  }, [values, editingComponent, editingResolution]);

  const debouncedHandleElementTransparencyUpdate = useDebouncedCallback(
    handleElementTransparencyUpdate,
    200
  );

  const handleChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setTransparency(value);
    debouncedHandleElementTransparencyUpdate(
      editingComponent,
      `${value}%`,
      editingResolution
    );
  };

  return (
    <Box sx={{ width: 50 }}>
      <TextField
        value={transparency.replace("%", "")}
        onChange={handleChange}
        type='text'
        sx={{
          mt: 0, // Reduced margin top
          width: "100%",
          "& .MuiInputBase-input": {
            padding: "2px 10px", // Reduced padding
            fontSize: "0.7rem", // Further reduced font size
            color: "primary.main", // Text color
          },
          "& .MuiInputLabel-root": {
            fontSize: "0.6rem", // Reduced font size for the label
            transform: "translate(16px, -4px) scale(0.75)", // Move right and adjust position and scale
            color: "primary.main", // Label color
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "primary.main", // Border color
            },
            "&:hover fieldset": {
              borderColor: "primary.main", // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "primary.main", // Border color when focused
            },
          },
          "& .MuiInputAdornment-root": {
            color: "primary.main", // Adornment color
            "& .MuiTypography-root": {
              fontSize: "0.6rem", // Font size
              color: "primary.main", // Text color
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ ml: -2 }} position='end'>
              %
            </InputAdornment>
          ),
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
    </Box>
  );
};

function debounce(func, wait, immediate) {
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default TransparencyControl;
