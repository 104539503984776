import { createSlice } from "@reduxjs/toolkit";
import isEqual from 'lodash/isEqual';

const notificationSlice = createSlice({
  name: "notifications",
  initialState: [],

  reducers: {
    addNotification: (state, action) => {
      const existingNotification = state.find(
        (notification) => notification.id === action.payload.id
      );
      if (!existingNotification) {
        state.push(action.payload);
      }
    },
    setNotifications: (state, action) => {
      return action.payload;
    },
    updateNotification: (state, action) => {
      const index = state.findIndex(
        (notificationItem) => notificationItem.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteNotification: (state, action) => {
      return state.filter(
        (notificationItem) => notificationItem.id !== action.payload.id
      );
    },
    clearNotifications: (state, action) => {
      return [];
    },
  },
});

export const {
  addNotification,
  setNotifications,
  updateNotification,
  deleteNotification,
  clearNotifications,
} = notificationSlice.actions;
export default notificationSlice.reducer;
