import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmDialog } from "../../../components/ConfirmationDialog";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ACTIONS_URL } from "../../../config";
import { useTheme } from "@mui/material/styles";

function LayoutPreview({ List, label, data, thumbnail, id, onClick }, props) {
  const [selected, setSelected] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [flipped, setFlipped] = useState(false);

  const theme = useTheme();

  // REMOVE <p> tag FROM DESCRIPTION ******************************
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const handleClick = () => {
    setFlipped(!flipped);
  };

  const navigate = useNavigate();

  const deleteAD = "delete";

  // FIND AD WITH ID === TO POSTID
  const adMap = {};

  const ad = adMap[props.id];

  const handleDelete = async (props) => {
    const adTitle = ad.title;
    const createdAt = new Date().toISOString();
    const mysqlDateTime = createdAt.slice(0, 19).replace("T", " ");

    confirmDialog("Do you really want to delete all the data?", async () => {
      try {
        await axios.delete(`/posts/${props.id}`);
        const time = new Date();
        props.socket?.emit("sendCommentNotification", {
          id: props.id,
          senderName: currentUser,
          input: adTitle,
          action: "deleted",
          time: mysqlDateTime,
        });
        await axios
          .post(ACTIONS_URL, {
            action: deleteAD,
            uid: currentUser.id,
            adTitle: adTitle,
            createdAt: mysqlDateTime,
          })
          .then((response) => {
            // do something with the response
          })
          .catch((error) => {
            // handle the error
          });
      } catch (err) {
        console.log(err);
      }
    });
  };

  // STATUS ICONS
  const getStatusText = (status) => {
    switch (status) {
      case "approved":
        return (
          <CheckCircleIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
      default:
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
    }
  };

  const StatusCell = ({ status, comment }) => {
    const [currentComment, setCurrentComment] = useState(comment);
    const [currentStatus, setCurrentStatus] = useState(status);

    const handleNewComment = (newComment) => {
      setCurrentComment(newComment);
      setCurrentStatus("changed");
    };

    return (
      <Tooltip title={currentComment}>
        <div>{getStatusText(currentStatus)}</div>
      </Tooltip>
    );
  };

  return (
    <Card onClick={handleClick}>
      {props.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CardMedia
            onClick={onClick}
            label={label}
            sx={{
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
              // p: 0,
              // position: "absolute",
              // objectFit: "fit",
              // maxWidth: 400,
              // maxHeight: 240,
            }}
            component='img'
            src={thumbnail}
          ></CardMedia>
        </>
      )}
    </Card>
  );
}

export default LayoutPreview;
