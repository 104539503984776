import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ColorInfoCard from "../../../components/ColorInfoCard";
import StyleToggleButtons from "./StyleToggleButtons ";
import FontSelectors from "./FontSelectors";
import TextAlignmentButtons from "./TextAlignmentButtons";
import ImageControls from "./ImageControls ";
import PositionDimensionControls from "./PositionDimensionControls ";
import AlignmentButtons from "./AlignmentButtons";
import { useTheme } from "@mui/material/styles";
import AiIconsGroup from "./AiIconsGroup";
import TextSpacingMenu from "./TextSpacingMenu ";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";

const EditorToolbar = ({
  editingComponent,
  values,
  setValues,
  editingResolution,
  existingAd,
  fontsFromStore,
  selectedBrand,
  bannerWidth,
  bannerHeight,
  handleElementDragResize,
  editStates,
  toggleResizeMode,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // Check if the editingComponent is one of the specified values or starts with 'text'
  const shouldShowTextControls =
    ["headline", "subHeadline", "disclaimer"].includes(editingComponent) ||
    editingComponent.startsWith("text");

  // Condition to show ImageControls
  const shouldShowImageControls =
    ["product", "background", "cta", "sticker1"].includes(editingComponent) ||
    ["line", "rectangle", "circle"].some((shape) =>
      editingComponent.startsWith(shape)
    );

  // Dynamically initialize the fontStyle state
  const initialFontStyle = (() => {
    const currentFont =
      values?.positions?.[editingComponent]?.[editingResolution]?.fontName;
    const currentStyle = currentFont ? currentFont.split("-")[1] : null;
    return currentStyle || "Regular";
  })();

  const [fontStyle, setFontStyle] = useState(initialFontStyle);

  const [letterSpacing, setLetterSpacing] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.letterSpacing ||
      1
  );
  const [lineHeight, setLineHeight] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.lineHeight ||
      1.5
  );

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  // State to track bullet list status
  const [isBulletList, setIsBulletList] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.isBulletList ||
      false
  );
  const [isOrderedList, setIsOrderedList] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.isOrderedList ||
      false
  );

  // State for fontSize
  const [fontSize, setFontSize] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.fontSize || ""
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Update states when editingComponent or editingResolution changes
  useEffect(() => {
    // Update for bullet and ordered list
    const newIsBulletList =
      values?.positions?.[editingComponent]?.[editingResolution]
        ?.isBulletList || false;
    const newIsOrderedList =
      values?.positions?.[editingComponent]?.[editingResolution]
        ?.isOrderedList || false;

    setIsBulletList(newIsBulletList);
    setIsOrderedList(newIsOrderedList);

    // Update for fontSize
    const newFontSize =
      values?.positions?.[editingComponent]?.[editingResolution]?.fontSize ||
      "";
    setFontSize(newFontSize);
  }, [editingComponent, editingResolution, values]);

  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  // useEffect to update states when editingComponent changes
  useEffect(() => {
    updateTextDecorationStates();
  }, [editingComponent, values, editingResolution]);

  const [isSuperscript, setIsSuperscript] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]
      ?.textDecoration === "superscript" || false
  ); // State to track superscript status

  // Ensure fontsFromStore is an array before filtering and reducing
  const filteredFonts = Array.isArray(fontsFromStore)
    ? fontsFromStore.filter((font) => {
        return font.brandId === existingAd.brand_id;
      })
    : [];

  const [fontName, setFontName] = useState(
    values?.positions?.[editingComponent]?.[editingResolution]?.letterSpacing
  );

  // Function to check and update textDecoration states
  const updateTextDecorationStates = () => {
    const textDecoration =
      values?.positions?.[editingComponent]?.[editingResolution]
        ?.textDecoration || "";
    setIsUnderline(textDecoration.includes("underline"));
    setIsStrikethrough(textDecoration.includes("line-through"));
  };

  // Ensure fontOptions are correctly initialized
  const fontOptions = (
    Array.isArray(filteredFonts) ? filteredFonts : []
  ).reduce((acc, font) => {
    const [family, style = "Regular"] = font.data.fileName.split(/[-.]/);
    if (!acc[family]) {
      acc[family] = new Set();
    }
    acc[family].add(style);
    return acc;
  }, {});

  // Update the font family and style based on the options
  useEffect(() => {
    // Define familyNames within the useEffect
    const familyNames = Object.keys(fontOptions);

    // Extract the current font name and style from values
    const currentFont =
      values?.positions?.[editingComponent]?.[editingResolution]?.fontName;
    let currentFamily, currentStyle;
    if (currentFont) {
      [currentFamily, currentStyle = "Regular"] = currentFont.split("-");
    }

    // Set initial font family
    const defaultFamily =
      currentFamily && familyNames.includes(currentFamily)
        ? currentFamily
        : familyNames.includes("Arial")
        ? "Arial"
        : familyNames[0];
    setFontName(defaultFamily);

    // Set initial font style
    const styles = fontOptions[defaultFamily]
      ? Array.from(fontOptions[defaultFamily])
      : [];
    const defaultStyle =
      currentStyle && styles.includes(currentStyle)
        ? currentStyle
        : styles.includes("Regular")
        ? "Regular"
        : styles[0];
    setFontStyle(defaultStyle);

    // Initialize bold and italic states based on current font weight and style
    const currentFontWeight =
      values?.positions?.[editingComponent]?.[editingResolution]?.fontWeight;
    const currentFontStyle =
      values?.positions?.[editingComponent]?.[editingResolution]?.fontStyle;

    setIsBold(currentFontWeight === "bold");
    setIsItalic(currentFontStyle === "italic");
  }, [editingComponent, editingResolution, fontOptions, values]);

  // Handle changes in font family selection
  const handleFontFamilyChange = (event) => {
    const newFamily = event.target.value;
    setFontName(newFamily);

    const styles = fontOptions[newFamily]
      ? Array.from(fontOptions[newFamily])
      : [];
    const defaultStyle =
      styles.length > 0
        ? styles.includes("Regular")
          ? "Regular"
          : styles[0]
        : "Regular";
    setFontStyle(defaultStyle);

    updateStyle(
      "fontName",
      newFamily + (defaultStyle !== "Regular" ? `-${defaultStyle}` : "")
    );
  };

  const handleFontStyleChange = (event) => {
    const newStyle = event.target.value;
    setFontStyle(newStyle);

    updateStyle(
      "fontName",
      fontName + (newStyle !== "Regular" ? `-${newStyle}` : "")
    );
  };

  const generateFontSizes = () => {
    const sizes = [];
    for (let i = 1; i <= 200; i++) {
      sizes.push(i);
    }
    return sizes;
  };

  const fontSizes = generateFontSizes();

  // Update local state and global/parent state for letterSpacing
  const handleLetterSpacingChange = (newValue) => {
    setLetterSpacing(newValue); // Update local state
    handleSpacingChange("letterSpacing", newValue); // Update global/parent state
  };

  const handleSpacingChange = (property, newValue) => {
    setValues((prevState) => ({
      ...prevState,
      positions: {
        ...prevState.positions,
        [editingComponent]: {
          ...prevState.positions[editingComponent],
          [editingResolution]: {
            ...prevState.positions[editingComponent][editingResolution],
            [property]: newValue,
          },
        },
      },
    }));
  };

  if (!shouldShowTextControls && !shouldShowImageControls) {
    return null;
  }

  const updateStyle = (property, value) => {
    setValues((prevState) => ({
      ...prevState,
      positions: {
        ...prevState.positions,
        [editingComponent]: {
          ...prevState.positions[editingComponent],
          [editingResolution]: {
            ...prevState.positions[editingComponent][editingResolution],
            [property]: value,
          },
        },
      },
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "4px",
        backgroundColor: theme.palette.primary.editorToolbarBackground,
        height: "auto",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile || isTablet ? "column" : "row",
          alignItems: "center",
          gap: 2,
          p: 2,
          flexWrap: "wrap",
        }}
      >
        {shouldShowTextControls && (
          <>
            <IconButton onClick={toggleResizeMode}>
              <PanToolAltIcon />
            </IconButton>

            <FontSelectors
              fontName={fontName}
              fontSize={fontSize}
              fontStyle={fontStyle}
              fontOptions={fontOptions}
              fontSizes={fontSizes}
              handleFontFamilyChange={handleFontFamilyChange}
              handleFontStyleChange={handleFontStyleChange}
              updateStyle={updateStyle}
            />

            <TextAlignmentButtons
              values={values}
              editingComponent={editingComponent}
              editingResolution={editingResolution}
              updateStyle={updateStyle}
            />

            <IconButton onClick={handleMenuClick}>
              <LineStyleIcon />
            </IconButton>

            <TextSpacingMenu
              anchorEl={anchorEl}
              handleClose={handleMenuClose}
              letterSpacing={letterSpacing}
              handleLetterSpacingChange={handleLetterSpacingChange}
              lineHeight={lineHeight}
              handleLineHeightChange={(newValue) =>
                handleSpacingChange("lineHeight", newValue)
              }
            />

            <Box>
              <StyleToggleButtons
                isBold={isBold}
                setIsBold={setIsBold}
                isItalic={isItalic}
                setIsItalic={setIsItalic}
                isUnderline={isUnderline}
                setIsUnderline={setIsUnderline}
                isStrikethrough={isStrikethrough}
                setIsStrikethrough={setIsStrikethrough}
                setValues={setValues}
                fontName={fontName}
                editingComponent={editingComponent}
                editingResolution={editingResolution}
                setIsSuperscript={setIsSuperscript}
                isSuperscript={isSuperscript}
                isBulletList={isBulletList}
                setIsBulletList={setIsBulletList}
                setIsOrderedList={setIsOrderedList}
                isOrderedList={isOrderedList}
              />
            </Box>
          </>
        )}

        {shouldShowImageControls && <ImageControls updateStyle={updateStyle} />}
      </Box>

      <Box
        sx={{
          display: "flex",
          flex: isTablet ? "1 1 100%" : "1 1 auto",
          width: isTablet ? "100%" : "auto",
        }}
      >
        <PositionDimensionControls
          values={values}
          setValues={setValues}
          editingComponent={editingComponent}
          editingResolution={editingResolution}
          handleElementDragResize={handleElementDragResize}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
          handleClick={handleClick}
          editStates={editStates}
        />
      </Box>
    </Box>
  );
};

export default EditorToolbar;
