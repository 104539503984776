import { createSlice } from "@reduxjs/toolkit";

const actionsSlice = createSlice({
  name: "actions",
  initialState: [],

  reducers: {
    addAction: (state, action) => {
      state.push(action.payload);
    },
    setActions: (state, action) => {
      return action.payload;
    },
    updateAction: (state, action) => {
      const index = state.findIndex(
        (actionItem) => actionItem.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteAction: (state, action) => {
      return state.filter((actionItem) => actionItem.id !== action.payload.id);
    },
    updateActions: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  addAction,
  setActions,
  updateAction,
  deleteAction,
  updateActions,
} = actionsSlice.actions;
export default actionsSlice.reducer;
