import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedValues, theme) {
  return {
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function TemplateChip({ data, setValues, label }) {
  const theme = useTheme();
  const [brandName, setBrandName] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState("");

  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const selectedBrand = data.find((brand) => brand.name === value);

    if (name === "brand") {
      setBrandName(typeof value === "string" ? value.split(",") : value);

      if (selectedBrand) {
        setValues((prevValues) => ({
          ...prevValues,
          brand_id: selectedBrand.id,
        }));
      }
    } else if (name === "type") {
      setSelectedType(selectedBrand ? selectedBrand.type : "");
      setValues((prevValues) => ({
        ...prevValues,
        type: selectedBrand.type,
      }));
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          name={label}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          value={brandName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {data.map((brand) => (
            <MenuItem
              key={brand.id}
              value={brand.name}
              style={getStyles(brand.name, brandName, theme)}
            >
              {brand.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {brandName.length > 0 && <Chip sx={{ m: 1 }} label={brandName[0]} />}
      {selectedType && <Chip sx={{ m: 1 }} label={selectedType} />}
    </Box>
  );
}
