import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Toolbar,
  Typography,
  Backdrop,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import RefreshIcon from "@mui/icons-material/Refresh";
import BarChartIcon from "@mui/icons-material/BarChart";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import axios from "axios";

import FacebookAnalyticsComponent from "./social-media/FacebookAnalyticsComponent";
import CampaignAdPreview from "./social-media/CampaignAdPreview"; // Import the new component
import { AuthContext } from "../../../context/authContext";
import AdSuggestionsFromPosts from "./social-media/AdSuggestionsFromPosts";

const ListCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState({});
  const [backdropText, setBackdropText] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showAnalytics, setShowAnalytics] = useState(null);
  const [previewAdId, setPreviewAdId] = useState(null);
  const [previewPlatform, setPreviewPlatform] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [adSuggestionsOpen, setAdSuggestionsOpen] = useState(false); // Add state for ad suggestions dialog
  const currentUser = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialFetch = useRef(false);

  useEffect(() => {
    if (!initialFetch.current) {
      fetchCampaigns();
      initialFetch.current = true;
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchCampaigns = async () => {
    setLoading(true);
    setBackdropText("Getting your Facebook campaigns...");
    try {
      const response = await axios.get("/api/facebook/list_campaigns", {
        params: { clientId: currentUser?.currentUser?.clientIdUsers },
      });
      setCampaigns(response.data.campaigns || []);
    } catch (error) {
      console.error("Failed to fetch campaigns:", error);
      const errorResponse =
        error.response?.data?.details?.error?.message ||
        "Failed to fetch campaigns";
      setErrorMessage(errorResponse);
    } finally {
      setLoading(false);
    }
  };

  const handleCampaignClick = (campaign) => {
    if (selectedCampaignId === campaign.campaignId) {
      setSelectedCampaignId(null);
    } else {
      setSelectedCampaignId(campaign.campaignId);
    }
  };

  const performAction = async (action, campaignId, actionType) => {
    setButtonLoading((prev) => ({
      ...prev,
      [`${campaignId}-${actionType}`]: true,
    }));
    try {
      await action(campaignId);
    } catch (error) {
      console.error(`Failed to ${actionType} campaign:`, error);
    } finally {
      setButtonLoading((prev) => ({
        ...prev,
        [`${campaignId}-${actionType}`]: false,
      }));
    }
  };

  const publishCampaign = async (campaignId) => {
    await axios.post("/api/facebook/publish_campaign", {
      clientId: currentUser?.currentUser?.clientIdUsers,
      campaignId,
    });
    console.log("Campaign published successfully");
    updateCampaignStatus(campaignId, "ACTIVE");
  };

  const pauseCampaign = async (campaignId) => {
    await axios.post("/api/facebook/stop_campaign", {
      clientId: currentUser?.currentUser?.clientIdUsers,
      campaignId,
    });
    console.log("Campaign paused successfully");
    updateCampaignStatus(campaignId, "PAUSED");
  };

  const deleteCampaign = async (campaignId) => {
    setLoading(true);
    setBackdropText("Deleting your campaign...");
    try {
      await axios.post("/api/facebook/delete_campaign", {
        clientId: currentUser?.currentUser?.clientIdUsers,
        campaignId,
      });
      console.log("Campaign deleted successfully");
      setCampaigns(
        campaigns.filter((campaign) => campaign.campaignId !== campaignId)
      );
    } catch (error) {
      console.error("Failed to delete campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateCampaignStatus = (campaignId, status) => {
    setCampaigns(
      campaigns.map((campaign) =>
        campaign.campaignId === campaignId ? { ...campaign, status } : campaign
      )
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleShowAnalytics = (e, campaignId) => {
    e.stopPropagation();
    setShowAnalytics((prev) => (prev === campaignId ? null : campaignId));
  };

  const handleAdPreview = (e, adId, platform) => {
    e.stopPropagation();
    console.log("Ad ID:", adId, "Platform:", platform); // Add logging
    if (adId) {
      setPreviewAdId(adId);
      setPreviewPlatform(platform);
      setPreviewOpen(true);
    } else {
      console.warn("Ad ID is undefined");
    }
  };

  const handleAdSuggestionsOpen = () => {
    setAdSuggestionsOpen(true);
  };

  const handleAdSuggestionsClose = () => {
    setAdSuggestionsOpen(false);
  };

  const renderPlatformIcons = (platforms) => {
    return platforms.map((platform) => {
      switch (platform) {
        case "facebook":
          return <FacebookIcon key={platform} style={{ color: "#4267B2" }} />;
        case "instagram":
          return <InstagramIcon key={platform} style={{ color: "#E1306C" }} />;
        default:
          return null;
      }
    });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
        <Typography variant='h6' style={{ marginLeft: 16 }}>
          {backdropText}
        </Typography>
      </Backdrop>
      {errorMessage && (
        <Box sx={{ p: 2, bgcolor: "error.main", color: "white", mb: 2 }}>
          <Typography variant='body1'>{errorMessage}</Typography>
        </Box>
      )}
      {!loading && !errorMessage && (
        <>
          <Toolbar>
            <Typography variant='h6' style={{ flexGrow: 1 }}>
              Campaigns
            </Typography>
            <IconButton color='primary' onClick={fetchCampaigns}>
              <RefreshIcon />
            </IconButton>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Campaign ID</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Objective</TableCell>
                  <TableCell>Special Ad Categories</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>Stop Time</TableCell>
                  <TableCell>Platforms</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map((campaign) => (
                  <React.Fragment key={campaign.campaignId}>
                    <TableRow
                      onClick={() => handleCampaignClick(campaign)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        {campaign.adSets[0]?.ads[0]?.imageUrl ? (
                          <img
                            src={campaign.adSets[0]?.ads[0]?.imageUrl}
                            alt={campaign.name}
                            width={100}
                          />
                        ) : (
                          "No Image"
                        )}
                      </TableCell>
                      <TableCell>{campaign.campaignId}</TableCell>
                      <TableCell>{campaign.name}</TableCell>
                      <TableCell>
                        {campaign.status === "ACTIVE" ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <PauseCircleFilledIcon style={{ color: "gray" }} />
                        )}
                      </TableCell>
                      <TableCell>{campaign.objective}</TableCell>
                      <TableCell>
                        {campaign.specialAdCategories.join(", ")}
                      </TableCell>
                      <TableCell>{formatDate(campaign.startTime)}</TableCell>
                      <TableCell>{formatDate(campaign.stopTime)}</TableCell>
                      <TableCell>
                        {renderPlatformIcons(
                          campaign.adSets[0]?.platforms || []
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={(e) => {
                            e.stopPropagation();
                            performAction(
                              publishCampaign,
                              campaign.campaignId,
                              "publish"
                            );
                          }}
                          disabled={campaign.status === "ACTIVE"}
                          style={{ marginRight: 16 }}
                        >
                          {buttonLoading[`${campaign.campaignId}-publish`] ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            "Publish"
                          )}
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={(e) => {
                            e.stopPropagation();
                            performAction(
                              pauseCampaign,
                              campaign.campaignId,
                              "pause"
                            );
                          }}
                          disabled={campaign.status === "PAUSED"}
                          style={{ marginRight: 16 }}
                        >
                          {buttonLoading[`${campaign.campaignId}-pause`] ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            "Pause"
                          )}
                        </Button>
                        <IconButton
                          onClick={(e) =>
                            handleShowAnalytics(e, campaign.campaignId)
                          }
                        >
                          <BarChartIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            performAction(
                              deleteCampaign,
                              campaign.campaignId,
                              "delete"
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {/* <Button
                          variant='outlined'
                          onClick={(e) =>
                            handleAdPreview(
                              e,
                              campaign.adSets[0]?.ads[0]?.adId,
                              campaign.adSets[0]?.platforms[0]
                            )
                          }
                          style={{ marginLeft: 16 }}
                        >
                          Preview
                        </Button>
                        <Button
                          variant='outlined'
                          onClick={handleAdSuggestionsOpen} // Button to open the ad suggestions dialog
                          style={{ marginLeft: 16 }}
                        >
                          Ad Suggestions
                        </Button> */}
                      </TableCell>
                    </TableRow>
                    {selectedCampaignId === campaign.campaignId && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Collapse in={true} timeout='auto' unmountOnExit>
                            <Box margin={1}>
                              <h3>Ad Sets for Campaign: {campaign.name}</h3>
                              <Table size='small'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Ad Set ID</TableCell>
                                    <TableCell>Ad Set Name</TableCell>
                                    <TableCell>Daily Budget</TableCell>
                                    <TableCell>Targeting</TableCell>
                                    <TableCell>Platforms</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {campaign.adSets.map((adSet) => (
                                    <TableRow key={adSet.adSetId}>
                                      <TableCell>
                                        {adSet.ads[0]?.imageUrl ? (
                                          <img
                                            src={adSet.ads[0]?.imageUrl}
                                            alt={adSet.name}
                                            width={100}
                                          />
                                        ) : (
                                          "No Image"
                                        )}
                                      </TableCell>
                                      <TableCell>{adSet.adSetId}</TableCell>
                                      <TableCell>{adSet.name}</TableCell>
                                      <TableCell>{adSet.dailyBudget}</TableCell>
                                      <TableCell>
                                        <pre>
                                          {JSON.stringify(
                                            adSet.targeting,
                                            null,
                                            2
                                          )}
                                        </pre>
                                      </TableCell>
                                      <TableCell>
                                        {renderPlatformIcons(
                                          adSet.platforms || []
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                    {showAnalytics === campaign.campaignId && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Collapse in={true} timeout='auto' unmountOnExit>
                            <Box margin={1}>
                              <FacebookAnalyticsComponent
                                campaignId={campaign.campaignId}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {/* <CampaignAdPreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        adId={previewAdId}
        platform={previewPlatform}
      />
      <AdSuggestionsFromPosts
        open={adSuggestionsOpen}
        onClose={handleAdSuggestionsClose}
        platform={previewPlatform}
      /> */}
    </div>
  );
};

export default ListCampaigns;
