import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";

export default function AdChip({ List, label, data }) {
  const [chipName, setChipName] = useState([]);
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setChipName(typeof value === "string" ? value.split(",") : value);

    // Find the selected item in the List array
    const selectedItem = List?.find((item) => item.name === value);

    // Retrieve the id of the selected item
    const id = selectedItem.id;

    data(value, label, id);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%", mb: 2 }}>
        <InputLabel sx={{ mt: -1 }} id='demo-multiple-chip-label'>
          {label}
        </InputLabel>
        <Select
          sx={{ height: 40, color: chipName ? "primary.main" : "text.primary" }}
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple={false}
          value={chipName}
          onChange={handleChange}
          input={
            <OutlinedInput
              id='select-multiple-chip'
              label='Brands'
              sx={{
                "& input": {
                  color: chipName ? "primary.main" : "text.primary",
                },
              }}
            />
          }
          renderValue={(selected) => selected}
        >
          {List.map((name) => (
            <MenuItem
              key={name.id}
              value={name.name}
              sx={{
                color: chipName.includes(name.name)
                  ? "primary.main"
                  : theme.typography.fontColor,
              }}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
