import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/app/App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/authContext";
import { io } from "socket.io-client";
import "./global.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SOCKET_URL } from "./config";
import { SocketContext } from "./context/SocketContext ";

const socket = io(SOCKET_URL);




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketContext.Provider value={socket}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </SocketContext.Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
