import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AdsMessagesCard from "../AdsMessagesCard";
import { Box } from "@mui/material";

const AdsViewSidebar = ({ open, handleDrawerToggle, socket, user, data }) => {
  return (
    <Box>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{
            mr: { sm: 2 },
            mb: { xs: 2, sm: 0 },
            display: { xs: "block", sm: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant='h6'
          noWrap
          component='div'
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          asdasdasd
        </Typography>
      </Toolbar>

      <Drawer anchor='right' open={open} onClose={handleDrawerToggle}>
        <List sx={{ pt: 8, display: "flex", flexDirection: "column" }}>
          <Box sx={{ mr: 4, ml: 2, mt: 2, width: 570 }}>
            <AdsMessagesCard socket={socket} user={user} data={data} />
          </Box>
        </List>
      </Drawer>
    </Box>
  );
};

export default AdsViewSidebar;
