import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditingComponent } from "../../../features/editingComponentSlices";
import Moveable from "react-moveable";
import { ASSETS_URL } from "../../../../../config";
import { decode } from "html-entities";

const DraggableResizableText = ({
  item,
  values,
  setValues,
  resolution,
  handleElementDragResize,
  toCamelCase,
  setEditingResolution,
  editable,
  editingResolution,
  verticalGuidelines,
  horizontalGuidelines,
  editStates,
}) => {
  const moveableRef = useRef(null);
  const targetRef = useRef(null);
  const textRef = useRef(null);
  const dispatch = useDispatch();

  const fontsFromStore = useSelector((state) => state.fonts);
  const editingComponent = useSelector((state) => state.editingComponent);

  const positionData = values.positions[item]?.[resolution] || {};
  const initialPosition = { x: positionData.x || 0, y: positionData.y || 0 };

  const [rotation, setRotation] = useState(positionData.rotation || 0);
  const [isElementEditable, setIsElementEditable] = useState(
    positionData.editable || false
  );
  const [localPosition, setLocalPosition] = useState(initialPosition);
  const [localSize, setLocalSize] = useState({
    width: positionData.width || 200,
    height: positionData.height || 200,
  });

  const [textContent, setTextContent] = useState({
    headline: positionData?.headline || "",
    subHeadline: positionData?.subHeadline || "",
    disclaimer: positionData?.disclaimer || "",
  });

  // Update local position and size when positionData changes
  useEffect(() => {
    setLocalPosition({ x: positionData.x || 0, y: positionData.y || 0 });
    setLocalSize({
      width: positionData.width || 200,
      height: positionData.height || 200,
    });
  }, [positionData.x, positionData.y, positionData.width, positionData.height]);

  // Dynamically add text1, text2, etc.
  useEffect(() => {
    const dynamicTextContent = {};
    Object.keys(values.positions[item]?.[resolution] || {}).forEach((key) => {
      if (key.startsWith("text")) {
        dynamicTextContent[key] = values.positions[item][resolution][key] || "";
      }
    });
    setTextContent((prev) => ({ ...prev, ...dynamicTextContent }));
  }, [values, item, resolution]);

  const textDecoration = positionData?.textDecoration || "";
  const textShadow = positionData?.textShadow || "none";
  const opacity = positionData?.opacity || 1;
  const isVisible = values?.positions?.[item]?.[resolution]?.visible;

  useEffect(() => {
    setRotation(positionData.rotation || 0);
  }, [positionData.rotation]);

  useEffect(() => {
    setIsElementEditable(positionData.editable || false);
  }, [positionData.editable]);

  useEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  }, [localPosition, localSize, rotation]);

  useEffect(() => {
    Object.keys(values.positions).forEach((item) => {
      const fontName = values.positions[item][resolution]?.fontName;
      const fontData = fontsFromStore.find((font) =>
        font.data.fileName.includes(fontName)
      );
      if (fontData) {
        const fontUrl = `${ASSETS_URL}/${fontData.data.fileName}`;
        createFontFace(fontName, fontUrl);
      }
    });
  }, [values, resolution, fontsFromStore]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.style.textDecoration = textDecoration;
      textRef.current.style.textShadow = textShadow;
    }
  }, [textDecoration, textShadow]);

  useEffect(() => {
    const newTextContent = {
      headline: values?.positions?.[item]?.[resolution]?.headline || "",
      subHeadline: values?.positions?.[item]?.[resolution]?.subHeadline || "",
      disclaimer: values?.positions?.[item]?.[resolution]?.disclaimer || "",
    };

    // Add dynamic text elements (text1, text2, etc.)
    Object.keys(values.positions[item]?.[resolution] || {}).forEach((key) => {
      if (key.startsWith("text")) {
        newTextContent[key] = values.positions[item][resolution][key] || "";
      }
    });

    setTextContent(newTextContent);
  }, [values, item, resolution]);

  const createFontFace = (fontName, url) => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.textContent = `
      @font-face {
        font-family: '${fontName}';
        src: url('${url}') format('truetype');
      }
    `;
    document.head.appendChild(style);
  };

  const handleElementClick = (component, resolution) => {
    dispatch(setEditingComponent(component));
    setEditingResolution(resolution);
  };

  const onDrag = ({ target, beforeTranslate }) => {
    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${rotation}deg)`;
    setLocalPosition({ x: beforeTranslate[0], y: beforeTranslate[1] });
  };

  const onResize = ({ target, width, height, drag }) => {
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
    setLocalSize({ width, height });
    setLocalPosition({
      x: drag.beforeTranslate[0],
      y: drag.beforeTranslate[1],
    });
  };

  const onResizeEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize },
      item,
      true,
      resolution
    );
  };

  const onDragEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize, rotation },
      item,
      false,
      resolution
    );
  };

  const onRotate = ({ beforeRotate }) => {
    setRotation(beforeRotate);
  };

  const onRotateEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize, rotation },
      item,
      false,
      resolution
    );
  };

  const handleBlur = () => {
    console.log("handleBlur called");

    // Retrieve the updated content from the editable div
    const updatedContent = targetRef.current.innerText || "";
    console.log("Updated content from editable div:", updatedContent);

    const newText = { ...textContent, [editingComponent]: updatedContent };

    console.log("New text content before calling updateTextContent:", newText);
    console.log("Editing Component:", editingComponent);

    updateTextContent(editingComponent, newText);

    handleElementDragResize(
      null,
      { ...localPosition, ...localSize, ...newText },
      item,
      false,
      resolution
    );
  };

  const handleInput = (item, e) => {
    console.log("handleInput called for item:", item);
    const textElement = textRef.current;
    if (textElement) {
      const { offsetWidth, offsetHeight } = textElement;
      console.log("Text element size:", { offsetWidth, offsetHeight });

      if (
        offsetWidth !== localSize.width ||
        offsetHeight !== localSize.height
      ) {
        setLocalSize({ width: offsetWidth, height: offsetHeight });
        setValues((prevValues) => ({
          ...prevValues,
          positions: {
            ...prevValues.positions,
            [item]: {
              ...prevValues.positions[item],
              [resolution]: {
                ...prevValues.positions[item][resolution],
                width: offsetWidth,
                height: offsetHeight,
              },
            },
          },
        }));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.execCommand("insertLineBreak");
    }
  };

  const updateTextContent = (item, newText) => {
    console.log("updateTextContent called with:", { item, newText });

    if (!item || !newText) {
      console.log("Skipping updateTextContent due to missing data");
      return;
    }

    setValues((prevValues) => {
      const updatedPositions = { ...prevValues.positions };

      if (editStates["1200x630"] === false) {
        // Update only the editing component in the editing resolution
        updatedPositions[item] = {
          ...updatedPositions[item],
          [editingResolution]: {
            ...updatedPositions[item][editingResolution],
            [item]: newText[item],
          },
        };
      } else {
        // Update all resolutions
        Object.keys(updatedPositions).forEach((key) => {
          Object.keys(updatedPositions[key]).forEach((res) => {
            if (updatedPositions[key][res][item] !== undefined) {
              updatedPositions[key][res][item] = newText[item];

              // Log for headline in resolution 1200x630
              if (item === "headline" && res === "1200x630") {
                console.log("Updating headline for 1200x630:", {
                  resolution: res,
                  updatedValue: updatedPositions[key][res][item],
                });
              }
            }
          });
        });
      }

      console.log("Updated positions:", updatedPositions);

      return { ...prevValues, positions: updatedPositions };
    });

    setTextContent(newText);
  };

  const borderStyle = positionData?.borderStyle || "1px solid transparent";
  const zIndex = positionData?.zIndex || 1;
  const isCurrentEditingComponent = editingComponent === item;

  const isTextElement =
    ["headline", "subHeadline", "disclaimer"].includes(item) ||
    item.startsWith("text");

  const renderTextContent = (content) => {
    if (!content) return null; // Do not render if the content is empty

    if (positionData.isBulletList) {
      return (
        <ul>
          {content.split("\n").map((line, index) => (
            <li key={index}>{decode(line)}</li>
          ))}
        </ul>
      );
    } else if (positionData.isOrderedList) {
      return (
        <ol>
          {content.split("\n").map((line, index) => (
            <li key={index}>{decode(line)}</li>
          ))}
        </ol>
      );
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.split("\n").map(decode).join("<br />"),
          }}
        />
      );
    }
  };

  return (
    isTextElement &&
    isVisible &&
    textContent[item] && ( // Ensure only non-empty content is rendered
      <>
        {isCurrentEditingComponent && resolution === editingResolution && (
          <Moveable
            ref={moveableRef}
            target={targetRef.current}
            keepRatio={false}
            draggable={isElementEditable}
            resizable={isElementEditable}
            rotatable={isElementEditable}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onResize={onResize}
            onResizeEnd={onResizeEnd}
            onRotate={onRotate}
            onRotateEnd={onRotateEnd}
            snappable={true}
            snapThreshold={10}
            snapDirections={{
              left: true,
              top: true,
              right: true,
              bottom: true,
              center: true,
              middle: true,
            }}
            verticalGuidelines={verticalGuidelines}
            horizontalGuidelines={horizontalGuidelines}
            elementGuidelines={[]}
            snapRotationThreshold={5}
            snapRotationDegrees={[0, 30, 60, 90, 120, 150, 180, 210, 240, 270]}
            style={{
              position: "absolute",
              width: `${localSize.width}px`,
              height: `${localSize.height}px`,
              transform: `translate(${localPosition.x}px, ${localPosition.y}px) rotate(${rotation}deg)`,
              zIndex: zIndex,
              boxSizing: "border-box",
            }}
            onClick={() => handleElementClick(item, resolution)}
          />
        )}
        <div
          ref={targetRef}
          contentEditable={true}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          style={{
            position: "absolute",
            transform: `translate(${localPosition.x}px, ${localPosition.y}px) rotate(${rotation}deg)`,
            zIndex: zIndex,
            boxSizing: "border-box",
            border: borderStyle, // Apply border here
            opacity: opacity,
            whiteSpace: "pre-wrap",
            lineHeight: "normal",
            color: positionData?.textColor || "black",
            fontSize: positionData?.fontSize || "16px",
            fontWeight: positionData?.fontWeight || "normal",
            textAlign: positionData?.textAlign || "left",
            fontFamily: positionData?.fontName || "Arial",
            lineHeight: positionData?.lineHeight || "normal",
            letterSpacing: positionData?.letterSpacing || "normal",
            textDecoration: textDecoration,
            textShadow: textShadow,
          }}
          suppressContentEditableWarning={true}
          onInput={(e) => handleInput(item, e)}
          onClick={() => handleElementClick(item, resolution)}
        >
          {renderTextContent(textContent[item])}
        </div>
      </>
    )
  );
};

export default DraggableResizableText;
