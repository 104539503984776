import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS_URL, COMMENTS_URL } from "../../config";
import { addComment, setComments } from "./features/commentSlice";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";
import { useTheme } from "@mui/material/styles";

export default function AdsMessagesCard({ existingAd }) {
  const { currentUser } = useContext(AuthContext);
  const theme = useTheme();

  const socket = useContext(SocketContext);
  const UsersList = useSelector((store) => store.users);
  const CommentsList = useSelector((store) => store.comments);

  const [notifications, setNotificationData] = useState([]);
  const [input, setInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMention, setShowMention] = useState(false);
  const [mentionInput, setMentionInput] = useState("");

  const location = useLocation();
  const adId = location.pathname.split("/")[2];

  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("getCommentNotification", (data) => {
      setNotificationData(data);
    });
  }, [socket]);

  const fetchData = () => {
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(`${COMMENTS_URL}/${adId}`);
        dispatch(setComments(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [adId, notifications]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const targetUserId = selectedUser ? selectedUser.id : null;

    const commentData = {
      comments: input,
      ad_id: +adId,
      ad_uid: +currentUser.id,
      action: "commented",
      type: "COMMENT",
    };

    axios
      .post(COMMENTS_URL, commentData)
      .then((response) => {
        dispatch(addComment({ ...response.data, id: response.data.id }));
        socket?.emit("sendCommentNotification", {
          id: response.data.id,
          adId,
          senderName: currentUser,
          reciverName: "asdasd",
          input: commentData.comments,
          action: "commented",
          time: new Date().toISOString(),
          type: "COMMENT",
        });
        axios
          .post(ACTIONS_URL, {
            actionType: "Create",
            objectType: "Message",
            userId: currentUser.id,
            targetUserId: targetUserId || null,
            clientId: currentUser.clientIdUsers,
            content: response.data,
          })
          .catch((err) => {
            console.log("Error sending the ACTION:", err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    setInput("");
    setSelectedUser(null);
  };

  const handleUserSelect = (event, value) => {
    const selected = UsersList.find((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      return fullName === value;
    });
    setSelectedUser(selected);
  };

  const handleInputChange = (event, value) => {
    setMentionInput(value);
    setShowMention(value.includes("@"));
  };

  // Sort comments by date
  const sortedComments = [...CommentsList].sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  // Get the last comment and the user who made it
  const lastComment = sortedComments[0];
  const lastUser = UsersList?.find((user) => user.id === lastComment?.ad_uid);

  // Get the date of the last comment
  const lastCommentdate = new Date(lastComment?.createdAt);

  // Group sorted comments by date
  const groupedComments = sortedComments.reduce(
    (acc, { createdAt, ...rest }) => {
      const dateStr = new Date(createdAt).toLocaleDateString(); // Convert the date to a string format
      if (!acc[dateStr]) acc[dateStr] = [];
      acc[dateStr].push({ createdAt, ...rest });
      return acc;
    },
    {}
  );

  return (
    <Box sx={{ width: "100%", minWidth: 350, ml: -2 }}>
      <Grid
        sx={{
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.10)",
          width: "100%",
          backgroundColor: "background.paper",
          p: 2,
        }}
      >
        <CardHeader
          avatar={<Avatar aria-label='recipe'></Avatar>}
          action={<Box display='flex' justifyContent='space-evenly'></Box>}
          title={
            lastUser?.id === currentUser.id
              ? "You sent a message!"
              : lastUser?.firstName
              ? "You got a message from: " + lastUser.firstName
              : "You have no new messages"
          }
          subheader={lastUser?.firstName ? "Created at " + lastCommentdate : ""}
        />
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={mentionInput}
            inputValue={input} // Control the input value
            id='user-search'
            freeSolo
            options={
              showMention
                ? UsersList.map((user) => `${user.firstName} ${user.lastName}`)
                : []
            }
            filterOptions={(options, params) => {
              const filtered = options.filter((option) => {
                if (params.inputValue.includes("@")) {
                  const lastAt = params.inputValue.lastIndexOf("@");
                  const query = params.inputValue.slice(lastAt + 1);
                  return option.toLowerCase().includes(query.toLowerCase());
                }
                return true;
              });
              return filtered;
            }}
            onInputChange={handleInputChange}
            onChange={(event, value) => {
              handleUserSelect(event, value);
              setInput(""); // Reset input value after user selection
            }}
            renderInput={(params) => (
              <TextField
                value={input}
                onChange={(e) => setInput(e.target.value)}
                {...params}
                variant='outlined'
                fullWidth
                placeholder={input === "" ? "type @ to select receiver" : ""}
                sx={{
                  marginTop: 1,
                }}
              />
            )}
          />

          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <Button type='submit' variant='contained' color='primary'>
              Send
            </Button>
            {selectedUser && (
              <Chip
                sx={{}}
                label={`@${selectedUser.firstName} ${selectedUser.lastName}`}
              />
            )}
          </Box>
        </form>

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <CardActions disableSpacing></CardActions>
          {Object.keys(groupedComments).map((dateStr) => (
            <div key={dateStr}>
              <Divider
                sx={{ fontSize: 12, color: theme.palette.primary.divider }}
              >
                {dateStr}
              </Divider>
              {groupedComments[dateStr].map(
                ({ comments, createdAt, ad_uid }, index) => {
                  const user = UsersList?.find((user) => user.id === ad_uid);
                  const date = new Date(createdAt);
                  const timeFormatted = date.toLocaleTimeString();
                  const isCurrentUser = user?.id === currentUser.id;

                  function MyAvatar() {
                    return (
                      <Avatar
                        sx={{
                          maxWidth: 30,
                          maxHeight: 30,
                          backgroundColor: theme.palette.third.main,
                          fontSize: 14,
                        }}
                      >
                        {user?.firstName.slice(0, 1)}
                        {user?.lastName.slice(0, 1)}
                      </Avatar>
                    );
                  }

                  return (
                    <ListItem
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                      key={index}
                    >
                      {isCurrentUser ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: 3,
                              backgroundColor:
                                theme.palette.primary.messagesBackground,
                              width: "65%",
                              p: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                color: theme.palette.primary.messagesText,
                                textAlign: "right",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {comments}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 12,
                                color: theme.palette.primary.divider,
                                position: "absolute",
                                bottom: -8,
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                ml: 14,
                              }}
                            >
                              {timeFormatted}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <MyAvatar
                            sx={{ position: "absolute", top: -10, left: -10 }}
                          />
                          <Box
                            sx={{
                              borderRadius: 3,
                              backgroundColor:
                                theme.palette.primary.messagesBackground1,
                              width: "65%",
                              p: 2,
                              ml: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                color: theme.palette.primary.messagesText,
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                textAlign: "left",
                              }}
                            >
                              {comments}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 12,
                                color: theme.palette.primary.divider,
                                position: "absolute",
                                bottom: -8,
                                ml: -2,
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {timeFormatted}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </ListItem>
                  );
                }
              )}
            </div>
          ))}
        </List>
      </Grid>
    </Box>
  );
}
