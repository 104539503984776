import React, { useState, useEffect, useContext } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Backdrop,
  Button,
} from "@mui/material";
import axios from "axios";
import { AuthContext } from "../../../../context/authContext";

const FacebookAnalyticsComponent = ({ campaignId }) => {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backdropText, setBackdropText] = useState("");
  const currentUser = useContext(AuthContext);

  useEffect(() => {
    fetchCampaignInsights();
  }, [campaignId]);

  const fetchCampaignInsights = async () => {
    setLoading(true);
    setBackdropText("Getting campaign insights...");
    try {
      const response = await axios.get("/api/facebook/campaign_insights", {
        params: {
          clientId: currentUser?.currentUser?.clientIdUsers,
          campaignId,
        },
      });
      console.log("Campaign Insights:", response.data);
      setInsights(response.data);
    } catch (error) {
      console.error("Failed to fetch campaign insights:", error);
    } finally {
      setLoading(false);
    }
  };

  const simulateInteractions = async () => {
    setLoading(true);
    setBackdropText("Simulating ad interactions...");
    try {
      const response = await axios.post(
        "/api/facebook/simulate_ad_interactions",
        {
          clientId: currentUser?.currentUser?.clientIdUsers,
          adId: campaignId, // Use appropriate ID
          impressions: 1000,
          clicks: 50,
          spend: 1000,
        }
      );
      console.log("Simulated interactions:", response.data);
      setInsights(response.data);
    } catch (error) {
      console.error("Failed to simulate ad interactions:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
        <Typography variant='h6' style={{ marginLeft: 16 }}>
          {backdropText}
        </Typography>
      </Backdrop>
      <Button
        variant='contained'
        color='primary'
        onClick={simulateInteractions}
      >
        Simulate Interactions
      </Button>
      {insights && insights.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insights.map((insight, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>Impressions</TableCell>
                    <TableCell>{insight.impressions}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Clicks</TableCell>
                    <TableCell>{insight.clicks}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spend</TableCell>
                    <TableCell>${insight.spend}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Click-through Rate (CTR)</TableCell>
                    <TableCell>{insight.ctr}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cost per Click (CPC)</TableCell>
                    <TableCell>${insight.cpc}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cost per Thousand Impressions (CPM)</TableCell>
                    <TableCell>${insight.cpm}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Reach</TableCell>
                    <TableCell>{insight.reach}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Frequency</TableCell>
                    <TableCell>{insight.frequency}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conversions</TableCell>
                    <TableCell>{insight.conversions}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conversion Rate</TableCell>
                    <TableCell>{insight.conversionRate}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cost per Conversion</TableCell>
                    <TableCell>${insight.costPerConversion}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Engagement Rate</TableCell>
                    <TableCell>{insight.engagementRate}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Age Group - 18-24</TableCell>
                    <TableCell>
                      Impressions: {insight.ageGroup["18-24"].impressions},
                      Clicks: {insight.ageGroup["18-24"].clicks}, Spend: $
                      {insight.ageGroup["18-24"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Age Group - 25-34</TableCell>
                    <TableCell>
                      Impressions: {insight.ageGroup["25-34"].impressions},
                      Clicks: {insight.ageGroup["25-34"].clicks}, Spend: $
                      {insight.ageGroup["25-34"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Age Group - 35-44</TableCell>
                    <TableCell>
                      Impressions: {insight.ageGroup["35-44"].impressions},
                      Clicks: {insight.ageGroup["35-44"].clicks}, Spend: $
                      {insight.ageGroup["35-44"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location - US</TableCell>
                    <TableCell>
                      Impressions: {insight.location["US"].impressions}, Clicks:{" "}
                      {insight.location["US"].clicks}, Spend: $
                      {insight.location["US"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location - CA</TableCell>
                    <TableCell>
                      Impressions: {insight.location["CA"].impressions}, Clicks:{" "}
                      {insight.location["CA"].clicks}, Spend: $
                      {insight.location["CA"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Device Type - Mobile</TableCell>
                    <TableCell>
                      Impressions: {insight.deviceType["mobile"].impressions},
                      Clicks: {insight.deviceType["mobile"].clicks}, Spend: $
                      {insight.deviceType["mobile"].spend}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Device Type - Desktop</TableCell>
                    <TableCell>
                      Impressions: {insight.deviceType["desktop"].impressions},
                      Clicks: {insight.deviceType["desktop"].clicks}, Spend: $
                      {insight.deviceType["desktop"].spend}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default FacebookAnalyticsComponent;
