import { createSlice } from "@reduxjs/toolkit";

export const zIndexesSlice = createSlice({
  name: "zIndexes",
  initialState: {},
  reducers: {
    setZIndexes: (state, action) => {
      return action.payload;
    },
  },
});

export const { setZIndexes } = zIndexesSlice.actions;
export default zIndexesSlice.reducer;
