import { useContext, useEffect, useState } from "react";
import { Card, Grid, Typography, Box, Avatar } from "@mui/material";
import { SocketContext } from "../../../context/SocketContext ";
import { AuthContext } from "../../../context/authContext";
import { useTheme } from "@mui/material/styles";

function ActiveUsers({ users, currentUser }) {
  const socket = useContext(SocketContext);
  const { onlineUsers, setOnlineUsers } = useContext(AuthContext);
  const theme = useTheme();

  useEffect(() => {
    // Notify server of new user if the current user is not in the online users list
    if (currentUser && socket && !onlineUsers.includes(currentUser.id)) {
      socket.emit("userLogin", currentUser); // Changed to userLogin
    }

    // Listen for online users
    socket.on("onlineUsers", (users) => {
      const onlineUserIds = users.map((u) => u.user.id);
      setOnlineUsers(onlineUserIds);
    });

    // Cleanup the listener
    return () => {
      socket.off("onlineUsers");
    };
  }, [currentUser, socket]); // Removed onlineUsers from dependency array

  const relevantUsers = users.filter(
    (user) => user.clientIdUsers === currentUser.clientIdUsers
  );

  // Sort users to keep online ones at the top
  const sortedUsers = [...relevantUsers].sort(
    (a, b) => onlineUsers.includes(b.id) - onlineUsers.includes(a.id)
  );
  return (
    <Card
      sx={{
        display: "flex",
        // mt: 2,
        pr: 1,
        width: "100%",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Grid sx={{ m: 2 }} container direction='column' spacing={2}>
        <Grid item>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
              color: theme.palette.primary.tableLabelMain,
            }}
          >
            Active Users
          </Typography>
          <Box
            sx={{ borderBottom: "1px solid #D9D9D9", width: "100%", my: 1 }}
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent='space-between' sx={{ pl: 0 }}>
            <Typography
              variant='h6'
              sx={{
                fontSize: 12,
                fontWeight: "semiBold",
                color: "primary.main",
              }}
            >
              USER NAME
            </Typography>
            <Typography
              variant='h6'
              sx={{
                fontSize: 12,
                fontWeight: "semiBold",
                color: "primary.main",
                mr: 2,
              }}
            >
              ONLINE
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          {sortedUsers.map((user, index) => (
            <Card
              key={index}
              sx={{
                height: 92,
                borderTop: "1px solid #D9D9D9",
                mb: 2,
                p: 1,
                display: "flex",
                alignItems: "center",
                opacity: onlineUsers.includes(user.id) ? 1 : 0.5,
              }}
            >
              <Avatar sx={{ height: 50, width: 50, marginRight: 2 }} />
              <Typography
                sx={{
                  flexGrow: 1,
                  fontSize: 14,
                  color: theme.typography.fontColor,
                }}
              >
                {user.username}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  ml: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    color: theme.typography.fontColor,
                    marginRight: 2,
                  }}
                >
                  {user.lastOnline}
                </Typography>
                <Box
                  sx={{
                    mr: 2.5,
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    backgroundColor: onlineUsers.includes(user.id)
                      ? "green"
                      : "gray",
                  }}
                />
              </Box>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}

export default ActiveUsers;
