import { createSlice } from "@reduxjs/toolkit";

const badgesSlice = createSlice({
  name: "badges",
  initialState: [],
  reducers: {
    addBadge: (state, action) => {
      state.push(action.payload);
    },
    setBadges: (state, action) => {
      // Corrected the reducer name here
      // Replace the entire state with the new badges data
      return action.payload.res;
    },
    editBadge: (state, action) => {
      const badgeToUpdate = action.payload;
      const index = state.findIndex((badge) => badge.id === badgeToUpdate.id);
      if (index !== -1) {
        state[index] = badgeToUpdate; // Update the entire badge object
      }
    },

    deleteOneBadge: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((badge) => badge.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyBadges: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((badge) => {
        if (idsToDelete.has(badge.id)) {
          const index = state.findIndex((b) => b.id === badge.id);
          if (index !== -1) {
            state.splice(index, 1);
          }
        }
      });
    },
    updateBadges: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addBadge,
  setBadges,
  editBadge,
  deleteOneBadge,
  updateBadges,
  deleteManyBadges,
} = badgesSlice.actions;

export default badgesSlice.reducer;
