import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

const BorderControl = ({
  handleToggleBorder,
  editingComponent,
  editingResolution,
  values,
}) => {
  const [borderColor, setBorderColor] = useState("#000000");
  const [borderType, setBorderType] = useState("solid");
  const [borderThickness, setBorderThickness] = useState("1px");

  useEffect(() => {
    const borderStyle =
      values.positions[editingComponent][editingResolution]?.borderStyle;
    if (borderStyle && borderStyle !== "none") {
      const [thickness, type, color] = borderStyle.split(" ");
      setBorderThickness(thickness);
      setBorderType(type);
      setBorderColor(color);
    }
  }, [editingComponent, editingResolution, values.positions]);

  const handleThicknessChange = (event) => {
    setBorderThickness(event.target.value);
  };

  const updateBorder = () => {
    let finalThickness = borderThickness;
    // Check if the input is a number and doesn't already include 'px'
    if (!isNaN(finalThickness) && !finalThickness.includes("px")) {
      finalThickness += "px";
    }
    handleToggleBorder(
      editingComponent,
      finalThickness,
      borderType,
      borderColor,
      true
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Border Type</InputLabel>
        <Select
          value={borderType}
          label='Border Type'
          onChange={(e) => setBorderType(e.target.value)}
          sx={{ mb: 2 }}
        >
          <MenuItem value='solid'>Solid</MenuItem>
          <MenuItem value='dotted'>Dotted</MenuItem>
          <MenuItem value='dashed'>Dashed</MenuItem>
          <MenuItem value='double'>Double</MenuItem>
          <MenuItem value='groove'>Groove</MenuItem>
          <MenuItem value='ridge'>Ridge</MenuItem>
          <MenuItem value='inset'>Inset</MenuItem>
          <MenuItem value='outset'>Outset</MenuItem>
          <MenuItem value='none'>None</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label='Border Thickness'
        value={borderThickness}
        onChange={handleThicknessChange}
        sx={{ mb: 2 }}
      />

      <TextField
        label='Border Color'
        type='color'
        value={borderColor}
        onChange={(e) => setBorderColor(e.target.value)}
        sx={{ mb: 2 }}
      />

      <Button variant='contained' onClick={updateBorder}>
        Update Border
      </Button>
    </Box>
  );
};

export default BorderControl;
