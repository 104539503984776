// src/features/backgroundsReducer.js
import { createSlice } from "@reduxjs/toolkit";

const backgroundsSlice = createSlice({
  name: "backgrounds",
  initialState: [],
  reducers: {
    addBackground: (state, action) => {
      state.push(action.payload);
    },
    setBackgrounds: (state, action) => {
      const existingBackground = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );
      state.push(...existingBackground);
    },
    editBackground: (state, action) => {
      const { id, data } = action.payload;
      const existingBackground = state.find(
        (background) => background.id === id
      );
      if (existingBackground) {
        existingBackground.data = data;
      }
    },
    deleteOneBackground: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((background) => background.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyBackgrounds: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((background, index) => {
        if (idsToDelete.has(background.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateBackgrounds: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addBackground,
  setBackgrounds,
  editBackground,
  deleteOneBackground,
  updateBackgrounds,
  deleteManyBackgrounds,
} = backgroundsSlice.actions;
export default backgroundsSlice.reducer;
