import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ConditionalImage } from "../banners/pages/components/ConditionalImage";
import { boxStyle, textStyle, imageContainStyle } from "./adStylesPreview";
import { ASSETS_URL } from "../../../config";

const AdBannerPreview1200x630 = React.forwardRef(
  ({ data, visibility, resolution = "1200x630" }, ref) => {
    const [adsData, setAdsData] = useState(null);
    const [textStyles, setTextStyles] = useState({});
    const [state, setState] = useState({});

    const [bannerWidth, bannerHeight] = resolution.split("x").map(Number);

    useEffect(() => {
      if (adsData) {
        const keys = Object.keys(adsData);
        if (
          keys.includes("headline") ||
          keys.includes("subHeadline") ||
          keys.includes("disclaimer") ||
          keys.some((key) => key.startsWith("text"))
        ) {
          setTextStyles({
            headline: textStyle(adsData, "headline", resolution),
            subHeadline: textStyle(adsData, "subHeadline", resolution),
            disclaimer: textStyle(adsData, "disclaimer", resolution),
            ...keys
              .filter((key) => key.startsWith("text"))
              .reduce((acc, key) => {
                acc[key] = textStyle(adsData, key, resolution);
                return acc;
              }, {}),
          });
        }
      }
    }, [adsData, resolution]);

    useEffect(() => {
      if (data && data.positions) {
        try {
          const parsedData = data.positions;
          setAdsData(parsedData);
        } catch (error) {
          console.error("Unable to parse data.positions", error);
        }
      }
    }, [data]);

    useEffect(() => {
      if (adsData) {
        const items = [
          "product",
          "headline",
          "subHeadline",
          "logoBrand1",
          "logoBrand2",
          "logoBrand3",
          "logoThirdParty",
          "badge",
          "sticker1",
          "disclaimer",
          ...Object.keys(adsData).filter((key) => key.startsWith("text")),
          ...Object.keys(adsData).filter((key) => key.startsWith("background")),
          ...Object.keys(adsData).filter((key) => key.startsWith("circle")),
          ...Object.keys(adsData).filter((key) => key.startsWith("rectangle")),
          ...Object.keys(adsData).filter((key) => key.startsWith("line")),
        ];
        const state = items.reduce((acc, item) => {
          const elementData = adsData?.[item]?.[resolution];
          acc[`${item}Position`] = {
            x: elementData?.x,
            y: elementData?.y,
          };
          if (
            item !== "headline" &&
            item !== "subHeadline" &&
            item !== "disclaimer" &&
            !item.startsWith("text")
          ) {
            acc[`${item}Size`] = {
              width: elementData?.width,
              height: elementData?.height,
            };
          }
          return acc;
        }, {});
        setState(state);
      }
    }, [adsData, resolution]);

    useEffect(() => {
      if (data && data.positions) {
        setAdsData(data.positions);
      }
    }, [data]);

    const renderElement = (elementKey) => {
      const elementData = adsData?.[elementKey]?.[resolution];
      if (!elementData || !elementData.visible) return null;

      const style = {
        position: "absolute",
        left: `${elementData.x}px`,
        top: `${elementData.y}px`,
        width: `${elementData.width}px`,
        height: `${elementData.height}px`,
        background: elementData.color, // Gradient
        borderRadius: elementKey.includes("circle") ? "50%" : "0",
        border: elementData.border,
        zIndex: 1,
      };

      return <Box sx={style} />;
    };

    const renderConditionalImage = (item) => {
      const logoData = adsData?.[item]?.[resolution];
      if (!logoData || !logoData.visible) return null;
      return (
        <ConditionalImage
          key={item}
          style={imageContainStyle(
            state[`${item}Position`]?.x,
            state[`${item}Position`]?.y,
            state[`${item}Size`]?.height
          )}
          defaultSrc={data?.[item]}
          altSrc={`${ASSETS_URL}/${logoData?.imageUrl}`}
        />
      );
    };

    return (
      <Box
        ref={ref}
        sx={
          adsData?.background?.[resolution]?.showImage === undefined ||
          (adsData?.background?.[resolution]?.showImage === true &&
            adsData?.background?.[resolution]?.visible === true)
            ? {
                ...boxStyle(bannerWidth, bannerHeight),
                backgroundImage: `url(${
                  adsData?.background?.[resolution]?.imageUrl
                    ? `${ASSETS_URL}/${adsData?.background?.[resolution]?.imageUrl}`
                    : data?.background
                })`,
                backgroundColor: adsData?.background?.[resolution]?.color,
                backgroundSize: `${state.backgroundSize?.width}px ${state.backgroundSize?.height}px`,
                backgroundPosition: `${state.backgroundPosition?.x}px ${state.backgroundPosition?.y}px`,
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
              }
            : {
                ...boxStyle(bannerWidth, bannerHeight),
                background: adsData?.background?.[resolution]?.color,
                backgroundSize: `${state.backgroundSize?.width}px ${state.backgroundSize?.height}px`,
                backgroundPosition: `${state.backgroundPosition?.x}px ${state.backgroundPosition?.y}px`,
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
              }
        }
      >
        {[
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("background")
          ),
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("circle")
          ),
          ...Object.keys(adsData || {}).filter((key) =>
            key.startsWith("rectangle")
          ),
          ...Object.keys(adsData || {}).filter((key) => key.startsWith("line")),
        ].map(renderElement)}
        {[
          "logoBrand1",
          "logoBrand2",
          "logoBrand3",
          "logoThirdParty",
          "badge",
          "sticker1",
        ].map(renderConditionalImage)}
        {[
          "headline",
          "subHeadline",
          "disclaimer",
          ...Object.keys(adsData || {}).filter((key) => key.startsWith("text")),
        ].map((item) => {
          const elementData = adsData?.[item]?.[resolution];
          if (!elementData || !elementData.visible) return null;

          const text = elementData[item] || "";

          return (
            <Box
              key={item}
              sx={{
                position: "absolute",
                top: state[`${item}Position`]?.y || "auto",
                left: state[`${item}Position`]?.x || "auto",
                color: textStyles[item]?.color,
                letterSpacing: textStyles[item]?.letterSpacing,
                lineHeight: textStyles[item]?.lineHeight,
                textAlign: textStyles[item]?.textAlign,
                fontSize: textStyles[item]?.fontSize,
                fontFamily: textStyles[item]?.fontName,
                display: textStyles[item]?.display,
                maxWidth: textStyles[item]?.maxWidth,
                wordWrap: textStyles[item]?.wordWrap,
                overflowWrap: textStyles[item]?.overflowWrap,
                whiteSpace: textStyles[item]?.whiteSpace,
                zIndex: textStyles[item]?.zIndex,
              }}
              dangerouslySetInnerHTML={{
                __html:
                  typeof text === "string"
                    ? text.replace(/<\/?p[^>]*>/g, "<br/>")
                    : "",
              }}
            ></Box>
          );
        })}

        <Box bounds='parent'>
          <ConditionalImage
            style={{
              position: "relative",
              top: state?.productPosition?.y,
              left: state?.productPosition?.x,
              width: state?.productSize?.width,
              height: state?.productSize?.height,
              objectFit: "contain",
              pointerEvents: "none",
            }}
            altSrc={`${ASSETS_URL}/${adsData?.product?.[resolution]?.imageUrl}`}
          />
        </Box>
      </Box>
    );
  }
);

export default AdBannerPreview1200x630;
