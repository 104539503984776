export const boxStyle = (bannerWidth, bannerHeight) => ({
  position: "relative",
  width: bannerWidth || 0,
  height: bannerHeight || 0,
  backgroundColor: "#fff",
  overflow: "hidden",
  color: "#fff",
  zIndex: 0,
  boxSizing: "border-box", // Add this line
});

export const absoluteFullCoverStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

export const imageContainStyle = (x, y, height, width) => ({
  position: "absolute",
  top: `${y}px`,
  left: `${x}px`,
  width: `${width}px`,
  height: `${height}px`,
  objectFit: "contain",
  maxHeight: "80px",
  zIndex: 1,
});

export const textStyle = (adData, type, resolution) => {
  const resData = adData?.[type]?.[resolution];
  return {
    position: "absolute",
    color: resData?.textColor,
    letterSpacing: resData?.letterSpacing,
    lineHeight: resData?.lineHeight,
    textAlign: resData?.textAlign,
    fontSize: resData?.fontSize,
    fontFamily: resData?.fontName,
    display: "inline-block",
    maxWidth: "100%",
    wordWrap: "break-word",
    overflowWrap: "anywhere",
    whiteSpace: "pre-line",
    zIndex: 2,
  };
};

export const textStyleEdit = (positionData) => {
  const validMaxHeight =
    isNaN(positionData.lineHeight) || positionData.lineHeight === undefined
      ? "10%"
      : `${positionData.lineHeight}%`;

  const textShadow =
    positionData.textShadow && positionData.textShadow !== "none"
      ? positionData.textShadow
      : "none";

  let style = {
    position: "relative",
    letterSpacing: positionData.letterSpacing,
    lineHeight: positionData?.lineHeight,
    textAlign: positionData.textAlign,
    fontSize: positionData.fontSize,
    fontFamily: positionData.fontName,
    fontWeight: positionData.fontWeight,
    maxHeight: validMaxHeight,
    wordWrap: "break-word",
    overflowWrap: "anywhere",
    // zIndex: positionData.zIndex,
    border: positionData.borderStyle,
    textShadow: textShadow,
    textDecoration: positionData.textDecoration || "none",
  };

  // Check if textColor contains a gradient and adjust the style accordingly
  if (positionData.textColor && positionData.textColor.includes("gradient")) {
    style.background = positionData.textColor;
    style.WebkitBackgroundClip = "text";
    style.WebkitTextFillColor = "transparent";
    style.color = "transparent"; // Ensures text color doesn't override gradient
  } else {
    style.color = positionData.textColor;
  }

  return style;
};

export const productImageStyle = {
  width: "auto",
  height: "100%",
  objectFit: "contain",
  pointerEvents: "none",
};

export const draggableStyle = (adsData, type, resolution) => {
  const baseTextStyle = textStyle(adsData, type, resolution);
  return {
    ...baseTextStyle,
    maxHeight: type === "Headline" ? "100%" : "10%",
    zIndex: 1,
    wordWrap: "break-word",
    overflowWrap: "anywhere",
  };
};
