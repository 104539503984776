import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const COLOR_MAP = {
  Create: "#A06EAF",
  Update: "#80AFDE",
  Delete: "#B1AB9F",
  Login: "#F0D338",
  Logout: "#15254B",
  Approve: "#8ACC84",
  Reject: "#F37979",
  Send: "#F2A51D",
};

const USER_COLORS_DESCENDING = [
  "#8ACC84", // Most active
  "#A06EAF",
  "#80AFDE",
  "#B1AB9F",
  "#15254B",
  "#F2A51D",
  "#F0D338",
];

function customTooltipFormatter(value, name, props) {
  if (props.mode === "day") {
    const date = new Date(name);
    return `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}: ${value}`;
  } else if (props.mode === "hour") {
    return `${name}:00: ${value}`;
  } else if (props.mode === "week") {
    return `${name}: ${value}`; // Assume it's already formatted as "Week 35" or similar
  } else {
    return `${name}: ${value}`;
  }
}

function PieActivityChartWithPaddingAngle({ data, mode }) {
  let pieData = [];

  if (mode === "user") {
    const activeUsers = data.filter((d) => d.totalActions > 0);
    const sortedUsers = [...activeUsers].sort(
      (a, b) => b.totalActions - a.totalActions
    );

    const adjustedColors = [
      ...USER_COLORS_DESCENDING.slice(0, sortedUsers.length - 1),
      "#F37979", // Always end with the color for least active
    ];

    pieData = sortedUsers.map((d, index) => ({
      name: d.username,
      value: d.totalActions,
      color: adjustedColors[index],
    }));
  } else {
    pieData = Object.keys(data[0])
      .filter((key) => key !== "month" && COLOR_MAP[key])
      .map((key) => ({
        name: key,
        value: data.reduce((sum, d) => sum + d[key], 0),
        color: COLOR_MAP[key],
      }));
  }

  return (
    <PieChart width={600} height={400}>
      <Pie
        data={pieData}
        cx={300}
        cy={200}
        labelLine={false}
        outerRadius={150}
        fill="#8884d8"
        dataKey="value"
        paddingAngle={5}
        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
      >
        {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip formatter={customTooltipFormatter} />
    </PieChart>
  );
}

export default PieActivityChartWithPaddingAngle;
