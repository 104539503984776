const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const HOME_URL = "/";
export const USERS_URL = `${API_BASE_URL}/users`;
export const AUTH_URL = `${API_BASE_URL}/auth/register`;
export const REFRESH_TOKEN_URL = `${API_BASE_URL}/auth/refresh`;

export const ACTIONS_URL = `${API_BASE_URL}/actions`;
export const NOTIFICATIONS_URL = `${API_BASE_URL}/notifications`;

export const SIGNIN_URL = `${API_BASE_URL}/auth/signIn`;

export const LOGOUT_URL = `${API_BASE_URL}/auth/logout`;

export const CLIENTS_URL = `${API_BASE_URL}/clients`;
export const BRANDS_URL = `${API_BASE_URL}/brands`;
export const UPLOAD_URL = `${API_BASE_URL}/upload/single`;
export const UPLOAD_MULTIPLE_URL = `${API_BASE_URL}/upload/multiple`;
export const UPLOAD_BRAND_LOGO_URL = `${API_BASE_URL}/upload/brandLogo`;

export const ASSETS_URL = `${ASSETS_BASE_URL}/assets`;

export const CATEGORIES_URL = `${API_BASE_URL}/categories`;
export const COMMENTS_URL = `${API_BASE_URL}/comments`;

export const TEMPLATES_URL = `${API_BASE_URL}/templates`;
export const ADS_URL = `${API_BASE_URL}/ads`;
export const LAYOUTS_URL = `${API_BASE_URL}/layouts`;

export const AI_URL = `${API_BASE_URL}/translate`;

export const ADS_PREVIEW_URL = `${ASSETS_BASE_URL}`;
