import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import Mstandard1 from "../images/MicrosoftStandard/mStandard1.png";
import Mstandard2 from "../images/MicrosoftStandard/mStandard2.png";
import Mstandard3 from "../images/MicrosoftStandard/mStandard3.png";
import Mstandard4 from "../images/MicrosoftStandard/mStandard4.png";

const StandardSelectInfo = ({
  drawerOpen,
  setDrawerOpen,
  onCheckboxChange,
}) => {
  const images = [Mstandard1, Mstandard2, Mstandard3, Mstandard4];
  const descriptions = [
    "Windows logo in a corner, centered on the same plane as the partner logo vertically, or left-aligned horizontally.",
    "Windows logo in the top-left corner for a Windows-led execution, the partner logo usually goes either directly to its right, or in any of the other corners.",
    "Windows logo in the bottom-left corner for a Windows-led execution, the partner logo usually goes either directly to its right, or in the bottom-right corner.",
    "Brands have a longer-term partnership established (so not for usual co-branding marketing or advertisement), we use the partnership bar.",
  ];

  return (
    <>
      {drawerOpen && (
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            position: "relative",
            zIndex: 1300,
            backgroundColor: "background.paper",
            overflowY: "auto",
          }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography color='primary' sx={{ m: 2 }} variant='h5'>
                Logo placement in co-branding
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    color='primary'
                    onChange={onCheckboxChange}
                  />
                }
                label={
                  <Typography color='primary'>Don't show again</Typography>
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              height: "70%",
              mt: -2,

              //   py: 1,
            }}
            direction='row'
          >
            {descriptions.map((description, index) => (
              <Card
                sx={{
                  m: 1,
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid item container xs={12} alignItems='center'>
                  <Grid item xs={6}>
                    <Typography
                      color='primary'
                      key={index}
                      sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}
                      variant='subtitle2'
                    >
                      {description}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      key={index}
                      src={images[index]}
                      alt={`MStandard ${index + 1}`}
                      style={{ margin: "20px 0" }} // Add margins as needed
                    />
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StandardSelectInfo;
