import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comments",
  initialState: [],

  reducers: {
    addComment: (state, action) => {
      state.push(action.payload);
    },
    setComments: (state, action) => {
      return action.payload;
    },
    updateComment: (state, action) => {
      const index = state.findIndex(
        (comment) => comment.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteComment: (state, action) => {
      return state.filter((comment) => comment.id !== action.payload.id);
    },
    deleteCommentsByAdId: (state, action) => {
      return state.filter((comment) => comment.ad_id !== action.payload.adId);
    },
    updateComments: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  addComment,
  setComments,
  updateComment,
  deleteComment,
  deleteCommentsByAdId,
  updateComments,
} = commentSlice.actions;
export default commentSlice.reducer;
