import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    addProduct: (state, action) => {
      state.push(action.payload);
    },
    setProducts: (state, action) => {
      const existingProduct = action.payload.res.filter(
        (ad) => !state.some((a) => a.id === ad.id)
      );

      state.push(...existingProduct);
    },
    editProduct: (state, action) => {
      const productToUpdate = action.payload;
      const index = state.findIndex(
        (product) => product.id === productToUpdate.id
      );
      if (index !== -1) {
        state[index] = productToUpdate; // Update the entire product object
      }
    },
    deleteOneProduct: (state, action) => {
      const { id } = action.payload;
      const index = state.findIndex((product) => product.id === id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    deleteManyProducts: (state, action) => {
      const idsToDelete = new Set(action.payload.ids);
      return state.filter((product, index) => {
        if (idsToDelete.has(product.id)) {
          state.splice(index, 1);
        }
      });
    },
    updateProducts: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const {
  addProduct,
  setProducts,
  editProduct,
  deleteOneProduct,
  updateProducts,
  deleteManyProducts,
} = productsSlice.actions;
export default productsSlice.reducer;
