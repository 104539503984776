import * as React from "react";
import { Card, Grid, Typography, Box, Avatar, Button } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";

function RecentComments({ title }) {
  const { currentUser } = useContext(AuthContext);
  const AdsList = useSelector((store) => store.ads);
  const CommentsList = useSelector((store) => store.comments);

  const navigate = useNavigate();
  const theme = useTheme();
  const getAdTitle = (ad_id) => {
    const ad = AdsList.find((ad) => ad.id === ad_id);
    return ad ? ad.title : "N/A";
  };

  const getAdStatus = (ad_id) => {
    const ad = AdsList.find((ad) => ad.id === ad_id);
    return ad ? ad.status : null;
  };

  const statusIcon = (status) => {
    switch (status) {
      case "approved":
        return (
          <CheckCircleIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
      default:
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
    }
  };

  const getLastComments = () => {
    let lastCommentsObj = {};
    CommentsList.forEach((comment) => {
      if (
        !lastCommentsObj[comment.ad_id] ||
        new Date(comment.createdAt) >
          new Date(lastCommentsObj[comment.ad_id].createdAt)
      ) {
        lastCommentsObj[comment.ad_id] = comment;
      }
    });
    return Object.values(lastCommentsObj);
  };

  const filteredCommentsList = getLastComments();

  const handleCommentClick = (ad_id) => {
    if (currentUser.role === "creator") {
      navigate(`/edit-ad/${ad_id}`);
    } else {
      navigate(`/ad/${ad_id}`);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, 15) + "\n" + text.slice(12, maxLength) + "...";
  };

  return (
    <Card
      sx={{
        display: "flex",
        mt: 2,
        pr: 1,
        width: "100%",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Grid sx={{ m: 2 }} container direction='column' spacing={2}>
        <Grid item>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
              color: theme.typography.fontColorHeadline,
              position: "relative",
              "::after": {
                content: '""',
                display: "inline-block",
                height: "2px",
                background: "linear-gradient(90deg, #F37979 0%, #A06EAF 100%)",
                position: "absolute",
                left: 0,
                right: 200,
                bottom: "-9px",
                width: 146,
              },
            }}
          >
            Recent Comments
          </Typography>
          <Box
            sx={{ borderBottom: "1px solid #D9D9D9", width: "100%", my: 1 }}
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent='space-between' sx={{ pl: 0 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "semiBold",
                color: "primary.main",
                position: "relative",
                "::after": {
                  content: '""',
                  display: "block",
                  height: "2px",
                  background: theme.palette.primary.main,
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: "-9px",
                },
              }}
              variant='h6'
            >
              AD NAME
            </Typography>
          </Grid>
          <Box
            sx={{ borderBottom: "1px solid #D9D9D9", width: "100%", my: 1 }}
          />
        </Grid>
        <Grid item>
          {filteredCommentsList.map((comment, index) => {
            const isLongComment =
              title === "Dashboard" && comment.comments.length > 30;

            return (
              <motion.div
                initial={{ rotateY: 0 }}
                whileHover={isLongComment ? { rotateY: 180 } : {}}
                transition={{ duration: 0.5 }}
                key={index}
                style={{ perspective: "1000px", marginBottom: "2rem" }}
              >
                <Card
                  sx={{
                    height: 92,
                    borderBottom: "1px solid #D9D9D9",
                    flexDirection: "column",
                    cursor: "pointer",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onClick={() => handleCommentClick(comment.ad_id)}
                >
                  {/* Front of the Card */}
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      backfaceVisibility: "hidden",
                      transform: "rotateY(0deg)",
                      backgroundColor: "white",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        variant='caption'
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: theme.typography.fontColor,
                        }}
                      >
                        {getAdTitle(comment.ad_id)}
                      </Typography>
                      {statusIcon({ status: getAdStatus(comment.ad_id) })}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "70%",
                          marginBottom: 3,
                        }}
                      >
                        <Avatar sx={{ width: 31, height: 31 }} />
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: 14,
                            color: theme.typography.fontColor,
                            marginLeft: "0.5rem",
                            display: "-webkit-box",
                            "-webkit-line-clamp": 2,
                            "-webkit-box-orient": "vertical",
                            overflow: "hidden",
                            wordWrap: "break-word",
                          }}
                        >
                          {title === "Dashboard"
                            ? truncateText(comment.comments, 30)
                            : comment.comments}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          width: "25%",
                          minWidth: "110px",
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: 12,
                            color: theme.typography.fontColorCard,
                          }}
                        >
                          {new Date(comment.createdAt).toLocaleDateString()}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: 12,
                            color: theme.typography.fontColorCard,
                          }}
                        >
                          {new Date(comment.createdAt).toLocaleTimeString()}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: 12,
                            color: theme.typography.fontColorCard,
                          }}
                        >
                          AD ID: {comment.ad_id}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* Back of the Card */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: theme.palette.primary.light,
                      transform: "rotateY(180deg)",
                      padding: 1,
                      zIndex: 0,
                      display: isLongComment ? "flex" : "none",
                      // overflow: "auto", // Added this for scrolling if content goes beyond the box
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        fontSize: 14,
                        color: theme.typography.fontColor,
                        marginLeft: "0.5rem",
                        wordWrap: "break-word",
                        maxWidth: "100%", // Ensure the Typography doesn't exceed its parent width
                      }}
                    >
                      {comment.comments}
                    </Typography>
                  </Box>
                </Card>
              </motion.div>
            );
          })}
        </Grid>
      </Grid>
    </Card>
  );
}

export default RecentComments;
