import React from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";

const PostSelectionGrid = ({ posts, onSelectPost, selectedPostId }) => {
  const selectedPost = posts.find((post) => post.id === selectedPostId);
  const selectedImageUrl =
    selectedPost?.attachments?.data[0]?.media?.image?.src;
  const selectedMessage = selectedPost?.message;
  const selectedCreatedTime = selectedPost?.created_time;
  const selectedDimensions = selectedPost?.attachments?.data[0]?.media?.image;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {selectedPost && (
        <Box sx={{ maxWidth: 800, mx: "auto", mb: 3 }}>
          {selectedImageUrl && (
            <Box
              component='img'
              src={selectedImageUrl}
              alt='Selected Post'
              sx={{
                width: "100%",
                height: "auto",
                display: "block",
                mx: "auto",
              }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant='caption' color='textSecondary'>
              {new Date(selectedCreatedTime).toLocaleString()}
            </Typography>
            <Typography
              variant='body2'
              color='textPrimary'
              component='p'
              sx={{ fontWeight: "bold", mx: 2 }}
            >
              {selectedMessage || "No caption provided"}
            </Typography>
            {selectedDimensions && (
              <Typography variant='caption' color='textSecondary'>
                Dimensions: {selectedDimensions.width} x{" "}
                {selectedDimensions.height}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Grid container spacing={3} justifyContent='center'>
        {posts.map((post) => {
          const { id, message, created_time, attachments } = post;
          const imageUrl = attachments?.data[0]?.media?.image?.src;
          const imageDimensions = attachments?.data[0]?.media?.image;
          const isSelected = selectedPostId === id;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
              <Card
                onClick={() => onSelectPost(post)}
                sx={{
                  maxWidth: 500,
                  mx: "auto",
                  border: isSelected ? "2px solid #3f51b5" : "none",
                  boxShadow: isSelected ? "0 0 10px rgba(0,0,0,0.5)" : "none",
                }}
              >
                <CardActionArea>
                  {imageUrl && (
                    <Box
                      sx={{
                        height: 0,
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />
                  )}
                  <CardContent>
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      display='block'
                      gutterBottom
                    >
                      {new Date(created_time).toLocaleString()}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textPrimary'
                      component='p'
                      sx={{ fontWeight: "bold" }}
                    >
                      {message || "No caption provided"}
                    </Typography>
                    {imageDimensions && (
                      <Typography variant='caption' color='textSecondary'>
                        Dimensions: {imageDimensions.width} x{" "}
                        {imageDimensions.height}
                      </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PostSelectionGrid;
