import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const FontSelectors = ({
  fontName,
  fontSize,
  fontStyle,
  fontOptions,
  fontSizes,
  handleFontFamilyChange,
  handleFontStyleChange,
  updateStyle,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <FormControl
        variant='outlined'
        size='small'
        sx={{
          minWidth: isMobile ? 60 : isTablet ? 70 : 80,
          "& .MuiOutlinedInput-root": {
            height: "30.5px",
            minHeight: "30.5px",
            fontSize: "0.8rem",
          },
          "& .MuiInputLabel-root": {
            fontSize: "0.8rem",
          },
          "& .MuiSelect-select": {
            paddingTop: "5px",
            paddingBottom: "5px",
            fontSize: "0.8rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
        }}
      >
        <InputLabel>Font</InputLabel>
        <Select
          label='Font'
          value={fontName || ""}
          onChange={handleFontFamilyChange}
        >
          {Object.keys(fontOptions).map((family, index) => (
            <MenuItem key={index} value={family} sx={{ fontSize: "0.8rem" }}>
              {family}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        size='small'
        sx={{
          minWidth: isMobile ? 50 : isTablet ? 55 : 60,
          "& .MuiAutocomplete-root": {
            height: "30.5px",
          },
          "& .MuiInputBase-root": {
            height: "30.5px",
            fontSize: "0.8rem",
          },
          "& .MuiInputLabel-root": {
            fontSize: "0.8rem",
          },
          "& .MuiAutocomplete-option": {
            fontSize: "0.8rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
          "& .MuiAutocomplete-clearIndicator": {
            fontSize: "0.7rem",
          },
        }}
        value={fontSize}
        onChange={(event, newValue) => {
          updateStyle("fontSize", newValue);
        }}
        options={fontSizes}
        getOptionLabel={(option) => option.toString()}
        ListboxProps={{
          style: {
            fontSize: "0.8rem",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Size'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  {/* Any icon or text you want to show */}
                </InputAdornment>
              ),
              style: {
                fontSize: "0.8rem",
              },
            }}
            style={{ width: isMobile ? 60 : isTablet ? 70 : 80 }}
          />
        )}
      />

      <FormControl
        variant='outlined'
        size='small'
        sx={{
          minWidth: isMobile ? 100 : isTablet ? 110 : 120,
          "& .MuiOutlinedInput-root": {
            height: "30.5px",
            minHeight: "30.5px",
            fontSize: "0.8rem",
          },
          "& .MuiInputLabel-root": {
            fontSize: "0.8rem",
          },
          "& .MuiSelect-select": {
            paddingTop: "5px",
            paddingBottom: "5px",
            fontSize: "0.8rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
        }}
      >
        <InputLabel>Style</InputLabel>
        <Select
          label='Style'
          value={fontStyle}
          onChange={handleFontStyleChange}
        >
          {fontName &&
            Array.from(fontOptions[fontName] || []).map((style, index) => (
              <MenuItem key={index} value={style} sx={{ fontSize: "0.8rem" }}>
                {style}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default FontSelectors;
