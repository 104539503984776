import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  LinearProgress,
  Button,
  IconButton,
  Tooltip,
  Chip,
  Grid,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog2, {
  confirmDialog,
} from "../../components/ConfirmationDialog";
import { ACTIONS_URL, CATEGORIES_URL, USERS_URL } from "../../config";
import { formatDateToLocaleString } from "../../helpers/dateHelper";
import CategoriesTable from "../../components/Table";

import {
  addCategory,
  deleteOneCategory,
  editCategory,
} from "../categories/features/categoriesReducer";
import UserCard from "../../components/UserCard";
import AdReadyHelp from "../../components/AdReadyHelp";
import AddAndEditModal from "../../components/AddAndEditModal";
import moment from "moment";
import axiosInstance from "../../services/axiosInstance";
import { SocketContext } from "../../context/SocketContext ";

export default function Categories(props) {
  const socket = useContext(SocketContext);

  const dispatch = useDispatch();
  const CategoriesList = useSelector((store) => store.categories);
  const [categoryState, setCategoryState] = useState(CategoriesList);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);
  // const [post, setPost] = useState({});
  const [users, setUsers] = useState();
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const updated = useRef(false);

  const inititalValues = {
    name: "",
    description: "",
  };

  const [values, setValues] = useState(inititalValues);

  useEffect(() => {}, [values]);

  useEffect(() => {
    const transformedCategories = CategoriesList.map((category) => {
      return {
        id: category.id,
        name: category.name,
        description: category.description,
        created: formatDateToLocaleString(category.createdAt),
        updated: formatDateToLocaleString(category.updatedAt),
      };
    });

    // Now set the state
    setCategoryState(transformedCategories);
  }, [CategoriesList]);

  useEffect(() => {
    socket?.on("getCommentNotification", (data) => {
      setNotifications((prev) => [...prev, data]);
      if (!updated.current) {
        setIsLoading(true);
        updated.current = true;
      }
    });
    return () => {
      updated.current = false;
    };
  }, [CategoriesList]);

  // EDIT AND DELETE BUTTONS
  const actionsDetailButton = (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <IconButton onClick={() => handleDelete(params)} aria-label='delete'>
          <DeleteIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setValues(params);
            setShowCategoryModal(true);
          }}
          aria-label='edit'
        >
          <EditIcon color='primary' sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    );
  };

  // DELETE AD FUNCTION
  const deleteCategory = "deleted";
  const type = "CLIENT";

  const handleDelete = async (row) => {
    const id = row.id;
    const name = row.name;

    confirmDialog(
      `Do you really want to delete "${name}" Category, created at ${row.created} ?`,
      async () => {
        try {
          setIsLoading(true);

          // Log the "Delete" action
          await axios.post(ACTIONS_URL, {
            actionType: "Delete",
            objectType: "Category",
            userId: currentUser.id,
            clientId: currentUser.clientIdUsers,
            content: row,
          });

          // Perform the delete action
          await axios.delete(`${CATEGORIES_URL}/${id}`);
          dispatch(deleteOneCategory({ id: id }));

          // Optional: Show success message to the user
        } catch (error) {
          console.error("Error in deleting category:", error);
          // Optional: Show error message to the user
        } finally {
          setIsLoading(false);
        }
      }
    );
  };

  const handlePostRequest = async () => {
    try {
      const response = await axiosInstance.post(CATEGORIES_URL, {
        name: values.name,
        description: values.description,
        clientId: currentUser.clientIdUsers, // send clientId when creating category
      });

      const { id, name, description, createdAt, updatedAt } = response.data;

      dispatch(
        addCategory({
          id,
          name,
          description,
          createdAt: createdAt || null,
          updatedAt: updatedAt || null,
        })
      );

      // Log the "Create" action for objectType "Category"
      await axios.post(ACTIONS_URL, {
        actionType: "Create",
        objectType: "Category",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        content: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCategory = async () => {
    if (values.name && values.description) {
      try {
        await handlePostRequest();
        setTimeout(() => {
          navigate("/categories");
        }, 500);
      } catch (err) {
        console.error("Error while creating category:", err);
      }
    }
  };

  const handlePutRequest = async () => {
    try {
      const response = await axios.put(`${CATEGORIES_URL}/${values.id}`, {
        id: values.id,
        name: values.name,
        description: values.description,
      });

      const responseData = response.data;

      dispatch(
        editCategory({
          id: responseData.id,
          name: responseData.name,
          description: responseData.description,
          createdAt: responseData.createdAt,
          updatedAt: responseData.updatedAt,
        })
      );

      // Log the "Update" action for objectType "Category"
      await axios.post(ACTIONS_URL, {
        actionType: "Update",
        objectType: "Category",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers,
        content: responseData,
      });

      socket?.emit("sendClientNotification", {
        id: responseData.id,
        senderName: currentUser,
        input: values.name,
        action: "Update",
        time: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        type: type,
      });
    } catch (err) {
      console.error(
        "Error updating the category:",
        err.response ? err.response.data : err.message
      );
    }
  };

  const handleEditCategory = async () => {
    try {
      await handlePutRequest();
      navigate("/categories");
    } catch (err) {
      console.error("Error in handleEditCategory:", err);
    }
  };

  const cardColors = {
    item1: "#F5A67C",
    item2: "#777777",
    item3: "#777777",
  };

  const backgroundColor = "linear-gradient(45deg, #FFB58E 30%, #F37979 90%)";

  return (
    <Grid sx={{ pl: 10, pr: 2, pt: 2 }} container spacing={2}>
      <ConfirmDialog2 />
      <Grid item xs={12} md={7}>
        <UserCard
          CategoriesList={CategoriesList}
          rows={categoryState}
          cardColors={cardColors}
          currentUser={currentUser}
          numberOfItems={categoryState.length}
          cardHeader={"Categories"}
          backgroundColor={backgroundColor}
          values={values}
          setValues={setValues}
          addCategory={handleAddCategory}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <AdReadyHelp backgroundColor={backgroundColor} />
      </Grid>

      <Grid item xs={12}>
        <CategoriesTable
          title='Categories'
          rows={categoryState}
          name={categoryState.name}
          actionsDetailButton={actionsDetailButton}
        />
      </Grid>
      <AddAndEditModal
        backgroundColor={backgroundColor}
        handleAddEntity={handleEditCategory}
        values={values}
        setValues={setValues}
        currentUser={currentUser}
        userTitle={"Edit Category"}
        open={showCategoryModal}
        setOpen={setShowCategoryModal}
        clients={CategoriesList}
      />
    </Grid>
    // <Box
    //   sx={{
    //     paddingTop: 5,
    //     paddingLeft: 15,
    //     paddingRight: 10,
    //     height: 800,
    //     width: "100%",

    //     border: "none",
    //     "& .MuiGrid-root": {
    //       "& .MuiPaper-root": {
    //         backgroundColor: "black",
    //       },
    //     },
    //   }}
    // >
    //   <Box
    //     sx={{
    //       display: "flex",
    //       justifyContent: "flex-end",
    //       paddingBottom: 2,
    //     }}
    //   >
    //     <Button
    //       sx={{ width: 110, height: 40 }}
    //       onClick={() => navigate("/add-category")}
    //       variant="outlined"
    //     >
    //       ADD CATEGORY
    //     </Button>
    //   </Box>

    //   <CategoriesTable
    //     title="Categories"
    //     rows={categoryState}
    //     name={categoryState.name}
    //     actionsDetailButton={actionsDetailButton}
    //   />
    // </Box>
  );
}
