export const types = [
  "headline",
  "subHeadline",
  "text1",
  "text2",
  "text3",
  "text4",
  "text5",
  "product",
  "background",
  "logoBrand1",
  "logoBrand2",
  "logoBrand3",
  "logoThirdParty",
  "sticker1",
  "disclaimer",
  "cta",
];
export const attributes = {
  headline: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "headline",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  subHeadline: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "subHeadline",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  text1: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "text",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  text2: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "text",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  text3: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "text",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  text4: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "text",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  text5: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "text",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  disclaimer: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "disclaimer",
    "fontName",
    "fontWeight",
    "fontSize",
    "textAlign",
    "lineHeight",
    "letterSpacing",
    "textColor",
  ],
  product: ["x", "y", "width", "height", "visible", "editable", "imageUrl"],
  cta: ["x", "y", "width", "height", "visible", "editable", "imageUrl"],

  background: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "color",
    "showImage",
  ],
  background1: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "color",
    "showImage",
  ],
  logoBrand1: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "selectedCorner",
    "selectedCornerPosition",
    "type",
  ],
  logoBrand2: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "selectedCorner",
    "selectedCornerPosition",
    "type",
  ],
  logoBrand3: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "selectedCorner",
    "selectedCornerPosition",
    "type",
  ],
  logoThirdParty: [
    "x",
    "y",
    "width",
    "height",
    "visible",
    "editable",
    "imageUrl",
    "selectedCorner",
    "selectedCornerPosition",
    "type",
  ],
  sticker1: ["x", "y", "width", "height", "visible", "editable", "imageUrl"],
};

// List of logos to consider
export const logos = [
  "logoBrand1",
  "logoBrand2",
  "logoBrand3",
  "logoThirdParty",
];

export const logoCorners = {
  logoBrand1: "topRight",
  logoBrand2: "bottomLeft",
  logoBrand3: "topLeft",
  logoThirdParty: "topRight",
};

export const networkTags = {
  All: [
    "1900x620",
    "1200x1200",
    "1200x628",
    "1200x630",
    "1080x1080",
    "1080x1920",
    "300x250",
    "600x829",
    "160x600",
    "320x50",
    "728x90",
    "960x676",
    "875x225",
    "300x600",
    "970x250",
  ],
  Facebook: [
    "1080x1080",
    "1200x1200",
    "1200x630",
    "1200x628",
    "1900x620",
    "1080x1920",
  ],
  Instagram: ["1080x1080", "300x250", "600x829", "1080x1920", "875x225"],
  LinkedIn: ["160x600", "320x50", "728x90", "960x676", "875x225", "1200x630"],
  Web: [
    "160x600",
    "320x50",
    "300x600",
    "300x250",
    "728x90",
    "970x250",
    "600x829",
    "960x676",
    "875x225",
    "1200x630",
  ],
};
