import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { Stage, Layer, Rect, Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import axios from "axios";
import { AI_URL } from "../../../config";

const EditImageModal = ({
  open,
  onClose,
  imageUrl,
  prompt,
  setPrompt,
  onRegenerate,
  isLoading,
  onEditComplete,
  clientId,
}) => {
  const [selectedRect, setSelectedRect] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const stageRef = useRef(null);

  useEffect(() => {
    if (imageUrl) {
      const img = new window.Image();
      img.src = imageUrl;
      img.onload = () => {
        setImage(img);
        setImageSize({ width: img.width, height: img.height });
      };
    }
  }, [imageUrl]);

  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setSelectedRect({ x, y, width: 0, height: 0 });
    setIsSelecting(true);
  };

  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    setSelectedRect((prev) => ({
      ...prev,
      width: x - prev.x,
      height: y - prev.y,
    }));
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    if (selectedRect) {
      console.log("Selected area:", selectedRect);
      // Perform any additional actions with the selected area here
    }
  };

  const handleEditImage = async () => {
    if (selectedRect && prompt) {
      try {
        const mask = {
          x: selectedRect.x / imageSize.width,
          y: selectedRect.y / imageSize.height,
          width: selectedRect.width / imageSize.width,
          height: selectedRect.height / imageSize.height,
        };

        const response = await axios.post(`${AI_URL}/edit-image`, {
          clientId, // Include clientId in the request body
          prompt,
          imageUrl,
          mask,
        });

        if (response.data) {
          onEditComplete(response.data);
        }
      } catch (error) {
        console.error("Error editing image:", error);
      }
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    maxWidth: "90%",
    maxHeight: "90%",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Edit Image
        </Typography>
        <TextField
          onChange={(e) => setPrompt(e.target.value)}
          fullWidth
          label='Enter your prompt'
          variant='outlined'
          value={prompt}
          sx={{ mb: 2 }}
        />
        <Button
          variant='contained'
          onClick={handleEditImage} // Call the function here
          disabled={isLoading}
          sx={{
            color: "white",
            fontWeight: "bold",
            mb: 2,
            "&:hover": {
              backgroundColor: "#06beb6",
            },
          }}
        >
          Edit Image
        </Button>
        {image && (
          <Box sx={containerStyle}>
            <Stage
              width={window.innerWidth * 0.8}
              height={window.innerHeight * 0.8}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              ref={stageRef}
            >
              <Layer>
                <KonvaImage
                  image={image}
                  width={window.innerWidth * 0.8}
                  height={window.innerHeight * 0.8}
                  listening={false}
                />
                {selectedRect && (
                  <Rect
                    x={selectedRect.x}
                    y={selectedRect.y}
                    width={selectedRect.width}
                    height={selectedRect.height}
                    stroke='red'
                    strokeWidth={2}
                    listening={false}
                  />
                )}
              </Layer>
            </Stage>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditImageModal;
