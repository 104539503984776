import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Paper,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { Token, Refresh, SwapHoriz } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const TOKEN_TO_USD_RATE = 2.3; // 1 ADT = 2.3 USD
const USD_TO_EUR_RATE = 0.85; // Example fixed conversion rate
const USD_TO_GBP_RATE = 0.75; // Example fixed conversion rate
const USD_TO_JPY_RATE = 110.0; // Example fixed conversion rate

const TokenBalance = () => {
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const theme = useTheme();

  const fetchBalance = async () => {
    try {
      setLoading(true);
      // Fetch token balance
      const balanceResponse = await axios.get("/api/tokens/token-balance");
      setBalance(balanceResponse.data.balance);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleTransferClick = async () => {
    try {
      const response = await axios.post("/api/tokens/send-tokens", {
        amount: parseFloat(amount),
        receiverAddress,
        clientId: 1, // replace with actual client ID if needed
      });
      console.log("Transfer successful:", response.data);
      fetchBalance(); // Refresh balance after transfer
    } catch (error) {
      console.error("Error transferring tokens:", error);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (balance === null) {
    return (
      <Container>
        <Typography variant='h6'>Error fetching data</Typography>
      </Container>
    );
  }

  // The balance fetched is already in whole tokens
  const tokenBalance = balance; // Assuming balance is in ADT, not the smallest unit
  const usdValue = tokenBalance * TOKEN_TO_USD_RATE;
  const eurValue = usdValue * USD_TO_EUR_RATE;
  const gbpValue = usdValue * USD_TO_GBP_RATE;
  const jpyValue = usdValue * USD_TO_JPY_RATE;

  return (
    <Container>
      <Paper
        sx={{
          borderRadius: 1,
          overflow: "hidden",
          boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
          width: "100%",
          padding: 2,
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box display='flex' alignItems='center'>
                  <Token sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography variant='body1' component='div'>
                    AdReady Token (ADT)
                  </Typography>
                </Box>
                <Typography variant='body2'>
                  USD:{" "}
                  {usdValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography variant='body2'>
                  GBP:{" "}
                  {gbpValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    onClick={fetchBalance}
                  >
                    <Refresh />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: theme.palette.primary.main,
                  }}
                  variant='body1'
                  component='div'
                >
                  Balance: {tokenBalance.toLocaleString()} ADT
                </Typography>
                <Typography variant='body2' sx={{ textAlign: "center" }}>
                  EUR:{" "}
                  {eurValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography variant='body2' sx={{ textAlign: "center" }}>
                  JPY:{" "}
                  {jpyValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ mt: 4 }}>
              <TextField
                label='Amount'
                type='number'
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label='Receiver Address'
                fullWidth
                value={receiverAddress}
                onChange={(e) => setReceiverAddress(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={handleTransferClick}
              >
                Transfer
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default TokenBalance;
