import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import React from "react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CampaignIcon from "@mui/icons-material/Campaign";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PersonIcon from "@mui/icons-material/Person";
import DvrIcon from "@mui/icons-material/Dvr";
import CategoryIcon from "@mui/icons-material/Category";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import AddAndEditModal from "./AddAndEditModal";
import DownloadMenu from "./DownloadMenu";
import WizardModal from "../pages/ads/components/CreateAdWizzard";
import { useTheme } from "@mui/material/styles";

function UserCard({
  currentUser,
  numberOfItems,
  pendingAds,
  cardHeader,
  cardColors,
  backgroundColor,
  rows,
  currentCategory,
  handleStatusApprove,
  handleStatusReject,
  handleDrawerToggle,
  CommentsList,
  ClientsList,
  values,
  setValues,
  handleAddUser,
  handleChipSelect,
  cardHeader1,
  inititalValues,
  addClient,
  addCategory,
  CategoriesList,
  progress,
  handleCheckboxChange,
  handleDownload,
  anchorEl,
  handleMenuOpen,
  handleClose,
  networkTags,
  selectedResolutions,
  anchorRef,
  UsersList,
  isLoading,
}) {
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState(0);
  const [currentAd, setCurrentAd] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [approverName, setApproverName] = useState("Unknown");
  const [trigger, setTrigger] = useState(false);
  const [showWizardModal, setShowWizardModal] = useState(false);

  const params = useParams();
  const theme = useTheme();

  const items =
    cardHeader === "Users"
      ? rows.map((row) => row.firstName)
      : cardHeader === "Ads"
      ? rows.map((row) => row.title)
      : rows.map((row) => row.name);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % items.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const viewedAd = rows.find((row) => row.id === +params.id);
    setCurrentAd(viewedAd);
  }, [rows]);

  useEffect(() => {
    // Trigger the animation
    setTrigger(true);

    // Reset back after 2.5 seconds or however long your animation is
    setTimeout(() => setTrigger(false), 2500);
  }, [pendingAds, CommentsList, UsersList]);

  const statusIcon = (data) => {
    switch (data.status) {
      case "approved":
        return (
          <CheckCircleIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.approve }}
          />
        );
      case "rejected":
        return (
          <CancelIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.rejected }}
          />
        );
      case "pending":
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
      default:
        return (
          <QueryBuilderOutlinedIcon
            sx={{ fontSize: 23, color: theme.palette.approveIcons.pending }}
          />
        );
    }
  };

  const variants = {
    hidden: { opacity: 0, y: -50 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0, y: 50 },
  };

  const backgroundColorClient =
    "linear-gradient(45deg, #BAE0A4 30%, #80AFDE 90%)";
  const backgroundColorUser =
    "linear-gradient(45deg, #F37979 30%, #273860 90%)";
  const backgroundColorCategory =
    "linear-gradient(45deg, #FFB58E 30%, #F37979 90%)";

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const isButtonDisabled = currentAd?.approverId !== currentUser.id;

  useEffect(() => {
    const approver = UsersList?.find(
      (user) => user.id === currentAd?.approverId
    );
    const newApproverName = approver ? approver?.firstName : "Unknown";
    setApproverName(newApproverName);
  }, [currentAd, UsersList]);

  return (
    <Card
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
        bgcolor: "#F9F9F9",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", height: 151 }}>
        <Grid container item xs={3} direction='row'>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardContent>
              {cardHeader === "Created by" ? (
                <Typography
                  sx={{
                    color: theme.palette.primary.tableLabelMain,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  variant='subtitle1'
                >
                  {cardHeader}
                </Typography>
              ) : cardHeader1 === "Your profile" ? (
                <Typography
                  // Add styles or content specific to "Your profile" here
                  sx={{
                    color: theme.palette.primary.tableLabelMain,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  variant='subtitle1'
                >
                  {cardHeader1}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: theme.palette.primary.tableLabelMain,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                  variant='subtitle1'
                >
                  {cardHeader}
                </Typography>
              )}
            </CardContent>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Avatar
              alt='User Avatar'
              style={{ width: "65px", height: "65px" }}
            />

            <Typography
              sx={{ color: theme.typography.fontColor, fontSize: 14 }}
              variant='subtitle1'
            >
              {currentUser.firstName}
            </Typography>
          </Grid>
        </Grid>

        <Divider
          orientation='vertical'
          variant='middle'
          flexItem
          sx={{ height: 61, alignSelf: "center" }}
        />

        {(cardHeader === "Clients" ||
          cardHeader === "Brands" ||
          cardHeader === "Categories" ||
          cardHeader === "Users" ||
          cardHeader === "Ads") && (
          <>
            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Grid container item xs={9} justifyContent='center'>
                  <Grid item xs={8}>
                    <motion.div
                      variants={variants}
                      initial='hidden'
                      animate='show'
                      key={currentItem}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.typography.fontColorCard,
                            fontSize: 16,
                            fontWeight: "bold",
                            paddingLeft: 1,
                            pt: 0.4,
                          }}
                          variant='subtitle1'
                        >
                          {items[currentItem]}
                        </Typography>
                      </Box>
                    </motion.div>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>

            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ height: 61, alignSelf: "center" }}
            />

            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: cardColors && cardColors.item1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  {cardHeader === "Clients" ? (
                    <WorkHistoryIcon
                      sx={{ color: theme.palette.primary.light }}
                    />
                  ) : cardHeader === "Brands" ? (
                    <WorkspacePremiumIcon
                      sx={{ color: theme.palette.primary.light }}
                    />
                  ) : cardHeader === "Categories" ? (
                    <CategoryIcon sx={{ color: theme.palette.primary.light }} />
                  ) : cardHeader === "Users" ? (
                    <PersonIcon sx={{ color: theme.palette.primary.light }} />
                  ) : (
                    <DvrIcon sx={{ color: theme.palette.primary.light }} /> // Replace with the actual Icon you have for Ads
                  )}
                </Box>

                <Typography
                  sx={{ color: theme.typography.fontColorCard, fontSize: 14 }}
                  variant='subtitle1'
                >
                  {cardHeader === "Clients"
                    ? "All Clients"
                    : cardHeader === "Brands"
                    ? "All Brands"
                    : cardHeader === "Categories"
                    ? "All Categories"
                    : cardHeader === "Users"
                    ? "All Users"
                    : "All Ads"}
                </Typography>

                <Typography
                  variant='subtitle1'
                  sx={{
                    color: cardColors.item1,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {numberOfItems}
                </Typography>
              </CardContent>
            </Grid>

            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ height: 61, alignSelf: "center" }}
            />

            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Button
                  variant='contained'
                  onClick={() => {
                    if (cardHeader === "Users") {
                      setValues({ ...inititalValues }); // Ensure form values are reset to initial values
                      setShowUserModal(true);
                    } else if (cardHeader === "Clients") {
                      setValues(inititalValues); // Reset form values for clients as well
                      setShowClientModal(true);
                    } else if (cardHeader === "Categories") {
                      setValues(inititalValues); // Reset form values for categories as well
                      setShowCategoryModal(true);
                    } else if (cardHeader === "Ads") {
                      setShowWizardModal(true);
                    } else {
                      navigate(
                        cardHeader === "Brands"
                          ? "/add-brand"
                          : cardHeader === "Categories"
                          ? "/add-category"
                          : "/add-ad"
                      );
                    }
                  }}
                  sx={{
                    bgcolor: cardColors && cardColors.item1,
                    color: theme.palette.primary.light,
                  }}
                >
                  {cardHeader === "Clients"
                    ? "ADD CLIENT"
                    : cardHeader === "Brands"
                    ? "ADD BRAND"
                    : cardHeader === "Categories"
                    ? "ADD CATEGORY"
                    : cardHeader === "Users"
                    ? "ADD USER"
                    : "ADD AD"}
                </Button>

                {showWizardModal && (
                  <WizardModal setShowWizardModal={setShowWizardModal} />
                )}

                <AddAndEditModal
                  backgroundColor={backgroundColor}
                  values={values}
                  setValues={setValues}
                  currentUser={currentUser}
                  userTitle='Add User'
                  open={showUserModal}
                  setOpen={setShowUserModal}
                  clients={ClientsList}
                  onSelect={handleChipSelect}
                  handleAddEntity={handleAddUser} // Ensure this is correctly mapped to handleAddUser
                  inititalValues={inititalValues} // Pass initial values
                  type={"Add user"}
                />
                <AddAndEditModal
                  backgroundColor={backgroundColorClient}
                  userTitle='Add Client'
                  open={showClientModal}
                  setOpen={setShowClientModal}
                  values={values}
                  setValues={setValues}
                  clients={ClientsList}
                  onSelect={handleChipSelect}
                  handleAddEntity={addClient}
                />
                <AddAndEditModal
                  backgroundColor={backgroundColorCategory}
                  userTitle='Add Category'
                  open={showCategoryModal}
                  setOpen={setShowCategoryModal}
                  values={values}
                  setValues={setValues}
                  clients={CategoriesList}
                  onSelect={handleChipSelect}
                  handleAddEntity={addCategory}
                />
              </CardContent>
            </Grid>
          </>
        )}

        {cardHeader === "Created by" && (
          <>
            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  // paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      color: theme.typography.fontColor,
                      fontSize: 14,
                      fontWeight: 600,
                      // mb: 1,
                    }}
                  >
                    Title:{" "}
                    <span style={{ fontWeight: 400 }}>{currentAd?.title}</span>
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      color: theme.typography.fontColor,
                      fontSize: 14,
                      fontWeight: 600,
                      // mb: 1,
                    }}
                  >
                    ID: <span style={{ fontWeight: 400 }}>{currentAd?.id}</span>
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      color: theme.typography.fontColor,
                      fontSize: 14,
                      fontWeight: 600,
                      // mb: 1,
                    }}
                  >
                    Category:{" "}
                    <span
                      style={{
                        fontWeight: 400,
                        color: currentCategory?.color,
                      }}
                    >
                      {currentCategory?.name}
                    </span>
                  </Typography>
                </Box>
              </CardContent>
            </Grid>

            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ height: 61, alignSelf: "center" }}
            />

            <Grid item xs={6}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* Box for status icon */}
                  <Box sx={{ mt: 1 }}>{statusIcon(currentAd)}</Box>

                  {/* Box for status text and approver name */}
                  <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
                    <Typography
                      variant='subtitle1'
                      sx={{
                        color: theme.typography.fontColor,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      STATUS:
                    </Typography>
                    {currentAd.status === "pending" && (
                      <Typography
                        variant='subtitle1'
                        sx={{
                          color: "#F37979",
                          fontSize: 14,
                          // fontWeight: "semibold",
                          mt: -0.6, // Adjusted the margin-top to a negative value to make the gap smaller
                        }}
                      >
                        {`Waiting for ${approverName}`}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  {+progress === 0 || +progress === 100 ? (
                    <Button onClick={handleMenuOpen}>
                      <DownloadIcon color='primary' />
                      <Typography sx={{ ml: 1 }}>DOWNLOAD</Typography>
                    </Button>
                  ) : (
                    <>
                      <LinearProgress
                        variant='determinate'
                        value={progress}
                        sx={{ width: "100px", mr: 1 }} // Adjust the width as per your requirement
                      />
                      <Typography color='primary'>
                        {`${Math.round(progress)}%`}
                      </Typography>
                    </>
                  )}

                  <Box
                    ref={anchorRef}
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                </Box>

                <DownloadMenu
                  networkTags={networkTags}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleCheckboxChange={handleCheckboxChange}
                  handleDownload={handleDownload}
                  selectedResolutions={selectedResolutions}
                />

                <IconButton onClick={handleDrawerToggle}>
                  <MenuOpenIcon color='primary' />
                </IconButton>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    onClick={
                      isButtonDisabled
                        ? () => setSnackbarOpen(true)
                        : handleStatusApprove
                    }
                    variant='contained'
                    sx={{
                      marginBottom: 1,
                      backgroundColor: theme.palette.primary.approve,
                      maxHeight: 25,
                    }}
                    disabled={isButtonDisabled}
                  >
                    APPROVE
                  </Button>
                  <Button
                    onClick={
                      isButtonDisabled
                        ? () => setSnackbarOpen(true)
                        : handleStatusReject
                    }
                    variant='contained'
                    sx={{
                      backgroundColor: theme.palette.primary.reject,
                      maxHeight: 25,
                    }}
                    disabled={isButtonDisabled}
                  >
                    REJECT
                  </Button>
                </Box>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity='warning'
                    sx={{ width: "100%" }}
                  >
                    You are not authorized to do this action
                  </Alert>
                </Snackbar>
              </CardContent>
            </Grid>
          </>
        )}

        {cardHeader === "Dashboard" && (
          <>
            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: cardColors && cardColors.item1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  <CampaignIcon sx={{ color: theme.palette.primary.light }} />
                </Box>
                <Typography
                  sx={{ color: theme.typography.fontColorCard, fontSize: 14 }}
                  variant='subtitle1'
                >
                  All ads
                </Typography>

                <Typography
                  variant='subtitle1'
                  sx={{
                    color: theme.palette.primary.tableLabel,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {numberOfItems}
                </Typography>
              </CardContent>
            </Grid>

            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ height: 61, alignSelf: "center" }}
            />

            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: cardColors && cardColors.item2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  <QueryBuilderOutlinedIcon
                    sx={{ height: 20, color: theme.palette.primary.light }}
                  />
                </Box>
                <Typography
                  sx={{ color: theme.typography.fontColorCard, fontSize: 14 }}
                  variant='subtitle1'
                >
                  Pending ads
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: theme.palette.primary.tableLabel,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {pendingAds?.length}
                </Typography>
              </CardContent>
            </Grid>

            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ height: 61, alignSelf: "center" }}
            />

            <Grid item xs={3}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%", // Use 100% of the parent's height
                }}
              >
                <Box
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: cardColors && cardColors.item3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  <ChatBubbleIcon
                    sx={{ height: 15, color: theme.palette.primary.light }}
                  />
                </Box>
                <Typography
                  sx={{ color: theme.typography.fontColorCard, fontSize: 14 }}
                  variant='subtitle1'
                >
                  Pending Messages
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: theme.palette.primary.tableLabel,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {CommentsList?.length}
                </Typography>
              </CardContent>
            </Grid>
          </>
        )}
      </Box>
      <LinearProgress
        variant={isLoading || trigger ? "indeterminate" : "determinate"}
        value={isLoading || trigger ? undefined : 100}
        sx={{
          mt: "-6px",
          height: "6px",
          background: backgroundColor,
          width: "100%", // Adjust the width to match the desired animation direction
          height: 6, // Adjust the height to match the desired animation direction
          transform: "rotate(0deg)", // Set the rotation angle to 0deg
          transformOrigin: "0 0",
        }}
      />
    </Card>
  );
}

export default UserCard;
