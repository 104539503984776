import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axiosInstance from "../../services/axiosInstance";
import {
  ACTIONS_URL,
  ASSETS_URL,
  BRANDS_URL,
  UPLOAD_MULTIPLE_URL,
} from "../../config";
import { AuthContext } from "../../context/authContext";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setBrands } from "./features/brandsReducer";

const EditBrandComponent = ({ brand }) => {
  const [values, setValues] = useState({
    name: brand.name,
    description: brand.description,
    id: brand.id,
    brandLogo: brand.brandLogo, // Assuming this is a URL or some identifier
    industry: brand.data?.industry || "", // Assuming industry is stored in brand.data
  });

  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();

  const getIndustries = () => {
    return [
      "Agriculture",
      "Automotive",
      "Banking",
      "Biotechnology",
      "Construction",
      "Consumer Goods",
      "Education",
      "Energy",
      "Entertainment",
      "Fashion",
      "Finance",
      "Food & Beverage",
      "Healthcare",
      "Hospitality",
      "Information Technology",
      "Insurance",
      "Manufacturing",
      "Media",
      "Mining",
      "Pharmaceuticals",
      "Real Estate",
      "Retail",
      "Telecommunications",
      "Transportation",
      "Utilities",
    ];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the payload for the server
      const payload = {
        id: values.id,
        name: values.name,
        description: values.description,
        brandLogo: values.brandLogo, // Use 'brandLogo' which now stores the server's file name
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers, // send clientId when updating brand
        data: {
          industry: values.industry, // Add industry to the data field
        },
      };

      // Submit the brand
      const response = await axiosInstance.put(
        `${BRANDS_URL}/${values.id}`,
        payload
      );

      dispatch(setBrands({ res: [response.data] }));

      // Log the action
      await axios.post(ACTIONS_URL, {
        actionType: "Edit",
        objectType: "Brand",
        userId: currentUser.id,
        clientId: currentUser.clientIdUsers, // Include the clientId from currentUser
        content: response.data, // Include the brand data as content
      });

      // navigate("/brands"); // Uncomment to navigate
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const files = Array.from(e.target.files);
      const formData = new FormData();

      formData.append("brand", values.name); // Ensure the brand name is appended correctly
      formData.append("type", "brandLogo"); // Set the type to "brandLogo"
      formData.append("clientId", currentUser.clientIdUsers); // Include clientId if necessary

      files.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await axios.post(UPLOAD_MULTIPLE_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const uploadedFileName = response.data[0].fileName;

        setValues((prevState) => ({
          ...prevState,
          brandLogo: uploadedFileName, // Update brandLogo with the new fileName
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit} sx={{ pl: 8, m: 3 }}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' gutterBottom>
          Edit Brand
        </Typography>
        {values.brandLogo && (
          <Box ml={3}>
            <img
              alt='Brand Logo'
              src={`${ASSETS_URL}/${values.brandLogo}`}
              style={{ width: 100, height: 100, objectFit: "contain" }}
            />
          </Box>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label='Brand ID'
            name='id'
            disabled
            value={values.id}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label='Brand Name'
            name='name'
            onChange={handleChange}
            required
            value={values.name}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label='Description'
            multiline
            rows={4}
            name='description'
            onChange={handleChange}
            required
            value={values.description}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel htmlFor='industry-select'>Industry</InputLabel>
            <Select
              id='industry-select'
              name='industry'
              value={values.industry}
              onChange={handleChange}
              label='Industry'
            >
              {getIndustries().map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant='outlined'>
            <Input
              id='logo-upload'
              type='file'
              onChange={handleImageChange}
              sx={{ display: "none" }}
              inputProps={{ accept: "image/*" }}
            />
            <Button
              fullWidth
              variant='contained'
              component='label'
              htmlFor='logo-upload'
              sx={{ mt: 1 }}
              startIcon={<PhotoCamera />}
            >
              Upload Logo
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant='contained' color='primary' type='submit'>
            Update Brand
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBrandComponent;
