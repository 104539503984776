import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import {
  Button,
  Avatar,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  //   Link,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdreadyImg from "../images/adreadyimg.jpg";

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {"Copyright © "}
      <Link color='inherit' href='https://mui.com/'>
        AdReady
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const validateUserInputs = () => {
    return inputs.username.length && inputs.password.length;
  };

  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { signIn, currentUser } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // SUBMIT FORM FOR SIGNIN
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signIn(inputs);
      // Remove the navigate("/") from here
    } catch (error) {
      setError(error.response.data);
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            background: `url(${AdreadyImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                background: "linear-gradient(45deg, #293e6f, #a06eaf)",
              }}
            >
              <LockOutlinedIcon />
            </Avatar>

            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                color='primary'
                margin='normal'
                required
                fullWidth
                name='username'
                label='Username'
                type='username'
                id='username'
                autoComplete='current-username'
                onChange={handleChange}
                variant='outlined'
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#a06eaf",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#a06eaf",
                    },
                    "&.Mui-focused .MuiOutlinedInput-input": {
                      color: "#a06eaf",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#a06eaf",
                  },
                }}
              />
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              /> */}
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#a06eaf",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#a06eaf",
                    },
                    "&.Mui-focused .MuiOutlinedInput-input": {
                      color: "#a06eaf",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#a06eaf",
                  },
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value='remember'
                    color='primary'
                    sx={{
                      "&.Mui-checked": {
                        color: "#a06eaf",
                      },
                    }}
                  />
                }
                label='Remember me'
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  mt: 3,
                  mb: 2,
                  background: "linear-gradient(45deg, #293e6f, #a06eaf)",
                  color: "white", // Add this line
                }}
                disabled={!validateUserInputs()}
              >
                Sign In
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
