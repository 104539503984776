import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SocialNetworksShareModal from "./SocialNetworksShareModal";
import axios from "axios";
import { AuthContext } from "../../../context/authContext";

const ShareOptionsModal = ({ open, onClose, data }) => {
  const [socialNetworksOpen, setSocialNetworksOpen] = useState(false);
  const [pages, setPages] = useState([]);
  const currentUser = useContext(AuthContext); // Use AuthContext to get currentUser

  const handleSocialNetworksClick = () => {
    setSocialNetworksOpen(true);
    onClose(); // Close the ShareOptionsModal
  };

  const handleEmailClick = () => {
    // Handle email sharing logic here
    onClose(); // Close the ShareOptionsModal
  };

  const handleWhatsAppClick = () => {
    // Handle WhatsApp sharing logic here
    onClose(); // Close the ShareOptionsModal
  };

  const handleSocialNetworksClose = () => {
    setSocialNetworksOpen(false);
  };

  const fetchLinkedInPages = async () => {
    try {
      const response = await axios.post("/api/linkedin/list-pages", {
        clientId: currentUser.currentUser.clientIdUsers, // Pass the clientId from currentUser
      });
      setPages(response.data);
    } catch (error) {
      console.error("Error fetching LinkedIn pages:", error);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
        <DialogTitle>Share via</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleSocialNetworksClick} color='primary'>
                <ShareIcon />
              </IconButton>
              <Typography variant='caption'>Social Network</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleEmailClick} color='primary'>
                <EmailIcon />
              </IconButton>
              <Typography variant='caption'>Email</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleWhatsAppClick} color='primary'>
                <WhatsAppIcon />
              </IconButton>
              <Typography variant='caption'>WhatsApp</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={fetchLinkedInPages} color='primary'>
            Fetch LinkedIn Pages
          </Button>
          <Button onClick={onClose} color='secondary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SocialNetworksShareModal
        open={socialNetworksOpen}
        onClose={handleSocialNetworksClose}
        data={data}
      />
      {pages.length > 0 && (
        <Dialog
          open={true}
          onClose={() => setPages([])}
          maxWidth='xs'
          fullWidth
        >
          <DialogTitle>LinkedIn Pages</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {pages.map((page) => (
                <Typography key={page}>{page}</Typography>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPages([])} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ShareOptionsModal;
