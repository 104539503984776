  export const formatNotification = (
    actionType,
    objectType,
    targetUserId,
    currentUserId
  ) => {
    let action = "";
    switch (actionType) {
      case "Login":
      case "Logout":
        action = actionType === "Login" ? "Logged in" : "Logged out";
        return action; // Return early for Login and Logout, no object type needed
      case "Create":
        if (objectType === "Message" && targetUserId === currentUserId) {
          return "Sent you a message";
        }
        action = "Created";
        break;
      case "Delete":
        action = "Deleted";
        break;
      case "Update":
        action = "Updated";
        break;
      case "Upload":
        action = "Uploaded";
        break;
      case "SendMessage":
        action = "Sent";
        break;
      case "SentForApproval":
        return `SENT an ${objectType} for approval`;
      case "Approve":
        action = "Approved";
        break;
      case "Reject":
        action = "Rejected";
        break;
      case "Download":
        action = "Downloaded";
        break;
      case "Translate":
        action = "Translated";
        break;
      case "Send":
        action = "Sent";
        break;
      case "Edit":
        action = "Edited";
        break;
      default:
        action = actionType;
        break;
    }

    let object = "";
    switch (objectType) {
      case "Ad":
        object = "an Ad";
        break;
      case "User":
        object = "a User";
        break;
      case "Client":
        object = "a Client";
        break;
      case "Brand":
        object = "a Brand";
        break;
      case "Category":
        object = "a Category";
        break;
      case "Template":
        object = "a Template";
        break;
      case "Message":
        object = "a Message";
        break;
      case "Image":
        object = "Images";
        break;
      default:
        object = objectType.toLowerCase();
        break;
    }

    return `${action} ${object}`;
  };
