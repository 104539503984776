import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ASSETS_URL } from "../../../config";
import { useFetchBrandData } from "../../../components/hooks/useFetchBrandData";

const ProductSelector = ({
  // selectedBrand,
  selectedProduct,
  setSelectedProduct,
  setValues,
  values,
}) => {
  const allProducts = useSelector((store) => store.products);
  const allBrands = useSelector((store) => store.brands); // Assuming you have brands in your Redux store
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("");

  const filteredProducts = allProducts.filter((product) => {
    const brandMatch =
      selectedBrand === "" || product.brandId === selectedBrand;

    const productName = product?.data?.productName ?? ""; // Fallback to an empty string if undefined
    const fileName = product?.data?.fileName ?? ""; // Fallback to an empty string if undefined

    const searchTermMatch =
      !searchTerm ||
      productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fileName.toLowerCase().includes(searchTerm.toLowerCase());

    // Check if selectedSeries matches product.data.selectedSeries directly
    const seriesMatch =
      !selectedSeries || product.data.selectedSeries === selectedSeries;

    console.log(selectedSeries);

    let processorFlagMatch = true;
    if (values.brand === "Amd") {
      processorFlagMatch = product.data.flags?.processorAMD ?? false;
    } else if (values.brand === "Intel") {
      processorFlagMatch = product.data.flags?.processorIntel ?? false;
    }

    return brandMatch && searchTermMatch && seriesMatch && processorFlagMatch;
  });

  const handleSeriesSelection = (series) => {
    setSelectedSeries((prevSeries) => (prevSeries === series ? null : series));
  };

  useFetchBrandData(selectedBrand);

  const handleImageClick = (fileName, baseWidth, baseHeight) => {
    const selected = allProducts.find(
      (product) => product.data.fileName === fileName
    );
    setSelectedProduct(selected);

    setValues((prevValues) => {
      const layoutSettings = selected.data.layout || {};

      const updatedPositions = Object.keys(prevValues.positions).reduce(
        (acc, key) => {
          if (key !== "background") {
            acc[key] = { ...prevValues.positions[key], ...layoutSettings[key] };
          } else {
            acc[key] = { ...prevValues.positions[key] };
          }
          return acc;
        },
        {}
      );

      const updatedProductPositions = updatedPositions.product || {};
      Object.keys(updatedProductPositions).forEach((resolutionKey) => {
        const [resWidth, resHeight] = resolutionKey.split("x").map(Number);
        let scalingFactor = 1;
        let maxDimension =
          resWidth > resHeight ? resHeight * 0.7 : resWidth * 0.7;

        if (resWidth > resHeight) {
          if (baseHeight > maxDimension) {
            scalingFactor = maxDimension / baseHeight;
          }
        } else {
          if (baseWidth > maxDimension) {
            scalingFactor = maxDimension / baseWidth;
          }
        }

        const resizedWidth = baseWidth * scalingFactor;
        const resizedHeight = baseHeight * scalingFactor;

        let x =
          (updatedProductPositions[resolutionKey].x || 0) -
          (resizedWidth - updatedProductPositions[resolutionKey].width) / 2;
        let y =
          (updatedProductPositions[resolutionKey].y || 0) -
          (resizedHeight - updatedProductPositions[resolutionKey].height) / 2;

        if (x + resizedWidth > resWidth) {
          x = resWidth - resizedWidth;
        }
        if (y + resizedHeight > resHeight) {
          y = resHeight - resizedHeight;
        }

        updatedProductPositions[resolutionKey] = {
          ...updatedProductPositions[resolutionKey],
          imageUrl: fileName,
          width: resizedWidth,
          height: resizedHeight,
          x,
          y,
        };
      });

      return {
        ...prevValues,
        positions: {
          ...updatedPositions,
          product: updatedProductPositions,
          productData: selected.data, // Add the selected product data to positions
        },
      };
    });
  };

  useEffect(() => {
    if (selectedProduct && values.positions.adType) {
      setValues((prevValues) => {
        const updatedLogo = { ...prevValues.positions.logoBrand1 };
        let updatedBadge = prevValues.positions.badge
          ? { ...prevValues.positions.badge }
          : {};

        const adTypeKey = Object.keys(prevValues.positions.adType).find(
          (key) => prevValues.positions.adType[key]
        );

        if (!adTypeKey) {
          console.log("No adTypeKey found.");
          return prevValues;
        }

        const isPositiveBackground =
          values.positions.background?.["1200x630"]?.positive ?? false;
        console.log("Background Positive:", isPositiveBackground);

        const textColor = isPositiveBackground
          ? "rgba(94, 94, 94, 1)"
          : "white";

        const updateTextColor = (positionsKey) => {
          const updatedPositions = { ...prevValues.positions[positionsKey] };
          Object.keys(updatedPositions).forEach((resolutionKey) => {
            updatedPositions[resolutionKey] = {
              ...updatedPositions[resolutionKey],
              textColor,
            };
          });
          return updatedPositions;
        };

        const updatedHeadline = updateTextColor("headline");
        const updatedSubHeadline = updateTextColor("subHeadline");
        const updatedDisclaimer = updateTextColor("disclaimer");

        if (
          selectedProduct.data.logos &&
          selectedProduct.data.logos.length > 0
        ) {
          console.log("Product Logos:", selectedProduct.data.logos);

          const matchingLogo = selectedProduct.data.logos.find(
            (logo) =>
              logo.data.flags &&
              logo.data.flags[adTypeKey] &&
              logo.data.flags.positive === isPositiveBackground
          );

          if (matchingLogo) {
            const logoFileName = matchingLogo.data.fileName ?? "";
            const logoWidth = matchingLogo.data.width ?? 0;
            const logoHeight = matchingLogo.data.height ?? 0;
            console.log("Matching Logo Found:", matchingLogo);

            Object.keys(updatedLogo).forEach((resolutionKey) => {
              const [resWidth, resHeight] = resolutionKey
                .split("x")
                .map(Number);
              let scalingFactor = 1;
              let maxDimension =
                resWidth > resHeight ? resHeight * 0.7 : resWidth * 0.7;

              if (resWidth > resHeight) {
                if (logoHeight > maxDimension) {
                  scalingFactor = maxDimension / logoHeight;
                }
              } else {
                if (logoWidth > maxDimension) {
                  scalingFactor = maxDimension / logoWidth;
                }
              }

              const resizedWidth = logoWidth * scalingFactor;
              const resizedHeight = logoHeight * scalingFactor;

              let logoX = updatedLogo[resolutionKey]?.x ?? 0;
              let logoY = updatedLogo[resolutionKey]?.y ?? 0;

              if (logoX + resizedWidth > resWidth) {
                logoX = resWidth - resizedWidth;
              }
              if (logoY + resizedHeight > resHeight) {
                logoY = resHeight - resizedHeight;
              }

              updatedLogo[resolutionKey] = {
                ...updatedLogo[resolutionKey],
                imageUrl: logoFileName,
                width: resizedWidth,
                height: resizedHeight,
                x: logoX,
                y: logoY,
              };
            });
          } else {
            console.log(
              "No matching logo found for:",
              adTypeKey,
              isPositiveBackground
            );
          }
        }

        if (adTypeKey === "etailMarketing") {
          console.log("Checking badges for 'etailMarketing'");
          const matchingBadge = selectedProduct.data.badges?.find(
            (badge) =>
              badge.data.flags &&
              badge.data.flags[adTypeKey] &&
              badge.data.flags.positive === isPositiveBackground
          );

          if (matchingBadge) {
            console.log("Matching Badge Found:", matchingBadge);
            const badgeAspectRatio =
              matchingBadge.data.width / matchingBadge.data.height;
            const isBadgeBoxShaped =
              badgeAspectRatio > 0.9 && badgeAspectRatio < 1.1;

            Object.keys(prevValues.positions.product).forEach(
              (resolutionKey) => {
                const [resWidth, resHeight] = resolutionKey
                  .split("x")
                  .map(Number);
                const productPosition =
                  prevValues.positions.product[resolutionKey];
                const badgeFileName = matchingBadge?.data.fileName ?? "";

                let badgeWidth, badgeHeight;

                const isBannerHorizontal = resWidth > resHeight;

                if (isBannerHorizontal) {
                  if (isBadgeBoxShaped) {
                    badgeHeight = resHeight * 0.2;
                    badgeWidth = badgeHeight;
                  } else {
                    badgeHeight = resHeight * 0.2;
                    badgeWidth = badgeHeight * badgeAspectRatio;
                  }
                } else {
                  badgeWidth = resWidth * 0.2;
                  badgeHeight = badgeWidth / badgeAspectRatio;
                }

                updatedBadge[resolutionKey] = {
                  imageUrl: badgeFileName,
                  width: badgeWidth,
                  height: badgeHeight,
                  x: productPosition.x,
                  y: productPosition.y,
                  visible: true,
                  editable: true,
                  zindex: 12,
                };
              }
            );
          } else {
            console.log(
              "No matching badge found for:",
              adTypeKey,
              isPositiveBackground
            );
          }
        }

        return {
          ...prevValues,
          positions: {
            ...prevValues.positions,
            logoBrand1: updatedLogo,
            badge: updatedBadge,
            headline: updatedHeadline,
            subHeadline: updatedSubHeadline,
            disclaimer: updatedDisclaimer,
            productData: selectedProduct.data, // Add the selected product data to positions
          },
        };
      });
    }
  }, [selectedProduct, values.positions.adType]);

  const resetSelection = () => {
    setSelectedProduct(null);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  console.log(selectedProduct);
  return (
    <Box sx={{ width: "100%", mt: -2 }}>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        {!selectedProduct && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Adjust for space between elements
              alignItems: "center",
              width: "100%", // Full width of the container
              // transform: "scale(0.75)",
              // ml: -8,
            }}
          >
            <Select
              value={selectedBrand}
              onChange={handleBrandChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                mt: -0.5,
                width: "10%", // Half of the original 15%
                height: "38px", // Reduced height
                ".MuiSelect-select": {
                  paddingTop: "3px", // Smaller padding
                  paddingBottom: "3px",
                  fontSize: "0.8rem", // Smaller font size
                },
                ".MuiOutlinedInput-notchedOutline": {
                  top: 0,
                },
              }}
            >
              <MenuItem value='' sx={{ fontSize: "0.8rem" }}>
                <em>All Brands</em>
              </MenuItem>
              {allBrands.map((brand) => (
                <MenuItem
                  key={brand.id}
                  value={brand.id}
                  sx={{ fontSize: "0.8rem" }}
                >
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label='Search Products'
              variant='outlined'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: "11%", // Smaller width
                ".MuiInputLabel-root": {
                  // Smaller label size
                  fontSize: "0.8rem",
                  mt: -1, // Adjust margin top
                },
                ".MuiInputLabel-root.Mui-focused": {
                  // Adjust label position when TextField is focused
                  transform: "translate(20px, 0px) scale(0.75)", // Custom transform for focused state
                },
                ".MuiInputBase-input": {
                  // Smaller text size inside the TextField
                  fontSize: "0.75rem",
                  padding: "8px 14px", // Adjust padding for smaller height
                },
              }}
            />

            <Stack direction='row' spacing={1}>
              {[
                "AMD RYZEN 7000",
                "AMD RYZEN 6000",
                "AMD RYZEN 5000",
                "AMD RADEON RX 7000",
                "AMD RADEON RX 6000",
              ].map((series) => (
                <Chip
                  key={series}
                  label={series}
                  color='primary'
                  variant={selectedSeries === series ? "filled" : "outlined"}
                  onClick={() => handleSeriesSelection(series)}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%", height: 400, overflowY: "scroll" }}>
        {selectedProduct ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
              mx: 2,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-around", // Changed to space-around for better spacing
              }}
            >
              <Typography
                variant='subtitle1'
                sx={{ maxWidth: "30%", textAlign: "left" }} // Adjusted maxWidth for better balance
              >
                {selectedProduct.data.productName}
              </Typography>
              <img
                src={`${ASSETS_URL}/${selectedProduct.data.fileName}`}
                alt={selectedProduct.data.fileName}
                loading='lazy'
                style={{
                  maxWidth: "40%", // Adjust image size
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant='subtitle1'
                sx={{ maxWidth: "30%", textAlign: "right" }} // Adjusted maxWidth for better balance
              >
                {selectedProduct.data.productDescription}
              </Typography>
            </Box>
            <Button onClick={resetSelection} variant='outlined' sx={{ mt: 2 }}>
              Select Another Product
            </Button>
          </Box>
        ) : (
          <ImageList variant='quilted' cols={5} gap={10} rowHeight={164}>
            {filteredProducts.map((product) => (
              <ImageListItem
                key={product.id}
                onClick={() =>
                  handleImageClick(
                    product.data.fileName,
                    product.data.width,
                    product.data.height
                  )
                }
                sx={{
                  display: "flex",
                  flexDirection: "column", // Change to column for vertical stacking
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  cursor: "pointer",
                }}
              >
                <img
                  src={`${ASSETS_URL}/${product.data.fileName}`}
                  alt={product.data.fileName}
                  loading='lazy'
                  style={{
                    maxWidth: "80%",
                    maxHeight: "80%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                {product.data.productName && (
                  <Typography
                    variant='body2'
                    sx={{ mt: 1, textAlign: "center" }}
                  >
                    {product.data.productName}
                  </Typography>
                )}
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
    </Box>
  );
};

export default ProductSelector;
