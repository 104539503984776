import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setEditingComponent } from "../../../features/editingComponentSlices";
import { ASSETS_URL } from "../../../../../config";
import Moveable from "react-moveable";
import { cloneDeep } from "lodash";

// Define the order and corresponding zIndex values
const zIndexMapping = {
  logoBrand1: 900,
  logoBrand2: 800,
  logoBrand3: 700,
  logoThirdParty: 600,
  badge: 500,
  sticker1: 400,
  cta: 300,
  product: 200,
  background: 100,
};

const DraggableResizableImage = ({
  item,
  values,
  resolution,
  handleElementDragResize,
  toCamelCase,
  setEditingResolution,
  editable,
  editingResolution,
  verticalGuidelines,
  horizontalGuidelines,
}) => {
  const lowercaseItem = item.charAt(0).toLowerCase() + item.slice(1);

  const [bannerWidth, bannerHeight] = resolution.split("x").map(Number);

  const moveableRef = useRef(null);

  // Read initial rotation value from values
  const initialRotation =
    values?.positions?.[lowercaseItem]?.[resolution]?.rotation || 0;
  const [rotation, setRotation] = useState(initialRotation);

  // UseEffect to update rotation state when values.positions changes
  useEffect(() => {
    const newRotation =
      values?.positions?.[lowercaseItem]?.[resolution]?.rotation || 0;
    setRotation(newRotation);
  }, [values.positions, lowercaseItem, resolution]);

  const camelCaseItem = toCamelCase(item);

  const targetRef = useRef(null);

  const editingComponent = useSelector((state) => state.editingComponent);

  const logosWithVariant = [
    "LogoBrand1",
    "LogoBrand2",
    "LogoBrand3",
    "LogoThirdParty",
  ];
  const isLogo = logosWithVariant.includes(camelCaseItem);

  // Fetch the image URL from values.positions for logos
  const positionData = values.positions[lowercaseItem]?.[resolution];
  const defaultImageUrl = positionData?.imageUrl
    ? `${ASSETS_URL}/${positionData.imageUrl}`
    : null;
  let imageUrl = defaultImageUrl;

  const [isElementEditable, setIsElementEditable] = useState(false);

  // Extract border style from values state
  const getBorderStyle = () => {
    if (
      values.positions &&
      values.positions[lowercaseItem] &&
      values.positions[lowercaseItem][resolution]
    ) {
      const borderStyle =
        values.positions[lowercaseItem][resolution].borderStyle;
      return borderStyle && borderStyle !== "none"
        ? borderStyle
        : "1px solid transparent";
    }
    return "1px solid transparent";
  };

  // Extract flipping transform values from values state
  const transformFlip =
    values?.positions?.[lowercaseItem]?.[resolution]?.transform || "";

  useEffect(() => {
    if (isLogo) {
      setIsElementEditable(editable);
    } else {
      setIsElementEditable(
        values?.positions?.[lowercaseItem]?.[resolution]?.editable || false
      );
    }
  }, [values, item, resolution, isLogo, editable]);

  const initialPosition = {
    x: values?.positions?.[lowercaseItem]?.[resolution]?.x || 0,
    y: values?.positions?.[lowercaseItem]?.[resolution]?.y || 0,
  };

  const initialSize = {
    width: values?.positions?.[lowercaseItem]?.[resolution]?.width || 200,
    height: values?.positions?.[lowercaseItem]?.[resolution]?.height || 200,
  };

  const [localPosition, setLocalPosition] = useState(initialPosition);
  const [localSize, setLocalSize] = useState(initialSize);
  const dispatch = useDispatch();

  // Use the zIndex from the mapping

  useEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  }, [localPosition, localSize, rotation]);

  const opacity =
    values?.positions?.[lowercaseItem]?.[resolution]?.opacity || 1;

  // Define boxShadow style for images from values.positions
  const boxShadow =
    values?.positions?.[lowercaseItem]?.[resolution]?.boxShadow || "none";

  useEffect(() => {
    setLocalPosition(initialPosition);
    setLocalSize(initialSize);
  }, [values, item, resolution, isLogo]);

  const isVisible = values?.positions?.[lowercaseItem]?.[resolution]?.visible;
  if (!isVisible) return null;

  const handleElementClick = (component, resolution) => {
    dispatch(setEditingComponent(component));
    setEditingResolution(resolution);
  };

  const onDrag = ({ target, beforeTranslate }) => {
    // Combine translate and rotate in transform
    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${rotation}deg)`;
    setLocalPosition({ x: beforeTranslate[0], y: beforeTranslate[1] });
  };

  const onResize = ({ target, width, height, drag }) => {
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
    setLocalSize({ width, height });
    setLocalPosition({
      x: drag.beforeTranslate[0],
      y: drag.beforeTranslate[1],
    });
  };

  const onResizeEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize },
      item,
      true,
      resolution
    );
    // Retain the position after resizing
    setLocalPosition(localPosition);
  };

  const onDragEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize, rotation },
      item,
      false,
      resolution
    );
  };

  const onRotate = ({ beforeRotate }) => {
    setRotation(beforeRotate);
  };

  const onRotateEnd = () => {
    handleElementDragResize(
      null,
      { ...localPosition, ...localSize, rotation },
      item,
      false,
      resolution
    );
  };

  if (!isVisible) return null;

  const borderStyle = getBorderStyle(); // Get border style for the image
  // Modify the zIndex logic for the logo
  // Determine zIndex: use the value from values if set, otherwise use initial from mapping
  const zIndex =
    values.positions[lowercaseItem]?.[resolution]?.zIndex ||
    zIndexMapping[lowercaseItem] ||
    1;
  const isCurrentEditingComponent = editingComponent === item;

  const handleImageLoad = (e) => {
    const img = e.target;
    const { width, height } = img;
    setLocalSize({ width, height });
    handleElementDragResize(
      null,
      { ...localPosition, width, height, rotation },
      item,
      true,
      resolution
    );
  };

  return (
    <>
      {isCurrentEditingComponent && resolution === editingResolution && (
        <Moveable
          ref={moveableRef}
          target={targetRef.current}
          keepRatio={true}
          draggable={isElementEditable}
          resizable={isElementEditable}
          rotatable={isElementEditable}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          onResize={onResize}
          onResizeEnd={onResizeEnd}
          onRotate={onRotate}
          onRotateEnd={onRotateEnd}
          snappable={true} // Enable snapping
          snapThreshold={10} // Set snap threshold
          snapDirections={{
            left: true,
            top: true,
            right: true,
            bottom: true,
            center: true,
            middle: true,
          }} // Snap directions
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={[]} // Element guidelines, add elements if needed
          snapRotataionThreshold={5}
          snapRotationDegrees={[0, 30, 60, 90, 120, 150, 180, 210, 240, 270]}
          style={{
            position: "absolute",
            width: `${localSize.width}px`,
            height: `${localSize.height}px`,
            transform: `translate(${localPosition.x}px, ${localPosition.y}px) rotate(${rotation}deg) ${transformFlip}`,
            border: borderStyle,
            zIndex: zIndex,
            boxSizing: "border-box",
          }}
          onClick={() => handleElementClick(item, resolution)}
        />
      )}
      <div
        ref={targetRef}
        style={{
          position: "absolute",
          width: `${localSize.width}px`,
          height: `${localSize.height}px`,
          transform: `translate(${localPosition.x}px, ${localPosition.y}px) rotate(${rotation}deg) ${transformFlip}`,
          border: borderStyle,
          zIndex: zIndex,
          boxSizing: "border-box",
        }}
        onClick={() => handleElementClick(item, resolution)}
      >
        <img
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            opacity: opacity,
            boxShadow: boxShadow,
            objectFit: "cover",
          }}
          alt=''
          src={imageUrl}
          onLoad={handleImageLoad}
        />
      </div>
    </>
  );
};

export default DraggableResizableImage;
