import React from "react";
import {
  Box,
  Typography,
  Slider,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const TextSpacingMenu = ({
  anchorEl,
  handleClose,
  letterSpacing,
  handleLetterSpacingChange,
  lineHeight,
  handleLineHeightChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              fontSize: isMobile ? "0.6rem" : isTablet ? "0.65rem" : "0.7rem",
            }}
            variant='body2'
          >
            Letter Spacing:
          </Typography>
          <Slider
            size='small'
            sx={{ minWidth: isMobile ? 80 : isTablet ? 90 : 100 }}
            value={letterSpacing}
            onChange={(e, newValue) => handleLetterSpacingChange(newValue)}
            step={0.1}
            min={-5}
            max={10}
            valueLabelDisplay='auto'
          />
        </Box>
      </MenuItem>

      <MenuItem>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant='body2'
            sx={{
              fontSize: isMobile ? "0.6rem" : isTablet ? "0.65rem" : "0.7rem",
            }}
          >
            Line Height:
          </Typography>
          <Slider
            size='small'
            sx={{ minWidth: isMobile ? 80 : isTablet ? 90 : 100 }}
            value={lineHeight}
            onChange={(e, newValue) => handleLineHeightChange(newValue)}
            step={0.1}
            min={0.5}
            max={3}
            valueLabelDisplay='auto'
          />
        </Box>
      </MenuItem>
    </Menu>
  );
};

export default TextSpacingMenu;
