import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layouts",
  initialState: [],

  reducers: {
    addLayout: (state, action) => {
      state.push(action.payload);
    },
    setLayouts: (state, action) => {
      return action.payload;
    },
    updateLayout: (state, action) => {
      const index = state.findIndex(
        (layout) => layout.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteLayout: (state, action) => {
      return state.filter((layout) => layout.id !== action.payload.id);
    },
    updateLayouts: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  addLayout,
  setLayouts,
  updateLayout,
  deleteLayout,
  updateLayouts,
} = layoutSlice.actions;
export default layoutSlice.reducer;
