import React from "react";
import {
  Box,
  Button,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import {
  alignElementBottom,
  alignElementLeft,
  alignElementRight,
  alignElementTop,
  centerElementHorizontally,
  centerElementVertically,
} from "../helpers/alignmentHelpersalignmentHelpers";

const AlignmentButtons = ({
  editingComponent,
  editingResolution,
  values,
  bannerWidth,
  bannerHeight,
  handleElementDragResize,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        // gap: 1,
        // p: 2,
      }}
    >
      <ToggleButtonGroup
        exclusive={false}
        sx={{
          "& .MuiToggleButton-root": {
            padding: "6px",
            "& .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
          },
        }}
      >
        <Tooltip title='Align item left' arrow>
          <ToggleButton
            value='alignLeft'
            onClick={() =>
              alignElementLeft(
                editingComponent,
                editingResolution,
                values,
                handleElementDragResize
              )
            }
          >
            <AlignHorizontalLeftIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip title='Center item horizontally' arrow>
          <ToggleButton
            value='centerHorizontal'
            onClick={() =>
              centerElementHorizontally(
                editingComponent,
                editingResolution,
                values,
                bannerWidth,
                handleElementDragResize
              )
            }
          >
            <AlignHorizontalCenterIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip title='Align item right' arrow>
          <ToggleButton
            value='alignRight'
            onClick={() =>
              alignElementRight(
                editingComponent,
                editingResolution,
                values,
                bannerWidth,
                handleElementDragResize
              )
            }
          >
            <AlignHorizontalRightIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip title='Align item top' arrow>
          <ToggleButton
            value='alignTop'
            onClick={() =>
              alignElementTop(
                editingComponent,
                editingResolution,
                values,
                handleElementDragResize
              )
            }
          >
            <AlignVerticalTopIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip title='Center item vertically' arrow>
          <ToggleButton
            value='centerVertical'
            onClick={() =>
              centerElementVertically(
                editingComponent,
                editingResolution,
                values,
                bannerHeight,
                handleElementDragResize
              )
            }
          >
            <AlignVerticalCenterIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip title='Align item bottom ' arrow>
          <ToggleButton
            value='alignBottom'
            onClick={() =>
              alignElementBottom(
                editingComponent,
                editingResolution,
                values,
                bannerHeight,
                handleElementDragResize
              )
            }
          >
            <AlignVerticalBottomIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Box>
  );
};

export default AlignmentButtons;
