import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const ColorInfoCard = ({ editingResolution }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const editingComponent = useSelector((state) => state.editingComponent);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        height: "auto",
        gap: isMobile ? 0 : 1,
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: isMobile ? 1 : 0,
        }}
      >
        <Typography
          variant='subtitle2'
          sx={{
            marginLeft: 2,
            fontSize: isMobile ? "0.7em" : "0.8em",
            color: theme.typography.fontColor,
            fontWeight: 400,
          }}
        >
          EDITING:
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            marginLeft: 2,
            fontSize: isMobile ? "0.7em" : "0.8em",
            color: theme.typography.fontColor,
            fontWeight: 700,
          }}
        >
          {editingComponent.toUpperCase()}
        </Typography>
      </Box>
      <Divider
        orientation={isMobile ? "horizontal" : "vertical"}
        flexItem
        sx={{ bgcolor: theme.palette.primary.light }}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant='subtitle2'
          sx={{
            marginRight: 2,
            fontSize: isMobile ? "0.7em" : "0.8em",
            color: theme.typography.fontColor,
            fontWeight: 400,
          }}
        >
          RESOLUTION:
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            marginRight: 2,
            fontSize: isMobile ? "0.7em" : "0.8em",
            color: theme.typography.fontColor,
            fontWeight: 700,
          }}
        >
          {editingResolution.toUpperCase()}
        </Typography>
      </Box>
    </Card>
  );
};

export default ColorInfoCard;
