import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdCardNewAd = ({ setShowWizardModal }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => setShowWizardModal(true)}
      sx={{
        position: "relative",
        maxWidth: "100%",
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "2px 2px 25px 2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <AddIcon color="primary" sx={{ mr: 1 }} />

                <Typography color="text.secondary" variant="h6" component="div">
                  Create new Ad
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
};

export default AdCardNewAd;
