import React from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function PaginationAppBar({
  page,
  totalPages,
  onPrevPage,
  onNextPage,
  setPage,
}) {
  const theme = useTheme();
  const handleFirstPageButtonClick = (event) => {
    setPage(0);
  };

  const handleBackButtonClick = (event) => {
    if (page > 0) {
      onPrevPage();
    }
  };

  const handleNextButtonClick = (event) => {
    if (page < totalPages - 1) {
      onNextPage();
    }
  };

  const handleLastPageButtonClick = (event) => {
    setPage(totalPages - 1);
  };
  return (
    <AppBar
      position='relative'
      sx={{
        top: "auto",
        bottom: 0,
        maxWidth: "100%",
        background: "transparent",
        color: theme.palette.primary.dark,
      }}
    >
      <Toolbar>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1}>
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography variant='subtitle1'>
          Page {page + 1} of {totalPages}
        </Typography>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= totalPages}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= totalPages}
        >
          <LastPageIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default PaginationAppBar;
