import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const CreateCampaign = ({
  data,
  selectedResolution,
  handleResolutionChange,
  isGenerating,
  generatedImage,
  isPublishing,
}) => {
  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Resolution</InputLabel>
        <Select
          value={selectedResolution}
          onChange={handleResolutionChange}
          label='Resolution'
        >
          {data?.positions?.resolutions?.map((resolution) => (
            <MenuItem key={resolution} value={resolution}>
              {resolution}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isGenerating && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {generatedImage && (
        <Box sx={{ position: "relative" }}>
          <Box
            component='img'
            src={generatedImage}
            alt='Generated Ad Preview'
            sx={{
              width: "100%",
              height: "auto",
              mt: 2,
              filter: isPublishing ? "blur(4px)" : "none",
            }}
          />
          {isPublishing && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CreateCampaign;
