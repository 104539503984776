import React, { useState } from "react";
import { AppBar, Grid, Typography, IconButton, Toolbar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LayoutCard from "./LayoutCard";

const LayoutsGrid = ({
  layoutsList,
  layoutImage,
  values,
  setValues,
  existingAd,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDelete = (index) => {
    // Here, implement the logic to delete a layout card
  };

  return (
    <>
      <AppBar
        position='static'
        sx={{
          mb: 1,
          height: 30,
          justifyContent: "center",
          background: "linear-gradient(45deg, #A06EAF 30%, #80AFDE 90%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: 14 }} variant='h6' component='div'>
            SELECT LAYOUT
          </Typography>
          <IconButton
            sx={{ ml: 1.5 }}
            edge='end'
            color='inherit'
            onClick={handleDrawerToggle}
          >
            {drawerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      {drawerOpen && (
        <Grid sx={{ p: 1 }} container spacing={1}>
          {layoutsList
            .filter((layout) => layout.type.includes("4"))
            .map((layout, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <LayoutCard
                  existingAd={existingAd}
                  values={values}
                  setValues={setValues}
                  layout={layout}
                  layoutImage={layoutImage}
                  handleDelete={() => handleDelete(index)}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

export default LayoutsGrid;
