import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import PostSelectionGrid from "../social-media/PostSelectionGrid";

const CreateCampaignFromPost = ({
  promotablePosts,
  handlePostSelection,
  selectedPostId,
  selectedPostCaption,
  isGenerating,
  generatedImage,
  isPublishing,
}) => {
  return (
    <>
      <Box minWidth={800}>
        <PostSelectionGrid
          posts={promotablePosts}
          onSelectPost={handlePostSelection}
          selectedPostId={selectedPostId}
        />
      </Box>
      {selectedPostCaption && (
        <>
          <Typography variant='subtitle1'>Caption:</Typography>
          <Typography variant='body1' sx={{ mb: 2 }}>
            {selectedPostCaption}
          </Typography>
        </>
      )}
      {isGenerating && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {generatedImage && (
        <Box sx={{ position: "relative" }}>
          <Box
            component='img'
            src={generatedImage}
            alt='Generated Ad Preview'
            sx={{
              width: "100%",
              height: "auto",
              mt: 2,
              filter: isPublishing ? "blur(4px)" : "none",
            }}
          />
          {isPublishing && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default CreateCampaignFromPost;
