import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./SyncFigmaStatus.css"; // Importing the CSS file

const SyncFigmaStatus = ({ progressValue, latestItem, resolutionData }) => {
  const progressBar = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: 400 }}>
        <LinearProgress
          variant="buffer"
          value={progressValue}
          sx={{
            backgroundColor: "#F0F0F0",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#80AFDE",
            },
          }}
        />
      </Box>
      <Typography color="text.secondary" sx={{ ml: 1 }}>
        {`${progressValue}%`}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: -2,
          width: "95%",
        }}
      >
        <Button
          sx={{
            width: "30%",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            mb: 3,
          }}
        >
          <TransitionGroup>
            <CSSTransition
              key={latestItem.elementName}
              timeout={500}
              classNames="rotate"
            >
              <Typography
                variant="body1"
                color="third.main"
                sx={{ position: "absolute" }}
              >
                {latestItem.elementName}
              </Typography>
            </CSSTransition>
          </TransitionGroup>
        </Button>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Box
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            "{resolutionData.resolutionName}"
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Box
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            with ADREADY
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>{progressBar}</Box>
    </Box>
  );
};

export default SyncFigmaStatus;
