import React, { useState } from "react";
import {
  ImageList,
  ImageListItem,
  Modal,
  Box,
  IconButton,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // Icon for visual feedback
import { useSelector } from "react-redux";
import { ASSETS_URL } from "../../../config";

const LogoSelector = ({
  onLogoClick,
  showIntel,
  showAMD,
  open,
  handleClose,
}) => {
  const logosList = useSelector((store) => store.logos);
  const [selectedLogos, setSelectedLogos] = useState([]);
  const [filter, setFilter] = useState("all");

  // Toggle logo selection
  const handleLogoSelect = (logo) => {
    if (selectedLogos.includes(logo)) {
      setSelectedLogos(selectedLogos.filter((l) => l.id !== logo.id));
    } else {
      setSelectedLogos([...selectedLogos, logo]);
    }
  };

  // Call onLogoClick with all selected logos
  const handleConfirmSelection = () => {
    onLogoClick(selectedLogos);
    handleClose();
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    console.log(`Filter changed to: ${event.target.value}`);
  };

  // Filter logos based on the provided flags and selected filter
  const filteredLogos = logosList.filter((logo) => {
    console.log(`Logo: `, logo); // Log each logo object for debugging
    if (filter === "positive") {
      console.log(`Filtering for positive logos: ${logo.data.flags.positive}`);
      return logo.data.flags.positive === true;
    }
    if (filter === "negative") {
      console.log(`Filtering for negative logos: ${logo.data.flags.positive}`);
      return logo.data.flags.positive === false;
    }
    return true; // If no specific filter, show all logos
  });

  console.log(`Filtered logos: `, filteredLogos);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: 550, // Increased height to accommodate filter options
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          backgroundColor: "gray",
        }}
      >
        <FormControl component='fieldset' sx={{ marginBottom: 2 }}>
          <RadioGroup
            row
            aria-label='filter'
            name='filter'
            value={filter}
            onChange={handleFilterChange}
          >
            <FormControlLabel value='all' control={<Radio />} label='All' />
            <FormControlLabel
              value='positive'
              control={<Radio />}
              label='Positive'
            />
            <FormControlLabel
              value='negative'
              control={<Radio />}
              label='Negative'
            />
          </RadioGroup>
        </FormControl>

        <ImageList cols={6} gap={2} sx={{ width: "100%", height: "80%" }}>
          {filteredLogos.map((logo) => (
            <ImageListItem
              key={logo.id}
              onClick={() => handleLogoSelect(logo)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
                margin: 2,
                position: "relative",
              }}
            >
              <img
                src={`${ASSETS_URL}/${logo.data.fileName}`}
                alt={`Logo ${logo.id}`}
                loading='lazy'
                style={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
              {selectedLogos.includes(logo) && (
                <IconButton
                  color='success'
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              )}
            </ImageListItem>
          ))}
        </ImageList>
        <Box textAlign='center' marginTop={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirmSelection}
          >
            Confirm Selection
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoSelector;
